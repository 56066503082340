import { Box, Flex, Icon, Img, Text, useToast } from "@chakra-ui/react"
import { ExamenInt } from "../../../../../../interfaces/ExamenInt";
import { InformationBox } from "../../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { CustomSwitch } from "../../../../../../shared/components/CustomElements/CustomSwitch";
import { InformationFilepond } from "../../../../../../shared/components/Information/InformationFilepond";
import { useClient } from "../../../../../../shared/context/client.context";
import { BiLock, BiPencil } from "react-icons/bi";
import { useEffect, useState } from "react";
import { InformationTextEditor } from "@imaginagroup/bit-components.ui.elements.information-text-editor"
import { InformationTimePicker } from "../../../../../../shared/components/Information/InformationTimePicker";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { SkeletonPerfilExamenes } from "../../../../components/utils/ExamenesPerfilSkeleton";
import { InformationTextarea } from "@imaginagroup/bit-components.ui.elements.information-textarea";

interface Props {
    examen: ExamenInt | undefined;
    updateValue: (value: Record<string, string | string[] | boolean>) => Promise<void>;
    Refresh: () => void;
    loading: boolean;
}

export const TabPerfilExamen = ({
    examen,
    updateValue,
    Refresh,
    loading
}: Props) => {
    const toast = useToast();
    const client = useClient();
    const [editNombre, setEditNombre] = useState<boolean>(false);
    const [editDuracion, setEditDuracion] = useState<boolean>(false);
    const [editIntentos, setEditIntentos] = useState<boolean>(false);
    const [editEstado, setEditEstado] = useState<boolean>(false);
    const [estado, setEstado] = useState<boolean>();

    useEffect(() => {
        examen?.publicado === null || examen?.publicado === undefined
            ? setEstado(false)
            : setEstado(examen?.publicado)
    }, [examen])

    const updateEstado = () => {
        if (
            !examen?.id ||
            estado === null ||
            estado === undefined
        ) return

        updateValue({ publicado: !estado })
    }

    return (
        loading ?
            <SkeletonPerfilExamenes />
            :

            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox title="Examen">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={examen?.nombre}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <Flex w="100%">
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="curso"
                                        label="Curso"
                                        defaultValue={examen?.curso?.nombre || ""}
                                        updateValue={updateValue}
                                        isDisabled={true}
                                    />
                                </Flex>

                                <Icon as={BiLock} boxSize="20px" />
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                pr="18px"
                                onMouseEnter={() => setEditEstado(true)}
                                onMouseLeave={() => setEditEstado(false)}
                            >
                                <CustomSwitch
                                    label="Estado"
                                    optionLeft="Publicado"
                                    optionRight="Oculto"
                                    setValue={setEstado}
                                    value={estado}
                                    colorSwitch="light_grey"
                                    onClick={updateEstado}
                                />

                                <Flex boxSize="22px">
                                    {editEstado && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDuracion(true)}
                                onMouseLeave={() => setEditDuracion(false)}
                                gap="10px"
                            >
                                <InformationTimePicker
                                    name="duracion"
                                    label="horas"
                                    defaultValue={examen?.duracion}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editDuracion && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditIntentos(true)}
                                onMouseLeave={() => setEditIntentos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="numIntentos"
                                    label="Cantidad de intentos"
                                    type="number"
                                    min={0}
                                    defaultValue={examen?.numIntentos}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editIntentos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <InformationBox
                        title="Portada"
                    >
                        <>
                            {examen?.imagen?.url &&
                                <Img
                                    p="20px"
                                    m="auto"
                                    h="full"
                                    boxSize="100%"
                                    rounded="10px"
                                    src={examen?.imagen?.url}
                                />
                            }

                            <Flex
                                p="10px 20px"
                            >
                                <InformationFilepond
                                    noHead={true}
                                    name="imagen"
                                    label="Portada"
                                    putEP={`${client}/examenes/${examen?.id}`}
                                    isDisabled={!examen?.id}
                                    setRefresh={Refresh}
                                />
                            </Flex>
                        </>

                    </InformationBox>
                </Flex>

                <Flex
                    direction="column"
                    flex="1"
                >
                    <InformationBox
                        title="Instrucciones"
                    >
                        <Flex
                            px="20px"
                            pb="20px"
                            fontSize="15px"
                            color="font"
                            fontWeight="500"
                            lineHeight="20px"
                        >
                            <Box as="ul" pl="20px" pt="10px" listStyleType="disc">
                                <Box as="li" mb="10px">Para que los alumnos tengan acceso al examen, debes asociarlo a un módulo o curso.</Box>
                                <Box as="li" mb="10px">Si quieres asociar un examen a un módulo debes hacerlo desde el contenido del curso en el módulo deseado.</Box>
                                <Box as="li" mb="10px">Si lo asocias a un módulo, podrán rendirlo a la finalización del mismo.</Box>
                                <Box as="li" mb="10px">Si quieres asociar un examen a un curso debes hacerlo desde el perfil del curso.</Box>
                                <Box as="li" mb="10px">Si lo asocias a un curso, podrán rendirlo una vez superado el 75% del mismo.</Box>
                                <Box as="li">Un examen solo puede pertenecer a un curso o módulo. Si no ves el examen al intentar asociarlo, es porque ya está vinculado a otro curso o módulo.</Box>
                            </Box>
                        </Flex>
                    </InformationBox>

                    <InformationBox title="Descripción">
                        <Box
                            p="20px"
                            pt="10px"
                        >
                            <InformationTextarea
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                name="descripcion"
                                placeholder="No hay descripción"
                                defaultValue={examen?.descripcion || ""}
                                updateValue={updateValue}
                                isDisabled={false}
                            />
                        </Box>
                    </InformationBox>
                </Flex>
            </Flex>
    )
}