import { Flex, Progress, Text, Link, Button, Icon } from "@chakra-ui/react";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate";
import { InscripcionesInt } from "../../../../../../interfaces/InscripcionesInt";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { BiBookReader } from "react-icons/bi";

interface Props {
    onEventConvertirInscripcion: (inscripcion: InscripcionesInt) => void;
    rechazarInscripcion: (inscripcionId: string | undefined) => Promise<void>;
}

export const InscripcionesColumns = ({
    onEventConvertirInscripcion,
    rechazarInscripcion
}: Props): ColumnProps[] => {

    const columns: ColumnProps[] = [
        {
            header: 'nombre',
            field: 'users',
            key: 'nombre',
            style: { width: "250px" },
            body: (rowData: InscripcionesInt) => (
                <ColumnSimpleText
                    text={rowData?.nombre + " " + rowData?.apellidos}
                />
            )
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: InscripcionesInt) => (
                <ColumnSimpleText text={rowData?.email || '---'} />
            )
        },
        {
            header: 'Teléfono',
            field: 'telefono',
            key: 'telefono',
            body: (rowData: InscripcionesInt) => (
                <ColumnSimpleText text={rowData?.telefono || '---'} />
            )
        },
        {
            header: 'DNI',
            field: 'dni',
            key: 'dni',
            body: (rowData: InscripcionesInt) => (
                <ColumnSimpleText text={rowData?.dni || '---'} />
            )
        },
        {
            header: 'Curso',
            field: 'curso',
            key: 'curso',
            body: (rowData: InscripcionesInt) => (
                <ColumnSimpleText text={rowData?.curso?.nombre || '---'} />
            )
        },
        {
            header: "Pendiente",
            field: "pendiente",
            key: "pendiente",
            body: (rowData: InscripcionesInt) => (
                <ColumnBadge
                    text={rowData?.pendiente ? "Si" : "No"}
                    ui={{
                        backgroundColor: !rowData?.pendiente ? "success_main" : "warning",
                        colorFont: !rowData?.pendiente ? "success_bg" : "warning_bg",
                        fontSize: "12px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: !rowData?.pendiente ? "success_variant" : "warning_variant",
                            textTransform: "uppercase",
                            borderWidth: "1px"
                        }
                    }}
                />
            )
        },
        {
            header: "Aceptado",
            field: "aceptado",
            key: "aceptado",
            body: (rowData: InscripcionesInt) => (
                <ColumnBadge
                    text={rowData?.aceptado ? "Si" : "No"}
                    ui={{
                        backgroundColor: rowData?.aceptado ? "success_main" : "fail_main",
                        colorFont: rowData?.aceptado ? "success_bg" : "fail_bg",
                        fontSize: "12px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.aceptado ? "success_variant" : "fail_variant",
                            textTransform: "uppercase",
                            borderWidth: "1px"
                        }
                    }}
                />
            )
        },
        {
            header: 'Inscripción',
            field: 'createdAt',
            key: 'createdAt',
            body: (rowData: InscripcionesInt) => (
                <ColumnDate
                    date={rowData?.createdAt}
                />
            )
        },
        {
            header: '',
            field: 'matricular',
            key: 'matricular',
            body: (rowData: InscripcionesInt) => (
                <Flex
                    px="16px"
                    gap="10px"
                >
                    <Button
                        bg="main"
                        color="purewhite"
                        _hover={{
                            bg: "secondary"
                        }}
                        w="fit-content"
                        h="fit-content"
                        py="8px"
                        px="15px"
                        fontSize="14px"
                        fontWeight="500"
                        rounded="14px"
                        leftIcon={
                            <Icon
                                as={BiBookReader}
                                boxSize="20px"
                            />
                        }
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();

                            onEventConvertirInscripcion(rowData);
                        }}
                    >
                        Matricular
                    </Button>

                    <Button
                        bg="main"
                        color="purewhite"
                        _hover={{
                            bg: "secondary"
                        }}
                        w="fit-content"
                        h="fit-content"
                        py="8px"
                        px="15px"
                        fontSize="14px"
                        fontWeight="500"
                        rounded="14px"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();

                            rechazarInscripcion(rowData?.id)
                        }}
                    >
                        Rechazar
                    </Button>
                </Flex>
            )
        },
    ];

    return columns
}