import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, ModalCloseButton, Text, Switch, FormLabel, useDisclosure, Tooltip } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { getGuiaInicio } from "../../../../shared/middlewares/informes.middleware";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { NewSupervisorModal } from "../../../Usuarios/components/Modals/NewSupervisorModal";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    grupo: GruposInt | undefined;
}
export const GuiaInicioModal = ({
    isOpen,
    onClose,
    grupo,
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [empresaId, setEmpresaId] = useState<string | null>(null);
    const { isOpen: isOpenNewSupervisor, onOpen: onOpenNewSupervisor, onClose: onCloseNewSupervisor } = useDisclosure();

    useEffect(() => {
        if (grupo?.empresas && grupo?.empresas?.length === 1) setEmpresaId(grupo?.empresas[0]?.id as string);
    }, [grupo])

    const initialValues = {
        email: null,
        userId: null,
        testGuia: false,
        empresaId: null,
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("El email no es válido")
            .notRequired()
            .nullable(),
        userId: Yup.string().when("email", {
            is: (email: string) => !email,
            then: (validationScheme) => validationScheme.required("Debe asignar un supervisor"),
            otherwise: (validationScheme) => validationScheme.notRequired()
        }),
        testGuia: Yup.boolean().notRequired(),
        empresaId: Yup.string().notRequired()
    });

    const handleSubmit = async (values: any) => {
        if (!grupo?.id || !grupo?.empresas || grupo?.empresas?.length === 0) return toastNotify(toast, "No se ha podido enviar la guía de inicio", StatusEnumTypes.ERROR);
        setIsLoading(true);

        let data: {
            grupoId: string;
            empresaId: string;
            debug: boolean;
            email: string | null;
            userId: string | null;
        } = {
            grupoId: grupo?.id,
            empresaId: empresaId ? empresaId : grupo?.empresas[0]?.id as string,
            debug: true,
            email: (values?.testGuia && values?.email) ? values?.email : null,
            userId: (values?.testGuia && values?.email) ? null : values?.userId,
        }

        if (values.email) data = { ...data, email: values.email }

        getGuiaInicio({
            data,
            client
        }).then(() => {
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Se ha enviado la guía de inicio correctamente");
            onClose();
        })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const loadSupervisores = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.SUPERVISORES,
        query: QueryTypes.NOMBRE_EMAIL,
        nombreCompleto: true
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="purewhite"
                        bg="black"
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                        >
                            Inicio FUNDAE
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onClose()} />
                    </Box>

                    <Box
                        m="10px"
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            display="flex"
                                            flexDirection="column"
                                            gap="10px"
                                        >
                                            {grupo?.empresas && grupo?.empresas?.length === 1 ?
                                                <Flex
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Text
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                        color="font"
                                                    >
                                                        Los datos de inicio fundae corresponden a la empresa:
                                                    </Text>

                                                    <Text
                                                        fontSize="18px"
                                                        fontWeight="700"
                                                        color="secondary"
                                                    >
                                                        {grupo?.empresas[0]?.nombre}
                                                    </Text>
                                                </Flex>
                                                :
                                                <Flex
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <FormLabel
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                        color="font"
                                                    >
                                                        Los datos de inicio fundae corresponden a la empresa:
                                                    </FormLabel>

                                                    <FormSelect
                                                        name="empresaId"
                                                        noHead
                                                        placeholder="Seleccione una empresa"
                                                        options={grupo?.empresas?.map((empresa: EmpresasInt) => ({
                                                            label: empresa.nombre,
                                                            value: empresa.id
                                                        }))}
                                                        onChangeCallback={(e: { value: string }) => setEmpresaId(e.value)}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                            styleLabel: {
                                                                color: "font",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                            }
                                                        }}
                                                    />
                                                </Flex>
                                            }

                                            <Flex
                                                direction="column"
                                                mt={grupo?.empresas && grupo?.empresas?.length === 1 ? "20px" : "0"}
                                                mb="10px"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    justifyContent="center"
                                                    gap="10px"
                                                    alignItems="center"
                                                >
                                                    <Text
                                                        color="font"
                                                        fontSize="16px"
                                                        fontWeight="600"
                                                    >
                                                        Enviar guía de inicio de prueba
                                                    </Text>

                                                    <Switch
                                                        size="md"
                                                        colorScheme="black"
                                                        sx={{
                                                            'span.chakra-switch__track': {
                                                                bg: 'gray.200',
                                                            },
                                                            '&[data-checked] span.chakra-switch__track': {
                                                                bg: 'main',
                                                            }
                                                        }}
                                                        isChecked={values.testGuia}
                                                        onChange={() => formik.setFieldValue("testGuia", !values.testGuia)}
                                                    />
                                                </Flex>

                                                <Text
                                                    color="main"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                >
                                                    Si envías la guía de inicio de prueba no se hará tracking del email enviado.
                                                </Text>
                                            </Flex>

                                            {!values.testGuia ?
                                                <Flex
                                                    direction="column"
                                                    mb="20px"
                                                >
                                                    <FormAsyncSelect
                                                        name="userId"
                                                        label="¿A quién quieres enviar la guía de inicio?"
                                                        placeholder="Seleccione un supervisor"
                                                        loadOptions={loadSupervisores}
                                                        defaultOptions
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                            styleLabel: {
                                                                color: "font",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                            }
                                                        }}
                                                    />

                                                    <Flex alignItems="center" gap="20px">
                                                        <Text
                                                            color="#67748E"
                                                            fontSize="13px"
                                                            fontWeight="500"
                                                            lineHeight="16px"
                                                            letterSpacing="-0.11px"
                                                        >
                                                            ¿No encuentras el supervisor?
                                                        </Text>

                                                        <Tooltip
                                                            label="Debes seleccionar una empresa para crear un supervisor"
                                                            isDisabled={!!empresaId}
                                                            placement="right"
                                                            fontSize="13px"
                                                            fontWeight="500"
                                                            color="purewhite"
                                                            bg="pureblack"
                                                            rounded="4px"
                                                            py="5px"
                                                            px="10px"
                                                        >
                                                            <Text
                                                                cursor="pointer"
                                                                color="black"
                                                                fontSize="13px"
                                                                fontWeight="600"
                                                                letterSpacing="-0.11px"
                                                                opacity={!empresaId ? 0.5 : 1}
                                                                onClick={(e: React.MouseEvent) => {
                                                                    if (!empresaId) return;
                                                                    e.stopPropagation();

                                                                    onOpenNewSupervisor()
                                                                }}
                                                                _hover={{ textDecoration: "underline" }}
                                                            >
                                                                Crear supervisor
                                                            </Text>
                                                        </Tooltip>
                                                    </Flex>
                                                </Flex>
                                                :
                                                <FormInput
                                                    name="email"
                                                    label="¿Dónde quieres recibir la guía de inicio?"
                                                    placeholder="Email"
                                                />
                                            }
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="black"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="600"
                                                _hover={{ color: "black", backgroundColor: "light_grey" }}
                                            >
                                                Enviar
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <NewSupervisorModal
                isOpen={isOpenNewSupervisor}
                onClose={onCloseNewSupervisor}
                idEmpresa={empresaId}
            />
        </>
    )
}