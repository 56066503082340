import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { HiCursorClick, HiOutlineMailOpen, HiOutlinePaperAirplane } from "react-icons/hi";
import { AiOutlineStop } from "react-icons/ai";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";

enum ComunicacionesTypes {
    DELIVERY = "delivery",
    OPEN = "open",
    CLICK = "click",
    BOUNCE = "bounce",
    INITIAL_OPEN = "initial_open"
}

export const LogsColumns = (): ColumnProps[] => {
    const validateKey = (object: object, key: ComunicacionesTypes): boolean => Reflect.has(object || {}, key)
    const timeZone = useTimeZone();

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.user?.nombre + rowData?.user?.apellidos} src={rowData?.user?.avatar?.url} size="35px" />
                    }
                />
            ),
        },
        {
            header: 'destinatario',
            field: 'destinatario',
            key: 'destinatario',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.destinatario}
                />
            ),
        },
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.grupo ? rowData?.grupo?.curso?.nombre : rowData?.matricula?.grupo?.curso?.nombre || "---"}
                    text={rowData?.grupo ? rowData?.grupo?.nombre : rowData?.matricula?.grupo?.nombre || "---"}
                    tooltip={{
                        isDisabled: !rowData?.matricula?.fechaInicio || !rowData?.matricula?.fechaFin,
                        label: (
                            <Flex
                                direction="column"
                                alignItems="center"
                                gap="5px"
                                color="purewhite"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {(rowData?.matricula?.fechaInicio && rowData?.matricula?.fechaFin) &&
                                    <>
                                        <Text>Fecha inicio: {formatTimezone({ date: rowData?.matricula?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                                        <Text>Fecha fin: {formatTimezone({ date: rowData?.matricula?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                                    </>
                                }
                            </Flex>
                        )
                    }}
                />
            )
        },
        {
            header: 'asunto',
            field: 'asunto',
            key: 'asunto',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.asunto}
                />
            ),
        },
        {
            header: 'Fecha envío',
            field: 'fechaEnvio',
            key: 'fechaEnvio',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    isDateTime
                    date={rowData?.fechaEnvio}
                />
            )
        },
        {
            header: 'estado de mensaje',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <Flex
                    gap="15px"
                >
                    {validateKey(rowData?.eventos, ComunicacionesTypes.BOUNCE) &&
                        <Tooltip
                            label={
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Text>Devuelto</Text>
                                    <Text>{formatTimezone({ date: rowData?.eventos?.[ComunicacionesTypes.BOUNCE], timeZone, customFormat: "dd/MM/yyyy HH:mm" })}</Text>
                                </Flex>
                            }
                            hasArrow
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                            >
                                <Icon
                                    as={AiOutlineStop}
                                    boxSize="30px"
                                    color="font"
                                    _hover={{ color: "main" }}
                                />
                            </Flex>
                        </Tooltip>
                    }

                    {validateKey(rowData?.eventos, ComunicacionesTypes.DELIVERY) &&
                        <Tooltip
                            label={
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Text>Enviado</Text>
                                    <Text>{formatTimezone({ date: rowData?.eventos?.[ComunicacionesTypes.DELIVERY], timeZone, customFormat: "dd/MM/yyyy HH:mm" })}</Text>
                                </Flex>
                            }
                            hasArrow
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                            >
                                <Icon
                                    as={HiOutlinePaperAirplane}
                                    boxSize="30px"
                                    color="font"
                                    _hover={{ color: "main" }}
                                />
                            </Flex>
                        </Tooltip>
                    }

                    {validateKey(rowData?.eventos, ComunicacionesTypes.INITIAL_OPEN) &&
                        <Tooltip
                            label={
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Text>Apertura inicial</Text>
                                    <Text>{formatTimezone({ date: rowData?.eventos?.[ComunicacionesTypes.INITIAL_OPEN], timeZone, customFormat: "dd/MM/yyyy HH:mm" })}</Text>
                                </Flex>
                            }
                            hasArrow
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                            >
                                <Icon
                                    as={MdOutlineMarkEmailUnread}
                                    boxSize="30px"
                                    color="font"
                                    _hover={{ color: "main" }}
                                />
                            </Flex>
                        </Tooltip>
                    }

                    {(validateKey(rowData?.eventos, ComunicacionesTypes.OPEN) &&
                        validateKey(rowData?.eventos, ComunicacionesTypes.OPEN) !==
                        validateKey(rowData?.eventos, ComunicacionesTypes.INITIAL_OPEN)
                    ) &&
                        <Tooltip
                            label={
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Text>Abierto</Text>
                                    <Text>{formatTimezone({ date: rowData?.eventos?.[ComunicacionesTypes.OPEN], timeZone, customFormat: "dd/MM/yyyy HH:mm" })}</Text>
                                </Flex>
                            }
                            hasArrow
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                            >
                                <Icon
                                    as={HiOutlineMailOpen}
                                    boxSize="30px"
                                    color="font"
                                    _hover={{ color: "main" }}
                                />
                            </Flex>
                        </Tooltip>
                    }
                    {validateKey(rowData?.eventos, ComunicacionesTypes.CLICK) &&
                        <Tooltip
                            label={
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Text>Interacción</Text>
                                    <Text>{formatTimezone({ date: rowData?.eventos?.[ComunicacionesTypes.CLICK], timeZone, customFormat: "dd/MM/yyyy HH:mm" })}</Text>
                                </Flex>
                            }
                            hasArrow
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                            >
                                <Icon
                                    as={HiCursorClick}
                                    boxSize="30px"
                                    color="font"
                                    _hover={{ color: "main" }}
                                />
                            </Flex>
                        </Tooltip>
                    }
                </Flex>
            )
        },
    ]
}