import { Badge, Flex, Text } from "@chakra-ui/react"
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs"
import { ColumnProps } from "../../../../shared/components/Table/Table"
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon"
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"

export const GruposSupervisores = (): ColumnProps[] => {
    const timeZone = useTimeZone();

    return [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'duracion',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start" ml="16px" py={"16px"}>
                    <Badge
                        color={
                            rowData?.estado == 2 ? "font" :
                                rowData?.estado == 1 ? "#EEEDFA"
                                    : "success_main"
                        }
                        bg={
                            rowData?.estado == 2 ? "light_grey" :
                                rowData?.estado == 1 ? "secondary"
                                    : "success_bg"
                        }
                        borderColor={
                            rowData?.estado == 2 ? "light_grey" :
                                rowData?.estado == 1 ? "secondary"
                                    : "success_variant"
                        }
                        borderWidth="1px"
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="14px"
                        p="2px 5px"
                    >
                        {
                            rowData?.estado == 2 ? "Inactivo" :
                                rowData?.estado == 1 ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>
                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            sortable: true,
            body: (rowData: any) => (
                rowData?.fundae
                    ?
                    <ColumnIcon
                        icon={BsCheckCircleFill}
                        ui={{
                            color: "#E9B418",
                            boxSize: "18px"
                        }}
                    />
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                    />
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.nombre}
                    text={rowData?.curso?.nombre}
                />
            )
        },
        {
            header: 'Matrículas totales',
            field: 'matriculasTotales',
            key: 'matriculasTotales',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculas || 0) + " matrículas"}
                />
            )
        },
    ]
}