import { Flex, Skeleton } from "@chakra-ui/react";


export default function CursosPerfilSkeleton ( {moduloFundae} : {moduloFundae:boolean} ) {

    return (
        <Flex gap="20px">
            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="569px" rounded="10px"></Skeleton>
                <Skeleton height="409px" rounded="10px"></Skeleton>
            </Flex>

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="425px" rounded="10px"></Skeleton>
                {moduloFundae && 
                    <Skeleton height="256px" rounded="10px"></Skeleton>
                }
            </Flex>
        </Flex>
    )
}
