import { Flex, Input, FormLabel, InputGroup, InputRightElement, Icon, Box, Tooltip } from '@chakra-ui/react';
import { BiLock } from 'react-icons/bi';
import { RxCross1 } from "react-icons/rx";

export interface CustomShowInputProps {
    label?: string;
    type?: string;
    noHead?: boolean;
    value: any;
    placeholder?: string;
    style?: React.CSSProperties;
    autoComplete?: 'on' | 'off';
    min?: number;
    max?: number;
    step?: number;
    inputStyles?: React.CSSProperties;
    isChangeable?: boolean;
    valueChange?: (value: null) => void;
    onUnblock?: () => void;
    tooltip?: string;
}

export const CustomShowInput = ({
    noHead,
    label,
    type = 'text',
    placeholder = '',
    min,
    max,
    step,
    autoComplete,
    style,
    inputStyles = {},
    value,
    isChangeable = false,
    valueChange = (value: null) => { },
    onUnblock,
    tooltip = "Desbloquear campo",
}: CustomShowInputProps) => {

    return (
        <Box style={style}>
            {!noHead && (
                <FormLabel
                    display="flex"
                    gap="3px"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    textTransform="capitalize"
                >
                    {label}
                </FormLabel>
            )}

            <InputGroup pos="relative">
                <Input
                    borderRadius="5px"
                    color="black"
                    bgColor="purewhite"
                    borderColor="light_grey"
                    _focusVisible={{ bg: "#FFFFFF" }}
                    min={min}
                    max={max}
                    type={type}
                    step={step}
                    value={value}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    isDisabled={true}
                    style={inputStyles}
                    _placeholder={{
                        color: "dark_grey",
                        fontSize: "13px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        letterSpacing: "-0.078px",
                    }}
                    _disabled={{
                        color: "black",
                        cursor: !isChangeable ? "not-allowed" : "default",
                    }}
                />

                {!isChangeable ?
                    <InputRightElement>
                        <Tooltip
                            label={tooltip}
                            hasArrow
                            bg="pureblack"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="400"
                            borderRadius="5px"
                            px="10px"
                            py="5px"
                            placement="bottom"
                            textAlign="center"
                        >
                            <Flex>
                                <Icon
                                    as={BiLock}
                                    boxSize="24px"
                                    cursor={onUnblock ? "pointer" : "not-allowed"}
                                    onClick={onUnblock && onUnblock}
                                />
                            </Flex>
                        </Tooltip>
                    </InputRightElement>
                    :
                    <InputRightElement>
                        <Icon
                            as={RxCross1}
                            boxSize="20px"
                            cursor="pointer"
                            onClick={() => { isChangeable ? valueChange(null) : null }}
                        />
                    </InputRightElement>
                }
            </InputGroup>
        </Box>
    );
};
