import { Flex, Progress, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { BiMedal } from "react-icons/bi";
import TiempoFormato from "../../../../../../shared/utils/functions/formatTime";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";

export const AtencionColumns = (): ColumnProps[] => {
    const { user } = useAuthContex();

    const validateRequisitos = (progreso: number, puntuacion: number) => {
        if (!progreso || !puntuacion) return false

        if (progreso >= 75 && puntuacion >= 75) return true
        else return false
    }

    const getHoraProcentaje = (tiempoAcceso: number, duracionCurso: number) => {
        if (!tiempoAcceso || !duracionCurso) return 0

        const tiempo = tiempoAcceso / 3600;

        const porcentaje = (tiempo / duracionCurso) * 100;

        return parseInt(porcentaje.toFixed(0)) <= 100 ? parseInt(porcentaje.toFixed(0)) : 100
    }

    return [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.nombre + ' ' + rowData?.user?.apellidos}
                    icon={{
                        name: BiMedal,
                        color: validateRequisitos(rowData?.progresoLecciones, rowData?.puntuacionMedia)
                            ? "main"
                            : "dark_grey",
                        size: "20px"
                    }}
                    link={{
                        to: `/matriculas/${rowData?.id}/perfil`,
                    }}
                />
            )
        },
        {
            header: 'grupo',
            field: 'grupo',
            key: 'grupo',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.grupo?.nombre}
                    link={{
                        to: `/matriculas/${rowData?.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: '% actividades completadas',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/matriculas/${rowData?.id}/perfil`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex gap="5px" alignItems="center" pr="10%" px="16px">
                        <Text
                            color={rowData?.progresoLecciones < 75 ? "fail" : "font"}
                            fontSize="14px"
                            fontWeight="400"
                            textAlign="center"
                        >
                            {(rowData?.progresoLecciones).toFixed(0)} %
                        </Text>

                        <Progress value={rowData?.progresoLecciones || 0}
                            flex="1"
                            h="7px"
                            rounded="10px"
                            sx={{ '& > div': { background: "secondary" } }} />
                    </Flex>
                </Link>
            )
        },
        {
            header: '% ejercicios completados',
            field: 'progresoEntregables',
            key: 'progresoEntregables',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/matriculas/${rowData?.id}/perfil`}

                    _hover={{ textDecoration: "none" }}
                >
                    <Flex gap="5px" alignItems="center" pr="10%" px="16px">
                        <Text
                            color={rowData?.progresoEntregables < 75
                                ? "fail"
                                : "success"
                            }
                            fontSize="14px"
                            fontWeight="400"
                            textAlign="center"
                        >
                            {(rowData?.progresoEntregables).toFixed(0)} %
                        </Text>

                        <Progress
                            value={rowData?.progresoEntregables || 0}
                            flex="1"
                            h="7px"
                            rounded="10px"
                            sx={{ '& > div': { background: "#E21" } }} />
                    </Flex>
                </Link>
            )
        },
        {
            header: 'nota media',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.puntuacionMedia || 0 + "/100"}
                    ui={{
                        colorFont: rowData?.puntuacionMedia || 0 < 75 ? "fail" : "success",
                    }}
                    link={{
                        to: `/matriculas/${rowData?.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: 'tiempo de acceso (horas/%)',
            field: 'tiempoAcceso',
            key: 'tiempoAcceso',
            sortable: true,
            body: (rowData: any) => (
                <Link as={RouterLink}
                    to={`/matriculas/${rowData?.id}/perfil`}

                    _hover={{ textDecoration: "none" }}
                >
                    <Flex
                        justifyContent="center"
                        color="font"
                        gap="5px"
                    >
                        <Text fontWeight="700">
                            <TiempoFormato tiempoAcceso={rowData?.tiempoAcceso} />{' '}
                        </Text>

                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="font"
                        >
                            h / {getHoraProcentaje(rowData?.tiempoAcceso, rowData?.grupo?.curso?.duracion)}<Text fontSize="14px" as="span"> %</Text>
                        </Text>


                    </Flex>
                </Link>
            )
        },
    ];
}