import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { useState } from "react";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { addTenant } from "../../../shared/middlewares/tenant.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    Refresh: () => void;
}
export const NewTenantModal = ({ isOpen, onClose, Refresh }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    const initialValues = {
        nombre: null,
        slug: null,
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        slug: Yup.string()
            .required('El slug es obligatorio.')
            .typeError('El slug es obligatorio.')
            .matches(/^[a-z]+$/, 'El slug solo debe contener letras minúsculas, sin espacios o caracteres especiales.'),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const newTenant = {
            nombre: values.nombre,
            slug: values.slug,
        }

        addTenant({
            data: newTenant
        })
            .then(() => {
                Refresh();
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado la empresa ${newTenant.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nueva empresa
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onClose()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="700"
                                                mb="15px"
                                            >
                                                Datos de la empresa
                                            </Text>

                                            <Flex gap="20px" mb="15px">
                                                <FormInput
                                                    name="nombre"
                                                    label="Nombre"
                                                    placeholder="Nombre"
                                                    isRequired={!values?.nombre ? true : false}
                                                />

                                                <FormInput
                                                    name="slug"
                                                    label="slug"
                                                    placeholder="slug"
                                                    isRequired={!values?.slug ? true : false}
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear empresa
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}