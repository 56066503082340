import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button, Input, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ModalAlert } from "./ModalAlert";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    label: string;
    modalAlert?: {
        onOpen: () => boolean;
        title: string;
    };
}

export const ModalDelete = ({ isOpen, onClose, onDelete, label, modalAlert }: Props) => {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    useEffect(() => {
        if (
            modalAlert?.onOpen() 
            && isOpen
        ) onOpenAlert();
    }, [isOpen]);

    const handleClose = () => {
        setConfirmDelete(false);
        onClose();
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => handleClose()}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="lg"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius="10px"
                >
                    <ModalBody>
                        <Text
                            color="dark-blue"
                            textAlign="center"
                            fontSize="18px"
                            fontWeight="700"
                            my="10px"
                        >
                            ¿Estás segur@ que quieres borrar {label}?
                        </Text>

                        <Text
                            color="font"
                            textAlign="center"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Esta acción no se puede deshacer. Para borrarlo, escribe "borrar {label}" en el siguiente campo.
                        </Text>

                        <Input
                            mt="15px"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (
                                    e.target.value?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').trim().replace(/\s+/g, ' ')
                                    === `borrar ${label}`?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').trim().replace(/\s+/g, ' ')
                                ) {
                                    setConfirmDelete(true);
                                } else {
                                    setConfirmDelete(false);
                                }
                            }}
                        />
                    </ModalBody>

                    <ModalFooter justifyContent="center" gap="15px">
                        <Button
                            type="submit"
                            p="10px 15px"
                            bg="black"
                            rounded="5"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>

                        <Button
                            type="submit"
                            bg="purewhite"
                            p="10px 15px"
                            rounded="5"
                            borderWidth="1px"
                            borderColor="black"
                            color="black"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={onDelete}
                            isDisabled={!confirmDelete}
                        >
                            Borrar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {modalAlert &&
                <ModalAlert
                    isOpen={isOpenAlert}
                    onClose={onCloseAlert}
                    onConfirm={handleClose}
                    title={modalAlert?.title}
                />
            }
        </>
    )
}