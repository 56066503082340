import { Flex } from "@chakra-ui/react"
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar"
import { ColumnProps } from "../../../../../../shared/components/Table/Table"
import { Actions } from "../../../../../../shared/components/TableElements/Actions"
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../../../shared/context/auth.context"
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate"

interface Props {
    contenido: boolean;
    onEventRemove: (event: any) => void;
}

export const AdministradoresColumns = ({
    contenido,
    onEventRemove,
}: Props): ColumnProps[] => {
    const { user } = useAuthContex();

    const columns: ColumnProps[] = [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/usuarios/administradores/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={{
                        to: `/usuarios/administradores/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Tipo',
            field: 'subTipo',
            key: 'subTipo',
            body: (rowData: any) => {
                return (
                    <ColumnSimpleText
                        text={rowData?.subTipo}
                        link={{
                            to: `/usuarios/administradores/${rowData.id}/perfil`,
                        }}
                    />
                )
            },
        },
        {
            header: 'Fecha de creación',
            field: 'createdAt',
            key: 'createdAt',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                    link={{
                        to: `/usuarios/administradores/${rowData?.id}/perfil`
                    }}
                />
            )
        }
    ]

    const actionsColumn: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar administradores",
                            onClick: () => {
                                onEventRemove(rowData)
                            },
                            isDisabled: contenido,
                            disableAlert: "No tienes permisos para esta acción"
                        }}
                    />
                </Flex>
            )
        },
    ]

    return (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) ? [...columns, ...actionsColumn] : columns
}
