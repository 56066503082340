import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import ReactChart from "./charts/ReactChart";
import { HiOutlineChartBar } from "react-icons/hi";
import { DateTime } from "luxon";
import { StatsMatriculasInt } from "../../../interfaces/StatsInt";
import { useTenantInfo } from "../../../shared/hooks/useTenantInfo";

interface Props {
    statsMatriculas: StatsMatriculasInt;
    loading: boolean
    colors?: any;
}

export default function ImportesMatriculas({ loading, statsMatriculas, colors, ...rest }: Props) {
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });

    const groupData = (data: any) => {
        return data?.reduce((acc: any, item: any) => {
            const key = item?.fecha
            if (!acc[key]) {
                acc[key] = {}
            }

            const matriculasFundae = item?.fundae ? Number(item?.total) : 0
            const matriculasNoFundae = !item?.fundae ? Number(item?.total) : 0

            acc[key] = {
                ...acc[key],
                matriculasFundae: matriculasFundae + (acc[key]?.matriculasFundae || 0),
                matriculasNoFundae: matriculasNoFundae + (acc[key]?.matriculasNoFundae || 0),
                matriculasTotales: (matriculasFundae + matriculasNoFundae) + (acc[key]?.matriculasTotales || 0)
            }
            return acc
        }, {})
    }

    const formatedData = () => {
        const formatedMatriculas: any = []
        Object.entries(groupData(statsMatriculas?.data) || {})?.map(([key, value]) => {
            const formatedMes = {
                fecha: DateTime.fromISO(key).setZone("Europe/Madrid").setLocale('es').toFormat('MMM').toUpperCase(),
                matriculasFundae: (value as any)?.matriculasFundae,
                matriculasNoFundae: (value as any)?.matriculasNoFundae,
                matriculasTotales: (value as any)?.matriculasTotales,
                gap: 10 * 0.05
            }
            formatedMatriculas.push(formatedMes)
        })
        return formatedMatriculas
    }

    const countMatriculas = () => {
        let totalMatriculas = 0;

        formatedData()?.forEach((item: any) => {
            totalMatriculas += item.matriculasTotales
        })

        return totalMatriculas
    }

    return (

        <Flex
            justifyContent="flex-start"
            alignItems="start"
            flexDirection="column"
            w="100%"
            bgColor="purewhite"
            borderWidth={"1px"}
            borderColor={"#DFE2E6"}
            p="24px"
            rounded="14px"
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
            {...rest}
        >
            <Flex
                w="100%"
                display="flex"
                flexDirection="column"
            >

                <Flex
                    justifyContent="space-between"
                    align={"start"}
                >

                    <Flex
                        gap="12px"
                        align={"center"}
                        justify={"end"}
                    >
                        <Flex
                            boxSize={"28px"}
                            borderRadius={"4px"}
                            bgColor={"#F9FAFB"}
                            align={"center"}
                            justify={"center"}
                        >
                            <Icon
                                boxSize={"18px"}
                                as={HiOutlineChartBar}
                                color={"#616675"}
                            />
                        </Flex>

                        <Text
                            color="pureblack"
                            fontSize="18px"
                            textAlign="start"
                            fontWeight="400"
                            lineHeight="120%"
                        >
                            Importes Matrículas
                        </Text>

                    </Flex>

                    <Flex gap="20px" direction="column">

                        Por mes

                    </Flex>
                </Flex>
                <Flex
                    direction="column"
                    justify="space-between"

                >
                    <Text
                        fontSize="32px"
                        fontWeight="500"
                        color="pureblack"
                    >
                        {typeof countMatriculas() === 'number' ? countMatriculas().toLocaleString('es-ES') : countMatriculas()} €
                    </Text>
                </Flex>

                {countMatriculas() > 0 ?
                    <Box
                        mr="30px"
                        mt="40px"
                    >
                        <ReactChart
                            data={formatedData()}
                            colors={colores}
                            isImporte
                        />
                    </Box>
                    : !loading &&
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        w="100%"
                        h="200px"
                    >
                        <Text
                            fontSize="22px"
                            fontWeight="500"
                            color="main"
                        >
                            Para ver las estadísticas, debe haber matrículas existentes
                        </Text>
                    </Flex>
                }
            </Flex>
        </Flex>

    );
}
