import { useClient } from '../context/client.context';
import { EndpointTypes } from '../utils/Types/EndpointTypes';
import { useDataId } from './useDataId';

export const useTimeZone = (): string => {
    const client = useClient();

    const { data } = useDataId({
        client: client,
        endpoint: EndpointTypes.CAMPUS_CONFIG_KEY,
        id: 'timeZone'
    })

    return data
};