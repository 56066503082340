import { Avatar } from '@chakra-ui/react';

interface AvatarProps {
  src?: string | null;
  name: string;
  size?: string;
  onClick?: (...args: any[]) => void;
}

export const CustomAvatar = ({
  src,
  name,
  size,
  onClick = (...args: any[]) => { },
  ...props
}: AvatarProps) => {

  const boringURL = `https://source.boringavatars.com/beam/120/${name}?colors='BB4275,FBC865,EB915F,D36D56,9C68C6'}`;

  return (
    <Avatar
      src={src || name}
      {...props}
      minW={size}
      minH={size}
      boxSize={size}
      onClick={onClick}
    />
  );
};