import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { BiCloudUpload, BiFile, BiFileFind, BiTrashAlt } from 'react-icons/bi';
import { Button, Flex, Icon, Progress, Text, useToast } from '@chakra-ui/react';
import { passTypeFile } from '../../utils/functions/passTypeFile';
import { toastNotify } from '../../utils/functions/toastNotify';
import { StatusEnumTypes } from '../../utils/Types/StatusEnumTypes';

interface FileUploaderProps {
  files: File[];
  maxFiles?: number;
  maxSize?: string;
  supportedFormats?: string[];
  isUploading?: boolean;
  hideDropZone?: boolean;
  onDeleteFile?: (file: File) => void;
  onLoadedFiles?: (files: File[]) => void;
  label?: string;
}

const emptyFunc = () => { };
const regex = /(^\d+)([T|G|M|K])B/;

export const FileUploader = ({
  files,
  maxFiles = 1,
  maxSize = '2MB',
  supportedFormats = ['.png'],
  isUploading = false,
  onDeleteFile = emptyFunc,
  onLoadedFiles = emptyFunc,
  hideDropZone = false,
  label = "Subir documento PDF"
}: FileUploaderProps) => {
  const toast = useToast();

  const onDrop = useCallback(async (acceptedFiles: any, fileRejections: any) => {
    fileRejections.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          toastNotify(toast, StatusEnumTypes.ERROR, 'El tamaño máximo de los archivos es de ' + maxSize);
        }

        if (err.code === 'file-invalid-type') {
          toastNotify(
            toast,
            StatusEnumTypes.ERROR,
            'No es un tipo de archivo valido. Archivos válidos: ' + supportedFormats.join(',')
          );
        }
      });
    });

    await onLoadedFiles(acceptedFiles);
  }, []);

  const parseSize = (size: string) => {
    const match = size.match(regex);
    let magnitude = 1;

    if (match)
      switch (match[2]) {
        case 'T':
          magnitude = 1000000000000;
          break;
        case 'G':
          magnitude = 1000000000;
          break;
        case 'M':
          magnitude = 1000000;
          break;
        case 'K':
          magnitude = 1000;
          break;
      }

    if (match) return parseInt(match[1]) * magnitude;
    else return 0;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: passTypeFile(supportedFormats),
    maxFiles: maxFiles,
    maxSize: parseSize(maxSize),
  });

  return (
    <Flex gap="10px" direction="column">
      {!hideDropZone && (
        <Flex w="100%" rounded="15px" {...getRootProps()} direction="column" cursor="pointer">
          <Flex w="100%" p="10px 15px" alignItems="center" justifyContent="center" borderRadius="5px" border="1px dashed #7A7D81" gap="8px">
            <Icon boxSize="22px" color="#7A7D81" as={BiCloudUpload} />
            
            <Text
              color="#7A7D81"
              fontSize="13px"
              fontWeight="500"
              lineHeight="16px"
              letterSpacing="-0.078px"
            >
              {label}
            </Text>
          </Flex>

          <input {...getInputProps()} />
        </Flex>
      )}

      {files?.map((file: File, index: number) => (
        <Flex h="45px" w="100%" p="10px 15px" bg="#7A7D81" borderRadius="10px" alignItems="center" key={index}>
          <Icon color="#FFF" boxSize="22px" as={BiFile} />

          <Flex w="100%">
            <Flex
              direction="column"
              w="100%"
              px="10px"
              color="#FFF"
              fontSize="12px"
              fontWeight="500"
            >
              <Text p="0px">
                {file.name}
              </Text>

              {!isUploading ? (
                <Text p="0px">
                  {(file.size / 1000).toFixed(0)} KB
                </Text>
              ) : (
                <Progress colorScheme="whatsapp" rounded="full" boxSize="14px" isIndeterminate />
              )}
            </Flex>
          </Flex>

          <Button
            color="font"
            fontSize="12px"
            fontWeight="500"
            letterSpacing="-0.12px"
            bg="inherit"
            _hover={{ bg:"inherit" }}
            borderRadius="5px"
            p="0"
            onClick={() => onDeleteFile(file)} isDisabled={!!files?.find((f) => +(f.size / 1048576).toFixed(1) > 20)}
          >
            <Icon as={BiTrashAlt} boxSize="22px" color="#FFF" />
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};