import {
  Flex,
  Box,
  Text,
  Icon,
  Progress,
} from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./UltimasMatriculas.scss"
import { useNavigate } from "react-router-dom";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { fmtSndsHourMin } from "../../../shared/utils/functions/fmtSnds";


export interface ColumnProps {
  stats: any;
}

export default function TopPerformers({ stats }: ColumnProps) {
  const navigate = useNavigate();

  return (
    <Flex
      className="matriculas"
      direction="column"
      width="100%"
      bgColor="purewhite"
      p="24px"
      rounded="14px"
      border="1px"
      borderColor="#DFE2E6"
      boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
      gap="28px"
      maxH="670px"
    >
      <Flex
        justifyContent="space-between"
        align="start"

      >
        <Flex
          align="center"
          justify="center"
          gap="12px"
        >
          <Flex
            bgColor="#F9FAFB"
            boxSize="28px"
            borderRadius="4px"
            align="center"
            justify="center"
          >
            <Icon
              boxSize="18px"
              color="#616675"
              as={HiOutlineClipboardCopy}
            />
          </Flex>
          <Text
            color="black"
            fontSize="18px"
            fontWeight="400"
            lineHeight="120%"

          >
            TOP Rendimiento
          </Text>
        </Flex>
        <Text
          color="secondary"
          fontSize="16px"
          fontWeight="400"
          cursor="pointer"
          onClick={() => navigate("/matriculas/matriculas")}
        >
          Ver todo
        </Text>

      </Flex>

      <Box
        className="matriculasTable"
        maxW="100%"
        overflowX="hidden"
      >
        <DataTable
          className="dataTableMatriculas"
          value={stats}
          resizableColumns
          columnResizeMode="fit"
          onRowClick={({ data }) => navigate(`/matriculas/${data.id}/perfil`)}

        >

          <Column field="nombre_completo" header="Nombre" />

          <Column field="total_cursos" header="Cursos" />

          <Column header="Puntuación" body={(rowData: any) => (
            <Text>
              {((rowData?.puntuacion_total / rowData?.total_cursos).toFixed(2) === '0.00') ? '0' : (rowData?.puntuacion_total / rowData?.total_cursos).toFixed(2)}
            </Text>
          )} />

          <Column field="progreso_total" header="Progreso Total" body={(rowData: any) => (
            <Flex
              justify="start"
              align="center"
              gap="8px"

            >
              <Progress
                value={rowData?.progreso_total / rowData?.total_cursos}
                w="70%"
                rounded="50px"
                sx={{ '& > div': { backgroundColor: "main !important" } }}
              />

              <Text
                w="40px"
              >
                {rowData?.progreso_total / rowData?.total_cursos >= 100 ? `100%` : `${(rowData?.progreso_total / rowData?.total_cursos).toFixed(2)}%`}
              </Text>

            </Flex>

          )}
          />

          <Column header="Tiempo total" body={(rowData: any) => (
            <Text>
              {fmtSndsHourMin(rowData?.tiempo_total)}
            </Text>
          )} />

        </DataTable>
      </Box>
    </Flex>
  );
}
