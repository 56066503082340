import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { TabsExamenesIndex } from "./views/TabsExamenes"
import { TabsExamenesInformation } from "./views/Examenes/Information/TabsInformation"

export const Examenes = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Routes>
                <Route
                    path="/*"
                    element={
                        <TabsExamenesIndex />
                    }
                />

                <Route
                    path="examenes/:id/*"
                    element={
                        <TabsExamenesInformation />
                    }
                />
            </Routes>
        </Flex>
    )
}