import { Flex, Skeleton } from '@chakra-ui/react'

export default function PerfilSupervisorSkeleton() {
    return (
        <Flex gap="20px">
            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="439px" rounded="10px"></Skeleton>
            </Flex>

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="138px" rounded="10px"></Skeleton>
            </Flex>
        </Flex>
    )
}
