import { Button, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors"
import { useNavigate } from "react-router-dom"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { BiPlusCircle } from "react-icons/bi"
import { NewTenantModal } from "../../components/NewTenantModal"
import { NewAdminTenant } from "../../components/NewAdminTenant"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { Table } from "../../../../shared/components/Table/Table"
import { TenantColumns } from "./Columns/TenantColumns"
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession"
import { useData } from "../../../../shared/hooks/useData"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { ClientInt } from "../../../../shared/services/http.service"

export const TenantTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.TENANT });
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenAdmin, onOpen: onOpenAdmin, onClose: onCloseAdmin, getButtonProps: getModalAdminProps } = useDisclosure();
    const { data: tenants, loading, Refresh, error, status, errorsList } = useData({
        client: "" as ClientInt,
        endpoint: "tenants" as EndpointTypes ,
        query: query 
    });
    const selectedTenantRef = useRef(null);

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Empresas Tenant
                </Text>
            }
        >
            <Flex p="20px" direction="column" gap="20px">
                <Button
                    onClick={onOpen}
                    bg="secondary"
                    color="white"
                    p="8px 15px"
                    w="fit-content"
                    ml="auto"
                    borderRadius="5px"
                    fontSize="14px"
                    fontWeight="500"
                    leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                    _hover={{ bg: "variant" }}
                >
                    Nueva empresa
                </Button>

                <Table
                    data={tenants || []}
                    columns={TenantColumns({ selectedTenantRef, onOpenAdmin })}
                    isLoading={loading}
                    total={tenants?.length || 0}
                    query={query}
                    setQuery={setQuery}
                />

                <NewTenantModal
                    isOpen={isOpen}
                    onClose={onClose}
                    Refresh={Refresh}
                />


                <NewAdminTenant
                    isOpen={isOpenAdmin}
                    onClose={onCloseAdmin}
                    slug={selectedTenantRef.current}
                />
            </Flex>
        </Topbar>
    )
}