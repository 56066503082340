import { Flex, Icon, Image, Text } from "@chakra-ui/react"
import { CursosInt } from "../../../interfaces/CursosInt"
import { HiOutlineClock, HiOutlineMenuAlt2 } from "react-icons/hi";
import defaultPortada from "../../../assets/img/DefaultPortada.png"

interface Props {
    curso: CursosInt;
    index: number;
}

export const CardCursos = ({
    curso,
    index
}: Props) => {

    return (
        <Flex
            rounded="20px"
            w="320px"
            minW="320px"
            bg="purewhite"
            border="1px solid #E6E6EA"
            direction="column"
            pt="20px"
            pb="25px"
            px="25px"
            gap="25px"
        >
            <Text
                color="#A5A8B3"
                fontSize="42px"
                fontWeight="700"
                lineHeight="75%"
            >
                {index}
            </Text>

            <Flex
                direction="column"
                gap="8px"
            >
                <Text
                    fontSize="18px"
                    color="black"
                    fontWeight="600"
                    letterSpacing="-0.09px"
                >
                    {curso.nombre}
                </Text>

                <Flex
                    alignItems="center"
                    gap="15px"
                >
                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            color="black"
                            boxSize="20px"
                            as={HiOutlineMenuAlt2}
                        />
                        <Text
                            fontSize="14px"
                            color="#A5A8B3"
                            fontWeight={"400"}
                        >
                            {curso?.meta?.totalModulos || 0} modulos
                        </Text>
                    </Flex>

                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            color="black"
                            boxSize="20px"
                            as={HiOutlineClock}
                        />
                        <Text
                            fontSize="14px"
                            color="#A5A8B3"
                            fontWeight={"400"}
                        >
                            {curso?.duracion} h
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Image
                src={curso?.imagen?.url ? curso?.imagen?.url : defaultPortada}
                w="100%"
                h="100px"
                objectFit="cover"
                rounded="12px"
            />
        </Flex>
    )
}