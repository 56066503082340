interface Dato {
    createdAt: string;
}

export function calcularComparativa(
    datos: Dato[] | undefined,
    firstDayOfMonth: Date,
    firstDayOfLastMonth: Date

): number {

    const datosMesActual = datos?.filter((dato: Dato) => {
        const fechaCreacion = new Date(dato.createdAt);
        return fechaCreacion >= firstDayOfMonth && fechaCreacion < new Date();
    });

    const datosMesAnterior = datos?.filter((dato: Dato) => {
        const fechaCreacion = new Date(dato.createdAt);

        const ultimoDiaMesAnterior = new Date(firstDayOfMonth);
        ultimoDiaMesAnterior.setDate(ultimoDiaMesAnterior.getDate() - 1);

        const primerDiaMesAnterior = new Date(firstDayOfLastMonth);
        primerDiaMesAnterior.setDate(1);

        return fechaCreacion >= primerDiaMesAnterior && fechaCreacion <= ultimoDiaMesAnterior;
    });

    const cantidadDatosMesAnterior = datosMesAnterior?.length || 0;
    const cantidadDatosMesActual = datosMesActual?.length || 0;

    const cambioPorcentual = cantidadDatosMesAnterior !== 0
        ? (cantidadDatosMesActual / cantidadDatosMesAnterior) * 100
        : 0;

    return cambioPorcentual;
}
