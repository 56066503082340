import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton, Spinner, Checkbox } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { useEffect, useRef, useState } from "react";
import { addGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input";
import { useClient } from "../../../../shared/context/client.context";
import { NewEmpresaModal } from "../../../Empresas/components/NewEmpresaModal";
import { useNavigate } from "react-router-dom";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { CustomShowInput } from "../../../../shared/components/CustomElements/CustomShowInput";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { getKey } from "../../../../shared/middlewares/config.middleware";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { parseDate } from "../../../../shared/utils/functions/parseDate";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { DateTime } from "luxon";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
    copyData?: GruposInt | undefined | null;
}
export const NewGrupoNoFundae = ({
    isOpen,
    onClose,
    setRefreshTable,
    copyData,
}: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const { user, refreshUser } = useAuthContex();
    const { total, gestion, contenido } = useUserPolicy();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const { isOpen: isOpenEmpresa, onOpen: onOpenEmpresa, onClose: onCloseEmpresa } = useDisclosure();
    const toast = useToast();
    const [newEmpresa, setNewEmpresa] = useState<EmpresasInt | null>(null)
    const selectedCurso = useRef<string | null>(null);
    const selectedEmpresa = useRef<string | null | undefined>(null);
    const selectedFechaInicio = useRef<string | null>(null);
    const selectedFechaFin = useRef<string | null>(null);
    const [isUniqueEmpresa, setIsUniqueEmpresa] = useState<boolean>(true)
    const [empresaDefectoId, setEmpresaDefectoId] = useState<string | null>(null)
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        if (empresas && empresas?.data?.length > 0) {
            setIsUniqueEmpresa(empresas?.meta?.total === 1)

            if (empresas?.meta?.total === 1) {
                setEmpresaDefectoId(empresas?.data[0]?.id)
            }
        }
    }, [empresas])

    const initialValues = {
        nombre: copyData?.nombre ? copyData?.nombre : null,
        cursoId: copyData?.cursoId ? copyData?.cursoId : selectedCurso.current,
        empresaId: (copyData?.empresas && copyData?.empresas?.length > 0) ? copyData?.empresas[0]?.id : selectedEmpresa.current,
        empresaOrganizadoraId: copyData?.empresaOrganizadoraId ? copyData?.empresaOrganizadoraId : null,
        fechaInicio: selectedFechaInicio.current,
        fechaFin: selectedFechaFin.current,
        fundae: false,
        gestionaEmpresa: true,
        isUniqueEmpresa: isUniqueEmpresa,
        sinFechaInicio: false,
        sinFechaFin: false,
        estado: null,
    };

    const validationSchema = Yup.object({
        isUniqueEmpresa: Yup.boolean(),
        nombre: Yup.string()
            .required('El nombre del grupo es obligatorio.')
            .typeError('El nombre del grupo es obligatorio.')
            .matches(/^[a-zA-Z0-9\sñÑáéíóúÁÉÍÓÚ-]*$/, 'El nombre del grupo solo puede contener letras, números y subguiones.'),
        empresaId: Yup.string()
            .notRequired(),
        cursoId: Yup.string()
            .required('Debe asociar un curso al grupo.')
            .typeError('Debe asociar un curso al grupo.'),
        fechaInicio: Yup.string().when(['sinFechaInicio'], {
            is: (sinFechaInicio: boolean) => !sinFechaInicio,
            then: (validationScheme) => validationScheme
                .required('La fecha de inicio es obligatoria.')
                .typeError('La fecha de inicio es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        fechaFin: Yup.string().when(['sinFechaFin'], {
            is: (sinFechaFin: boolean) => !sinFechaFin,
            then: (validationScheme) => validationScheme
                .required('La fecha de fin es obligatoria.')
                .typeError('La fecha de fin es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        empresaOrganizadoraId: Yup.string().when(['isUniqueEmpresa'], {
            is: (isUniqueEmpresa: boolean) => isUniqueEmpresa,
            then: (validationScheme) => validationScheme
                .notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('La empresa factura es obligatoria.')
                .typeError('La empresa factura es obligatoria.'),
        }),
        sinFechaInicio: Yup.boolean(),
        sinFechaFin: Yup.boolean(),
        estado: Yup.string().when(['sinFechaFin'], {
            is: (sinFechaFin: boolean) => sinFechaFin,
            then: (validationScheme) => validationScheme
                .required('Debe asignar un estado al grupo.')
                .typeError('Debe asignar un estado al grupo.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        let fechaInicio = parseDate(values.fechaInicio) + "T12:00"
        let fechaFin = parseDate(values.fechaFin) + "T12:00"

        fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()
        fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

        let newGrupo: any = {
            nombre: values?.nombre,
            modalidad: "teleformacion",
            perfil: "organizadora",
            cursoId: values.cursoId,
            fechaInicio: !values.sinFechaInicio ? fechaInicio : null,
            fechaFin: !values.sinFechaFin ? fechaFin : null,
            fundae: false,
            permitirDescargaDiploma: true,
            empresas: newEmpresa ? [newEmpresa?.id] : [values.empresaId],
            empresaOrganizadoraId: isUniqueEmpresa ? empresaDefectoId : values.empresaOrganizadoraId,
            gestionaEmpresa: true,
        }

        if (values?.sinFechaFin || values?.sinFechaInicio) {
            newGrupo = {
                ...newGrupo,
                estado: values?.estado
            }
        }

        addGrupo({
            grupo: newGrupo,
            client: client
        })
            .then((response: any) => {
                const id = response?.data?.data?.id;

                setRefreshTable();
                handleClose();
                navigate(`/grupos/${id}/configuracion`)

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado el curso ${newGrupo.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(async () => {
                setIsLoading(false)

                if (!user?.config?.grupos && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                grupos: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    grupos: true
                                }
                            })
                        })
                }
            })
    }

    const handleClose = () => {
        onClose()
        selectedCurso.current = null
        selectedEmpresa.current = null
        selectedFechaInicio.current = null
        selectedFechaFin.current = null
    }

    const loadCursos = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: QueryTypes.NOMBRE,
        specificQuery: QueryTypes.ACTIVO
    })

    const loadEmpresa = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.EMPRESA,
        client: client,
        query: QueryTypes.NOMBRE,
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo grupo
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;
                                
                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            pt="30px"
                                            px="30px"
                                            pb="0px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="15px"
                                            >
                                                {!isUniqueEmpresa &&
                                                    <FormSelect
                                                        name="empresaOrganizadoraId"
                                                        label={"Empresa Organizadora / Factura"}
                                                        noOptionsMessage={
                                                            () => (
                                                                <Flex
                                                                    w="100%"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                >
                                                                    <Spinner
                                                                        color="secondary"
                                                                        size="xs"
                                                                    />
                                                                </Flex>
                                                            )
                                                        }
                                                        defaultValue={copyData ?
                                                            { value: copyData?.empresaOrganizadora?.id, label: copyData?.empresaOrganizadora?.nombre }
                                                            : null
                                                        }
                                                        options={
                                                            (empresas === undefined || empresas === null)
                                                                ? []
                                                                : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                        }
                                                        placeholder="Empresa asociada"
                                                        isRequired={
                                                            copyData
                                                                ? copyData?.empresaOrganizadora?.id ? false : true
                                                                : !values.empresaOrganizadoraId ? true : false
                                                        }
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                }
                                            </Flex>

                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="600"
                                                mb="15px"
                                            >
                                                Datos del grupo
                                            </Text>

                                            <Flex direction="column">
                                                <FormAsyncSelect
                                                    name="cursoId"
                                                    label="Curso asociado"
                                                    defaultValue={copyData ?
                                                        { value: copyData?.curso?.id, label: copyData?.curso?.nombre }
                                                        : null
                                                    }
                                                    loadOptions={loadCursos}
                                                    defaultOptions={true}
                                                    placeholder="Curso asociado"
                                                    isRequired={!values.cursoId ? true : false}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles
                                                    }}
                                                />

                                                <FormInput
                                                    name="nombre"
                                                    defaultValue={copyData ? copyData?.nombre : null}
                                                    label="Nombre del grupo"
                                                    placeholder="Nombre del grupo"
                                                />

                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                    mb="20px"
                                                >
                                                    Datos de la formación
                                                </Text>

                                                {newEmpresa ?
                                                    <CustomShowInput
                                                        value={newEmpresa?.nombre}
                                                        label="Empresa"
                                                        placeholder="Empresa asociada"
                                                        isChangeable={true}
                                                        valueChange={setNewEmpresa}
                                                    />
                                                    :
                                                    <FormAsyncSelect
                                                        name="empresaId"
                                                        label="Empresa"
                                                        defaultValue={
                                                            (copyData?.empresas && copyData?.empresas?.length > 0) ?
                                                                { value: copyData?.empresas[0]?.id, label: copyData?.empresas[0]?.nombre }
                                                                : null
                                                        }
                                                        defaultOptions={true}
                                                        loadOptions={loadEmpresa}
                                                        placeholder="Empresa asociada"
                                                        isRequired={
                                                            (copyData?.empresas && copyData?.empresas?.length > 0)
                                                                ? copyData?.empresas[0]?.id ? false : true
                                                                : !values.empresaId ? true : false
                                                        }
                                                        ui={{
                                                            formSelectStyles: formSelectStyles
                                                        }}
                                                    />
                                                }

                                                <Flex alignItems="center" gap="20px" mb="20px">
                                                    <Text
                                                        color="#67748E"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing="-0.11px"
                                                    >
                                                        ¿No encuentras la empresa?
                                                    </Text>

                                                    <Text
                                                        cursor="pointer"
                                                        color="black"
                                                        fontSize="13px"
                                                        fontWeight="600"
                                                        letterSpacing="-0.11px"
                                                        onClick={onOpenEmpresa}
                                                        _hover={{ textDecoration: "underline" }}
                                                    >
                                                        Crear empresa
                                                    </Text>
                                                </Flex>

                                                <Flex
                                                    gap="15px"
                                                    alignItems="center"
                                                >
                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        <Checkbox
                                                            gap="0"
                                                            alignItems="center"
                                                            isChecked={values.sinFechaInicio}
                                                            onChange={() => formik.setFieldValue("sinFechaInicio", !values.sinFechaInicio)}
                                                        >
                                                            Grupo sin fecha inicio
                                                        </Checkbox>

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaInicio"
                                                            label="Fecha Inicio"
                                                            isRequired={!values.fechaInicio ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaInicio}
                                                        />
                                                    </Flex>

                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        <Checkbox
                                                            gap="0"
                                                            alignItems="center"
                                                            isChecked={values.sinFechaFin}
                                                            onChange={() => formik.setFieldValue("sinFechaFin", !values.sinFechaFin)}
                                                        >
                                                            Grupo sin fecha fin
                                                        </Checkbox>

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaFin"
                                                            label="Fecha Fin"
                                                            isRequired={!values.fechaFin ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaFin}
                                                        />
                                                    </Flex>
                                                </Flex>

                                                {values?.sinFechaFin &&
                                                    <Flex
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent={"center"}
                                                    >
                                                        <Text
                                                            fontSize="15px"
                                                            color="secondary"
                                                            textAlign={"center"}
                                                        >
                                                            Si el grupo no tiene fecha fin debe asignar un estado manual.
                                                        </Text>

                                                        <FormSelect
                                                            name="estado"
                                                            options={[
                                                                { value: "2", label: "Inactivo" },
                                                                { value: "0", label: "Activo" },
                                                            ]}
                                                            placeholder="Estado"
                                                            ui={{
                                                                formSelectStyles: formSelectStyles,
                                                            }}
                                                        />
                                                    </Flex>
                                                }
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear grupo
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal >

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />

            <NewEmpresaModal
                isOpen={isOpenEmpresa}
                onClose={onCloseEmpresa}
                selectEmpresaGrupo={setNewEmpresa}
            />
        </>
    )
}