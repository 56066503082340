import { Flex } from "@chakra-ui/react";
import { useClient } from "../../../shared/context/client.context";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { CardInfo } from "../../../shared/components/Cards/CardInfo";
import TopPerformers from "../components/TopPerformers";
import ImportesMatriculas from "../components/ImportesMatriculas";
import { HiAcademicCap, HiOutlineAcademicCap } from "react-icons/hi";
import { RiMoneyEuroCircleFill, RiMoneyEuroCircleLine } from "react-icons/ri";
import DashboardSkeletonRendimiento from "../components/utils/DashboardSkeletonRendimiento";

export default function TabRendimiento() {
    const client = useClient();

    const { data: statsTopPerformers } = useData({
        client: client,
        endpoint: EndpointTypes.STATS_TOP
    })

    const { data: statsKpis } = useData({
        client: client,
        endpoint: EndpointTypes.STATS_KPIS
    })

    const { data: statsMatriculas, loading } = useData({
        client: client,
        endpoint: EndpointTypes.STATS_IMPORTE_MATRICULAS
    })

    const CardInfoData: any = [
        {
            label: "Matrículas Finalizadas Correctamente",
            info: statsKpis?.totalMatriculasFinalizadasOK,
            infoTotal: statsKpis?.totalMatriculasFinalizadas,
            bg: "purewhite",
            tooltip: "Matrículas finalizadas pero sin requisitos completados.",
            icon: HiOutlineAcademicCap
        },
        {
            label: "Matrículas Finalizadas FUNDAE Correctamente",
            info: statsKpis?.totalMatriculasFUNDAEFinalizadasOK,
            infoTotal: statsKpis?.totalMatriculasFUNDAEFinalizadas,
            bg: "purewhite",
            tooltip: "Matrículas finalizadas con todo completado.",
            icon: HiAcademicCap
        },
        {
            label: "Importe Matrículas Finalizadas",
            info: statsKpis?.totalImporteMatriculasFinalizadasOK,
            infoTotal: statsKpis?.totalImporteMatriculasFinalizadas,
            bg: "purewhite",
            tooltip: "Importe total de matrículas finalizadas",
            isEuros: true,
            icon: RiMoneyEuroCircleFill
        },
        {
            label: "Importe Matrículas Finalizadas FUNDAE",
            info: statsKpis?.totalImporteMatriculasFUNDAEFinalizadasOK,
            infoTotal: statsKpis?.totalImporteMatriculasFUNDAEFinalizadas,
            bg: "purewhite",
            tooltip: "Importe Matrículas Finalizadas FUNDAE",
            isEuros: true,
            icon: RiMoneyEuroCircleLine
        }
    ]

    return (
        <Flex
            pt="10px"
            direction="column"
            gap="20px"
        >
            {loading ? (
                <DashboardSkeletonRendimiento />
            ) : (

                <Flex
                    direction="column"
                    gap="20px"
                >
                    <Flex
                        gap="20px"
                        flexWrap="wrap"
                    >
                        {CardInfoData?.map((item: any, index: number) => (
                            <CardInfo
                                key={index}
                                label={item?.label}
                                info={item?.info}
                                infoTotal={item?.infoTotal}
                                isEuros={item?.isEuros}
                                icon={item?.icon}
                            />
                        ))}

                    </Flex>

                    <Flex
                        gap="20px"
                    >
                        <TopPerformers
                            stats={statsTopPerformers}
                        />

                        <ImportesMatriculas
                            statsMatriculas={statsMatriculas}
                            loading={loading}
                        />
                    </Flex>
                </Flex>

            )}

        </Flex>
    )
}
