import { ColumnProps } from "../../../../shared/components/Table/Table";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";

export const MatriculasBajaColumns = (): ColumnProps[] => {
    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.user?.username} src={rowData?.user?.avatar?.url} size="35px" />
                    }
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.email}
                />
            )
        },
        {
            header: 'Creación',
            field: 'matriculas.created_at',
            key: 'cratedAt',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                />
            )
        },
        {
            header: 'Baja',
            field: 'matriculas.fechaBaja',
            key: 'fechaBaja',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.fechaBaja}
                />
            )
        },
    ]
}