import { Flex, useDisclosure } from "@chakra-ui/react"
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum"
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../../shared/context/auth.context"
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton"
import { BiPlusCircle } from "react-icons/bi"
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes"
import { useData } from "../../../../../shared/hooks/useData"
import { useClient } from "../../../../../shared/context/client.context"
import { NewExamenModal } from "../../../components/Modals/NewExamenModal"
import { Table } from "../../../../../shared/components/Table/Table"
import { Filters } from "../../../../../shared/components/Filters/Filters"
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession"
import { EvaluacionesColumns } from "./Columns/EvaluacionesColumns"

export const EvaluacionesTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.EVALUACIONES });
    const { user } = useAuthContex()
    const client = useClient()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: evaluaciones, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.EVALUACIONES,
        query: query
    });

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Filters
                query={query}
                setQuery={setQuery}
                filterElements={[
                    {
                        name: FiltrosNames.EXAMEN,
                        type: FiltrosTypes.ASYNC_SELECT,
                    }
                ]}
                bgSelects="purewhite"
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={evaluaciones?.data || []}
                columns={EvaluacionesColumns()}
                isLoading={loading}
                total={evaluaciones?.meta?.total || 0}
            />

            <NewExamenModal
                isOpen={isOpen}
                onClose={onClose}
                Refresh={Refresh}
            />
        </Flex>
    )
}