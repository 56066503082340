import { Flex, Icon, Text } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineLink, HiOutlinePaperClip, HiOutlinePlay } from "react-icons/hi";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { AiOutlineHolder } from "react-icons/ai";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { Dispatch, SetStateAction } from "react";
import { LeccionTipoEnum } from "../../../../shared/utils/Types/LeccionTipoEnum";
import { BsPerson } from "react-icons/bs";

interface Props {
    leccion: any;
    selected: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined;
    setSelected: ({ type, data }: { type: TipoSelect, data: ModulosInt | LeccionInt | null }) => void;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}

export const DndLecciones = ({
    leccion,
    selected,
    setSelected,
    setRefreshData
}: Props) => {
    const {
        setNodeRef,
        listeners,
        transform,
        transition,
    } = useSortable({
        id: leccion?.id
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <Flex
            opacity={leccion?.publicado ? 1 : 0.4}
            ref={setNodeRef}
            style={style}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            py="20px"
            px="10px"
            rounded="5px"
            border="1px solid"
            borderColor={selected?.data?.id === leccion?.id ? "secondary" : "#DFE2E6"}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation();

                setSelected({
                    type: TipoSelect.LECCION,
                    data: leccion
                })
            }}
        >
            <Flex
                alignItems="center"
                gap="8px"
            >
                <Icon as={AiOutlineHolder} boxSize="18px" color="pureblack" cursor="grab" {...listeners} />

                <Text
                    color="pureblack"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="100%"
                    letterSpacing="-0.16px"
                >
                    {leccion?.nombre}
                </Text>
            </Flex>

            <Flex
                alignItems="center"
                gap="8px"
            >
                <Icon
                    as={
                        leccion?.tipo === LeccionTipoEnum.FICHERO
                            ? HiOutlinePaperClip
                            : leccion?.tipo === LeccionTipoEnum.ENTREGABLE
                                ? HiOutlineClipboard
                                : leccion?.tipo === LeccionTipoEnum.URL
                                    ? HiOutlineLink
                                    : leccion?.tipo === LeccionTipoEnum.TEXTO
                                        ? HiOutlineDocumentText
                                        : leccion?.tipo === LeccionTipoEnum.SESION_VIVO
                                            ? (leccion?.sesionVivo?.online ? HiOutlinePlay : BsPerson)
                                            : HiOutlineDocumentDownload
                    }
                    boxSize="15px"
                />
            </Flex>
        </Flex>
    );
}