import sindatacursos from "../../../assets/img/NoContent1.png";
import sindatagrupos from "../../../assets/img/NoContent2.png";
import sindatamatriculas from "../../../assets/img/NoContent9.png";
import sindatausuarios from "../../../assets/img/NoContent3.png";
import sindataempresas from "../../../assets/img/NoContent8.png";
import sindatacorrecciones from "../../../assets/img/NoContent9.png";
import sindataanuncios from "../../../assets/img/NoContent5.png"
import sindatainformes from "../../../assets/img/NoContent2.png"


export const EmptyMessageType = {
    cursos: {
        title: "Aún no existen cursos",
        src: sindatacursos
    },
    grupos: {
        title: "Aún no existen grupos",
        src: sindatagrupos
    },
    matriculas: {
        title: "Aún no existen matrículas",
        src: sindatamatriculas
    },
    usuarios: {
        title: "Aún no existen usuarios",
        src: sindatausuarios
    },
    empresas: {
        title: "Aún no existen empresas",
        src: sindataempresas
    },
    correcciones: {
        title: "Aún no existen correcciones",
        src: sindatacorrecciones
    },
    foros: {
        title: "No existen dudas pendientes",
        src: sindatacorrecciones
    },
    cursosFiltros: {
        title: "No se han encontrado cursos con los filtros seleccionados",
        src: sindatacursos
    },
    gruposFiltros: {
        title: "No se han encontrado grupos con los filtros seleccionados",
        src: sindatagrupos
    },
    matriculasFiltros: {
        title: "No se han encontrado matrículas con los filtros seleccionados",
        src: sindatamatriculas
    },
    usuariosFiltros: {
        title: "No se han encontrado usuarios con los filtros seleccionados",
        src: sindatausuarios
    },
    empresasFiltros: {
        title: "No se han encontrado empresas con los filtros seleccionados",
        src: sindataempresas
    },
    correccionesFiltros: {
        title: "No se han encontrado correcciones con los filtros seleccionados",
        src: sindatacorrecciones
    },
    forosFiltros: {
        title: "No se han encontrado dudas con los filtros seleccionados",
        src: sindatacorrecciones
    },
    anuncios: {
        title: "No hay anuncios creados. Crea un anuncio.",
        src: sindataanuncios
    },
    informes: {
        title: "No hay informes para generar.",
        src: sindatainformes
    }
}