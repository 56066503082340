// Chakra UI
import { Flex, Text, Image, Link } from "@chakra-ui/react";
// Components
import { FormLogin } from "./Views/FormLogin";
// Img
import loginImg from '../../assets/img/Login_img.png'

export const Login = () => {
    return (
        <Flex
            direction="column"
            w="100%"
            bg="purewhite"
        >
            <Flex
                h="90vh"
                w="100%"
            >
                <Flex flex="1">
                    <FormLogin />
                </Flex>

                <Flex
                    justifyContent="flex-end"
                    flex="1"
                >
                    <Image
                        src={loginImg}
                        alt="login/img"
                        objectFit="cover"
                    />
                </Flex>
            </Flex>

            <Flex
                h="10vh"
                alignItems="center"
                justifyContent="center"
            >
                <Text
                    color="secondary_font"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="-0.443px"
                >
                    Copyright © 2024 <Link color="secondary" href="https://proacademy.app" target="_blank">Proacademy</Link> by Imagina Formación
                </Text>
            </Flex>
        </Flex>
    );
};