import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonPerfilExamenes = () => {
    return(
        <Flex
            gap="20px"
            w="full"
        >
            <Flex
                w="50%"
                direction="column"
                gap="20px"
            >
                <Skeleton w="full" rounded="10px" h="415px" />
                <Skeleton w="full" rounded="10px" h="189px" />                
            </Flex>
            <Skeleton w="50%" rounded="10px" h="425px" />
        </Flex>
    )
}