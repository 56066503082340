export enum QueryTypes {
    NOMBRE = "?nombre=",
    NOMBRE_EMAIL = "?nombreYemail=",
    NOMBRE_COMPLETO = `?nombreCompleto=`,
    ROL_TUTOR = "?role=tutor",
    ACTIVO = "?activo=true",
    TIPO_FEEDBACK = "?tipo=",
    SUBTIPO_FEEDBACK = "?subtipo=",
    ENTRGAS_PENDIENTES = "?entregasPendientes=true",
    EMPRESA_INTERNA = "?interna=true",
    EMPRESA_EXTERNA = "?interna=false",
    TIPO_PLANTILLA = "?categoria=",
}

export enum SpecificQuerysTypes {

}