import { ComponentConfig } from "@measured/puck";
import { PunkHeadingProps, PunkHeading } from "../CustomElements/Heading/PunkHeading";
import { Section } from "../Section/Section";
import { TextVariable } from "../CustomElements/Fields/TextVariable";

export type HeadingProps = {
    align: "left" | "center" | "right";
    text?: string;
    level?: PunkHeadingProps["rank"];
    size: PunkHeadingProps["size"];
    padding?: string;
};

const sizeOptions = [
    { value: "xxxl", label: "XXXL" },
    { value: "xxl", label: "XXL" },
    { value: "xl", label: "XL" },
    { value: "l", label: "L" },
    { value: "m", label: "M" },
    { value: "s", label: "S" },
    { value: "xs", label: "XS" },
];

const levelOptions = [
    { label: "", value: "" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
];

export const Heading: ComponentConfig<HeadingProps> = {
    fields: {
        text: {
            type: "custom",
            label: "Texto",
            render: ({ name, onChange, puck, value }: any) => {
                const textarea = document.querySelector('textarea[name="text"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                )
            }
        },
        size: {
            type: "select",
            options: sizeOptions,
        },
        level: {
            type: "select",
            options: levelOptions,
        },
        align: {
            type: "radio",
            options: [
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Right", value: "right" },
            ],
        },
        padding: { type: "text" },
    },
    defaultProps: {
        align: "left",
        text: "Heading",
        padding: "24px",
        size: "m",
    },
    render: ({ align, text, size, level, padding }) => {
        const style = {
            display: "block",
            textAlign: align,
            width: "100%",
            padding,
            fontFamily: "'Poppins', sans-serif",
        }

        return (
            <h1 style={style}>
                {text}
            </h1>
        );
    },
};