import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const tenant = await tenantInstance();

export const sendEmail = async ({ id, client } : { id: string, client: ClientInt }) => {
    if(!client) return;

    return _axios.post(
        `${tenant}/${client}/pendingEmails/sendNow`,
        {
            id: id
        },
        {}
    )
};

export const resendEmailLog = async ({ emailId, client } : { emailId: string | undefined; client: ClientInt }) => {
    if(!client) return;

    return _axios.put(
        `${tenant}/${client}/emailLogs/resend/${emailId}`,
    )
}

export const deletePendingEmails = async ({ id, client }: { id: string | undefined, client: ClientInt }) => {
    if (!client) return;

    return _axios.delete(`${tenant}/${client}/pendingEmails/${id}`)
};