import { useEffect, useState } from "react";

declare global {
    interface Window {
        loadliveworksheet?: (id: number, code: string, lang: number, domain: string, mode: string) => void;
    }
}

interface Props {
    url: string;
}

export const LiveWorksheet = ({ url }: Props) => {
    const [worksheetData, setWorksheetData] = useState<{
        id: number;
        code: string;
        lang: number;
        domain: string;
        mode: string;
    } | null>(null);

    useEffect(() => {
        // Función para extraer los datos de la URL
        const extractDataFromUrl = (url: string) => {
            const regex = /loadliveworksheet\((\d+),\s*'(\w+)',\s*(\d+),\s*'(\w+)',\s*'(\w+)'\)/;
            const match = url.match(regex);
            if (match) {
                return {
                    id: parseInt(match[1]),
                    code: match[2],
                    lang: parseInt(match[3]),
                    domain: match[4],
                    mode: match[5]
                };
            }
            return null;
        };

        const data = extractDataFromUrl(url);
        setWorksheetData(data);
    }, [url]);

    useEffect(() => {
        if (!worksheetData) return;

        const script = document.createElement('script');
        script.src = "https://www.liveworksheets.com/embed/embed.js?v=1";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (window.loadliveworksheet && worksheetData) {
                window.loadliveworksheet(
                    worksheetData.id,
                    worksheetData.code,
                    worksheetData.lang,
                    worksheetData.domain,
                    worksheetData.mode
                );
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, [worksheetData]);

    if (!worksheetData) return null;

    return (
        <div id={`liveworksheet${worksheetData.id}`} style={{ width: '100%' }}>
            <span id={`lwslink${worksheetData.id}`} />
        </div>
    );
};
