export enum AuditsEnum {
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    CREATE = "CREATE"
}

export enum AuditsTipoEnum {
    ASIGNACION = "Asignacion",
    CAMBIO_DATO = "CambioDato",
    CURSO = "Curso",
    EMPRESA = "Empresa",
    EXAMEN = "Examen",
    GRUPO = "Grupo",
    LECCION = "Leccion",
    LIQUIDACION = "Liquidacion",
    MATRICULA = "Matricula",
    MODULO = "Modulo",
    SUPERVISOR = "Supervisor",
    TEMA = "Tema",
    USER = "User",
}