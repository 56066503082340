import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const EjecutarTarea = async ({ id, client } : { id: string, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/pendingTasks/executeNow`,
        {
            id: id
        },
        {}
    )
};

export const deleteTarea = async ({ id, client }: { id: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/pendingTasks/${id}`)
};
