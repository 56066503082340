import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button, Input, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { ModalAlert } from "./ModalAlert";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    matricula?: MatriculasInt | null;
    label: string | undefined;
}

export const ModalBajaMatricula = ({ isOpen, onClose, onConfirm, matricula, label = "Dar de baja" }: Props) => {
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    useEffect(() => {
        if(isOpen && matricula?.asignacion?.liquidada) onOpenAlert();
    }, [matricula]);

    return (
        <>
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="lg"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="10px"
            >
                <ModalBody>
                    <Text
                        color="dark-blue"
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="700"
                        my="10px"
                    >
                        ¿Estás segur@ que quieres dar de baja la matrícula?
                    </Text>

                    <Text
                        color="font"
                        textAlign="center"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Esta acción no se puede deshacer. Para dar de baja la matrícula, escribe "{label}" en el siguiente campo.
                    </Text>
                    
                    <Input 
                        mt="15px"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (
                                e.target.value?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').trim().replace(/\s+/g, ' ')
                                === label?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').trim().replace(/\s+/g, ' ')
                                ) {
                                setConfirmDelete(true);
                            } else {
                                setConfirmDelete(false);
                            }
                        }}
                    />
                </ModalBody>

                <ModalFooter justifyContent="center" gap="15px">
                    <Button
                        type="submit"
                        p="10px 15px"
                        bg="black"
                        rounded="5"
                        color="purewhite"
                        fontSize="14px"
                        fontWeight="500"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>

                    <Button
                        type="submit"
                        bg="purewhite"
                        p="10px 15px"
                        rounded="5"
                        borderWidth="1px"
                        borderColor="black"
                        color="black"
                        fontSize="14px"
                        fontWeight="500"
                        onClick={onConfirm}
                        isDisabled={!confirmDelete}
                    >
                        Dar de baja
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <ModalAlert 
            isOpen={isOpenAlert}
            onClose={onCloseAlert}
            onConfirm={onClose}
            title="Esta matrícula tiene liquidaciones pagadas, ¿Quieres continuar?"
        />
        </>
    )
}