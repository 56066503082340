import { Tooltip, Flex, Icon, Text, Link, Image } from "@chakra-ui/react";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import { defaultIcon } from "../../Icons/IconBox";
import { Navlink, NavlinkProps } from "./Navlink";
import { PiDotsThreeOutlineLight } from "react-icons/pi";
import { SecondarySidebar } from "./SecondarySidebar";
import { useTenantInfo } from "../../../hooks/useTenantInfo";
import { SetColors } from "../../../../ui/colors";

export interface Seccion {
  isAllowed: boolean;
  navlink: NavlinkProps;
}

export interface SidebarProps {
  secciones: Seccion[];
}

export function BaseSidebar({
  secciones,
}: SidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [subMenu, setSubMenu] = useState<boolean>(false)
  const { handleGetInfo } = useTenantInfo();
  const icono = handleGetInfo({ key: "icono" });
  const colores = SetColors()

  const subMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target as Node)) {

        setSubMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);


    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const activePath = (text: string): boolean => {
    if (text === "/") {
      if (location.pathname === "/")
        return true
      return false
    } else {
      let newText: string[] | string = text.split("/")
      newText = `/${newText[1]}`

      if (location.pathname.startsWith(newText)) { return true }
      else { return false }
    }
  };

  const allLinks = secciones
    .filter((link) => link.isAllowed)
    .map((link) => link.navlink);

  const handleKeyDown = (event: KeyboardEvent) => {
    const target = event.target as HTMLElement;

    const isInteractiveElement = (element: HTMLElement): boolean => {
      const interactiveTags = ['INPUT', 'TEXTAREA', 'SELECT', 'A'];

      return (
        interactiveTags.includes(element.tagName) ||
        element.isContentEditable ||
        element.hasAttribute('tabindex')
      );
    };

    if (isInteractiveElement(target)) {
      return;
    }

    const isCtrlPressed = event.ctrlKey;
    const isCmdPressed = event.metaKey;
    const isShiftPressed = event.shiftKey;

    if (isCtrlPressed || isCmdPressed || isShiftPressed) return;

    const key = event.key === '0' ? 10 : parseInt(event.key, 10);

    let index = -1;

    if (key >= 1 && key < 9) {
      index = key;
    }

    if (index >= 0 && index <= 8) {
      const selectedLink = allLinks[index - 1];
      if (selectedLink) {
        navigate(selectedLink.path);
      }
    } else if (key === 9) {
      setSubMenu((prev) => !prev);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [allLinks]);

  return (
    <Flex
      pos={"relative"}
      zIndex={"999"}
      ref={subMenuRef}
    >
      <Flex
        h="100vh"
        bg={colores?.main}
        borderRight={"1px solid"}
        borderColor="light_grey"
        transition={"all 0.3s"}
        w={"65px"}
        direction="column"
        pt="20px"
        alignItems="center"
        gap="50px"
      >
        <Link
          as={RouterLink}
          to="/"
          display="flex"
          alignItems="center"
          _hover={{ textDecoration: "none" }}
          w="100%"
          boxSize="45px"
          justifyContent="center"
          bg="purewhite"
          rounded="8px"
          p="5px"
        >
          {
            icono ?
              <Image
                w="100%"
                h="100%"
                src={icono}
                alt='img/logo'
                objectFit="contain"
                rounded="8px"
              />
              :
              <Image
                w="100%"
                h="100%"
                src={`data:image/svg+xml;utf8,${defaultIcon}`}
                alt='img/logo'
                objectFit="contain"
                rounded="8px"
              />
          }
        </Link>

        <Flex
          direction="column"
          gap="10px"
        >
          {secciones?.map((link: Seccion, indexItem: number) => (
            <Fragment
              key={indexItem}
            >
              {link?.isAllowed && indexItem < 8 &&
                <Navlink
                  key={indexItem}
                  keyboardNumber={indexItem + 1}
                  active={activePath(link.navlink.path)}
                  setState={setSubMenu}
                  {...link.navlink}
                />
              }

              {link?.isAllowed && indexItem == 8 &&
                <>
                  <Flex
                    rounded="10px"
                    w="100%"
                    cursor={"pointer"}
                    bg={subMenu ? "purewhite" : "transparent"}
                    border={subMenu ? "1px solid" : "1px solid"}
                    borderColor={"transparent"}
                    _hover={{ border: "1px solid", borderColor: "purewhite" }}
                    onClick={() => setSubMenu((prev) => !prev)}
                  >
                    <Tooltip
                      label={
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          gap="5px"
                        >
                          <Text
                            fontSize={"12px"}
                            fontWeight={"500"}
                          >
                            Mas
                          </Text>

                          {indexItem + 1 &&
                            <Flex
                              bg={"purewhite"}
                              color="main"
                              alignItems="center"
                              justifyContent="center"
                              py="3px"
                              px="7px"
                              h="fit-content"
                              w="fit-content"
                              fontSize={"10px"}
                              fontWeight="600"
                              rounded="4px"
                            >
                              {indexItem + 1}
                            </Flex>
                          }
                        </Flex>
                      }
                      rounded={"6px"}
                      textAlign={"center"}
                      bg={"main"}
                      color={"#FFFFFF"}
                      px={"10px"}
                      py={"5px"}
                      placement='right'
                    >
                      <Flex
                        p="10px 12px"
                        alignItems="center"
                        gap="10px"
                        className="icon-box"
                      >
                        <Icon
                          as={PiDotsThreeOutlineLight}
                          boxSize="22px"
                          color={subMenu ? "main" : "purewhite"}
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>

                  <SecondarySidebar
                    setState={setSubMenu}
                    state={subMenu}
                    extraLinks={secciones.slice(8)}
                    activePath={activePath}
                  />
                </>
              }
            </Fragment>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}