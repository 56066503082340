import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { BiError, BiPlay } from 'react-icons/bi';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Center, Flex, Icon, useMediaQuery, Spinner } from '@chakra-ui/react';
import { Controls } from './Controls';
import { LeccionInt } from '../../../interfaces/CursosInt';

export type Quality = '360p' | '720p' | '1080p' | 'auto';

let controlsTimeout: any;
const values = { duration: 0, played: 0 };

interface OpenPlayerProps {
  player: React.MutableRefObject<any>;
  src?: string;
  maxSpeed?: number;
  minSpeed?: number;
  leccion?: LeccionInt | null;
  playedSeconds: number;
  onPlay: (e?: any) => void | any;
  setPlayedSeconds: (e?: any) => void | any;
}

export const OpenPlayer = ({
  src,
  player,
  leccion,
  onPlay,
  maxSpeed = 1,
  minSpeed = 1,
  playedSeconds,
  setPlayedSeconds,
}: OpenPlayerProps) => {
  const valuesRef = useRef(values);
  const fullscreenHandler = useFullScreenHandle();

  const [error, setError] = useState<boolean>(false);
  const [isMobilePlayer] = useMediaQuery('(max-width: 490px)');

  const [volume, setVolume] = useState<number>(100);
  const [duration, setDuration] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [quality, setQuality] = useState<Quality>('auto');
  const [savedSeconds, setSavedSeconds] = useState<number>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [isFirstPlay, setIsFirstPlay] = useState<boolean>(true);
  const [isBuffering, setIsBuffering] = useState<boolean>(false);

  const [shouldMountPlayer, setShouldMountPlayer] = useState<boolean>(true);
  const [areControlsVisible, setAreControlsVisible] = useState<boolean>(true);

  useEffect(() => {
    setIsReady(false);
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    setIsFirstPlay(true);

    setError(false);
    setIsPlaying(false);
    setSavedSeconds(0);
    setPlayedSeconds(0);
  }, [leccion?.id]);

  useEffect(() => {
    valuesRef.current.duration = duration;
    valuesRef.current.played = playedSeconds;
  }, [duration, playedSeconds]);

  useEffect(() => {
    if (shouldMountPlayer && isReady && player?.current) seekTo(playedSeconds);
    if (shouldMountPlayer && player?.current) seekTo(playedSeconds);
  }, [shouldMountPlayer]);

  const showControls = (shouldSetTimeout: boolean) => {
    setAreControlsVisible(true);
    clearTimeout(controlsTimeout);

    if (shouldSetTimeout) controlsTimeout = setTimeout(() => setAreControlsVisible(false), 2000);
  };

  /** Método interno para navegar a los segundos del video marcados. */
  const seekTo = async (seconds: number) => {
    if (seconds)
      try {
        await player?.current?.seekTo(seconds);

      } catch (error) {
        console.error('Error al cambiar el tiempo 😅');
      }
  };

  const handleOnReady = async (msg: any) => {

    /** Verificamos que no vayamos a navegar a unos segundos del video incorrectos. */
    if (savedSeconds !== undefined && savedSeconds >= 0) await seekTo(savedSeconds);

    await setIsPlaying(false);
    setIsReady(true);

    return true;
  };

  function handleOnSeek(seconds: number) {
    setPlayedSeconds(seconds);
  }

  const handleOnPlayClick = () => {
    if (!leccion?.contenido) return;

    setIsPlaying(!isPlaying);

    setIsFirstPlay(false);
    onPlay();
  };

  const handleOnPlay = () => {
    console.log(`📹 Reproduciendo vídeo... La url ${src ? 'SI' : 'NO'} está cargada`);

    setIsPlaying(true);
  };

  function handleOnPause() {
    setIsPlaying(false);
  }

  const handleChangeQuality = async (quality: Quality) => {
    await setShouldMountPlayer(false);
    await setQuality(quality);
    await setShouldMountPlayer(true);
  };

  return error ? (
    <Center color="white" w="100%" bg="#000" style={{ aspectRatio: '16/9' }}>
      <Icon m="20px" fontSize="7xl" as={BiError} />

      <Flex fontWeight="bold" fontSize="3xl">
        Se ha producido un error inesperado
      </Flex>

      <Flex fontSize="xl">Inténtalo de nuevo más tarde</Flex>
    </Center>
  ) : (
    <FullScreen handle={fullscreenHandler} className="fullscreen-container hidden">
      <Flex boxSize="100%" position="relative" align="center">
        <Flex
          bg="#000"
          align="center"
          w="100%"
          position="relative"
          h={fullscreenHandler.active ? "100%" : "450px"}
        >
          <ReactPlayer
            ref={player}
            pip={true}
            controls={false}
            url={leccion?.contenido || undefined}
            width="100%"
            muted={isMuted}
            playing={isPlaying}
            stopOnUnmount={false}
            volume={(volume || 0) / 100}
            playbackRate={playbackSpeed || 1}
            height={fullscreenHandler.active ? 'unset' : '100%'}
            onSeek={handleOnSeek}
            onPlay={handleOnPlay}
            onReady={handleOnReady}
            onPause={handleOnPause}
            onDuration={setDuration}
            onBuffer={() => setIsBuffering(true)}
            onBufferEnd={() => setIsBuffering(false)}
            onProgress={(p) => setPlayedSeconds(p?.playedSeconds)}
            config={{ vimeo: { playerOptions: { quality, responsive: true, pip: true } } }}
            style={{ aspectRatio: '16/9', display: shouldMountPlayer ? 'unset' : 'none' }}
          />

          <Flex
            top="0px"
            left="0px"
            right="0px"
            bottom="54px"
            align="center"
            justify="center"
            cursor="pointer"
            position="absolute"
            onClick={handleOnPlayClick}
            onMouseMove={() => showControls(true)}
            bg={isBuffering || !isPlaying ? 'blackAlpha.400' : 'transparent'}
          >
            {isBuffering && <Spinner size="xl" color="white" />}

            {!isBuffering && !isPlaying && <Icon as={BiPlay} boxSize="90px" color="#FFF" />}
          </Flex>

          <Controls
            volume={volume}
            quality={quality}
            isMuted={isMuted}
            maxSpeed={maxSpeed}
            minSpeed={minSpeed}
            duration={duration}
            isPlaying={isPlaying}
            playedSeconds={playedSeconds}
            playbackSpeed={playbackSpeed}
            isMobilePlayer={isMobilePlayer}
            fullscreenHandler={fullscreenHandler}
            areControlsVisible={areControlsVisible}
            seekTo={seekTo}
            setVolume={setVolume}
            setIsMuted={setIsMuted}
            setIsPlaying={setIsPlaying}
            showControls={showControls}
            setPlaybackSpeed={setPlaybackSpeed}
            handleChangeQuality={handleChangeQuality}
          />
        </Flex>
      </Flex>
    </FullScreen>
  );
};
