import { Flex, Text, useToast } from "@chakra-ui/react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { useEffect, useState } from "react";
import { BiBookContent, BiUserCircle } from "react-icons/bi";
import { TabPerfilExamen } from "./TabItem/TabPerfil";
import { TabContenidoExamen } from "./TabItem/TabContenido";
import { updateExamen } from "../../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { TabsRouterInt } from "../../../../../interfaces/ComponentsInt";
import { TabsRouter } from "../../../../../shared/components/TabsRouter/TabsRouter";
import { Helmet } from "react-helmet";

export const TabsExamenesInformation = () => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const { data: examen, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.EXAMENES,
        client: client
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/examenes/examenes/${id}/perfil`,
            `/examenes/examenes/${id}/contenido`,
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("perfil");
        }
    }, [location.pathname]);

    const updateValue = async (value: any) => {
        console.log("aijdaosijdasl")
        if (!id) return;

        return updateExamen({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "perfil",
            label: "Perfil",
            icon: BiUserCircle,
            content:
                <TabPerfilExamen
                    examen={examen}
                    updateValue={updateValue}
                    loading={loading}
                    Refresh={Refresh}
                />
        },
        {
            isAllowed: true,
            path: "contenido",
            label: "Contenido",
            icon: BiBookContent,
            content:
                <TabContenidoExamen
                    loading={loading}
                    examen={examen}
                    Refresh={Refresh}
                />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: true,
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.EXAMEN}
        //         />
        // }
    ];

    return (
        <>
            <Helmet>
                <title>Exámenes </title>
            </Helmet>

            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        Exámenes
                    </Text>
                }
                buttonBack="/examenes/examenes"
            >
                <Flex
                    direction="column"
                    px="20px"
                    pt="20px"
                    width="100%"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Topbar>
        </>
    )
}