import { Box, Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { RespuestaInt } from "../../../../interfaces/ExamenInt";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { deleteRespuesta, updateRespuesta } from "../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useState } from "react";
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";

interface Props {
    respuesta: RespuestaInt;
    Refresh: () => void;
    setClearRespuestas: (value: boolean) => void;
}

export const EditRespuesta = ({ respuesta, Refresh, setClearRespuestas }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false)
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const initialValues = {
        contenido: respuesta?.contenido,
        correcta: respuesta?.correcta,
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);

        updateRespuesta({
            id: respuesta?.id as string,
            data: { ...values },
            client: client
        })
            .then(() => {
                Refresh()
                setClearRespuestas(true)
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    const onDelete = () => {

        if (!respuesta?.id) {
            toastNotify(toast, StatusEnumTypes.ERROR, "ID de respuesta no válido");
            return;
        }

        deleteRespuesta({ respuestaId: respuesta?.id, client: client })
            .then(async() => {
                await Refresh()

                setClearRespuestas(true)
                
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Respuesta eliminada correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const isExistsChanges = (values: {
        contenido: string | undefined;
        correcta: boolean | undefined;
    }) => {
        if (
            values?.contenido !== respuesta?.contenido ||
            values?.correcta !== respuesta?.correcta
        ) return true

        return false
    }

    return (
        <Flex
            w="100%"
            direction="column"
            px="5px"
            pt="10px"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex>
                                <InformationBox
                                    noHeader
                                >
                                    <Flex
                                        direction="column"
                                        w="full"
                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                            justifyContent="space-between"
                                            my="20px"
                                            px="20px"
                                        >
                                            <Text
                                                w="full"
                                                color="main"
                                                fontSize="18px"
                                                fontWeight="500"
                                            >
                                                Respuesta
                                            </Text>

                                            <Flex
                                                w="full"
                                                gap="10px"
                                                justifyContent="flex-end"
                                            >
                                                <Button
                                                    h="fit-content"
                                                    w="fit-content"
                                                    py="10px"
                                                    px="10px"
                                                    rounded={"6px"}
                                                    bg="secondary"
                                                    fontSize="14px"
                                                    color="purewhite"
                                                    fontWeight="400"
                                                    type="submit"
                                                    isDisabled={!respuesta?.id || !isExistsChanges(values)}
                                                    isLoading={loading}
                                                >
                                                    Guardar pregunta
                                                </Button>

                                                <Button
                                                    isDisabled={loading || !isExistsChanges(values)}
                                                    h="fit-content"
                                                    w="fit-content"
                                                    py="10px"
                                                    px="10px"
                                                    rounded={"6px"}
                                                    border="1px solid"
                                                    borderColor="#AAA"
                                                    bg="white"
                                                    fontSize="14px"
                                                    color="pureblack"
                                                    fontWeight="400"
                                                    onClick={() => formik.resetForm()}
                                                >
                                                    Descartar cambios
                                                </Button>
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            px="20px"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            gap={"5px"}
                                        >
                                            <FormTextarea
                                                noHead
                                                name="contenido"
                                                ui={{
                                                    minHeigh: "150px",
                                                    styleLabel: {
                                                        color: "font",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                    },
                                                    styles: {
                                                        border: "1px solid",
                                                        borderColor: "#E2E8F0",
                                                        borderRadius: "5px",
                                                        color: "font",
                                                        padding: "5px",
                                                        maxHeight: "100px",
                                                    }
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            pb="20px"
                                            direction="column"
                                            alignItems="start"
                                            justifyContent="space-between"
                                            gap="20px"
                                        >
                                            <CustomSwitch
                                                label="Respuesta correcta"
                                                optionLeft="Si"
                                                optionRight="No"
                                                value={formik.values?.correcta}
                                                setValue={() => formik.setFieldValue('correcta', !formik.values.correcta)}
                                                colorSwitch="light_grey"
                                                labelStyles={{
                                                    color: "font",
                                                    fontSize: "15px",
                                                    fontWeight: "600",
                                                    flex: "1",
                                                }}
                                            />
                                            <Button
                                                border="1px solid"
                                                borderColor="light_grey"
                                                color="font"
                                                bg="white"
                                                fontSize="14px"
                                                mx="20px"
                                                onClick={onOpenDelete}
                                            >
                                                Eliminar respuesta
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </InformationBox>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label="respuesta"
            />

        </Flex >
    )
}