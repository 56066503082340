import React, { useContext } from "react";
import { UserContextInt, UserInt } from "../../interfaces/UserInt";

export const AuthContext = React.createContext<UserContextInt>({
    user: {
        auth: localStorage.getItem('tokenPanel') ? true : false,
        id: null,
        email: null,
        username: null,
        avatar: null,
        nombre: null,
        apellidos: null,
        localidad: null,
        telefono: null,
        role: null,
        sepe: null,
    },
    setUser: ( action: any ) => {},
    login: ( jwt: string, user: UserInt, navigate: (path: string) => void ) => {},
    logout: ( message: string, navigate: (path: string) => void, toast: any ) => {},
    refreshUser: (updateUser: Partial<UserInt>) => {}
});

export const useAuthContex = () => {
    return useContext(AuthContext);
}