import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { useDataId } from "../../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../../shared/context/client.context";
import { useData } from "../../../../../../shared/hooks/useData";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";

interface Props {
    grupoId: string;
    userId: string;
}

export const TabsAsistencias = ({
    grupoId,
    userId
}: Props) => {
    const client = useClient()
    const timeZone = useTimeZone()

    const { data: firmas } = useData({
        endpoint: EndpointTypes.FIRMAS,
        client: client,
        query: {
            userId
        }
    })

    const { data } = useDataId({
        endpoint: EndpointTypes.ASISTENCIAS,
        id: (!grupoId || !userId) ? undefined : `${grupoId}/${userId}`,
        client: client,
    })

    return (
        <Flex>
            <Flex
                py="40px"
                px="40px"
                border="1px solid #E6E6EA"
                bg="purewhite"
                rounded="20px"
            >
                <Flex
                    direction="column"
                    w="100%"
                >
                    <Flex
                        color="font"
                        fontSize="15px"
                        fontWeight="700"
                        lineHeight="16px"
                        letterSpacing="-0.078px"
                        bg="#E6E6EA"
                        py="10px"
                        rounded="10px"
                        mb="10px"
                    >
                        <Text
                            w="300px"
                            pl="20px"
                        >
                            Lección
                        </Text>

                        <Text
                            w="150px"
                            pl="20px"
                        >
                            Presente
                        </Text>

                        <Text
                            w="150px"
                            pl="20px"
                        >
                            Ausente
                        </Text>

                        <Text
                            w="200px"
                            pl="20px"
                        >
                            Fecha y hora
                        </Text>

                        <Text
                            w="150px"
                            pl="20px"
                        >
                            Firma
                        </Text>
                    </Flex>

                    {data?.map((a: any, index: number) => (
                        <Flex
                            alignItems="center"
                        >
                            <Flex
                                h="50px"
                                pl="20px"
                                alignItems="center"
                                border="1px solid #E6E6EA"
                                w="300px"
                                roundedTopLeft={index === 0 ? "10px" : "0px"}
                                roundedTopRight={index === 0 ? "10px" : "0px"}
                            >
                                {a?.meta?.nombre}
                            </Flex>

                            <Flex
                                pl="20px"
                                h="50px"
                                alignItems="center"
                                border="1px solid #E6E6EA"
                                w="150px"
                            >
                                <Flex
                                    boxSize="22px"
                                    border="3px solid"
                                    borderColor={"success"}
                                    rounded="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    opacity={a?.meta?.asistencia === "true" ? 1 : 0}
                                >
                                    <Box
                                        rounded="100%"
                                        bg={"success"}
                                        boxSize="10px"
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                pl="20px"
                                h="50px"
                                alignItems="center"
                                border="1px solid #E6E6EA"
                                w="150px"
                                justifyContent="start"
                            >
                                <Flex
                                    boxSize="22px"
                                    border="3px solid"
                                    borderColor={"fail"}
                                    rounded="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    opacity={a?.meta?.asistencia === "true" ? 0 : 1}
                                >
                                    <Box
                                        rounded="100%"
                                        bg={"fail"}
                                        boxSize="10px"
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                pl="20px"
                                h="50px"
                                alignItems="center"
                                border="1px solid #E6E6EA"
                                w="200px"
                                color="font"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="14px"
                                letterSpacing="-0.06px"
                            >
                                {formatTimezone({
                                    date: a?.meta?.sesion_vivo?.fechaInicio,
                                    timeZone: timeZone,
                                    customFormat: "dd/MM/yyyy HH:mm"
                                })}
                            </Flex>

                            <Flex
                                pl="20px"
                                h="50px"
                                alignItems="center"
                                border="1px solid #E6E6EA"
                                w="150px"
                                roundedTopRight={index === 0 ? "10px" : "0px"}
                                roundedBottomRight={index === data?.length - 1 ? "10px" : "0px"}
                            >
                                {firmas?.find((f: any) => f?.leccionId === a?.leccionId)?.id
                                    ?
                                    <Text
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="14px"
                                        letterSpacing="-0.06px"
                                    >
                                        Fimado
                                    </Text>
                                    :
                                    <Text
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="14px"
                                        letterSpacing="-0.06px"
                                    >
                                        No firmado
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <Flex
                flex="1"
            />
        </Flex>
    )
}
