import { Button, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { EmpresasInt } from "../../../../../interfaces/EmpresasInt";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { NewAlumnoModal } from "../../../../Usuarios/components/Modals/NewAlumnoModal";
import { useClient } from "../../../../../shared/context/client.context";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Table } from "../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { AlumnosColumns } from "../../../components/TabsColumns/AlumnosColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

interface Props {
    empresa: EmpresasInt | undefined;
}

export const TabAlumnos = ({ empresa }: Props) => {
    const client = useClient();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ALUMNOS_EMPRESA });
    const { data: alumnos, loading, Refresh } = useDataRelationship({
        id: empresa?.id,
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        relationship: RelationshipTypes.ALUMNOS,
        query: query,
    })

    return (
        <Flex gap="20px" direction="column">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                showBody={false}
                button={
                    <Button
                        onClick={onOpen}
                        bg="secondary"
                        color="#FFF"
                        p="8px 15px"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="500"
                        leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                        _hover={{ bg: "variant" }}
                    >
                        Nuevo alumno
                    </Button>
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={alumnos?.data || []}
                columns={AlumnosColumns()}
                isLoading={loading}
                total={alumnos?.meta?.total || 0}
            />

            <NewAlumnoModal
                isOpen={isOpen}
                onClose={onClose}
                setRefreshTable={Refresh}
                section="empresa"
                empresaId={empresa?.id}
            />
        </Flex>
    );
}