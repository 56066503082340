import { Flex } from "@chakra-ui/react"
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar"
import { ColumnProps } from "../../../../../../shared/components/Table/Table"
import { Actions } from "../../../../../../shared/components/TableElements/Actions"
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge"

interface Props {
    onEventRemove: (event: any) => void;
}

export const SupervisoresColumns = ({ onEventRemove }: Props): ColumnProps[] => {
    const column: ColumnProps[] = [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/usuarios/supervisores/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={{
                        to: `/usuarios/supervisores/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => {
                return (
                    <ColumnSimpleText
                        text={rowData?.empresa?.nombre}
                        link={{
                            to: `/empresas/${rowData?.empresa?.id}/perfil`,
                        }}
                    />
                )
            },
        },
        {
            header: 'Teléfono',
            field: 'telefono',
            key: 'telefono',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.telefono}
                    link={{
                        to: `/usuarios/supervisores/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'grupo',
            field: 'grupo',
            key: 'grupo',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={(rowData?.meta?.grupos_count || 0) + " grupos"}
                    link={{
                        to: `/usuarios/supervisores/${rowData.id}/perfil`,
                    }}
                />
        },
        {
            header: 'Estado',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.activo ? "Activo" : "Inactivo"}
                    ui={{
                        backgroundColor: rowData?.activo ? "success_bg" : "light_grey",
                        colorFont: rowData?.activo ? "success_main" : "font",
                        fontSize: "10px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.activo ? "success_variant" : "font",
                            borderWidth: "1px",
                            textTransform: "uppercase",
                            textAlign: "center",
                        }
                    }}
                    link={{
                        to: `/usuarios/supervisores/${rowData.id}/perfil`,
                    }}
                />
            )
        },
    ]

    const actionsColumn: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar supervisor",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        },
    ]

    return [...column, ...actionsColumn]
}