import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Text,
    Flex,
    Button,
    Icon
} from '@chakra-ui/react'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { BiDownload, BiFile } from 'react-icons/bi';
import { EntregableEstadoEnum } from '../../../shared/utils/Types/EntregableEstadoEnum';
import { useTimeZone } from '../../../shared/hooks/useTimeZone';
import { formatTimezone } from '../../../shared/utils/functions/parseDate';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    entregable: any;
}

export const CorreccionDrawer = ({ entregable, isOpen, onClose }: Props) => {
    const timeZone = useTimeZone();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="40vw"
                minW="40vw"
                w="40vw"
            >
                <DrawerBody
                    className='scroll-bar'
                >
                    <Flex
                        direction="column"
                        gap="50px"
                        p="40px 20px"
                        alignItems="flex-start"
                    >
                        <Flex
                            direction="column"
                            gap="15px"
                            w="100%"
                        >
                            <Text
                                fontSize="20px"
                                fontWeight="600"
                                borderBottom="1px solid"
                                borderColor="black"
                                w="fit-content"
                            >
                                Alumno
                            </Text>


                            <Flex
                                direction="column"
                                gap="5px"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    {entregable?.user?.nombre + " " + entregable?.user?.apellidos}
                                </Text>

                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Grupo - {entregable?.matricula?.grupo?.nombre}
                                </Text>

                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Lección - {entregable?.leccion?.nombre}
                                </Text>

                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Curso - {entregable?.curso?.nombre}
                                </Text>

                                <Text>
                                    Fecha entrega - {entregable?.fechaEntrega ? formatTimezone({ date: entregable?.fechaEntrega, timeZone, isDateTime: true }) : "---"}
                                </Text>
                            </Flex>

                            {(entregable?.adjunto?.url && entregable?.adjunto?.url !== "") &&
                                <Flex w="100%" p="10px 15px" bg="#AFB8CF" borderRadius="5px" alignItems="center" gap="10px">
                                    <Icon color="black" boxSize="22px" as={BiFile} />

                                    <Flex w="100%" alignItems="center">
                                        <Text
                                            w="100%"
                                            color="font"
                                            fontSize="13px"
                                            fontWeight="500"
                                        >
                                            {entregable?.adjunto?.name}
                                        </Text>

                                        <Button
                                            fontSize="13px"
                                            fontWeight="500"
                                            letterSpacing="-0.1px"
                                            color="#FFF"
                                            bg="inherit"
                                            border="1px solid #FFF"
                                            h="fit-content"
                                            p="7px 14px"
                                            leftIcon={
                                                <Icon as={BiDownload} boxSize="18px" />
                                            }
                                            onClick={() => location.href = entregable?.adjunto?.url}
                                        >
                                            Descargar
                                        </Button>
                                    </Flex>
                                </Flex>
                            }

                            <Text
                                fontSize="16px"
                                fontWeight="600"
                            >
                                Comentario:
                            </Text>

                            <Text
                                fontSize="16px"
                                fontWeight="400"
                            >
                                {entregable?.texto}
                            </Text>
                        </Flex>

                        <Flex
                            direction="column"
                            gap="15px"
                        >
                            <Text
                                fontSize="20px"
                                fontWeight="600"
                                borderBottom="1px solid"
                                borderColor="black"
                                w="fit-content"
                            >
                                Tutor
                            </Text>

                            {entregable?.correccion ?
                                <Flex
                                    direction="column"
                                    gap="15px"
                                    w="100%"
                                >
                                    <Text>
                                        Fecha corrección - {entregable?.correccion?.createdAt ?
                                            formatTimezone({ date: entregable?.correccion?.createdAt, timeZone, isDateTime: true })
                                            : "---"
                                        }
                                    </Text>

                                    <Flex
                                        alignItems="center"
                                        gap="20px"
                                    >
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                        >
                                            Puntuación - {entregable?.correccion?.puntuacion || "--"}
                                        </Text>

                                        <Text
                                            fontSize="13px"
                                            fontWeight="500"
                                            letterSpacing="0.26px"
                                            textTransform="uppercase"
                                            rounded="5"
                                            p="5px 10px"
                                            color={
                                                entregable?.estado === EntregableEstadoEnum.ERROR ? 'pureblack'
                                                    : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'pureblack'
                                                        : 'white'
                                            }
                                            bg={
                                                entregable?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                                                    : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg'
                                                        : 'pureblack'
                                            }
                                            w="fit-content"
                                        >
                                            {
                                                entregable?.estado === EntregableEstadoEnum.ERROR ? 'No superado'
                                                    : entregable?.estado === EntregableEstadoEnum.CORRECTO ? 'Superado'
                                                        : "pendiente"
                                            }
                                        </Text>
                                    </Flex>

                                    {(entregable?.correccion?.adjunto?.url && entregable?.adjunto?.url !== "") &&
                                        <Flex w="100%" p="10px 15px" bg="#AFB8CF" borderRadius="5px" alignItems="center" gap="10px">
                                            <Icon color="black" boxSize="22px" as={BiFile} />

                                            <Flex w="100%" alignItems="center">
                                                <Text
                                                    w="100%"
                                                    color="font"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                >
                                                    {entregable?.correccion?.adjunto?.name}
                                                </Text>

                                                <Button
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                    letterSpacing="-0.1px"
                                                    color="#FFF"
                                                    bg="inherit"
                                                    border="1px solid #FFF"
                                                    h="fit-content"
                                                    p="7px 14px"
                                                    leftIcon={
                                                        <Icon as={BiDownload} boxSize="18px" />
                                                    }
                                                    onClick={() => location.href = entregable?.correccion?.adjunto?.url}
                                                >
                                                    Descargar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    }

                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                    >
                                        Comentario:
                                    </Text>

                                    <MarkdownPreview
                                        source={entregable?.correccion?.texto || "Sin comentario"}
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#202020',
                                            width: '100%',
                                            maxWidth: '100%',
                                            background: "transparent",
                                        }}
                                    />
                                </Flex>
                                :
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="fail"
                                >
                                    Sin corrección
                                </Text>
                            }
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}