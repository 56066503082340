import { Flex, Icon, Image, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { LeccionInt } from "../../../interfaces/CursosInt"
import { BsArrowsFullscreen } from "react-icons/bs";

interface Props {
    leccion: LeccionInt;
    isModal?: boolean
}

export const ImagenViewer = ({ leccion, isModal }: Props) => {
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();

    return (
        <Flex
            py="20px"
            px="20px"
            w="100%"
            direction="column"
            justifyContent="center"
            gap="5px"
            alignItems="center"
        >
            <Image
                src={leccion?.contenido as string}
                alt="imagen"
                w="100%"
                h="auto"
                maxH={!isModal ? "450px" : "550px"}
                objectFit={"contain"}
            />

            <Flex
                bg="#444444"
                w="100%"
                justifyContent="end"
                py="5px"
                px="10px"
                rounded="4px"
            >
                <Icon
                    as={BsArrowsFullscreen}
                    boxSize="20px"
                    mr="10px"
                    color="purewhite"
                    cursor="pointer"
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();

                        onOpenFullScreen();
                    }}
                />
            </Flex>

            <ModalFullScreen
                isOpen={isOpenFullScreen}
                onClose={onCloseFullScreen}
                leccion={leccion}
            />
        </Flex>
    )
}


interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | null | undefined;
}

const ModalFullScreen = ({ isOpen, onClose, leccion }: PropsModal) => {

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w="100%"
                    direction="column"
                    justifyContent="center"
                    gap="5px"
                    alignItems="center"
                >
                    <Image
                        src={leccion?.contenido as string}
                        alt="imagen"
                        h={"calc(100vh - 35px)"}
                        objectFit={"contain"}
                    />

                    <Flex
                        bg="#444444"
                        w="100%"
                        justifyContent="end"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onClose();
                            }}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}