import Select from 'react-select';
import { filterSelectStyles } from '../../../ui/filterSelectStyles';

interface Props {
  value: any;
  options: { label: string; value: any }[];
  onChange: (e: any) => void;
  placeholder?: string;
  defaultValue?: { label: string; value: any };
  isMulti?: boolean;
  styles?: any;
}

export const FilterSelect = ({
  value,
  options,
  onChange,
  placeholder = 'Escribe para buscar',
  defaultValue,
  isMulti = false,
  styles,
}: Props) => {
  return (
    <Select
      isMulti={isMulti}
      value={value}
      options={options}
      defaultValue={defaultValue}
      isClearable={false}
      onChange={onChange}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        ...filterSelectStyles,
        ...styles
      }}
      closeMenuOnSelect={!isMulti}
    />
  );
};