import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addSupervisorGrupo = async ({
    data,
    client
} : {
    data: {
        userId: string;
        grupoId: string;
    },
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/supervisores`,
        data,
        {}
    )
};

export const updateSupervisorGrupo = async ({
    id, 
    data,
    client
}: { 
    id: string;
    data: any;
    client: ClientInt; 
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/supervisores/${id}`,
        data,
        {}
    )
};

export const removeSupervisorGrupo = async ({ id, client } : { id: string, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/supervisores/${id}`,
        {}
    )
};