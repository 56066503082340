import { ComponentConfig, DropZone } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { TextVariable } from "../../CustomElements/Fields/TextVariable";

const getClassName = getClassNameFactory("Condicionales", styles);

export type CondicionalesProps = {
    items: {
        content?: any;
        variable?: string;
    }[];
};

export const Condicionales: ComponentConfig<CondicionalesProps> = {
    fields: {
        items: {
            type: "array",
            arrayFields: {
                variable: {
                    type: "custom",
                    label: "Variable",
                    render: ({ name, onChange, value }: any) => {
                        const textarea = document.querySelector(`textarea[name="${name}"]`) as HTMLTextAreaElement;

                        return (
                            <TextVariable
                                textarea={textarea}
                                value={value}
                                name={name}
                                onChange={onChange}
                                minHeight="40px"
                            />
                        )
                    }
                }
            },
            label: "Contenido",
            getItemSummary: (_, id = -1) => `Condicional ${id + 1}`,
        }
    },
    defaultProps: {
        items: [
            {},
            {}
        ]
    },
    render: ({ items }) => {
        return (
            <table
                className={getClassName()}
                width={"100%"}
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
            >
                <tbody>
                    <tr>
                        <td>
                            <span>
                                {"\n"} @if({items[0].variable?.trim().replaceAll("{{", "").replaceAll("}}", "").trim()}) {"\n"}
                            </span>
                        </td>
                    </tr>
                    {items.map((_, idx) => {
                        const totalItems = items.length;

                        return (
                            <>
                                {idx !== 0 &&
                                    <tr>
                                        <td>
                                            <span>
                                                {totalItems > 2 && idx !== (totalItems - 1) ? `\n @elseif(${items[idx].variable?.trim().replaceAll("{{", "").replaceAll("}}", "").trim() || ""}) \n` : `\n @else \n`}
                                            </span>
                                        </td>
                                    </tr>
                                }

                                <tr key={idx} >
                                    <td
                                        className={getClassName("cell")}
                                    >
                                        <DropZone
                                            zone={`column-content-${idx}`}
                                            disallow={["Hero", "Logos", "Stats"]}
                                        />
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                    <tr>
                        <td>
                            <span>
                                {"\n"} @end {"\n"}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table >
        );
    },
}; 