import { Flex, Skeleton } from '@chakra-ui/react'

export default function GruposConfigSkeleton({ moduloFUNDAE, isSupervisor }: { moduloFUNDAE: boolean, isSupervisor?: boolean }) {
    return (

        <Flex gap="20px">

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="400px" rounded="10px" />
                <Skeleton height="200px" rounded="10px" />

            </Flex>

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="364px" rounded="10px" />

                {moduloFUNDAE || !isSupervisor &&
                    <Skeleton height="75px" rounded="10px" />
                }
                
                <Skeleton height="130px" rounded="10px" />

                {moduloFUNDAE || !isSupervisor &&
                    <Skeleton height="150px" rounded="10px" />
                }
            </Flex>


        </Flex>
    )
}
