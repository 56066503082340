import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { addUsuario, updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { validateDNI } from "../../../../shared/utils/functions/validateDNI";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { useEffect, useState } from "react";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { FormPhoneInput, phoneValidationYup } from "../../../../shared/components/FormElements/FormPhoneInput";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
    section: "usuario" | "grupo" | "empresa";
    empresaId?: string;
    grupoEmpresas?: EmpresasInt[]
    handleSelectAlumno?: (alumno: any) => void;
}

export const NewAlumnoModal = ({
    isOpen,
    onClose,
    setRefreshTable,
    section,
    empresaId,
    grupoEmpresas,
    handleSelectAlumno = () => { }
}: Props) => {
    const { user, refreshUser } = useAuthContex();
    const { total } = useUserPolicy();
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const [empresasGrupo, setEmpresasGrupo] = useState<{ value: string, label: string }[]>([]);

    useEffect(() => {
        const optionsEmpresas: { value: string, label: string }[] = []

        grupoEmpresas?.map((empresa: EmpresasInt) => {
            if (empresa?.id && empresa?.nombre)
                return optionsEmpresas.push({ value: empresa?.id, label: empresa?.nombre })
        })

        setEmpresasGrupo(optionsEmpresas)

    }, [grupoEmpresas])


    const loadEmpresas = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: QueryTypes.NOMBRE,
    })

    const initialValues = {
        nombre: null,
        apellidos: null,
        email: null,
        telefono: null,
        empresaId: (section === "empresa" && empresaId) ? empresaId : (section === "grupo" && empresasGrupo?.length == 1) ? empresasGrupo[0]?.value : null,
        dni: null,
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        apellidos: Yup.string()
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        telefono: Yup.string()
            .test('validator-custom-phone', function (value) {
                const validation = phoneValidationYup(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }).nullable().notRequired(),
        dni: Yup.string()
            .required('El DNI es obligatorio.')
            .test('dni-valido', 'El DNI/NIF ingresado no es válido.', validateDNI)
            .typeError('El DNI es obligatorio.'),
        empresaId: Yup.string()
            .required('Debe asociar una empresa al alumno.')
            .typeError('Debe asociar una empresa al alumno.'),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const newUsuario: any = {
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            telefono: values.telefono,
            dni: values.dni,
            empresaId: values.empresaId
        }

        addUsuario({
            data: newUsuario,
            client: client
        })
            .then(async (response: any) => {
                if (!user?.config?.alumnos && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                alumnos: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    alumnos: true
                                }
                            })
                        })
                }

                const alumno = response?.data?.data
                setRefreshTable();
                handleSelectAlumno({ value: alumno, label: `${alumno?.nombre} ${alumno?.apellidos}` })
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado correctamente`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo alumno
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                                mb="30px"
                                            >
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="700"
                                                    mb="5px"
                                                >
                                                    Datos del alumno
                                                </Text>

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.nombre ? true : false}
                                                        name="nombre"
                                                        label="Nombre"
                                                        placeholder="Nombre del alumno"
                                                    />

                                                    <FormInput
                                                        isRequired={!values?.apellidos ? true : false}
                                                        name="apellidos"
                                                        label="Apellidos"
                                                        placeholder="Apellidos del alumno"
                                                    />
                                                </Flex>

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    placeholder="Email del alumno"
                                                />

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.dni ? true : false}
                                                        name="dni"
                                                        label="DNI"
                                                        placeholder="Dni del alumno"
                                                    />

                                                    <FormPhoneInput
                                                        name="telefono"
                                                        label="Teléfono"
                                                        placeholder="Teléfono del alumno"
                                                    />
                                                </Flex>
                                            </Flex>

                                            {section === "usuario" &&
                                                <FormAsyncSelect
                                                    isRequired={!values?.empresaId ? true : false}
                                                    name="empresaId"
                                                    label="Empresa"
                                                    loadOptions={loadEmpresas}
                                                    defaultOptions={true}
                                                    placeholder="Empresa del alumno"
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                        styleLabel: { fontSize: "18px", fontWeight: "700" }
                                                    }}
                                                />
                                            }

                                            {(section === "grupo" && empresasGrupo?.length > 1) &&
                                                <Flex direction="column" mb="30px" gap="15px">
                                                    <Text
                                                        color="black"
                                                        fontSize="18px"
                                                        fontWeight="600"
                                                    >
                                                        Empresa
                                                    </Text>

                                                    <FormSelect
                                                        noHead={true}
                                                        name="empresaId"
                                                        options={empresasGrupo}
                                                        isRequired={values?.empresaId ? false : true}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                </Flex>
                                            }
                                        </ModalBody>

                                        <ModalFooter
                                            justifyContent="center"
                                            mt="35px"
                                        >
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear alumno
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}