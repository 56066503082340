import { ComponentConfig } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { backgroundColors, textColors } from "../constants";
import { TextVariable } from "../../CustomElements/Fields/TextVariable";

const getClassName = getClassNameFactory("EmailProgress", styles);

export type EmailProgressProps = {
    value: string;
    maxValue: number;
    label: string;
    height: string;
    backgroundColor: string;
    progressColor: string;
    showPercentage: string;
    align: "left" | "center" | "right";
    size: string;
    weight: string;
};

export const EmailProgress: ComponentConfig<EmailProgressProps> = {
    fields: {
        value: {
            type: "custom",
            label: "Porcentaje",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector('textarea[name="value"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                        minHeight="40px"
                    />
                )
            }
        },
        maxValue: {
            type: "number",
            label: "Valor máximo",
        },
        label: {
            type: "text",
            label: "Etiqueta",
        },
        height: {
            type: "select",
            label: "Altura de la barra",
            options: [
                { label: "Pequeña", value: "10px" },
                { label: "Media", value: "15px" },
                { label: "Grande", value: "20px" },
            ],
        },
        backgroundColor: {
            type: "select",
            label: "Color de fondo",
            options: backgroundColors,
        },
        progressColor: {
            type: "select",
            label: "Color de progreso",
            options: textColors,
        },
        align: {
            type: "select",
            label: "Alineación",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ],
        },
        size: {
            type: "select",
            label: "Tamaño de fuente",
            options: [
                { label: "S", value: "s" },
                { label: "M", value: "m" },
                { label: "L", value: "l" },
            ],
        },
        weight: {
            type: "select",
            label: "Grosor de fuente",
            options: [
                { label: "Normal", value: "normal" },
                { label: "Bold", value: "bold" },
            ],
        },
        showPercentage: {
            type: "radio",
            label: "Mostrar porcentaje",
            options: [
                { label: "Sí", value: "true" },
                { label: "No", value: "false" },
            ],
        },
    },
    defaultProps: {
        value: "75",
        maxValue: 100,
        label: "Progreso",
        height: "15px",
        backgroundColor: "#f0f0f0",
        progressColor: "#007bff",
        align: "left",
        showPercentage: "false",
        size: "m",
        weight: "normal",
    },
    render: ({
        value,
        maxValue,
        label,
        height,
        backgroundColor,
        progressColor,
        align,
        size,
        weight,
        showPercentage,
    }) => {

        return (
            <table
                className={getClassName()}
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
            >
                <tr>
                    <td
                        align={align}
                        style={{
                            paddingBottom: "8px"
                        }}
                    >
                        <span style={{
                            fontFamily: "Arial, sans-serif",
                            fontSize: size === "l" ? "16px" : size === "m" ? "14px" : "12px",
                            fontWeight: weight === "bold" ? "bold" : "normal",
                            color: "#333333",

                        }}>
                            {label} {showPercentage && `(${value}%)`}
                        </span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" role="presentation">
                            <tr>
                                <td style={{
                                    backgroundColor,
                                    borderRadius: "4px",
                                    padding: "0",
                                }}>
                                    <table cellPadding="0" cellSpacing="0" role="presentation" style={{
                                        width: `${value}%`,
                                    }}>
                                        <tr>
                                            <td style={{
                                                backgroundColor: progressColor,
                                                height,
                                                borderRadius: "4px",
                                            }}></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        );
    },
}; 