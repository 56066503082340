import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate";
import { Flex } from "@chakra-ui/react";
import { Actions } from "../../../../../../shared/components/TableElements/Actions";

interface Props {
    onEventRemove: (rowData: any) => void;
}

export const LiquidacionesColumns = ({ onEventRemove }: Props): ColumnProps[] => {

    return [
        {
            header: 'Tutor',
            field: 'tutor',
            key: 'tutor',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    link={{
                        to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Nº Factura',
            field: 'factura',
            key: 'factura',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.numeroFactura || "--"}
                    link={{
                        to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Fecha de Pago',
            field: 'fechaPago',
            key: 'fechaPago',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.fechaPago}
                    link={{
                        to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Pagado',
            field: 'liquidada',
            key: 'liquidada',
            sortable: true,
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.liquidada ? "Si" : "No"}
                    ui={{
                        backgroundColor: rowData?.liquidada ? "success_main" : "fail_main",
                        colorFont: rowData?.liquidada ? "success_bg" : "fail_bg",
                        fontSize: "12px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.liquidada ? "success_variant" : "fail_variant",
                            textTransform: "uppercase",
                            borderWidth: "1px"
                        }
                    }}
                    link={{
                        to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Importe total',
            field: 'importeTotal',
            key: 'importeTotal',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.importeTotal)?.toFixed(2) || 0 + "€"}
                    link={{
                        to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Empresa factura',
            field: 'formacion',
            key: 'formacion',
            sortable: true,
            body: (rowData: any) => (
                rowData?.empresaFactura?.nombre ?
                    <ColumnBadge
                        text={rowData?.empresaFactura?.nombre}
                        ui={{
                            backgroundColor: rowData?.empresaFactura?.nombre === "Imagina" ? "#081338" : "main",
                            colorFont: "purewhite",
                            fontSize: "12px",
                            fontWeight: 500,
                            rounded: "14px",
                            styles: {
                                textTransform: "uppercase",
                            }
                        }}
                        link={{
                            to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                        }}
                    />
                    :
                    <ColumnSimpleText
                        text={"---"}
                        link={{
                            to: `/facturacion/liquidaciones/${rowData?.user?.id}`,
                        }}
                    />
            )
        },
        {
            header: "",
            field: "",
            key: "",
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Revertir liquidación",
                            isDisabled: rowData?.liquidada,
                            disableAlert: "No se puede revertir una liquidación ya pagada",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        }
    ];
}