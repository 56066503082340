import { ComponentConfig, DropZone } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";

const getClassName = getClassNameFactory("EmailColumn", styles);

export type EmailColumnProps = {
    width: string;
    backgroundColor: string;
    border: string;
    borderRadius: string;
    boxShadow: string;
    items: { content?: any, height?: string, padding?: string }[];
};

export const EmailColumn: ComponentConfig<EmailColumnProps> = {
    fields: {
        width: {
            type: "select",
            label: "Ancho",
            options: [
                { label: "25%", value: "25%" },
                { label: "33%", value: "33.33%" },
                { label: "40%", value: "40%" },
                { label: "50%", value: "50%" },
                { label: "60%", value: "60%" },
                { label: "66%", value: "66.66%" },
                { label: "75%", value: "75%" },
                { label: "100%", value: "100%" },
            ],
        },
        backgroundColor: {
            type: "select",
            label: "Color de fondo",
            options: [
                { label: "Transparente", value: "transparent" },
                { label: "Blanco", value: "#ffffff" },
                { label: "Gris claro", value: "#f8f9fa" },
                { label: "Gris", value: "#e9ecef" },
            ],
        },
        border: {
            type: "select",
            label: "Borde",
            options: [
                { label: "Ninguno", value: "none" },
                { label: "Fino", value: "1px solid #dee2e6" },
                { label: "Medio", value: "2px solid #dee2e6" },
                { label: "Grueso", value: "3px solid #dee2e6" },
            ],
        },
        borderRadius: {
            type: "select",
            label: "Borde redondeado",
            options: [
                { label: "0", value: "0" },
                { label: "4px", value: "4px" },
                { label: "8px", value: "8px" },
                { label: "12px", value: "12px" },
            ],
        },
        boxShadow: {
            type: "select",
            label: "Sombra",
            options: [
                { label: "Ninguna", value: "none" },
                { label: "Suave", value: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)" },
                { label: "Media", value: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)" },
                { label: "Fuerte", value: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)" },
            ],
        },
        items: {
            type: "array",
            arrayFields: {
                height: {
                    type: "text",
                    label: "Altura",
                },
                padding: {
                    type: "select",
                    label: "Padding",
                    options: [
                        { label: "0", value: "0" },
                        { label: "8px", value: "8px" },
                        { label: "16px", value: "16px" },
                        { label: "24px", value: "24px" },
                    ],
                },
            },
            label: "Contenido",
            getItemSummary: (_, id = -1) => `Elemento ${id + 1}`,
        },
    },
    resolveData: async ({ props }) => {
        return { props }
    },
    defaultProps: {
        width: "100%",
        backgroundColor: "transparent",
        border: "none",
        borderRadius: "0",
        boxShadow: "none",
        items: [{
            height: "auto",
            padding: "0",
        }],
    },
    render: ({
        width,
        backgroundColor,
        border,
        borderRadius,
        boxShadow,
        items,
    }: EmailColumnProps) => {
        return (
            <table
                className={getClassName()}
                width={width}
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{
                    backgroundColor,
                    border,
                    borderRadius,
                    boxShadow
                }}
            >
                <tbody>
                    {items.map((c, idx) => (
                        <tr key={idx}>
                            <td
                                className={getClassName("cell")}
                                style={{
                                    padding: c?.padding,
                                    height: c?.height
                                }}
                            >
                                <DropZone
                                    zone={`column-content-${idx}`}
                                    disallow={["Hero", "Logos", "Stats"]}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    },
};
