export function formatKeys(value: string): string {
    if (!value || typeof value === "number") return value;

    let formattedValue = value.replace(/[-_]/g, ' ');
    formattedValue = formattedValue.replace(/([a-z])([A-Z])/g, '$1 $2');
    const words = formattedValue.split(' ');

    const capitalizedWords = words.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    const formattedString = capitalizedWords.join(' ');

    return formattedString;
}