import { Box, Button, Flex, Text, Tooltip } from "@chakra-ui/react"
import { CustomButton } from "../CustomElements/CustomButton";

interface Props {
    title: string;
    description: string;
    onClick: () => void;
    position: {
        top?: any;
        left?: any;
        right?: any;
        bottom?: any;
    }
    placement?: "top/left" | "top/right" | "bottom/left" | "bottom/right";
    isDisabled?: boolean;
    disabledTooltip?: string;
}

export const OnboardingStep = ({
    title,
    description,
    onClick,
    position,
    placement = "top/left",
    isDisabled = false,
    disabledTooltip
}: Props) => {

    return (
        <Flex
            zIndex="10"
            pos="absolute"
            top={position?.top}
            left={position?.left}
            right={position?.right}
            bottom={position?.bottom}
            alignItems={placement === "bottom/left" || placement === "bottom/right" ? "end" : "start"}
        >
            {(placement === "top/left" || placement === "bottom/left") &&
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    p="10px"
                    w="fit-content"
                    h="fit-content"
                    rounded="100%"
                    bg={"rgba(134, 148, 255, 0.2)"}
                    zIndex="99"
                >
                    <Box
                        rounded="100%"
                        boxSize="24px"
                        bg="secondary"
                    />
                </Flex>
            }

            <Flex
                bg="purewhite"
                mt={placement === "top/left" || placement === "top/right" ? "21px" : "0"}
                mb={placement === "bottom/left" || placement === "bottom/right" ? "21px" : "0"}
                ml={placement === "top/left" || placement === "bottom/left" ? "-23px" : "0"}
                mr={placement === "top/right" || placement === "bottom/right" ? "-23px" : "0"}
                p="20px"
                direction="column"
                minW="360px"
                maxW="460px"
                rounded="10px"
                shadow="0px 2px 8px 0px rgba(199, 205, 255, 0.60)"
            >
                <Text
                    color="pureblack"
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="24px"
                >
                    {title}
                </Text>

                <Text
                    color="pureblack"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="22px"
                    mt="10px"
                >
                    {description}
                </Text>

                <Tooltip
                    label={disabledTooltip}
                    placement="bottom"
                    hasArrow
                    bg="pureblack"
                    color="purewhite"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    rounded={"6px"}
                    p={"10px"}
                    textAlign="center"
                    isDisabled={!disabledTooltip || !isDisabled}
                >
                    <Button
                        isDisabled={isDisabled}
                        mt="20px"
                        ml="auto"
                        fontSize="14px"
                        fontWeight="600"
                        lineHeight="22px"
                        rounded="10px"
                        px="16px"
                        py="10px"
                        h="fit-content"
                        w="fit-content"
                        color="purewhite"
                        bg="secondary"
                        _hover={{ bg: "variant" }}
                        _disabled={{ bg: "disable_variant", _hover: {} }}
                        onClick={onClick}
                    >
                        Continuar
                    </Button>
                </Tooltip>
            </Flex>

            {(placement === "top/right" || placement === "bottom/right") &&
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    p="10px"
                    w="fit-content"
                    h="fit-content"
                    rounded="100%"
                    bg={"rgba(134, 148, 255, 0.2)"}
                    zIndex="99"
                >
                    <Box
                        rounded="100%"
                        boxSize="24px"
                        bg="secondary"
                    />
                </Flex>
            }
        </Flex>
    )
}