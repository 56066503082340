export const backgroundColors = [
    { label: "Transparente", value: "transparent" },
    { label: "Blanco", value: "#ffffff" },
    { label: "Gris muy claro", value: "#f8f9fa" },
    { label: "Gris claro", value: "#e9ecef" },
    { label: "Gris", value: "#dee2e6" },
    { label: "Azul claro", value: "#e3f2fd" },
    { label: "Azul muy claro", value: "#f0f7ff" },
    { label: "Verde claro", value: "#e8f5e9" },
    { label: "Verde muy claro", value: "#f0fff4" },
    { label: "Amarillo claro", value: "#fff9c4" },
    { label: "Naranja claro", value: "#fff3e0" },
    { label: "Rojo claro", value: "#ffebee" },
    { label: "Púrpura claro", value: "#f3e5f5" },
];

export const textColors = [
    { label: "Negro", value: "#000000" },
    { label: "Gris muy oscuro", value: "#212529" },
    { label: "Gris oscuro", value: "#343a40" },
    { label: "Gris", value: "#495057" },
    { label: "Gris medio", value: "#6c757d" },
    { label: "Gris claro", value: "#adb5bd" },
    { label: "Blanco", value: "#ffffff" },
    { label: "Azul", value: "#007bff" },
    { label: "Azul oscuro", value: "#0056b3" },
    { label: "Verde", value: "#28a745" },
    { label: "Verde oscuro", value: "#1e7e34" },
    { label: "Rojo", value: "#dc3545" },
    { label: "Rojo oscuro", value: "#bd2130" },
    { label: "Naranja", value: "#fd7e14" },
    { label: "Amarillo", value: "#ffc107" },
    { label: "Púrpura", value: "#6f42c1" },
];

export enum TemplateIds {
    BIENVENIDA = "1",
    RECOVERY_EMAIL = "2",
    BIENVENIDA_TUTOR = "3",
    DUDA = "4",
    ENTREGABLE = "5",
    CONTROL = "6",
    CORRECCIONES = "7",
    MENSAJE = "8",
    TUTOR_1 = "9",
    TUTOR_2 = "10",
    TUTOR_3 = "11",
    TUTOR_4 = "12",
    TUTOR_5 = "13",
    TUTOR_6 = "14",
    SIN_ACCESO = "15",
    RESUMEN_SEMANAL = "16",
    INICIO_FUNDAE = "17",
    BIENVENIDA_SUPERVISOR = "18",
    CONTROL_SUPERVISOR = "19",
    PREGUNTA = "20"
}

interface Options {
    label: string;
    value: string;
}

interface Template {
    id: TemplateIds;
    variables: Record<string, string>;
}

const templates: Template[] = [
    {
        id: TemplateIds.BIENVENIDA,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Grupo fecha inicio": "{{ grupo.fechaInicioString }}",
            "Grupo fecha fin": "{{ grupo.fechaFinString }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
            "Condicional: Grupo fundae": "{{ grupo.fundae }}",
            "Condicional: Usuario activo": "{{ user.activo }}",
        }
    },
    {
        id: TemplateIds.RECOVERY_EMAIL,
        variables: {
            "Nombre usuario": "{{ user.nombre }}",
            "Url de recuperación": "{{ recoveryUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.DUDA,
        variables: {
            "Nombre usuario": "{{ user.nombre }}",
            "Curso": "{{ curso.nombre }}",
            "Duda URL": "{{ mensajeUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.CONTROL,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Grupo": "{{ grupo.nombre }}",
            "Curso": "{{ curso.nombre }}",
            "Avance del curso en porcentaje": "{{ porcentaje }}",
            "Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Progreso en entregables": "{{ matricula.progresoEntregables }}",
            "Puntuación media": "{{ matricula.puntuacionMedia }}",
            "Grupo fecha fin": "{{ matricula.fechaFinString }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.BIENVENIDA_TUTOR,
        variables: {
            "Nombre tutor": "{{ user.nombre }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.ENTREGABLE,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Nombre lección": "{{ leccion.nombre }}",
            "Url de entregable": "{{ entregableUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.CORRECCIONES,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Nombre grupo": "{{ grupo.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Bucle: Entregables": "{{ entregables }}",
            "Nombre lección (Solo dentro del bucle)": "{{ item.leccion.nombre }}",
            "Nota entregable (Solo dentro del bucle)": "{{ item.correccion.puntuacion }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.MENSAJE,
        variables: {
            "Nombre usuario": "{{ user.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Emisor nombre": "{{ emisor.nombre }}",
            "Emisor apellidos": "{{ emisor.apellidos }}",
            "Url del mensaje": "{{ mensajeUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_1,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_2,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_3,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_4,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_5,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.TUTOR_6,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Condicional: Tiempo transcurrido en curso": "{{ matricula.meta.tiempo_transcurrido }}",
            "Condicional: Progreso en lecciones": "{{ matricula.progresoLecciones }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.SIN_ACCESO,
        variables: {
            "Nombre alumno": "{{ user.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Grupo fecha inicio": "{{ grupo.fechaInicioString }}",
            "Grupo fecha fin": "{{ grupo.fechaFinString }}",
            "Condicional: Usuario activo": "{{ user.activo }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.RESUMEN_SEMANAL,
        variables: {
            "Nombre usuario": "{{ user.nombre }}",
            "Nombre grupo": "{{ grupo.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Bucle: Matrículas": "{{ matriculas }}",
            "Matrícula Nombre (Solo dentro del bucle)": "{{ item.user.nombre }}",
            "Matrícula Apellidos (Solo dentro del bucle)": "{{ item.user.apellidos }}",
            "Progreso lecciones matrícula (Solo dentro del bucle)": "{{ item.progresoLecciones }}",
            "Progreso entregables matrícula (Solo dentro del bucle)": "{{ item.progresoEntregables }}",
            "Puntuación media matrícula (Solo dentro del bucle)": "{{ item.puntuacionMedia }}",
            "Grupo fecha fin": "{{ grupo.fechaFinString }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.INICIO_FUNDAE,
        variables: {
            "Username inspector": "{{ grupo.inspectorSepe.username }}",
            "Clave inspector": "{{ grupo.inspectorSepe.claveInspectorSepe }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Acción formativa": "{{ grupo.accionFormativa }}",
            "Código grupo": "{{ grupo.codigoGrupo }}",
            "Fecha inicio": "{{ grupo.meta.fechaInicioString }}",
            "Fecha fin": "{{ grupo.meta.fechaFinString }}",
            "Duración": "{{ grupo.curso.duracion }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.BIENVENIDA_SUPERVISOR,
        variables: {
            "Nombre supervisor": "{{ user.nombre }}",
            "Nombre grupo": "{{ grupo.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Grupo fecha inicio": "{{ grupo.fechaInicioString }}",
            "Grupo fecha fin": "{{ grupo.fechaFinString }}",
            "Condicional: Grupo fundae": "{{ grupo.fundae }}",
            "Condicional: Usuario activo": "{{ user.activo }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.CONTROL_SUPERVISOR,
        variables: {
            "Nombre supervisor": "{{ user.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Nombre grupo": "{{ grupo.nombre }}",
            "Condicional: Grupo fundae": "{{ grupo.fundae }}",
            "Condicional: Total alumnos con malos resultados": "{{ alumnosBad }}",
            "Grupo fecha fin": "{{ grupo.fechaFinString }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Campus URL": "{{ recoveryUrl }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    },
    {
        id: TemplateIds.PREGUNTA,
        variables: {
            "Nombre usuario": "{{ user.nombre }}",
            "Nombre curso": "{{ curso.nombre }}",
            "Url pregunta": "{{ preguntaUrl }}",
            "Campus icono": "{{ tenantInfo.icono }}",
            "Email contacto": "{{ tenantInfo.replyTo }}",
        }
    }
];

export const getVariablesByTemplateId = (id: string): Options[] => {
    const template = templates.find(t => t.id === id);

    if (!template) return [
        { label: "Nombre usuario", value: "{{ user.nombre }}" },
        { label: "Apellidos usuario", value: "{{ user.apellidos }}" },
        { label: "DNI usuario", value: "{{ user.dni }}" },
        { label: "Nombre curso", value: "{{ curso.nombre }}" },
        { label: "Icono curso", value: "{{ curso.icono?.url }}" },
        { label: "Nombre empresa usuario", value: "{{ empresa.nombre }}" },
        { label: "Cif empresa usuario", value: "{{ empresa.cif }}" },
        { label: "Nombre empresa interna", value: "{{ empresaInterna.nombre }}" },
        { label: "Firma/cuño empresa interna", value: "{{ empresaInterna.imagen.url }}" },
    ];

    return Object.entries(template.variables).map(([key, value]) => ({
        label: key,
        value: value
    }));
};