import { Box, Button, Flex, Image, ListItem, Text, UnorderedList, useDisclosure } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { useClient } from "../../../../../shared/context/client.context";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationBoxItem } from "../../../../../shared/components/CustomElements/InformationBox/InformationBoxItem";
import { IconBox } from "../../../../../shared/components/Icons/IconBox";
import { Skeleton } from "primereact/skeleton";
import { InformationModal } from "../../../components/InformationModal";
import { ContenidoModal } from "../../../components/ContenidoModal";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { useRef } from "react";
import { ResumenModal } from "../../../components/ResumenModal";
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";

export const GrupoInspectorInformation = () => {
    const timeZone = useTimeZone();
    const { id } = useParams();
    const client = useClient();
    const {
        data: grupo,
        loading,
    } = useDataId({
        client: client,
        id: id,
        endpoint: EndpointTypes.GRUPOS
    });
    const matriculaIdRef = useRef(null);

    const { onOpen: onOpenContenido, isOpen: isOpenContenido, onClose: onCloseContenido } = useDisclosure();
    const { onOpen: onOpenInformation, isOpen: isOpenInformation, onClose: onCloseInformation } = useDisclosure();
    const { onOpen: onOpenResumen, isOpen: isOpenResumen, onClose: onCloseResumen } = useDisclosure();

    return (
        <Topbar
            title={
                loading ?
                    <Flex gap="15px" alignItems="center" ml="-20px">
                        <Skeleton shape="circle" size="45px"></Skeleton>

                        <Skeleton height="25px" width="300px"></Skeleton>
                    </Flex>
                    :
                    <Flex gap="15px" alignItems="center" ml="20px">
                        {grupo?.curso?.icono?.url ?
                            <Image
                                src={grupo?.curso?.icono?.url}
                                padding="0"
                                boxSize="40px"
                            />
                            :
                            <IconBox
                                icon={undefined}
                                size="40px"
                                border="none"
                                padding="0"
                                bg="inherit"
                            />
                        }

                        <Flex
                            textTransform="capitalize"
                            direction="column"
                            justifyContent="space-between"
                        >
                            <Text
                                color="font"
                                fontSize="17px"
                                fontWeight="700"
                                lineHeight="128%"

                            >
                                {grupo?.nombre}
                            </Text>

                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="500"
                            >
                                {grupo?.curso?.nombre}
                            </Text>
                        </Flex>

                    </Flex>
            }
            buttonBack="/grupos-inspector"
            hideSearch={true}
        >
            <Flex direction="column" p="30px" gap="20px">
                <InformationBox title="Información del grupo">
                    <Flex>
                        <Box
                            flex="1"
                            borderRight="1px solid"
                            borderColor="light_grey"
                        >
                            <InformationBoxItem
                                label="nombre del grupo"
                                text={grupo?.nombre}
                            />

                            <InformationBoxItem
                                label="curso asociado"
                                text={grupo?.curso?.nombre}
                            />

                            <InformationBoxItem
                                label="fecha inicio"
                                text={grupo?.fechaInicio ? formatTimezone({ date: grupo?.fechaInicio, timeZone }) : "-"}
                            />

                            <InformationBoxItem
                                label="fecha fin"
                                text={grupo?.fechaFin ? formatTimezone({ date: grupo?.fechaFin, timeZone }) : "-"}
                            />

                            <InformationBoxItem
                                label="horas"
                                text={grupo?.curso?.duracion}
                            />

                            <InformationBoxItem
                                label="tutor"
                                text={
                                    (grupo?.tutores && grupo?.tutores?.length > 0)
                                        ? `${grupo?.tutores[0]?.nombre} ${grupo?.tutores[0]?.apellidos}`
                                        : ""
                                }
                            />
                        </Box>

                        <Box flex="1">
                            <InformationBoxItem
                                label="id grupo"
                                text={grupo?.idFundae}
                            />

                            <InformationBoxItem
                                label="acción formativa"
                                text={grupo?.accionFormativa}
                            />

                            <InformationBoxItem
                                label="código grupo"
                                text={grupo?.codigoGrupo}
                            />

                            <InformationBoxItem
                                label="empresa"
                                text={
                                    (grupo?.empresas && grupo?.empresas?.length > 0)
                                        ? grupo?.empresas[0]?.nombre
                                        : ""
                                }
                            />

                            <InformationBoxItem
                                label="modalidad"
                                text={grupo?.modalidad}
                            />

                            <InformationBoxItem
                                label="número expediente"
                                text={grupo?.numeroExpediente}
                            />
                        </Box>
                    </Flex>
                </InformationBox>

                <Flex
                    gap="40px"
                >
                    <Flex
                        flex="1"
                        h="auto"
                    >
                        <InformationBox title="Campus virtual de alumno">
                            <Flex
                                direction="column"
                                w="100%"
                                gap="20px"
                                p="30px"
                            >
                                <Flex
                                    direction="column"
                                    gap="20px"
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                    h="100%"
                                >
                                    <Text>
                                        Puede acceder al campus virtual del alumno que seleccione para ver todas las herramientas que tiene a su disposición.
                                    </Text>

                                    <Text>
                                        Entre otras cosas:
                                    </Text>

                                    <UnorderedList
                                        pl="20px"
                                    >
                                        <ListItem>Herramientas de comunicación como chat, foros, mensajería.</ListItem>
                                        <ListItem>Contenidos realizados por el alumno.</ListItem>
                                        <ListItem>Cuestionario de satisfacción.</ListItem>
                                        <ListItem>Ayuda/manual online.</ListItem>
                                        <ListItem>Guía didáctica.</ListItem>
                                    </UnorderedList>
                                </Flex>

                                <Flex
                                    w="100%"
                                    justifyContent="center"
                                >
                                    <Button
                                        bg="secondary"
                                        color="#FFF"
                                        p="10px 15px"
                                        h="fit-content"
                                        w="fit-content"
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        _hover={{ bg: "light_grey", color: "black" }}
                                        onClick={onOpenInformation}
                                    >
                                        Entrar como el alumno
                                    </Button>
                                </Flex>
                            </Flex>
                        </InformationBox>
                    </Flex>

                    <Flex
                        flex="1"
                    >
                        <InformationBox title="Contenidos del curso">
                            <Flex
                                direction="column"
                                w="100%"
                                gap="20px"
                                p="30px"
                            >
                                <Flex
                                    direction="column"
                                    gap="20px"
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    <Text>
                                        Desde aquí podrá ver todos los contenidos y controles de evaluación que componen el curso, así como la estructura del mismo.
                                    </Text>

                                    <Text>
                                        A diferencia del “acceso al campus virtual de un alumno” podrá acceder libremente a todos los contenidos sin las restricciones
                                        que los alumnos puedan tener. Como por ejemplo la obligación de completar una actividad para poder ver la siguiente(acceso secuencial)
                                    </Text>
                                </Flex>

                                <Flex
                                    w="100%"
                                    justifyContent="center"
                                    mt="auto"
                                >
                                    <Button
                                        bg="secondary"
                                        color="#FFF"
                                        p="10px 15px"
                                        h="fit-content"
                                        w="fit-content"
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        _hover={{ bg: "light_grey", color: "black" }}
                                        onClick={onOpenContenido}
                                    >
                                        Ver contenido
                                    </Button>
                                </Flex>
                            </Flex>
                        </InformationBox>
                    </Flex>

                    <Flex
                        flex="1"
                    >
                        <InformationBox title="Informes">
                            <Flex
                                direction="column"
                                w="100%"
                                gap="20px"
                                p="30px"
                            >
                                <Flex
                                    direction="column"
                                    gap="20px"
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    <Text>
                                        En el apartado de informes podrá ver y descargar información del grupo completo o de los participantes que seleccione.
                                    </Text>

                                    <Text>
                                        Los informes que puede generar son:
                                    </Text>

                                    <Flex
                                        gap="20px"
                                    >
                                        <UnorderedList
                                            pl="20px"
                                        >
                                            <ListItem>Expediente</ListItem>
                                            <ListItem>Conexiones</ListItem>
                                            <ListItem>Horarios</ListItem>
                                            <ListItem>Actividades</ListItem>
                                            <ListItem>Interacción en foros</ListItem>
                                        </UnorderedList>

                                        <UnorderedList>
                                            <ListItem>Mensajería</ListItem>
                                            <ListItem>Diploma</ListItem>
                                            <ListItem>Guía didáctica</ListItem>
                                            <ListItem>Cv Tutor</ListItem>
                                        </UnorderedList>
                                    </Flex>
                                </Flex>

                                <Flex
                                    w="100%"
                                    justifyContent="center"
                                >
                                    <Button
                                        bg="secondary"
                                        color="#FFF"
                                        p="10px 15px"
                                        h="fit-content"
                                        w="fit-content"
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        _hover={{ bg: "light_grey", color: "black" }}
                                        onClick={onOpenInformation}
                                    >
                                        Acceder a informes
                                    </Button>
                                </Flex>
                            </Flex>
                        </InformationBox>
                    </Flex>
                </Flex>

                {isOpenInformation &&
                    <InformationModal
                        isOpen={isOpenInformation}
                        onClose={onCloseInformation}
                        onOpenResumen={onOpenResumen}
                        matriculaIdRef={matriculaIdRef}
                    />
                }

                {isOpenResumen &&
                    <ResumenModal
                        isOpen={isOpenResumen}
                        onClose={onCloseResumen}
                        matriculaId={matriculaIdRef.current}
                    />
                }

                <ContenidoModal
                    isOpen={isOpenContenido}
                    onClose={onCloseContenido}
                    cursoId={grupo?.cursoId}
                />
            </Flex>
        </Topbar>
    )
}