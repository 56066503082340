import { Flex, Switch, Text } from "@chakra-ui/react"

interface Props {
    label: string;
    value?: boolean;
    name?: string;
    setValue?: (e: boolean) => void;
    onClick?: (e?: any) => void;
    isDisabled?: boolean;
}

export const SwitchItem = ({
    label,
    value = false,
    name, 
    setValue = (e: any) => {},
    onClick = (e: any) => {},
    isDisabled = false
}: Props) => {

    return (
        <Flex
            gap="20px"
            alignItems="center"
            justifyContent="space-between"
        >
            <Flex
                alignItems="center"
                gap="20px"
            >
                <Switch
                    size='md'
                    name={name}
                    isChecked={value}
                    onChange={(e: any) => {
                        setValue(e.target.checked)
                        onClick()
                    }}
                    isDisabled={isDisabled}
                />

                <Text
                    color="#00152D"
                    fontSize="12px"
                    fontWeight="600"
                    letterSpacing="0.24px"
                    textTransform="uppercase"
                >
                    {label}
                </Text>
            </Flex>            
        </Flex>
    );
}