import { Box, Button, Flex, FormLabel, Spinner, Text, Tooltip, useToast } from "@chakra-ui/react";
import { CursosInt } from "../../../../../interfaces/CursosInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { updateCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import ReactQuill from "react-quill";
import { SkeletonGuiaDidactica } from "../../../components/Skeletons/CursosGuiaDidacticaSkeleton";
// import { checkXML, downloadXML } from "../../../../../shared/middlewares/adjuntos.middleware";
// import { TipoXML } from "../../../../../shared/utils/Types/TipoXLM";
// import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
// import { FilterSelect } from "../../../../../shared/components/FiltersElements/FilterSelect";
// import { useData } from "../../../../../shared/hooks/useData";
// import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
// import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
// import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
// import { useAuthContex } from "../../../../../shared/context/auth.context";
// import { EmpresasInt } from "../../../../../interfaces/EmpresasInt";
// import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input";

interface Props {
    curso: CursosInt | undefined;
    loadingCurso: boolean
    Refresh: () => void;
    updateValue: (value: any) => void;
}

export const TabGuiaDidactica = ({ curso, Refresh, loadingCurso, updateValue }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const { gestion, contenido } = useUserPolicy();
    // const { user } = useAuthContex();
    // const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    // const [isUniqueEmpresa, setIsUniqueEmpresa] = useState<boolean>(true)
    // const [empresaDefectoId, setEmpresaDefectoId] = useState<string | null>(null)
    // const [empresaOrganizadora, setEmpresaOrganizadora] = useState<{ value: string, label: string } | null>(null)
    // const [faltanDatos, setFaltanDatos] = useState<string[]>([])
    // const { data: empresas } = useData({
    //     client: client,
    //     endpoint: EndpointTypes.EMPRESA,
    //     query: {
    //         interna: "true",
    //     },
    //     ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    // })

    // useEffect(() => {
    //     if (empresas && empresas?.data?.length > 0) {
    //         setIsUniqueEmpresa(empresas?.meta?.total === 1)

    //         if (empresas?.meta?.total === 1) {
    //             setEmpresaDefectoId(empresas?.data[0]?.id)
    //         }
    //     }
    // }, [empresas])

    const getInfoGuia = (type: "datos" | "dirigido" | "requisitos" | "objetivos") => {
        if (!curso?.infoGuia) return undefined;

        if (Reflect.has(curso?.infoGuia, type)) return curso?.infoGuia[type]
        else return undefined;
    }

    const initialValues = {
        datos: getInfoGuia("datos"),
        dirigido: getInfoGuia("dirigido"),
        requisitos: getInfoGuia("requisitos"),
        objetivos: getInfoGuia("objetivos"),
    };

    const validationSchema = Yup.object({
        datos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        dirigido: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        requisitos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        objetivos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
    });

    const handleSubmit = (values: any) => {
        if (!curso?.id) return;
        setIsLoading(true)

        const guiaDidactica = {
            datos: values?.datos,
            dirigido: values?.dirigido,
            requisitos: values?.requisitos,
            objetivos: values?.objetivos,
        }

        updateCurso({
            id: curso?.id,
            data: {
                infoGuia: guiaDidactica
            },
            client: client
        })
            .then(() => {
                Refresh();
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Guía didáctica actualizada correctamente")
            })
            .catch(() => { })
            .finally(() => setIsLoading(false))
    }

    // const downloadXMLFUNDAE = () => {
    //     if (!curso?.id) return;
    //     const empresaId = isUniqueEmpresa ? empresaDefectoId : empresaOrganizadora?.value;

    //     if (!empresaId) {
    //         toastNotify(toast, StatusEnumTypes.ERROR, "Debes seleccionar una empresa organizadora")
    //         return;
    //     }

    //     setLoadingDownload(true)

    //     downloadXML({
    //         data: {
    //             tipo: TipoXML.AccionFormativa,
    //             cursoId: curso?.id,
    //             empresaId: empresaId
    //         },
    //         client: client
    //     })
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(new Blob([response?.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', `af-${curso?.nombre}.xml`);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.parentNode?.removeChild(link);
    //         })
    //         .catch((error: any) => {
    //             const errors = handleErrors(
    //                 error?.response?.data?.errors,
    //                 error?.response?.status
    //             )

    //             errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
    //         })
    //         .finally(() => setLoadingDownload(false))
    // }

    // useEffect(() => {
    //     if (empresaDefectoId || empresaOrganizadora?.value) {
    //         checkXML({
    //             empresaId: isUniqueEmpresa ? empresaDefectoId : empresaOrganizadora?.value,
    //             client: client
    //         })
    //             .then((response) => {
    //                 setFaltanDatos(response?.data?.data?.falta)
    //             })
    //     }
    // }, [empresaDefectoId, empresaOrganizadora])

    return (

        loadingCurso ?
            <SkeletonGuiaDidactica />
            :
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex direction="column" gap="0px" className='information-texteditor'>
                                <Flex
                                    gap="20px"
                                    direction="row-reverse"
                                    pb="20px"
                                >
                                    {/*
                                    <Flex
                                        flex="1"
                                        direction="column"
                                    >
                                        <Flex
                                            direction="column"
                                        >
                                            <InformationBox
                                                noHeader
                                            >
                                                <Box p="20px">

                                                    <Flex
                                                        direction="column"
                                                        mb="20px"
                                                    >
                                                        <FormLabel
                                                            fontSize="12px"
                                                            fontWeight="600"
                                                            letterSpacing="0.24px"
                                                            textTransform="uppercase"
                                                        >
                                                            Codigo de Grupo Accion
                                                        </FormLabel>

                                                        <InformationInput
                                                            noHead
                                                            name="codigoGrupoAcccion"
                                                            defaultValue={curso?.infoGuia?.codigoGrupoAcccion}
                                                            updateValue={(value) => updateValue({
                                                                infoGuia: {
                                                                    ...curso?.infoGuia,
                                                                    ...value
                                                                }
                                                            })}
                                                        />
                                                    </Flex>

                                                    {!isUniqueEmpresa &&
                                                        <Flex
                                                            direction="column"
                                                            mb="20px"
                                                        >
                                                            <FormLabel
                                                                fontSize="12px"
                                                                fontWeight="600"
                                                                letterSpacing="0.24px"
                                                                textTransform="uppercase"
                                                            >
                                                                Empresa organizadora
                                                            </FormLabel>


                                                            <FilterSelect
                                                                value={empresaOrganizadora}
                                                                onChange={(e: { value: string, label: string }) => setEmpresaOrganizadora(e)}
                                                                options={
                                                                    (empresas === undefined || empresas === null)
                                                                        ? []
                                                                        : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                                }
                                                                placeholder="Empresa organizadora"
                                                            />
                                                        </Flex>
                                                    }

                                                    <Flex
                                                        justifyContent="flex-end"
                                                    >
                                                        <Tooltip
                                                            bg={"pureblack"}
                                                            px="10px"
                                                            py="5px"
                                                            rounded="4px"
                                                            label={
                                                                <Flex
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Text
                                                                        color="purewhite"
                                                                        fontSize="12px"
                                                                        fontWeight="500"
                                                                        borderBottom="1px solid"
                                                                        borderColor="purewhite"
                                                                        mb="5px"
                                                                    >
                                                                        Datos faltantes
                                                                    </Text>

                                                                    {faltanDatos?.map((dato: string) => (
                                                                        <Text
                                                                            color="purewhite"
                                                                            fontSize="12px"
                                                                            fontWeight="400"
                                                                        >
                                                                            {dato}
                                                                        </Text>
                                                                    ))}
                                                                </Flex>
                                                            }
                                                            hasArrow
                                                            placement="bottom"
                                                            isDisabled={!faltanDatos || faltanDatos?.length === 0 ? true : false}
                                                        >
                                                            <Button
                                                                h="fit-content"
                                                                w="fit-content"
                                                                bg="main"
                                                                color="purewhite"
                                                                p="10px 20px"
                                                                borderRadius="5px"
                                                                fontSize="16x"
                                                                fontWeight="700"
                                                                _hover={{ bg: "light_grey", color: "black" }}
                                                                isDisabled={
                                                                    faltanDatos && faltanDatos?.length > 0
                                                                        ? true
                                                                        : isUniqueEmpresa
                                                                            ? (curso?.infoGuia?.codigoGrupoAcccion ? false : true)
                                                                            : (empresaOrganizadora?.value && curso?.infoGuia?.codigoGrupoAcccion ? false : true)
                                                                }
                                                                isLoading={loadingDownload}
                                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                    e.stopPropagation()

                                                                    downloadXMLFUNDAE()
                                                                }}
                                                            >
                                                                Descargar XML FUNDAE
                                                            </Button>
                                                        </Tooltip>
                                                    </Flex>
                                                </Box>
                                            </InformationBox>
                                        </Flex>
                                    </Flex>
                                    */}

                                    <Flex
                                        flex="1"
                                        direction="column"
                                    >
                                        <InformationBox
                                            noHeader
                                        >
                                            <Box
                                                p="20px"
                                            >
                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Datos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.datos}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, datos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Requisitos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.requisitos}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, requisitos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        ¿A quién va dirigido?
                                                    </FormLabel>

                                                    <ReactQuill
                                                        theme="snow"
                                                        value={values.dirigido}
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, dirigido: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormLabel
                                                        fontSize="12px"
                                                        fontWeight="600"
                                                        letterSpacing="0.24px"
                                                        textTransform="uppercase"
                                                    >
                                                        Objetivos
                                                    </FormLabel>

                                                    <ReactQuill
                                                        value={values.objetivos}
                                                        theme="snow"
                                                        onChange={(e: any) => {
                                                            formik.setValues({ ...values, objetivos: e })
                                                        }}
                                                        placeholder={""}
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "100%",
                                                            color: "font",
                                                            background: "#FFF",
                                                            padding: "5px",
                                                            minHeight: "200px"
                                                        }}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </Flex>
                                            </Box>
                                        </InformationBox>

                                        <Flex
                                            w="100%"
                                            justifyContent="center"
                                        >
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                h="fit-content"
                                                w="fit-content"
                                                bg="main"
                                                color="purewhite"
                                                p="10px 20px"
                                                borderRadius="5px"
                                                fontSize="16x"
                                                fontWeight="700"
                                                _hover={{ bg: "light_grey", color: "black" }}
                                            >
                                                Guardar cambios
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
    )
}


const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],

    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ align: [] }],

    ["code-block"],
    ["image"],
];

const modules = {
    toolbar: toolbarOptions,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const formats = [
    "header",
    "image",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
];