import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BiCheck, BiPlus, BiX } from "react-icons/bi";
import { ExamenInt, PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt";
import { addPregunta, updatePregunta, updateRespuesta } from "../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { PreguntasModule } from "./PreguntasModule";
import { TipoSelect } from "../../views/Examenes/Information/TabItem/TabContenido";

interface Props {
    selected: { type: string, data: PreguntaInt | RespuestaInt | null } | undefined;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: PreguntaInt | RespuestaInt | null; } | undefined>>;
    examen: ExamenInt;
    refreshData: () => void;
    clearRespuestas: boolean;
    setClearRespuestas: Dispatch<SetStateAction<boolean>>;  
}

export const AccordeonContenidoExamen = ({
    selected,
    setSelected,
    examen,
    refreshData,
    clearRespuestas,
    setClearRespuestas
}: Props) => {
    const client = useClient();
    const toast = useToast();

    useEffect(() => {
        if (!examen?.preguntas || examen?.preguntas?.length === 0) return;

        if (!selected) {
            setSelected({
                type: TipoSelect.PREGUNTA,
                data: examen?.preguntas[0]
            });
        }

        if (selected?.type === TipoSelect.RESPUESTA) {
            const respuesta = examen?.preguntas?.find((leccion: any) => leccion?.id === selected?.data?.id)

            if (respuesta)
                setSelected({
                    type: TipoSelect.RESPUESTA,
                    data: respuesta
                })
        }
    }, [examen])

    const handleAddPreguntas = () => {
        if (!examen?.id) return;

        const contenido = examen?.preguntas ? `Pregunta ${examen?.preguntas?.length + 1}` : "Pregunta";

        const newPregunta = {
            examenId: examen?.id,
            contenido: contenido,
            publicado: false,
        }

        addPregunta({
            pregunta: newPregunta,
            client: client
        })
            .then((response) => {
                const pregunta = response?.data?.data

                refreshData()

                setSelected({
                    type: TipoSelect.PREGUNTA,
                    data: pregunta
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            pos="relative"
            direction="column"
            w="100%"
        >
            <Accordion
                defaultIndex={[0, 1]}
                display="flex"
                flexDirection="column"
                gap="15px"
                allowMultiple
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c7c7c7',
                        borderRadius: '5px',
                    },
                    scrollBehavior: 'smooth',
                }}
                p="5px"
                overflow="auto"
                h="calc(100vh - 215px)"
            >
                {examen?.preguntas?.map((pregunta: PreguntaInt) => (
                    <PreguntasModule 
                        pregunta={pregunta}
                        setSelected={setSelected}
                        selected={selected}
                        refreshData={refreshData}
                        clearRespuestas={clearRespuestas}
                        setClearRespuestas={setClearRespuestas}
                    />
                ))}
            </Accordion>

            <Button
                position="absolute"
                bottom="0px"
                h="fit-content"
                w="100%"
                py="10px"
                px="10px"
                rounded={"6px"}
                border="0.6px solid"
                borderColor="#AAA"
                bg="white"
                fontSize="14px"
                color="pureblack"
                fontWeight="400"
                leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()

                    handleAddPreguntas()
                }}
            >
                Añadir pregunta
            </Button>
        </Flex>
    )
}