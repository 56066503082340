import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { addTenantAdmin } from "../../../shared/middlewares/tenant.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    slug: string | null;
}
export const NewAdminTenant = ({ isOpen, onClose, slug }: Props) => {
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const initialValues = {
        nombre: null,
        email: null,
        password: null,
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        password: Yup.string()
            .matches(passwordRules, { message: "La contraseña debe contener 8 caracteres y al menos 1 mayuscula." })
            .required('El campo password es obligatorio')
            .typeError('El campo password es obligatorio'),
    });

    const handleSubmit = (values: any) => {
        if(!slug) return;

        setIsLoading(true)

        const newUserAdmin = {
            nombre: values.nombre,
            email: values.email,
            password: values.password,
            apellidos: "admin",
            roleId: 4,
            activo: true,
        }

        addTenantAdmin({
            slug: slug,
            data: newUserAdmin
        })
            .then(() => {
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado un administrador en ${slug}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="purewhite"
                        bg="black"
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                        >
                            Crear administrador
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        m="10px"
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody>
                                            <Text
                                                color="font"
                                                fontSize="18px"
                                                fontWeight="700"
                                                mb="15px"
                                            >
                                                Datos de la empresa
                                            </Text>

                                            <Flex gap="10px" direction="column">
                                                <FormInput
                                                    name="nombre"
                                                    label="Nombre"
                                                    placeholder="Nombre"
                                                    isRequired={!values?.nombre ? true : false}
                                                />

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="email"
                                                    type="email"
                                                    placeholder="Email"
                                                />

                                                <FormInput
                                                    name="password"
                                                    label="Contraseña"
                                                    type="password"
                                                    placeholder="Contraseña"
                                                    isRequired={!values?.password ? true : false}
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="black"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="600"
                                                _hover={{ color: "black", backgroundColor: "secondary" }}
                                            >
                                                Crear administrador
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}