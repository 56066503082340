export enum InformesEnumTypes {
    DIPLOMA = 'diploma',
    CONEXIONES = 'conexiones',
    CONEXIONES_HORARIO_LABORAL = 'conexionesHorarioLaboral',
    CONEXIONES_ACTIVIDAD = 'conexionesActividad',
    CONEXIONES_FORMADOR = 'conexionesTutor',
    CUESTIONARIO = 'cuestionario',
    RECIBI = 'recibi',
    FORO = 'foro',
    TUTOR = 'tutor',
    GUIA_DIDACTICA = 'guiaDidactica',
    CV_TUTOR = 'cvTutor',
}