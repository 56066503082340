import { UserInt } from "../../../interfaces/UserInt";
import { UserRolEnum, UserSubTypeEnum } from "../Types/UserRolEnum";

export const isRoleAllowed = (rolesAllowed: UserRolEnum[], currentRole?: UserRolEnum | null): boolean => {
    if (!currentRole) return false;
    else return rolesAllowed.includes(currentRole);
};

export const isInspector = (user: UserInt): boolean => {
    if (!user) return false;

    if(isRoleAllowed([UserRolEnum.SUPERVISOR], user?.role?.nombre) && user?.sepe === true) return true
    else return false
};

export const policyRole = (subTypeAllowed: UserSubTypeEnum[], currentSubType: UserSubTypeEnum | null): boolean => {
    if (!currentSubType) return false;
    else return subTypeAllowed.includes(currentSubType);
};