import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useState } from "react";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useClient } from "../../../../shared/context/client.context";
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt";
import { addRespuesta } from "../../../../shared/middlewares/examenes.middleware";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { QueryObserverResult } from "@tanstack/react-query";
import { TipoSelect } from "../../views/Examenes/Information/TabItem/TabContenido";

interface Props {
    data: Partial<RespuestaInt> | undefined;
    refreshData: () => Promise<QueryObserverResult>;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: PreguntaInt | RespuestaInt | null; } | undefined>>;
    setClearRespuestas: Dispatch<SetStateAction<boolean>>;
}

export const AddRespuesta = ({ data, refreshData, setSelected, setClearRespuestas }: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: pregunta } = useDataId({
        endpoint: EndpointTypes.EXAMEN_PREGUNTAS,
        client: client,
        id: data?.preguntaId,
        ignoreRequest: !data?.preguntaId
    });
    const toast = useToast();
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();

    const initialValues: any = {
        preguntaId: data?.preguntaId,
        contenido: data?.contenido,
        publicado: data?.publicado,
        correcta: data?.correcta,
    };

    const validationSchema = Yup.object().shape({
        contenido: Yup.string()
            .required('¡El contenido de la respuesta es obligatorio!')
            .typeError('¡El contenido de la respuesta es obligatorio.'),
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true)

        const newRespuesta = {
            preguntaId: values?.preguntaId,
            publicado: values?.publicado,
            contenido: values?.contenido,
            correcta: values?.correcta,
        }

        addRespuesta({
            respuesta: newRespuesta,
            client: client
        })
            .then(async (response) => {
                const respuesta = response?.data?.data;
                await refreshData();
                
                setClearRespuestas(true)

                setSelected({
                    type: TipoSelect.RESPUESTA,
                    data: respuesta
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Flex
            w="100%"
            direction="column"
            px="10px"
            pt="10px"
            css={{
                '&::-webkit-scrollbar': {
                    width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#c7c7c7',
                    borderRadius: '5px',
                },
                scrollBehavior: 'smooth',
            }}
            overflowY="auto"
            h="calc(100vh - 175px)"
        >
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >

                            <Flex
                                w="100%"
                                direction="column"
                            >
                                <Text
                                    fontSize="24px"
                                    fontWeight="400"
                                    color="pureblack"
                                    mb="20px"
                                >
                                    {values?.nombre}
                                </Text>

                                <InformationBox
                                    noHeader
                                >
                                    <Flex
                                        direction="column"
                                        py="20px"
                                        px="20px"
                                        gap="30px"
                                    >
                                        <Flex
                                            direction="column"
                                            gap="15px"
                                        >
                                            <Text
                                                color="pureblack"
                                                fontSize="16px"
                                                fontWeight="400"
                                            >
                                                Respuesta
                                            </Text>

                                            <FormTextarea
                                                noHead
                                                name="contenido"
                                                ui={{
                                                    minHeigh: "150px",
                                                    styleLabel: {
                                                        color: "font",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                    },
                                                    styles: {
                                                        border: "1px solid",
                                                        borderColor: "#E2E8F0",
                                                        borderRadius: "5px",
                                                        color: "font",
                                                        padding: "5px",
                                                        maxHeight: "100px",
                                                    }
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mr="5px"
                                        >
                                            <CustomSwitch
                                                label="Respuesta correcta"
                                                optionLeft="Si"
                                                optionRight="No"
                                                value={formik.values?.correcta}
                                                setValue={() => formik.setFieldValue('correcta', !formik.values.correcta)}
                                                colorSwitch="light_grey"
                                                labelStyles={{
                                                    color: "font",
                                                    fontSize: "15px",
                                                    fontWeight: "600",
                                                    flex: "1",
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </InformationBox>
                            </Flex>

                            <Flex
                                mt="30px"
                                gap="15px"
                                alignItems={"center"}
                                justifyContent="end"
                            >
                                <Button
                                    px="10px"
                                    py="10px"
                                    h="fit-content"
                                    w="fit-content"
                                    gap="10px"
                                    rounded="5px"
                                    border="1px solid"
                                    borderColor={"secondary"}
                                    fontSize="14px"
                                    fontWeight={"500"}
                                    color="purewhite"
                                    bg="secondary"
                                    _hover={{ bg: "variant" }}
                                    type="submit"
                                >
                                    Guardar
                                </Button>

                                <Button
                                    px="10px"
                                    py="10px"
                                    h="fit-content"
                                    w="fit-content"
                                    gap="10px"
                                    rounded="5px"
                                    border="1px solid"
                                    borderColor={"secondary"}
                                    fontSize="14px"
                                    fontWeight={"500"}
                                    color="secondary"
                                    bg="purewhite"
                                    onClick={onOpenAlert}
                                >
                                    Cancelar
                                </Button>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={() => {
                    setSelected({
                        type: TipoSelect.PREGUNTA,
                        data: pregunta
                    })

                    setClearRespuestas(true)
                    refreshData()
                    onCloseAlert();
                }}
            />
        </Flex>
    )
}