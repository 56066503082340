export enum UserRolEnum {
    SUPERADMIN = 'superadmin',
    ADMIN = 'Admin',
    ALUMNO = 'User',
    TUTOR = 'Tutor',
    SUPERVISOR = 'Supervisor',
}

export enum UserSubTypeEnum {
    TOTAL = 'total',
    GESTION = 'gestion',
    CONTENIDO = 'contenido',
}