import { Flex, Text } from "@chakra-ui/react";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { Helmet } from "react-helmet";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { BiBuilding } from "react-icons/bi";
import { MdOutlineVideoSettings } from "react-icons/md";
import TabGeneral from "../tabs/TabGeneral";
import TabRendimiento from "../tabs/TabRendimiento";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const TabsDashboard: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const subPaths = [
      `/dashboard/general`,
      `/dashboard/rendimiento`
    ];

    const isSubPath = subPaths.includes(location.pathname);

    if (!isSubPath) {
      navigate(`general`);
    }
  }, [location.pathname]);

  const tabs: TabsRouterInt[] = [
    {
      isAllowed: true,
      path: "general",
      label: "General",
      icon: BiBuilding,
      content:
        <TabGeneral
        />

    },
    {
      isAllowed: true,
      path: "rendimiento",
      label: "Rendimiento",
      icon: MdOutlineVideoSettings,
      content:
        <TabRendimiento
        />
    },
  ]

  return (
    <>
      <Helmet>
        <title>Dashboard </title>
      </Helmet>

      <Topbar
        title={
          <Text
            color="font"
            fontSize="20px"
            fontWeight="400"
          >
            Dashboard
          </Text>
        }
      >

        <Flex
          w="100%"
          p="30px"
          minH={"calc(100vh - 70px)"}
          pos={"relative"}
        >
          <TabsRouter
            tabs={tabs}
          />
        </Flex>

      </Topbar>
    </>
  );
};
