import { Helmet } from "react-helmet";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { Flex, Text } from "@chakra-ui/react";
import { UserRolEnum } from "../../../shared/utils/Types/UserRolEnum";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { useAuthContex } from "../../../shared/context/auth.context";
import { useLocation, useNavigate } from "react-router-dom";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { ExamenesTable } from "./Examenes/Table/ExamenesTable";
import { EvaluacionesTable } from "./Evaluaciones/Table/EvaluacionesTable";
import { LuFileBadge } from "react-icons/lu";
import { LiaAwardSolid } from "react-icons/lia";
import { useEffect } from "react";

export const TabsExamenesIndex = () => {
    const { user } = useAuthContex();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/examenes/examenes`,
            `/examenes/evaluaciones`
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("examenes");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Exámenes",
            path: "examenes",
            icon: LuFileBadge,
            content: <ExamenesTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Evaluaciones",
            path: "evaluaciones",
            icon: LiaAwardSolid,
            content: <EvaluacionesTable />
        },
    ]

    return (
        <>
            <Helmet>
                <title>Exámenes </title>
            </Helmet>

            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        {
                            location?.pathname?.includes("evaluaciones") ? "Evaluaciones"
                                : "Examenes"
                        }
                    </Text>
                }
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Topbar>
        </>
    );
};