import { Flex, Skeleton } from "@chakra-ui/react";

export default function PerfilSkeleton() {
    return (
        <Flex gap="60px">
            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="450px" rounded="10px"/>
                <Skeleton height="130px" />
            </Flex>

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="600px" rounded="10px"/>
            </Flex>
        </Flex>
    )
}
