import { useCallback, useState, useEffect } from 'react';
import { Avatar, Flex, Icon, Skeleton, Tooltip } from '@chakra-ui/react';
import { Accept, useDropzone } from 'react-dropzone';

interface Props {
    name: string;
    src?: string | File | null;
    onUpload: (...args: any[]) => any;
}

export const AvatarUploader = ({ name, src, onUpload }: Props) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    useEffect(() => {
        if (src instanceof File) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(src);
        } else {
            setPreviewUrl(src as string | null);
        }
    }, [src]);

    const onDrop = useCallback((file: any) => {
        onUpload(file);

    }, []);

    const passTypeFile = () => {
        let accept: Accept = {}
        const formats = ['.png', '.jpg', '.jpeg', '.gif']

        formats?.forEach((file: string) => {
            if (file === ".rar") {
                accept = { ...accept, 'application/vnd.rar': [".rar"] }
            } else if (file === ".zip") {
                accept = { ...accept, 'application/zip': [".zip"] }
            } else if (file === ".png") {
                accept = { ...accept, 'image/png': ['.png'] }
            } else if (file === ".jpg") {
                accept = { ...accept, 'image/jpg': ['.jpg'] }
            } else if (file === ".jpeg") {
                accept = { ...accept, 'image/jpeg': ['.jpeg'] }
            } else if (file === ".gif") {
                accept = { ...accept, 'image/gif': ['.gif'] }
            } else if (file === ".pdf") {
                accept = { ...accept, 'application/pdf': ['.pdf'] }
            }
        })

        return accept
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: passTypeFile(),
    });

    return (
        <Flex>
            <Flex
                cursor="pointer"
            >
                <Flex {...getRootProps()} gap="10px">

                    <input {...getInputProps()} />

                    <Flex>
                        <Tooltip
                            hasArrow
                            placeContent="bottom"
                            label="Presiona aquí para cargar tu imagen"
                            rounded="6px"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="400px"
                            px="10px"
                            py="5px"
                            bg="pureblack"
                        >
                            <Avatar
                                name={name}
                                src={previewUrl || ""}
                                boxSize="70px"
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};


