import { ConfigEnum } from "../Types/ConfigTypes"

export const configLabels = (key: string) => {
    switch (key) {
        case ConfigEnum.TIME_ZONE:
            return "Zona Horaria"
        case ConfigEnum.SENDER_NAME:
            return "Remitente de correos"
        case ConfigEnum.REPLY_TO:
            return "Responder a"
        case ConfigEnum.COLORES:
            return "Colores"
        case ConfigEnum.USER_URL:
            return "URL del campus"
        case ConfigEnum.MODULO_FUNDAE:
            return "FUNDAE"
        case ConfigEnum.MAIL_BIENVENIDA:
            return "Email de bienvenida"
        case ConfigEnum.MAIL_TUTOR:
            return "Email de tutor"
        case ConfigEnum.MAIL_CONTROL:
            return "Email control del alumno"
        case ConfigEnum.MAIL_SUPERVISOR:
            return "Email de supervisor"
        default:
            return key
    }
}

export const configOrder = (obj: Record<string, string | boolean | string[]>) => {
    const arrayObj = Object.entries(obj);

    const orderObj = arrayObj.sort(([claveA, valorA], [claveB, valorB]) => {
        if (typeof valorA === "boolean" && typeof valorB !== "boolean") {
            return 1;
        } else if (typeof valorA !== "boolean" && typeof valorB === "boolean") {
            return -1;
        } else {
            return 0;
        }
    });

    return Object.fromEntries(orderObj);
}