export enum ConfigEnum {
    TIME_ZONE = "timeZone",
    SENDER_NAME = "senderName",
    REPLY_TO = "replyTo",
    COLORES = "colores",
    USER_URL = "userURL",
    MODULO_FUNDAE = "moduloFUNDAE",
    MAIL_BIENVENIDA = "mailBienvenida",
    MAIL_TUTOR = "mailTutor",
    MAIL_CONTROL = "mailControl",
    MAIL_SUPERVISOR = "mailSupervisor",
}