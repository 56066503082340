import { Flex } from "@chakra-ui/react";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import "./MultiSelect.scss"

interface Props {
    options: any;
    selected: any[];
    setSelected: (selected: any) => void;
    placeholder?: string;
    maxSelectedLabels?: number;
    optionLabel: string;
}

export const CustomMultiSelect = ({ 
    options,
    selected,
    setSelected,
    placeholder = "Seleccione sus opciones",
    maxSelectedLabels = 3,
    optionLabel
}: Props) => {

    return(
        <Flex className="multiSelect-box">
            <MultiSelect 
                value={selected} 
                onChange={(e: MultiSelectChangeEvent) => setSelected(e.value)} 
                options={options} 
                display="chip" 
                optionLabel={optionLabel}
                placeholder={placeholder} 
                maxSelectedLabels={maxSelectedLabels}  
            />
        </Flex>
    );
}