import { Flex, Skeleton } from '@chakra-ui/react'

export default function GruposSkeleton({ moduloFundae }: { moduloFundae: boolean }) {
  return (


    <Flex gap="20px">
      {moduloFundae === true ? (
        <Flex flex="1" gap="32px" h="131.75px" >

          <Skeleton h="131.75px" flex="1" rounded="14px" />
          <Skeleton h="131.75px" flex="1" rounded="14px" />
          <Skeleton h="131.75px" flex="1" rounded="14px" />
          <Skeleton h="131.75px" flex="1" rounded="14px" />

        </Flex>
      ) : (
        <Flex flex="1" gap="32px" h="131.75px" >

          <Skeleton h="131.75px" flex="1" rounded="14px" />
          <Skeleton h="131.75px" flex="1" rounded="14px" />
          <Skeleton h="131.75px" flex="1" rounded="14px" />

        </Flex>
        )
      }
    </Flex>


  )
}
