import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Box, ModalCloseButton } from "@chakra-ui/react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import * as Yup from "yup"
import { Form as FormikForm, Formik } from 'formik';
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { useNavigate } from "react-router-dom";
import { formSelectStyles } from "../../../ui/formSelectStyles";


interface Props {
    matriculas: MatriculasInt[];
    isOpen: boolean;
    onClose: () => void;
}
export const RedirectModal = ({
    matriculas,
    isOpen,
    onClose,
}: Props) => {
    const navigate = useNavigate();

    const initialValues = {
        grupoId: null,
    }

    const validationSchema = Yup.object({
        grupoId: Yup.string().required("Debe seleccionar un grupo"),
    });

    const handleSubmit = (values: any) => {
        navigate(`/grupos-inspector/${values?.grupoId}`);
    }

    return (
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                        >
                            Grupos del alumno
                        </ModalHeader>

                        <ModalCloseButton onClick={onClose} />
                    </Box>

                    <Box
                        m="10px"
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            my="20px"
                                        >
                                            <FormSelect
                                                name="grupoId"
                                                label="Seleccione el grupo"
                                                options={[...matriculas?.map((m: MatriculasInt) => ({ value: m?.grupoId, label: m?.grupo?.nombre }))]}
                                                placeholder="Empresa asociada"
                                                isRequired={!values.grupoId ? true : false}
                                                ui={{
                                                    formSelectStyles: formSelectStyles,
                                                }}
                                            />
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                type="submit"
                                                bg="black"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="600"
                                                _hover={{ color: "black", backgroundColor: "light_grey" }}
                                            >
                                                Ir
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>
    )
}