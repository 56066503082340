import { Badge, Flex, Text, Link } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { Link as RouterLink } from "react-router-dom";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { handleLinkOpen } from "../../../../../../shared/utils/functions/linkOpen";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";

export const GruposInspectorColumns = (): ColumnProps[] => {
    const timeZone = useTimeZone();

    return [
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.nombre}
                    text={rowData?.curso?.nombre}
                    link={{
                        to: `${rowData.id}`,
                    }}
                />
            )
        },
        {
            header: 'Duracion',
            field: 'estado',
            key: 'estado',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`${rowData.id}`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column" alignItems="flex-start">
                        <Badge
                            color={
                                rowData?.estado == 2 ? "font" :
                                    rowData?.estado == 1 ? "white"
                                        : "purewhite"
                            }
                            bg={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "black"
                                        : "main"
                            }
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="5px"
                            p="2px 5px"
                        >
                            {
                                rowData?.estado == 2 ? "Inactivo" :
                                    rowData?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>

                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => (
                <Flex direction="column">
                    {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                        <Text
                            key={index}
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {empresa?.nombre}
                        </Text>
                    ))}
                </Flex>
            )
        },
        {
            header: 'Matrículas',
            field: 'totalMatriculas',
            key: 'totalMatriculas',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.totalMatriculas || 0 + " matrículas"}
                    link={{
                        to: `${rowData.id}`,

                    }}
                />
            ),
        },
    ];
}