import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Flex, Text } from "@chakra-ui/react";
import MarkdownPreview from '@uiw/react-markdown-preview';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    data: any;
}

export const ModalPrview = ({ isOpen, onClose, data }: Props) => {

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnOverlayClick={false}
            size="2xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="10px"
            >
                <ModalCloseButton />

                <ModalBody>
                    <Flex
                        direction="column"
                        p="10px"
                        gap="10px"
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            fontSize="18px"
                            mt="30px"
                            px="10px"
                        >
                            <Text>
                                <Text as="span" fontWeight="600">De: </Text>
                                {data?.emisor}
                            </Text>

                            <Text>
                                <Text as="span" fontWeight="600">Para: </Text>
                                {data?.receptor}
                            </Text>
                        </Flex>

                        <MarkdownPreview
                            source={data?.texto || ""}
                            style={{
                                padding: "10px",
                                fontSize: '15px',
                                width: '100%',
                                maxWidth: '100%',
                                background: "transparent",
                                color: "#000"
                            }}
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}