import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Box, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { useClient } from "../../../../shared/context/client.context";
import { EndpointTypes, RelationshipTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { formSelectStyles } from "../../../../ui/formSelectStyles";



interface Props {
    isOpen: boolean;
    onClose: () => void;
    handleResendCredentials: (grupoId: string) => void;
    supervisorId: string | undefined | null;
    loadingCredenciales: boolean;
}
export const ModalCredenciales = ({
    isOpen,
    onClose,
    handleResendCredentials,
    supervisorId,
    loadingCredenciales
}: Props) => {
    const client = useClient();
    const initialValues = {
        grupoId: null,
    };

    const validationSchema = Yup.object({
        grupoId: Yup.string()
            .required('El grupo es obligatorio.')
            .typeError('El grupo es obligatorio.'),
    });

    const handleSubmit = async (values: any) => {

        handleResendCredentials(values.grupoId)
    }

    const loadGrupos = async (value: string) => await loadData({
        value: value,
        client: client,
        id: supervisorId as string,
        endpoint: EndpointTypes.SUPERVISORES,
        relationship: RelationshipTypes.GRUPOS,
        query: QueryTypes.NOMBRE,
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <Box
                    color="font"
                    roundedTop="20px"
                >
                    <ModalHeader
                        textAlign="center"
                        fontSize="28px"
                        fontWeight="600"
                        letterSpacing="-0.32px"
                    >
                        Reenviar credenciales
                    </ModalHeader>

                    <ModalCloseButton onClick={onClose} />
                </Box>

                <Box
                    m="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody
                                        my="20px"
                                    >
                                        <FormAsyncSelect
                                            name="grupoId"
                                            label="Selecciona el grupo del supervisor"
                                            loadOptions={loadGrupos}
                                            placeholder="Grupo"
                                            isRequired={!values.grupoId}
                                            defaultOptions={true}
                                            ui={{
                                                formSelectStyles: formSelectStyles
                                            }}
                                        />
                                    </ModalBody>

                                    <ModalFooter justifyContent="center">
                                        <Button
                                            isLoading={loadingCredenciales}
                                            type="submit"
                                            bg="black"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="600"
                                            _hover={{ color: "black", backgroundColor: "light_grey" }}
                                        >
                                            Enviar
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}