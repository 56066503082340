type Key = "icono" | "colores" | "nombre" | "produccion";

type Value = { main: string; secondary: string; variant: string; } | string | boolean;

export const useTenantInfo = (): {
    handleSetInfo: (props: { key: Key; value: Value; }) => Promise<void>;
    handleGetInfo: (props: { key: Key; }) => any;
} => {
    const handleSetInfo = async ({
        key,
        value,
    }: {
        key: Key;
        value: Partial<Value>;
    }) => {
        if (key === "icono") {
            localStorage.setItem('icono', JSON.stringify(value))
        }

        if (key === "nombre") {
            localStorage.setItem('nombre', JSON.stringify(value))
        }

        if (key === "colores") {
            localStorage.setItem('colores', JSON.stringify(value))
        }

        if (key === "produccion") {
            localStorage.setItem('produccion', JSON.stringify(value))
        }
    }

    const handleGetInfo = ({
        key
    }: {
        key: Key;
    }) => {
        const data = localStorage.getItem(key)

        return (data !== null && data !== undefined)
            ? JSON?.parse(data)
            : null
    }

    return {
        handleSetInfo,
        handleGetInfo
    }
};