import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const getTutores = async ({ 
    client, 
    query = "" 
} : {
    client: ClientInt,
    query?: string
}) => {
    if(!client) return;
    const tenant = await tenantInstance();
    
    return await _axios.get(`${tenant}/${client}/tutores${query}`).then((response: any) => response)
}

export const getGruposTutor = async ({
    client,
    query = ""
} : {
    client: ClientInt,
    query?: string
}) => {
    if(!client) return;
    const tenant = await tenantInstance();
    
    return await _axios.get(`${tenant}/${client}/matriculasAgrupadas${query}`).then((response: any) => response)
}

export const addCVTutor = async ({ 
    id, 
    cv,
    client 
} : { 
    id: string, 
    cv: File,
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    const form = new FormData()

    form.append('cv', cv)

    return _axios.put(
        `${tenant}/${client}/users/${id}`,
        form,
        {}
    )
};

export const removeCVTutor = async ({
    id,
    client
 } : { 
    id: string,
    client: ClientInt 
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/users/${id}?borraCV=true`,
        {},
        {}
    )
};