import { Flex, Icon, Skeleton, StyleProps, Box, Link, Image, Text } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { BiChevronRight } from "react-icons/bi"
import { Fragment, useEffect, useState } from "react"
import { Pagination } from "./components/Pagination"
import { Link as RouterLink } from "react-router-dom"
import SinDataGrupos from "../../../assets/img/NoContent4.png"

export interface CustomColumnProps {
    key: string;
    field: string;
    header: string | JSX.Element;
    sortable?: boolean;
    body?: (data: any) => JSX.Element | undefined;
    redirect?: (id: string) => string | undefined;
    style?: StyleProps;
}

interface Props {
    data: any[]
    loading: boolean
    columns: CustomColumnProps[]
    total?: number
    columnsGroup?: CustomColumnProps[]
    groupByKey?: string
    setQuery: (query: Record<string, string | number | string[]>) => void
    query: Record<string, string | number | string[]>
    outContainer?: boolean
    hideFooter?: boolean
}

export const CustomGroupTable = ({
    data,
    loading,
    columns,
    total,
    setQuery,
    query,
    columnsGroup,
    groupByKey,
    outContainer = false,
    hideFooter = false
}: Props) => {
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25 | 50 | 75 | 100>(10);

    useEffect(() => {
        let options = Object.fromEntries(Object.entries(query).filter(q => q[0] !== "page" && q[0] !== "limit" && q[0] !== "sortBy" && q[0] !== "order"))
        options = sortOrder === 1 ? { order: "asc", ...options } : sortOrder === -1 ? { order: "desc", ...options } : { ...options };
        options = sortBy !== undefined ? { sortBy: sortBy, ...options } : { ...options };

        setQuery({
            page: page,
            limit: limit,
            ...options
        });

    }, [page, sortBy, sortOrder, limit]);

    const [rowOpen, setRowOpen] = useState<{
        id: string,
        isOpen: boolean
    }[]>([])

    return (
        <Flex
            w="100%"
            direction="column"
            p={!outContainer ? "25px" : "0"}
            bg="purewhite"
            borderRadius="14px"
            border={!outContainer ? "1px solid #DFE2E6" : "none"}
            gap="20px"
        >
            <Table
                variant="simple"
                width="full"
                overflowX="auto"
            >
                <Thead>
                    <Tr bg="#F9FAFB">
                        {groupByKey && (
                            <Th width="40px" padding="0" border="none">
                                {/* Columna para el icono de expansión */}
                            </Th>
                        )}
                        {columns.map((column) => (
                            <Th
                                key={column?.key}
                                fontSize="12px"
                                fontWeight="600"
                                width={column?.style?.width || "auto"}
                                {...column?.style}
                                whiteSpace={column.header.toString().includes(' ') || column.header.toString().includes('.') ? "wrap" : "nowrap"}
                                overflow="hidden"
                                border="none"
                            >
                                {column?.header}
                            </Th>
                        ))}
                    </Tr>
                </Thead>

                <Tbody>
                    {loading ? (
                        // Skeleton loader
                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                            <Tr key={item}>
                                {groupByKey && (
                                    <Td width="40px" padding="0">
                                        <Skeleton height="24px" width="24px" borderRadius="50%" />
                                    </Td>
                                )}

                                {columns.map((column, index) => (
                                    <Td
                                        key={index}
                                        width={column?.style?.width || "auto"}
                                        {...column?.style}
                                        whiteSpace="wrap"
                                        overflow="hidden"
                                        py="16px"
                                    >
                                        <Skeleton height="40px" width="100%" borderRadius="4px" />
                                    </Td>
                                ))}
                            </Tr>
                        ))
                    ) : data.length > 0 ? (
                        // Datos de la tabla
                        data?.map((item: any, index: number) => (
                            <Fragment key={item.id || index}>
                                <Tr _hover={{ bg: "gray.50" }} p="0px">
                                    {groupByKey && (
                                        <Td width="40px" padding="0">
                                            {item[groupByKey] && item[groupByKey]?.length > 0 && (
                                                <Flex justifyContent="center" alignItems="center">
                                                    <Icon
                                                        color="#616675"
                                                        _active={{ scale: 0.9 }}
                                                        cursor={"pointer"}
                                                        as={BiChevronRight}
                                                        boxSize="24px"
                                                        transition={'transform 0.3s ease'}
                                                        transform={rowOpen.find((i: any) => i.id === item.id)?.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                                                        onClick={() => {
                                                            setRowOpen((prev: any) => {
                                                                const findItem = prev.find((i: any) => i.id === item.id)
                                                                if (findItem) {
                                                                    return prev.filter((i: any) => i.id !== item.id)
                                                                } else {
                                                                    return [...prev, { id: item.id, isOpen: true }]
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Flex>
                                            )}
                                        </Td>
                                    )}

                                    {columns?.map((column) => (
                                        <Td
                                            key={column.key}
                                            width={column?.style?.width || "auto"}
                                            {...column?.style}
                                            whiteSpace="wrap"
                                            overflow="hidden"
                                            p="0px"
                                        >
                                            {column?.redirect && column?.redirect(item?.id) !== undefined ? (
                                                <Link
                                                    as={RouterLink}
                                                    to={column?.redirect(item?.id)}
                                                    _hover={{ textDecoration: "none" }}
                                                >
                                                    <Flex px="5px" py="16px">
                                                        {column?.body && column?.body(item)}
                                                    </Flex>
                                                </Link>
                                            ) : (
                                                <Flex px="5px" py="16px">
                                                    {column?.body && column?.body(item)}
                                                </Flex>
                                            )}
                                        </Td>
                                    ))}
                                </Tr>

                                {rowOpen.find((i: any) => i.id === item.id)?.isOpen && (
                                    <Tr>
                                        <Td colSpan={columns.length + (groupByKey ? 1 : 0)} padding="0">
                                            <Box ml="60px" mt="10px">
                                                <Table variant="simple" width="100%" style={{ tableLayout: "fixed" }}>
                                                    <Thead>
                                                        <Tr bg="#F9FAFB">
                                                            {columnsGroup?.map((column) => (
                                                                <Th
                                                                    key={column?.key}
                                                                    fontSize="12px"
                                                                    fontWeight="500"
                                                                    width={column?.style?.width || "auto"}
                                                                    {...column?.style}
                                                                    whiteSpace="nowrap"
                                                                    overflow="hidden"
                                                                    border="none"
                                                                >
                                                                    {column?.header}
                                                                </Th>
                                                            ))}
                                                        </Tr>
                                                    </Thead>

                                                    <Tbody>
                                                        {groupByKey &&
                                                            item[groupByKey]?.map((group: any, iGrupos: number) => (
                                                                <Tr
                                                                    key={iGrupos}
                                                                    borderBottom="none"
                                                                    _hover={{ bg: "gray.50" }}
                                                                >
                                                                    {columnsGroup?.map((column, index) => (
                                                                        <Td
                                                                            key={index}
                                                                            width={column?.style?.width || "auto"}
                                                                            {...column?.style}
                                                                            whiteSpace="wrap"
                                                                            overflow="hidden"
                                                                            border="none"
                                                                            p="0px"
                                                                        >
                                                                            {column?.redirect && column?.redirect(group?.id) !== undefined ? (
                                                                                <Link
                                                                                    as={RouterLink}
                                                                                    to={column?.redirect(group?.id)}
                                                                                    _hover={{ textDecoration: "none" }}
                                                                                >
                                                                                    <Flex px="5px" py="10px">
                                                                                        {column?.body && column?.body(group)}
                                                                                    </Flex>
                                                                                </Link>
                                                                            ) : (
                                                                                <Flex px="5px" py="10px">
                                                                                    {column?.body && column?.body(group)}
                                                                                </Flex>
                                                                            )}
                                                                        </Td>
                                                                    ))}
                                                                </Tr>
                                                            ))}
                                                    </Tbody>
                                                </Table>
                                            </Box>
                                        </Td>
                                    </Tr>
                                )}
                            </Fragment>
                        ))
                    ) : (
                        // Mensaje de no hay datos
                        <Tr>
                            <Td colSpan={columns.length + (groupByKey ? 1 : 0)} padding="0">
                                <Flex
                                    w="full"
                                    h="full"
                                    p="100px"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="40px"
                                >
                                    <Image w="300px" src={SinDataGrupos} />
                                    <Text
                                        fontSize="30px"
                                        fontWeight="600"
                                        color="font"
                                        w="550px"
                                        textAlign="center"
                                    >
                                        No hay grupos para mostrar
                                    </Text>
                                </Flex>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>

            {!hideFooter && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    total={total || 0}
                    loading={loading}
                    lastPage={Math.ceil((total || 0) / limit)}
                    firstPage={1}
                />
            )}
        </Flex>
    );
}
