import { Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { CustomButton } from "../../../../shared/components/CustomElements/CustomButton"
import { BiPlusCircle } from "react-icons/bi"
import { useParams } from "react-router-dom"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { useClient } from "../../../../shared/context/client.context"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { CursosInt } from "../../../../interfaces/CursosInt"
import { CardCursos } from "../../components/CardCursos"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch"
import { useEffect, useRef, useState } from "react"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { HiOutlineClipboardCopy } from "react-icons/hi"
import { AddCursoModal } from "../../components/AddCursoModal"
import { updateRuta } from "../../../../shared/middlewares/rutas.middleware"
import { GruposRutaInt, RutasInt } from "../../../../interfaces/RutasInt"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { NewMatriculaRutaModal } from "../../components/NewMatriculaRutaModal"
import { useData } from "../../../../shared/hooks/useData"
import { NewGrupoRutaNoFundae } from "../../components/NewGrupoRutaNoFundae"
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae"
import { CustomGroupTable } from "../../../../shared/components/Table/CustomGroupTable"
import { useQuerySession } from "../../../../shared/hooks/useQuerySession"
import { GruposRutaColumns } from "./Columns/GruposColumns"
import { GroupGruposRutaColumns } from "./Columns/GroupGruposColumns.tsx"
import { NewGrupoRutaModal } from "../../components/NewGrupoRutaModal.tsx"

export const InformationRuta = () => {
    const { id } = useParams();
    const client = useClient()
    const toast = useToast()
    const { query, setQuery } = useQuerySession({ entity: null })
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { onOpen: onOpenGrupo, onClose: onCloseGrupo, isOpen: isOpenGrupo } = useDisclosure()
    const moduloFUNDAE = useModuloFundae()
    const { onOpen: onOpenMatricula, onClose: onCloseMatricula, isOpen: isOpenMatricula } = useDisclosure()
    const { data: ruta, Refresh } = useDataId({
        client,
        endpoint: EndpointTypes.RUTAS,
        id: id,
        ignoreRequest: !id
    })
    const { data: gruposRuta, loading } = useData({
        client,
        endpoint: EndpointTypes.RUTAS_GRUPOS,
        query: {
            rutaId: id!,
            ...query
        }
    })
    const [estado, setEstado] = useState<boolean>()
    const grupoRef = useRef<GruposRutaInt | null>(null)


    useEffect(() => {
        setEstado(ruta?.publicado)
    }, [ruta])

    const updateValue = async (values: Partial<RutasInt>) => {
        if (!id) return

        updateRuta({
            id: id!,
            data: {
                ...values
            },
            client: client,
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente");
                Refresh();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const onMatriculaEvent = (grupo: GruposRutaInt) => {
        grupoRef.current = grupo

        if (isOpenMatricula) onCloseMatricula()
        onOpenMatricula()
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Hojas de Ruta
                </Text>
            }

        >
            <Flex
                direction="column"
                p="30px"
                minH="calc(100vh - 200px)"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        color="black"
                        fontSize="20px"
                        fontWeight="600"
                        mb="20px"
                    >
                        Cursos
                    </Text>

                    <CustomButton
                        button={{
                            label: "Añadir Curso",
                            isDisabled: false,
                            onClick: onOpen,
                            isLoading: false,
                            leftIcon: {
                                as: BiPlusCircle,
                                boxSize: "22px"
                            }
                        }}
                    />
                </Flex>

                <Flex
                    gap="10px"
                    mb="20px"
                    overflow="auto"
                    maxW="calc(100vw - 130px)"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        'scrollbarWidth': 'none',
                        '-ms-overflow-style': 'none',
                        scrollBehavior: 'smooth',
                    }}
                >
                    {ruta?.cursos?.map((curso: CursosInt, index: number) => (
                        <CardCursos
                            key={curso.id}
                            curso={curso}
                            index={index + 1}
                        />
                    ))}
                </Flex>

                <InformationBox
                    title="Datos generales"
                >
                    <Flex
                        direction="column"
                        p="25px"
                        gap="20px"
                        w="100%"
                    >
                        <Flex
                            w="100%"
                        >
                            <InformationInput
                                label="Nombre"
                                defaultValue={ruta?.nombre}
                                name="nombre"
                                updateValue={updateValue}
                            />
                        </Flex>

                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CustomSwitch
                                label="Estado"
                                optionLeft="Publicado"
                                optionRight="Oculto"
                                value={estado}
                                setValue={setEstado}
                                onClick={(e) => {
                                    const oldValue = ruta?.publicado

                                    updateValue({ publicado: e! }).catch(() => setEstado(oldValue))
                                }}
                                isLoading={estado !== ruta?.publicado}
                                colorSwitch="light_grey"
                                padding="0px"
                                labelStyles={{
                                    fontSize: "12px",
                                    color: "font",
                                    fontWeight: "600",
                                    textTransform: "uppercase"
                                }}
                            />
                        </Flex>
                    </Flex>
                </InformationBox>

                <Flex
                    direction="column"
                    w="100%"
                    p="25px"
                    bg="purewhite"
                    borderRadius="14px"
                    border="1px solid #DFE2E6"
                    gap="20px"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                    >
                        <Flex
                            gap="10px"
                            alignItems="center"
                        >
                            <Icon
                                as={HiOutlineClipboardCopy}
                                boxSize="18px"
                                color="black"
                            />

                            <Text
                                color="black"
                                fontSize="18px"
                                fontWeight="400"
                            >
                                Grupos
                            </Text>
                        </Flex>

                        <CustomButton
                            button={{
                                label: "Nuevo grupo",
                                isDisabled: false,
                                onClick: onOpenGrupo,
                                isLoading: false,
                                leftIcon: {
                                    as: BiPlusCircle,
                                    boxSize: "22px"
                                }
                            }}
                        />
                    </Flex>

                    <CustomGroupTable
                        data={gruposRuta?.data || []}
                        loading={loading}
                        columns={GruposRutaColumns({
                            onMatriculaEvent
                        })}
                        groupByKey="subGrupos"
                        columnsGroup={GroupGruposRutaColumns()}
                        total={gruposRuta?.meta?.total || 0}
                        setQuery={setQuery}
                        query={query}
                        outContainer
                    />
                </Flex>


                <AddCursoModal
                    isOpen={isOpen}
                    onClose={onClose}
                    cursos={ruta?.cursos}
                    refresh={Refresh}
                />

                {moduloFUNDAE ?
                    <NewGrupoRutaModal
                        isOpen={isOpenGrupo}
                        onClose={onCloseGrupo}
                        setRefreshTable={Refresh}
                        ruta={ruta}
                    />
                    :
                    <NewGrupoRutaNoFundae
                        isOpen={isOpenGrupo}
                        onClose={onCloseGrupo}
                        setRefreshTable={Refresh}
                        ruta={ruta}
                    />
                }

                <NewMatriculaRutaModal
                    grupo={grupoRef}
                    isOpen={isOpenMatricula}
                    onClose={onCloseMatricula}
                    setRefresh={Refresh}

                />
            </Flex>
        </Topbar>
    )
}