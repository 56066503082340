import { Navigate, Outlet } from "react-router-dom";
import { useAuthContex } from "../../context/auth.context";
import { isInspector } from "../../utils/functions/validateRol";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";
import { useUserPolicy } from "../../hooks/PermissionPolicy";
import { useTenantInfo } from "../../hooks/useTenantInfo";

interface Props {
    isRoleAllowed: boolean;
}

export const RequireAuth = ({ isRoleAllowed }: Props) => {
    const { contenido } = useUserPolicy();
    const { user } = useAuthContex();
    const { handleGetInfo } = useTenantInfo();
    const produccion = handleGetInfo({ key: "produccion" });

    return (
        !user?.auth
            ? <Navigate to={"/login"} />
            : (user?.auth && !produccion)
                ? <Navigate to={"/onboarding"} />
                : (user?.auth && isRoleAllowed)
                    ?
                    <Outlet />
                    :
                    <Navigate
                        to={
                            (!isInspector(user) && user?.role?.nombre === UserRolEnum.SUPERADMIN)
                                ? "/tenant"
                                : (!isInspector(user) && user?.role?.nombre !== UserRolEnum.SUPERADMIN && user?.role?.nombre === UserRolEnum.SUPERVISOR)
                                    ? "/matriculas/matriculas"
                                    : (!isInspector(user) && user?.role?.nombre !== UserRolEnum.SUPERADMIN)
                                        ? !contenido ? "/dashboard/general" : "/cursos"
                                        : "/grupos-inspector"
                        }
                    />
    )
};