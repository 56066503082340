import { Flex, Text, useQuery, useToast } from "@chakra-ui/react"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useClient } from "../../../../../shared/context/client.context";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { Table } from "../../../../../shared/components/Table/Table";
import { GruposInspectorColumns } from "./Columns/GruposInspectorColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

export const GruposInspectorTable = () => {
    const client = useClient();
    const { logout } = useAuthContex();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.INSPECTOR_GRUPOS })
    const navigate = useNavigate();
    const toast = useToast();
    const { data: grupos, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        query: query,
    })

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Grupos del inspector
                </Text>
            }
            hideSearch={true}
        >
            <Flex p="20px" direction="column" gap="20px">
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={false}
                    filterElements={[
                        {
                            name: FiltrosNames.ACCION_FORMATIVA,
                            type: FiltrosTypes.INPUT,
                        },
                        {
                            name: FiltrosNames.CODIGO_GRUPO,
                            type: FiltrosTypes.INPUT,
                        },
                        {
                            name: FiltrosNames.ID_GRUPO,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={grupos?.data || []}
                    columns={GruposInspectorColumns()}
                    isLoading={loading}
                    total={grupos?.meta?.total || 0}
                />
            </Flex>
        </Topbar>
    )
}