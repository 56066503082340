import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useClient } from "../../../../shared/context/client.context";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { CardPlantilla } from "../../components/CardPlantilla";
import { TabsRouterInt } from "../../../../interfaces/ComponentsInt";
import { LiaCertificateSolid, LiaChalkboardTeacherSolid } from "react-icons/lia";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";
import { HiOutlineDocumentText, HiTemplate } from "react-icons/hi";
import { TabsRouter } from "../../../../shared/components/TabsRouter/TabsRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { BiPlusCircle } from "react-icons/bi";
import { TipoPlantilla } from "../../../../shared/utils/Types/TipoPlantillas";
import { useEffect } from "react";
import { NewDiplomaModal } from "../../components/NewDiplomaModal";

export const TabsPlantillas = () => {
    const client = useClient();
    const { onClose: onCloseDiploma, isOpen: isOpenDiploma, onOpen: onOpenDiploma } = useDisclosure()
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/plantillas/todo`,
            `/plantillas/general`,
            `/plantillas/alumno`,
            `/plantillas/tutor`,
            `/plantillas/supervisor`,
            `/plantillas/diplomas`,
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("todo");
        }
    }, [location.pathname]);

    const categoriaFilter =
        Object.values(TipoPlantilla).reduce<{ categoria: TipoPlantilla } | undefined>((acc, tipo) => {
            if (location.pathname?.includes(tipo)) {
                return { categoria: tipo };
            }

            return acc;
        }, undefined);

    const { data: plantillas, loading, error, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.PLANTILLAS,
        specificQuery: categoriaFilter,
        query: {
            limit: 50
        }
    })

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "todo",
            label: "Todo",
            icon: HiTemplate,
            content:
                <Flex
                    gap="40px"
                    flexWrap={"wrap"}
                >
                    {plantillas?.data?.map((plantilla: any, index: number) => (
                        <CardPlantilla
                            key={index}
                            plantilla={plantilla}
                            loading={loading}
                            error={error}
                        />
                    ))}
                </Flex>
        },
        {
            isAllowed: true,
            label: "General",
            path: "general",
            icon: HiOutlineDocumentText,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Alumno",
            path: "alumno",
            icon: PiStudentFill,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Tutor",
            path: "tutor",
            icon: LiaChalkboardTeacherSolid,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        {
            isAllowed: true,
            label: "Supervisor",
            path: "supervisor",
            icon: MdOutlineSupervisorAccount,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
        // FIXME: DESCOMENTAR TAB CUANDO SE TERMINE EL EDITOR
        {
            isAllowed: true,
            label: "Diplomas personalizados",
            path: "diplomas",
            icon: LiaCertificateSolid,
            content: <Flex
                gap="40px"
                flexWrap={"wrap"}
            >
                {plantillas?.data?.map((plantilla: any, index: number) => (
                    <CardPlantilla
                        key={index}
                        plantilla={plantilla}
                        loading={loading}
                        error={error}
                    />
                ))}
            </Flex>
        },
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Plantillas
                </Text>
            }
        >
            <Flex
                direction="column"
                p="30px"
                minH="calc(100vh - 200px)"
                pos={"relative"}
            >
                <TabsRouter
                    tabs={tabs}
                    maxOptions={7}
                    buttons={[
                        {
                            isAllowed: true,
                            paths: ["diplomas"],
                            button: {
                                label: "Crear Diploma",
                                isDisabled: false,
                                onClick: onOpenDiploma,
                                leftIcon: {
                                    as: BiPlusCircle,
                                    boxSize: "20px",
                                },
                            },
                        }
                    ]}
                />


                {/* 
                    // TODO: COMO SE PASO EL PROBAR PLANTILLAS DENTRO DE :/ID HAY QUE VOLVER A PLANTEAR EL ONBOARDING EN EL GENERAL DE PLANTILLAS
                    <Flex
                        zIndex="99"
                    >
                        {(showStep && (!user?.config?.plantillas && total)) &&
                            <OnboardingStep
                                position={{
                                    right: "180px",
                                    top: "65px",
                                }}
                                title="Consulta las distintas plantillas"
                                description="Explora las plantillas de correo disponibles para diversas situaciones y realiza el seguimiento de los envíos y recepciones de estos mails."
                                placement="top/right"
                                isDisabled={(!plantillas || plantillas?.meta?.total === 0) || !completedConfig()}
                                disabledTooltip={
                                    !plantillas || plantillas?.meta?.total === 0
                                        ? "Debe tener plantillas creadas para poder probarlas"
                                        : "Debe completar toda la configuración para poder probar las plantillas"
                                }
                                onClick={() => {
                                    setShowStep(false)
                                    onOpen()

                                }}
                            />
                        }
                    </Flex> 
                */}

                <NewDiplomaModal
                    isOpen={isOpenDiploma}
                    onClose={onCloseDiploma}
                    Refresh={Refresh}
                />
            </Flex>
        </Topbar>
    );
}
