import { Flex, Image, Text } from "@chakra-ui/react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconBox } from "../../../../../shared/components/Icons/IconBox";
import { BiLabel, BiRightArrowAlt, BiTime, BiUserCircle } from "react-icons/bi";
import { TabInformes } from "./TabItems/TabInformes";
import { Skeleton } from "primereact/skeleton";
import { useClient } from "../../../../../shared/context/client.context";
import { TabPerfilMatricula } from "./TabItems/TabPerfil";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { TabSesiones } from "./TabItems/TabSesiones";
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { HiOutlineDocumentText } from "react-icons/hi";
import { TabResumen } from "./TabItems/TabResumen";
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md";
import { TbSubtask } from "react-icons/tb";
import { TabLogs } from "../../../../Emails/views/TabItems/TabLogs";
import { EmailsSectionTypes } from "../../../../../shared/utils/Types/EmailsSectionTypes";
import { TabPendientes } from "../../../../Emails/views/TabItems/TabPendientes";
import { TabTareas } from "../../../../Emails/views/TabItems/TabTareas";
import { Helmet } from "react-helmet";
import { TabsRouterInt } from "../../../../../interfaces/ComponentsInt";
import { TabsRouter } from "../../../../../shared/components/TabsRouter/TabsRouter";
import { BsPersonFillCheck } from "react-icons/bs";
import { TabsAsistencias } from "./TabItems/TabAsistencias";

export const TabsInformationMatriculas = () => {
    const { id } = useParams();
    const client = useClient();
    const { user } = useAuthContex();
    const { data: matricula, loading, Refresh } = useDataId({
        id: id,
        client: client,
        endpoint: EndpointTypes.MATRICULAS
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/matriculas/matriculas/${id}/perfil`,
            `/matriculas/matriculas/${id}/resumen`,
            `/matriculas/matriculas/${id}/informes`,
            `/matriculas/matriculas/${id}/asistencias`,
            `/matriculas/matriculas/${id}/enviados`,
            `/matriculas/matriculas/${id}/pendientes`,
            `/matriculas/matriculas/${id}/tareas`,
            `/matriculas/matriculas/${id}/sesiones`
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("perfil");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Perfil",
            path: "perfil",
            icon: BiUserCircle,
            content:
                <TabPerfilMatricula
                    matricula={matricula}
                    loading={loading}
                    client={client}
                    Refresh={Refresh}
                />
        },
        {
            isAllowed: true,
            path: "resumen",
            label: "Resumen",
            icon: HiOutlineDocumentText,
            content:
                <TabResumen
                    matricula={matricula}
                />
        },
        {
            isAllowed: true,
            path: "informes",
            label: "Informes",
            icon: BiLabel,
            content:
                <TabInformes
                    grupoId={matricula?.grupoId}
                />
        },
        {
            isAllowed: true,
            path: "asistencias",
            label: "Asistencias",
            icon: BsPersonFillCheck,
            content:
                <TabsAsistencias
                    grupoId={matricula?.grupoId}
                    userId={matricula?.userId}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "enviados",
            label: "Emails",
            icon: MdOutlineMarkEmailRead,
            content:
                <TabLogs
                    id={matricula?.id}
                    tipo={EmailsSectionTypes.MATRICULAS}
                    hideFilters={true}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "pendientes",
            label: "Pendientes",
            icon: MdOutlineMarkEmailUnread,
            content:
                <TabPendientes
                    id={matricula?.id}
                    tipo={EmailsSectionTypes.MATRICULAS}
                    hideFilters={true}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "tareas",
            label: "Tareas",
            icon: TbSubtask,
            content:
                <TabTareas
                    id={matricula?.id}
                    tipo={EmailsSectionTypes.MATRICULAS}
                    hideFilters={true}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            path: "sesiones",
            label: "Sesiones",
            icon: BiTime,
            content:
                <TabSesiones />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.MATRICULA}
        //         />
        // }
    ]

    return (
        <>
            <Helmet>
                <title>Matrículas </title>
            </Helmet>

            <Topbar
                title={
                    loading ?
                        <Flex gap="15px" w="100%" alignItems="center">
                            <Skeleton size="55px"></Skeleton>

                            <Flex direction="column" gap="10px">
                                <Skeleton height="30px" width="300px"></Skeleton>

                                <Skeleton height="20px" width="300px"></Skeleton>
                            </Flex>
                        </Flex>
                        :
                        <Flex
                            gap="5px"
                            alignItems="center"
                        >
                            {matricula?.grupo?.curso?.icono?.url ?
                                <Image
                                    src={matricula?.grupo?.curso?.icono?.url}
                                    padding="0"
                                    boxSize="40px"
                                />
                                :
                                <IconBox
                                    icon={undefined}
                                    size="40px"
                                    border="none"
                                    padding="0"
                                    bg="inherit"
                                />
                            }

                            <Flex
                                alignItems={"center"}
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="700"
                                    lineHeight="128%"
                                    textTransform="uppercase"
                                >
                                    {matricula?.grupo?.nombre}
                                </Text>

                                {matricula?.grupo?.fundae &&
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="12px"
                                        fontWeight="500"
                                        letterSpacing="0.18px"
                                        color="purewhite"
                                        p="3px 7px"
                                        borderRadius="5px"
                                        bg="secondary"
                                        w="fit-content"
                                    >
                                        FUNDAE
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                }
                buttonBack="/matriculas/matriculas"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                        buttons={[
                            {
                                isAllowed: true,
                                paths: ["perfil", "resumen", "informes", "enviados", "pendientes", "tareas", "sesiones"],
                                button: {
                                    label: "Ir al grupo",
                                    isDisabled: false,
                                    onClick: () => navigate(`/grupos/${matricula?.grupoId}/configuracion`),
                                    rightIcon: {
                                        as: BiRightArrowAlt,
                                        boxSize: "20px"
                                    }
                                }
                            },
                            {
                                isAllowed: true,
                                paths: ["perfil", "resumen", "informes", "enviados", "pendientes", "tareas", "sesiones"],
                                button: {
                                    label: "Ir al alumno",
                                    isDisabled: false,
                                    onClick: () => navigate(`/usuarios/alumnos/${matricula?.userId}/perfil`),
                                    rightIcon: {
                                        as: BiRightArrowAlt,
                                        boxSize: "20px"
                                    }
                                }
                            }
                        ]}
                    />
                </Flex>
            </Topbar>
        </>
    )
}