import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import "@fontsource/arimo"
import "@fontsource/inter"
import { modalCustomTheme } from './ui/modalTheme'
import { checkboxTheme } from './ui/checkbox'
import { SetColors } from './ui/colors'

const colors = SetColors();

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const breakpoints = {
    xs: '480px',
    sm: '768px',
    md: '1024px',
    lg: '1228px',
    xl: '1690px',
    '2xl': '1980px',
};

const theme = extendTheme({
    config,
    semanticTokens: {
        colors,
    },
    fonts: {
        body: `'Inter', sans-serif`
    },
    breakpoints: breakpoints,
    components: { Modal: modalCustomTheme, Checkbox: checkboxTheme, },
    colors: {
        blue: {
            50: '#e6eaff',
            100: '#c2d4ff',
            200: '#9fbfff',
            300: '#7caaff',
            400: '#5995ff',
            500: '#8694ff',
        },
        black: {
            50: '#ffffff',
            100: '#f2f2f2',
            200: '#d9d9d9',
            300: '#bfbfbf',
            400: '#a6a6a6',
            500: '#8c8c8c',
            600: '#737373',
            700: '#595959',
            800: '#404040',
            900: '#262626'
        }
    },
})

export default theme