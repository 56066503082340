import { Box, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react"
import { BiTrashAlt } from "react-icons/bi"
import { CustomAvatar } from "../Avatar/CustomAvatar"
import { addSupervisorGrupo, removeSupervisorGrupo } from "../../middlewares/supervisores.middleware"
import { toastNotify } from "../../utils/functions/toastNotify"
import { StatusEnumTypes } from "../../utils/Types/StatusEnumTypes"
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import { useAuthContex } from "../../context/auth.context"
import { isRoleAllowed } from "../../utils/functions/validateRol"
import { UserRolEnum } from "../../utils/Types/UserRolEnum"
import { useClient } from "../../context/client.context"
import { EndpointTypes } from "../../utils/Types/EndpointTypes"
import { loadData } from "../../utils/functions/loadData"
import { QueryTypes } from "../../utils/Types/QueryTypes"
import { useNavigate } from "react-router-dom"
import { EmpresasInt } from "../../../interfaces/EmpresasInt"
import { CopyTooltip } from "./CopyTooltip"
import { informationSelectStyles } from "../../../ui/informationSelectStyles"

interface Props {
    placeholder: string;
    name: string;
    label: string;
    isRequired?: boolean;
    type?: "supervisor";
    id?: string;
    data: any[] | undefined;
    setRefresh: () => void;
    empresas?: EmpresasInt[];
    isDisabled?: boolean;
}

export const AsyncCard = ({
    name,
    placeholder,
    label,
    type = "supervisor",
    isRequired = false,
    id,
    data,
    empresas,
    setRefresh,
    isDisabled = false
}: Props) => {
    const { user } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const navigate = useNavigate();

    const getEmpresasIds = () => {
        if (!empresas || empresas?.length === 0) return "";

        const ids: string[] = []

        empresas.forEach((empresa: any) => ids.push(empresa?.id))

        return ids.join(",")
    }

    const loadSupervisores = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.SUPERVISORES,
        specificQuery: value?.includes("@") ? undefined : `?empresa=${getEmpresasIds()}` as QueryTypes,
        query: QueryTypes.NOMBRE_EMAIL,
        nombreCompleto: true
    })

    const removeItem = (id: string) => {
        removeSupervisorGrupo({
            id: id,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Supervisor borrado")
                setRefresh();
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al borrar supervisor"))
    }

    const addValue = (values: any) => {
        const supervisorGrupo = {
            ...values,
            grupoId: id
        }

        return addSupervisorGrupo({
            data: supervisorGrupo,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Supervisor asociado")
                setRefresh()
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al asociar supervisor"))
    }

    const handleNavigate = (data: any) => {

        if (type === "supervisor" && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) {
            navigate(`/usuarios/supervisores/${data?.user?.id}/perfil`)
        }
    }

    return (
        <Flex w="100%" p="20px" direction="column">
            {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                <InformationAsyncSelect
                    isRequired={isRequired}
                    placeholder={placeholder}
                    name={name}
                    label={label}
                    updateValue={addValue}
                    loadOptions={loadSupervisores}
                    defaultOptions={true}
                    ui={{
                        informationSelectStyles: informationSelectStyles,
                        styleLabel: {
                            textTransform: "uppercase",
                            fontSize: "12px",
                            fontWeight: "600",
                            letterSpacing: "0.24px"
                        }
                    }}
                />
            }

            <Flex gap="20px" mt="10px">
                <Box flex="1" />

                <Flex
                    w="100%"
                    direction="column"
                    gap="10px"
                >
                    {data?.map((item: any, index: number) => (
                        <Flex
                            key={index}
                            borderRadius="9px"
                            p="10px 15px"
                            bg="purewhite"
                            gap="10px"
                            alignItems="center"
                            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                        >
                            <Flex
                                gap="10px"
                                alignItems="center"
                                cursor="pointer"
                            >

                                <Tooltip
                                    hasArrow
                                    placement="bottom"
                                    label="Ir al supervisor"
                                    display={!isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? "none" : "display"}
                                >
                                    <Box>
                                        <CustomAvatar
                                            name={item?.user?.nombre + item?.user?.apellidos || ""}
                                            size="30px"
                                            src={item?.user?.avatar?.url}
                                            onClick={() => handleNavigate(item)}
                                        />
                                    </Box>
                                </Tooltip>


                                <Box>
                                    <CopyTooltip
                                        idName={`copySupervisorName${index}`}
                                        isDisabled={!item?.user?.nombre}
                                    >
                                        <Text
                                            id={`copySupervisorName${index}`}
                                            cursor="copy"
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="600"
                                        >
                                            {item?.user?.nombre} {item?.user?.apellidos}
                                        </Text>
                                    </CopyTooltip>

                                    <CopyTooltip
                                        idName={`copySupervisorEmail${index}`}
                                        isDisabled={!item?.user?.email}
                                    >
                                        <Text
                                            id={`copySupervisorEmail${index}`}
                                            cursor="copy"
                                            color="font"
                                            fontSize="12px"
                                            fontWeight="500"
                                        >
                                            {item?.user?.email}
                                        </Text>
                                    </CopyTooltip>
                                </Box>
                            </Flex>

                            {(isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !isDisabled) &&
                                <Icon as={BiTrashAlt} boxSize="22px" ml="auto" cursor="pointer" onClick={() => removeItem(item?.id)} />
                            }
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    )
}