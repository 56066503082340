import { Button, Checkbox, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt";
import { CardInfo } from "../../../../../../shared/components/Cards/CardInfo";
import { AccordeonResumen } from "../../../../../Grupos/components/utils/AccordeonResumen";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../../shared/context/client.context";
import { CustomButton } from "../../../../../../shared/components/CustomElements/CustomButton";
import { useEffect, useState } from "react";
import { InformesEnumTypes } from "../../../../../../shared/utils/Types/InformesEnumTypes";
import { BiDownload } from "react-icons/bi";
import { getInformes } from "../../../../../../shared/middlewares/informes.middleware";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";
import tenantInstance from "../../../../../../shared/services/tenantInstance.service";
import { EntregableEstadoEnum } from "../../../../../../shared/utils/Types/EntregableEstadoEnum";
import { SkeletonTabResumen } from "../../../../components/SkeletonTabResumen";
import { useDataId } from "../../../../../../shared/hooks/useDataId";
import { useData } from "../../../../../../shared/hooks/useData";
import { getData } from "../../../../../../shared/middlewares/middlewares";

interface Props {
    matricula: MatriculasInt;
}

export const TabResumen = ({ matricula }: Props) => {
    const toast = useToast();
    const client = useClient();
    const [informesSelected, setInformesSelected] = useState<string[]>([]);
    const [downloadLoading, setDownloadLoading] = useState<{ status: boolean; name: string | undefined }>({ status: false, name: undefined });
    const [entregables, setEntregables] = useState<{ [key: string]: { estado: EntregableEstadoEnum; puntuacion: number; } }>({})
    const { data: curso, loading } = useDataId({
        id: matricula?.grupo?.cursoId,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: {
            preload: "true"
        }
    })
    
    const { data: progresos } = useData({
        endpoint: EndpointTypes.PROGRESOS,
        client: client,
        specificQuery: matricula ?
            {
                curso: matricula.grupo.cursoId!,
                matricula: matricula.id!
            }
            : undefined,
        ignoreRequest: !matricula || !matricula?.grupo?.cursoId || !matricula?.id
    })

    const validateLeccionCompleted = (leccion: string | undefined, data: any) => {
        if (!data?.data && data?.data?.length > 0) return false
        const progresos = data?.data[0]

        if (!progresos?.progresoLecciones || !leccion) return false

        if (progresos?.progresoLecciones?.hasOwnProperty(leccion)) {
            if (progresos?.progresoLecciones[leccion]?.estado === "finalizacion") return true
            else return false
        } else return false
    }

    const validateEntregable = (leccion: string | undefined, data: { [key: string]: { estado: EntregableEstadoEnum | null; puntuacion: number | null; } }) => {
        if (!data || !leccion) return { estado: null, puntuacion: null }

        if (data?.hasOwnProperty(leccion)) {
            return { estado: data[leccion]?.estado, puntuacion: data[leccion]?.puntuacion }
        } else return { estado: null, puntuacion: null }
    }

    useEffect(() => {
        if (!progresos?.data || progresos?.data?.length === 0 || !matricula?.id) return

        tenantInstance().then((tenant) => {
            if (!progresos?.data[0]?.progresoLecciones) return

            Object.keys(progresos?.data[0]?.progresoLecciones).map((leccionId: string) => {
                getData(
                    `${tenant}/${client}${EndpointTypes.ENTREGABLES}?leccion=${leccionId}&matricula=${matricula?.id}&sortBy=createdAt&order=desc&limit=1`
                ).then((data: any) => {
                    if (!data?.data?.data?.data || data?.data?.data?.data?.length === 0) return

                    if (leccionId in entregables) return

                    setEntregables((prev: { [key: string]: { estado: EntregableEstadoEnum; puntuacion: number; } }) => ({
                        ...prev,
                        [leccionId]: {
                            estado: data?.data?.data?.data[0]?.estado,
                            puntuacion: data?.data?.data?.data[0]?.correccion ? data?.data?.data?.data[0]?.correccion?.puntuacion : null
                        }
                    })
                    )
                })
            })
        })
    }, [progresos])

    const CardInfoData: any = [
        {
            onClick: () => { },
            label: "Actividades completadas",
            info: matricula?.progresoLecciones?.toFixed(0) + "%",
            bg: "purewhite",
            tooltip: "Total de grupos activos actualmente",
        }
        ,
        {
            onClick: () => { },
            label: "Ejercicios completados",
            info: matricula?.progresoEntregables?.toFixed(0) + "%",
            bg: "purewhite",
            tooltip: "Total de alumnos activos actualmente",
        },
        {
            onClick: () => { },
            label: "Nota media",
            info: matricula?.puntuacionMedia?.toFixed(0),
            bg: "purewhite",
            tooltip: "Total de grupos que inician la próxima semana",
        },
        // {
        //     onClick: () => { },
        //     label: "Rendimiento",
        //     info: "30%",
        //     bg: "purewhite",
        //     tooltip: "Total de grupos que inician la próxima semana",
        // }
    ]

    const optionsList = [
        {
            title: "Expediente y Conexión de actividad",
            subtitle: "Datos del alumno y del curso, y actividades realizadas con el registro de conexiones",
            name: InformesEnumTypes.CONEXIONES_ACTIVIDAD,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CONEXIONES_ACTIVIDAD)) return prev.filter((item: string) => item !== InformesEnumTypes.CONEXIONES_ACTIVIDAD)
                    else return [...prev, InformesEnumTypes.CONEXIONES_ACTIVIDAD]
                })
            },
        },
        {
            title: "Interacción con el tutor",
            subtitle: "Historial de mensajes del alumno con el tutor y resolución de las actividades",
            name: InformesEnumTypes.TUTOR,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.TUTOR)) return prev.filter((item: string) => item !== InformesEnumTypes.TUTOR)
                    else return [...prev, InformesEnumTypes.TUTOR]
                })
            },
        },
        {
            title: "Recibido diploma",
            subtitle: "Confirmación de que el alumno ha recibido el diploma",
            name: InformesEnumTypes.RECIBI,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.RECIBI)) return prev.filter((item: string) => item !== InformesEnumTypes.RECIBI)
                    else return [...prev, InformesEnumTypes.RECIBI]
                })
            },
        },
        {
            title: "Cuestionario final",
            subtitle: "Cuestionario de calidad de la formación",
            name: InformesEnumTypes.CUESTIONARIO,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CUESTIONARIO)) return prev.filter((item: string) => item !== InformesEnumTypes.CUESTIONARIO)
                    else return [...prev, InformesEnumTypes.CUESTIONARIO]
                })
            },
        },
        {
            title: "Conexión en horas laborales",
            subtitle: "Datos e historial de todas las conexiones en horario laboral",
            name: InformesEnumTypes.CONEXIONES_HORARIO_LABORAL,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CONEXIONES_HORARIO_LABORAL)) return prev.filter((item: string) => item !== InformesEnumTypes.CONEXIONES_HORARIO_LABORAL)
                    else return [...prev, InformesEnumTypes.CONEXIONES_HORARIO_LABORAL]
                })
            },
        },
        {
            title: "Interacción foro",
            subtitle: "Datos, preguntas y respuestas creadas por el alumno en el foro",
            name: InformesEnumTypes.FORO,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.FORO)) return prev.filter((item: string) => item !== InformesEnumTypes.FORO)
                    else return [...prev, InformesEnumTypes.FORO]
                })
            },
        },
        {
            title: "Conexiones",
            subtitle: "Datos e historial de las conexiones por actividad y por días",
            name: InformesEnumTypes.CONEXIONES,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CONEXIONES)) return prev.filter((item: string) => item !== InformesEnumTypes.CONEXIONES)
                    else return [...prev, InformesEnumTypes.CONEXIONES]
                })
            },
        },
        {
            title: "Conexiones formador",
            subtitle: "Datos e historial de las conexiones del formador",
            name: InformesEnumTypes.CONEXIONES_FORMADOR,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CONEXIONES_FORMADOR)) return prev.filter((item: string) => item !== InformesEnumTypes.CONEXIONES_FORMADOR)
                    else return [...prev, InformesEnumTypes.CONEXIONES_FORMADOR]
                })
            },
        },
        {
            title: "Diploma",
            subtitle: "Diploma del alumno",
            name: InformesEnumTypes.DIPLOMA,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.DIPLOMA)) return prev.filter((item: string) => item !== InformesEnumTypes.DIPLOMA)
                    else return [...prev, InformesEnumTypes.DIPLOMA]
                })
            },
        },
        {
            title: "Guía didáctica",
            subtitle: "Guía didáctica del curso",
            name: InformesEnumTypes.GUIA_DIDACTICA,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.GUIA_DIDACTICA)) return prev.filter((item: string) => item !== InformesEnumTypes.GUIA_DIDACTICA)
                    else return [...prev, InformesEnumTypes.GUIA_DIDACTICA]
                })
            },
        },
        {
            title: "Reseña profesional",
            subtitle: "Reseña del formador",
            name: InformesEnumTypes.CV_TUTOR,
            onSelected: () => {
                setInformesSelected((prev: string[]) => {
                    if (prev.includes(InformesEnumTypes.CV_TUTOR)) return prev.filter((item: string) => item !== InformesEnumTypes.CV_TUTOR)
                    else return [...prev, InformesEnumTypes.CV_TUTOR]
                })
            },
        }
    ]

    const downloadSelect = (informes: string[]) => {
        if (!matricula?.id) return
        if (informes?.length === 1) setDownloadLoading({ status: true, name: informes[0] })
        if (informes?.length > 1) setDownloadLoading({ status: true, name: "all" })

        getInformes({
            data: {
                grupoId: matricula?.grupoId as string,
                matriculas: [{
                    id: matricula?.id,
                    informes: informes,
                    debug: true
                }]
            },
            client: client
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Los informes se están generando, recibirá una notificación con los enlaces de descarga"))
            .catch((error) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setDownloadLoading({ status: false, name: undefined }))
    }

    return (
        !matricula ? <SkeletonTabResumen /> :
            <Flex
                direction="column"
                gap="30px"
            >
                <Flex
                    w="100%"
                    gap="30px"
                >
                    {CardInfoData?.map((item: any, index: number) => (
                        <CardInfo
                            key={index}
                            icon={item?.icon}
                            onClick={item?.onClick}
                            label={item?.label}
                            info={item?.info}
                            bg={item?.bg}
                            mb={item?.mb}
                            tooltip={item?.tooltip}
                            cambioPorcentualGrupos={item?.cambioPorcentual}
                        />
                    ))}
                </Flex>

                <Flex
                    gap="30px"
                >
                    <Flex
                        flex="1"
                    >
                        <AccordeonResumen
                            curso={curso}
                            validateLeccionCompleted={validateLeccionCompleted}
                            progresos={progresos}
                            validateEntregable={validateEntregable}
                            entregables={entregables}
                            loading={loading}
                        />
                    </Flex>

                    <Flex
                        flex="1"
                        bg={"purewhite"}
                        p="24px"
                        rounded="14px"
                        border={"1px solid #DFE2E6"}
                        direction="column"
                        gap="15px"
                    >
                        <Text
                            color="font"
                            fontSize="18px"
                            fontWeight="600"
                        >
                            Informes
                        </Text>

                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            gap="30px"
                        >
                            <Flex
                                alignItems="center"
                                gap="30px"
                            >
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="400"
                                >
                                    Selecciona los informes a descargar
                                </Text>

                                <Text
                                    color="#959596"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    {informesSelected?.length || 0} seleccionados
                                </Text>
                            </Flex>

                            <CustomButton
                                button={{
                                    label: "Descargar",
                                    onClick: () => downloadSelect(informesSelected),
                                    isDisabled: informesSelected?.length === 0,
                                    isLoading: (downloadLoading?.status && downloadLoading?.name === "all")
                                }}
                                tooltip={{
                                    label: informesSelected?.length === 0 ? "Debe seleccionar al menos 1 informe" : "Descargar informes seleccionados",
                                    isDisabled: false,
                                    placement: "right"
                                }}
                            />
                        </Flex>

                        <Flex
                            p="24px"
                            rounded="14px"
                            border={"1px solid #DFE2E6"}
                            direction="column"
                            gap="15px"
                        >
                            <Checkbox
                                color="#616675"
                                fontSize="14px"
                                fontWeight="400"
                                onChange={() => {
                                    setInformesSelected((prev: string[]) => {
                                        if (prev?.length > 0) return []
                                        else {
                                            const all: string[] = []
                                            Object?.values(InformesEnumTypes)?.map((item: string) => all.push(item))

                                            return all
                                        }
                                    })
                                }}
                            >
                                Seleccionar todos
                            </Checkbox>

                            <Flex
                                direction="column"
                                gap="20px"
                            >
                                {optionsList?.map((item: any, index: number) => (
                                    <Flex
                                        key={index}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap="30px"
                                    >
                                        <Checkbox
                                            onChange={item?.onSelected}
                                            isChecked={informesSelected?.includes(item?.name)}
                                        >
                                            <Flex
                                                direction="column"
                                                gap="4px"
                                            >
                                                <Text
                                                    color="font"
                                                    fontSize="15px"
                                                    fontWeight="400"
                                                >
                                                    {item?.title}
                                                </Text>

                                                <Text
                                                    color="#616675"
                                                    fontSize="12px"
                                                    fontWeight="400"
                                                >
                                                    {item?.subtitle}
                                                </Text>
                                            </Flex>
                                        </Checkbox>

                                        <Button
                                            h="fit-content"
                                            w="fit-content"
                                            py="5px"
                                            px="10px"
                                            bg="secondary"
                                            _hover={{ bg: "variant" }}
                                            onClick={() => downloadSelect([item?.name])}
                                            rounded="4px"
                                            isDisabled={informesSelected?.length > 0}
                                            isLoading={(downloadLoading?.status && downloadLoading?.name === item?.name)}
                                        >
                                            <Icon as={BiDownload} boxSize={"18px"} color="purewhite" />
                                        </Button>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex >
    );
};