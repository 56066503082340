import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { TabsInformationMatriculas } from "./views/Matriculas/Information/TabsInformation";
import { TabsTableMatriculas } from "./views/TabsMatriculas";

export const Matriculas = () => {

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Flex direction="column">
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <TabsTableMatriculas />
                        }
                    />

                    <Route
                        path='matriculas/:id/*'
                        element={
                            <TabsInformationMatriculas />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}