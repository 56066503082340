import { PuckButton } from "../../CustomElements/Button.tsx/PunkButton";
import { TextVariable } from "../../CustomElements/Fields/TextVariable";
import { textColors } from "../constants";

export type ButtonProps = {
    align?: "left" | "center" | "right";
    label: string;
    href: string;
    variant: "primary" | "secondary";
    colorScheme: string;
    textColor: string;
    size?: "medium" | "large";
    colorSchema: string;
    fontSize: string;
};

export const Button = {
    label: "Button",
    fields: {
        align: {
            type: "radio",
            options: [
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Right", value: "right" },
            ],
        },
        label: { type: "text" },
        href: {
            type: "custom",
            label: "Texto del título",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector('textarea[name="href"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                        minHeight="47px"
                    />
                )
            }
        },
        variant: {
            type: "select",
            options: [
                { label: "primary", value: "primary" },
                { label: "secondary", value: "secondary" },
            ],
        },
        size: {
            type: "select",
            options: [
                { label: "medium", value: "medium" },
                { label: "large", value: "large" },
            ],
        },
        fontSize: {
            type: "select",
            options: [
                { label: "12px", value: "12px" },
                { label: "14px", value: "14px" },
                { label: "16px", value: "16px" },
                { label: "18px", value: "18px" },
            ],
        },
        colorSchema: {
            type: "select",
            options: textColors
        }
    },
    defaultProps: {
        align: "left",
        size: "large",
        label: "Learn more",
        href: "#",
        variant: "primary",
        colorSchema: "#6f42c1",
        fontSize: "16px",
    },
    render: ({ label, href, variant, size, colorSchema, fontSize, align }: ButtonProps) => {
        return (
            <table width="100%" cellPadding="0" cellSpacing="0" role="presentation">
                <tr>
                    <td
                        align={align}
                        style={{
                            textAlign: align,
                            verticalAlign: "middle",
                            lineHeight: "normal",
                            minHeight: "50px"
                        }}
                    >
                        <PuckButton
                            href={href}
                            variant={variant}
                            size={size}
                            colorSchema={colorSchema}
                            fontSize={fontSize}
                        >
                            {label}
                        </PuckButton>
                    </td>
                </tr>
            </table>
        );
    },
};
