import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Box, Grid, Flex, Text, UnorderedList, ListItem } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    temario: any;
}
export const TemarioPreviewModal = ({
    isOpen,
    onClose,
    temario,
}: Props) => {

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="full"
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="95vh"
                rounded="20px"
                p="40px"
            >
                <Box
                    m="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >

                    <ModalBody>
                        <Text
                            fontSize="20px"
                            fontWeight="600"
                            color="secondary"
                            mb="40px"
                            textTransform={"uppercase"}
                        >
                            Contenidos impartidos
                        </Text>

                        <Grid
                            columnGap="30px"
                            rowGap="50px"
                            templateColumns="repeat(3, 1fr)"
                            w="100%"
                        >
                            {Object.entries(temario || {}).map(([key, value]) => (
                                <Flex
                                    direction="column"
                                >
                                    <Text
                                        fontSize="18px"
                                        fontWeight="600"
                                        color="pureblack"
                                        mb="20px"
                                    >
                                        {key}. {(value as any)?.tema}
                                    </Text>

                                    <UnorderedList>
                                        {(value as any)?.subtemas.map((subtema: string) => (
                                            <ListItem
                                                fontSize="14px"
                                                fontWeight="400"
                                                color="pureblack"
                                                mb="5px"
                                            >
                                                {subtema}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Flex>
                            ))}
                        </Grid>
                    </ModalBody>

                    <ModalFooter
                        display="flex"
                        flexDirection="column"
                        gap="20px"
                        mt="100px"
                    >
                        <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="pureblack"
                        >
                            * Esta previsualización muestra cómo se verá el temario en la guía didáctica y en los diplomas emitidos.
                        </Text>

                        <Button
                            bg="secondary"
                            p="10px 25px"
                            h="fit-content"
                            rounded="5"
                            color="purewhite"
                            fontSize="18px"
                            fontWeight="500"
                            onClick={onClose}
                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Box>
            </ModalContent>
        </Modal>
    )
}