import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Text,
    Flex,
    Icon,
    useToast,
} from '@chakra-ui/react'
import { CustomAvatar } from '../../../shared/components/Avatar/CustomAvatar';
import { VscClose } from 'react-icons/vsc';
import { useClient } from '../../../shared/context/client.context';
import { CustomButton } from '../../../shared/components/CustomElements/CustomButton';
import { resendEmailLog } from '../../../shared/middlewares/emails.middleware';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { useTimeZone } from '../../../shared/hooks/useTimeZone';
import { formatTimezone } from '../../../shared/utils/functions/parseDate';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    email: any;
}

export const EmailDrawer = ({ email, isOpen, onClose }: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const toast = useToast();
    const totalHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const totalWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const handleResendEmail = () => {
        if (!email.id) return;

        resendEmailLog({
            emailId: email.id,
            client: client
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, 'Email reenviado correctamente'))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="40vw"
                minW="40vw"
                w="40vw"
            >
                <DrawerBody
                    className='scroll-bar'
                    p="0"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        pr="40px"
                        pl="20px"
                        py="20px"
                        bg="light_grey"
                    >
                        <Flex
                            align="center"
                            gap="20px"
                        >
                            <CustomAvatar
                                name={email?.user?.nombre}
                                src={email?.user?.avatar?.url}
                                size="55px"
                            />

                            <Flex
                                direction="column"
                            >
                                <Text
                                    color="font"
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    {email?.user?.nombre} {email?.user?.apellidos}
                                </Text>

                                <Text
                                    color="secondary_font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    {email?.destinatario}
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            gap="15px"
                        >
                            <CustomButton
                                button={{
                                    label: 'Reenviar',
                                    isDisabled: false,
                                    onClick: handleResendEmail,
                                }}
                                tooltip={{
                                    label: 'Reenviar email',
                                    isDisabled: false,
                                }}
                            />

                            <Flex
                                bg="purewhite"
                                rounded="100%"
                                p="6px"
                                border="1px solid"
                                borderColor="grey"
                                shadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                                onClick={onClose}
                                cursor="pointer"
                                _active={{ transform: "scale(0.95)" }}
                            >
                                <Icon as={VscClose} boxSize="22px" />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        px="20px"
                        py="10px"
                        justifyContent="space-between"
                        alignItems={"center"}
                    >
                        <Flex
                            direction="column"
                        >
                            <Text
                                fontWeight={600}
                                fontSize="16px"
                                color="font"
                            >
                                Asunto
                            </Text>

                            <Text
                                fontWeight={500}
                                fontSize="14px"
                                color="font"
                            >
                                {email?.asunto}
                            </Text>
                        </Flex>

                        <Flex
                            direction="column"
                        >
                            <Text
                                fontWeight={600}
                                fontSize="16px"
                                color="font"
                            >
                                Fecha Envio
                            </Text>

                            <Text
                                fontWeight={500}
                                fontSize="14px"
                                color="font"
                            >
                                {email?.fechaEnvio && formatTimezone({ date: email?.fechaEnvio, timeZone, isDateTime: true })}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        px="20px"
                        py="10px"
                        gap="20px"
                        direction="column"
                    >
                        <Text
                            fontWeight={600}
                            fontSize="16px"
                            color="font"
                        >
                            Contenido
                        </Text>

                        <iframe
                            title="Email Preview"
                            srcDoc={email?.contenido?.replace("<!--[if mso]>", scrollBar)}
                            width={`${totalWidth / 2.65}px`}
                            height={`${totalHeight / 1.22}px`}
                        />
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}

const scrollBar = `<style>
::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}
</style>`