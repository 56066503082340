import { Flex, Skeleton, Text } from "@chakra-ui/react"
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md"
import { TabLogs } from "./TabItems/TabLogs"
import { TabPendientes } from "./TabItems/TabPendientes"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { TbSubtask } from "react-icons/tb";
import { TabTareas } from "./TabItems/TabTareas"
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter"
import { TabsRouterInt } from "../../../interfaces/ComponentsInt"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const TabsEmails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            "/emails/enviados",
            "/emails/pendientes",
            "/emails/tareas"
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("enviados");
        }
    }, [location.pathname]);


    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "enviados",
            label: "Emails Enviados",
            icon: MdOutlineMarkEmailRead,
            content: <TabLogs />
        },
        {
            isAllowed: true,
            path: "pendientes",
            label: "Emails Programados",
            icon: MdOutlineMarkEmailUnread,
            content: <TabPendientes />
        },
        {
            isAllowed: true,
            path: "tareas",
            label: "Tareas",
            icon: TbSubtask,
            content: <TabTareas />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Emails
                </Text>
            }
        >
            <Flex
                direction="column"
                p="30px"
            >

                <TabsRouter
                    tabs={tabs}
                />
            </Flex>
        </Topbar>
    )
}