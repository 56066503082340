import { Box, Flex, Icon, Image, StyleProps, Text, Tooltip } from "@chakra-ui/react"
import { BiTrashAlt } from "react-icons/bi"
import { CustomAvatar } from "../Avatar/CustomAvatar"
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import { IconBox } from "../Icons/IconBox"
import { useAuthContex } from "../../context/auth.context"
import { UserRolEnum } from "../../utils/Types/UserRolEnum"
import { isRoleAllowed } from "../../utils/functions/validateRol"
import { useNavigate } from "react-router-dom"
import { CopyTooltip } from "../CustomElements/CopyTooltip"
import { useModuloFundae } from "../../hooks/ModuloFundae"
import { useState } from "react"
import { informationSelectStyles } from "../../../ui/informationSelectStyles";
import { useUserPolicy } from "../../hooks/PermissionPolicy";

interface Props {
    updateValue: any;
    placeholder: string;
    name: string;
    label: string;
    isRequired?: boolean;
    loadOptions: any;
    data?: any;
    usuario?: any;
    type: "tutor" | "empresa" | "supervisor";
    removeItem: (id: any) => void;
    isAsynCard?: boolean;
    showMatriculas?: boolean;
    allowRemove?: boolean;
    isDisabled?: boolean;
    styleLabel?: StyleProps
    containerStyle?: StyleProps
}

export const InformationAsyncCard = ({
    updateValue,
    name,
    placeholder,
    label,
    loadOptions,
    data = [],
    usuario,
    type,
    removeItem,
    isRequired = false,
    isAsynCard = true,
    showMatriculas = true,
    allowRemove = true,
    isDisabled = false,
    styleLabel,
    containerStyle = {
        px: "20px",
        py: "20px"
    }
}: Props) => {
    const moduloFUNDAE = useModuloFundae();
    const { user } = useAuthContex();
    const navigate = useNavigate();

    const handleNavigate = (data: any) => {

        if (type === "empresa" && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) {
            navigate(`/empresas/${data?.id}/perfil`)
        } else if (type === "tutor" && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) {
            navigate(`/usuarios/tutores/${data?.id}/perfil`)
        } else if (type === "supervisor" && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) {
            navigate(`/usuarios/supervisores/${data?.user?.id}/perfil`)
        }
    }

    return (
        <Flex
            w="100%"
            direction="column"
            {...containerStyle}
        >
            {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                <Flex
                    w="100%"
                >
                    <InformationAsyncSelect
                        ui={{
                            informationSelectStyles: informationSelectStyles,
                            styleLabel: {
                                textTransform: "uppercase",
                                fontSize: "12px",
                                fontWeight: "600",
                                letterSpacing: "0.24px"
                            }
                        }}
                        isRequired={isRequired}
                        placeholder={placeholder}
                        name={name}
                        label={label}
                        updateValue={updateValue}
                        loadOptions={loadOptions}
                        isAsynCard={isAsynCard}
                        defaultOptions={true}
                        asynCardData={
                            data
                                ? data?.map((c: any) => ({
                                    label: c.nombre,
                                    value: c.id,
                                }))
                                : []
                        }
                    />
                </Flex>
            }

            <Flex
                gap="20px"
                mt="10px"
            >
                <Box
                    flex="1"
                />

                {type !== "supervisor" ?
                    <Flex
                        flex="100%"
                        rounded="9px"
                        direction="column"
                        gap="10px"
                    >
                        {data?.map((item: any, index: number) => (
                            <Flex
                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                key={index}
                                borderRadius="9px"
                                p="10px 15px"
                                bg="purewhite"
                                gap="10px"
                                alignItems="center"
                            >
                                <Flex
                                    gap="10px"
                                    alignItems="center"
                                    cursor="pointer"
                                >
                                    <Tooltip
                                        hasArrow
                                        placement="bottom"
                                        label={type === "empresa" ? "Ir a la empresa" : "Ir al tutor"}
                                        display={!isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? "none" : "display"}
                                    >
                                        <Flex
                                            onClick={() => handleNavigate(item)}
                                        >
                                            {type !== "empresa"
                                                ?
                                                <CustomAvatar
                                                    name={item?.nombre + item?.apellidos || ""}
                                                    size="30px"
                                                    src={item?.avatar?.url}
                                                />
                                                :
                                                (item?.icono?.url ?
                                                    <Image
                                                        src={item?.icono?.url}
                                                        padding="0"
                                                        boxSize="30px"
                                                    />
                                                    :
                                                    <IconBox
                                                        icon={undefined}
                                                        size="30px"
                                                        border="none"
                                                        padding="0"
                                                        bg="inherit"
                                                    />
                                                )
                                            }
                                        </Flex>
                                    </Tooltip>


                                    <Flex
                                        direction="column"
                                    >
                                        <CopyTooltip
                                            idName={type === "empresa" ? `copyEmpresa${index}` : `copyName${index}`}
                                            isDisabled={!item?.nombre}
                                        >
                                            {type == "empresa" ?
                                                <Text
                                                    id={`copyEmpresa${index}`}
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                    cursor="copy"
                                                >
                                                    {item?.nombre}
                                                </Text>
                                                :
                                                <Text
                                                    id={`copyName${index}`}
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                    cursor="copy"
                                                >
                                                    {item?.nombre} {item?.apellidos}
                                                </Text>
                                            }
                                        </CopyTooltip>

                                        {type == "empresa" &&
                                            <CopyTooltip
                                                idName={`copyCif${index}`}
                                                isDisabled={!item?.cif}
                                            >
                                                <Text
                                                    id={`copyCif${index}`}
                                                    color="font"
                                                    fontSize="12px"
                                                    fontWeight="500"
                                                    cursor="copy"
                                                >
                                                    {item?.cif}
                                                </Text>
                                            </CopyTooltip>
                                        }

                                        {(type === "tutor" && showMatriculas) &&
                                            <Flex direction="column">
                                                <Flex
                                                    color="font"
                                                    fontSize="13px"
                                                    fontWeight="400"
                                                    mb="5px"
                                                    gap="5px"
                                                >
                                                    <Text>
                                                        DNI:
                                                    </Text>

                                                    <CopyTooltip
                                                        idName={`copyDni${index}`}
                                                        isDisabled={!item?.dni}
                                                    >
                                                        <Text
                                                            cursor="copy"
                                                            id={`copyDni${index}`}
                                                        >
                                                            {item?.dni ? item?.dni : "Sin asignar"}
                                                        </Text>
                                                    </CopyTooltip>
                                                </Flex>
                                                {moduloFUNDAE &&
                                                    <>
                                                        <Text
                                                            color="font"
                                                            fontSize="12px"
                                                            fontWeight="400"
                                                        >
                                                            Matriculas FUNDAE
                                                        </Text>

                                                        <Flex
                                                            gap="15px"
                                                            color="font"
                                                            fontSize="11px"
                                                            fontWeight="400"
                                                        >
                                                            <Text>
                                                                Activas: {item?.meta?.matriculasFundaeActivas}
                                                            </Text>

                                                            <Text>
                                                                Próximas: {item?.meta?.matriculasFundaeProximas}
                                                            </Text>
                                                        </Flex>
                                                    </>
                                                }
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>

                                {allowRemove &&
                                    (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                                        <Icon as={BiTrashAlt} boxSize="22px" ml="auto" cursor="pointer" onClick={() => removeItem(item?.id)} />
                                    )
                                }
                            </Flex>
                        )
                        )}
                    </Flex>
                    :
                    usuario &&
                    <Flex
                        boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                        borderRadius="9px"
                        p="10px 15px"
                        bg="purewhite"
                        gap="10px"
                        alignItems="center"
                        flex="1"
                        ml="30px"
                    >
                        <CustomAvatar
                            name={usuario?.nombre + usuario?.apellidos || ""}
                            size="30px"
                            src={usuario?.avatar?.url}
                        />

                        <Flex direction="column">
                            <Flex gap="10px">
                                <Text fontWeight="600">User:</Text>
                                <Text>{usuario?.username}</Text>
                            </Flex>

                            <Flex gap="10px">
                                <Text fontWeight="600">clave:</Text>
                                <Text>ClaveImagina2023</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}