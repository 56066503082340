import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Image,
    Text,
    Flex,
    FormLabel,
    useToast,
    Button
} from '@chakra-ui/react'
import { AnunciosInt } from '../../../../interfaces/AnunciosInt';
import { useTimeZone } from '../../../../shared/hooks/useTimeZone';
import { formatTimezone } from '../../../../shared/utils/functions/parseDate';
import * as Yup from "yup"
import { Form as FormikForm, Formik } from 'formik';
import 'filepond/dist/filepond.min.css'
import { FilePond } from 'react-filepond';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { updateAnuncio } from '../../../../shared/middlewares/anuncios.middleware';
import { useClient } from '../../../../shared/context/client.context';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { InformationFilepond } from '../../../../shared/components/Information/InformationFilepond';
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { FormDateInput } from '@imaginagroup/bit-components.ui.form-date-input';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/Types/StatusEnumTypes';
import { useState } from 'react';
import { CustomButton } from '../../../../shared/components/CustomElements/CustomButton';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    anuncios: AnunciosInt | null;
    Refresh: () => void;
}

export const AnunciosDrawer = ({ anuncios, isOpen, onClose, Refresh }: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const toast = useToast()
    const [loading, setLoading] = useState<boolean>(false)

    const initialValues: any = {
        titulo: anuncios?.titulo,
        contenido: anuncios?.contenido,
        puvlicado: anuncios?.publicado,
        fechaExpiracion: anuncios?.fechaExpiracion,
    };

    const validationSchema = Yup.object().shape({
        titulo: Yup.string().notRequired(),
        contenido: Yup.string().notRequired(),
        publicado: Yup.boolean().notRequired(),
        fechaExpiracion: Yup.date().notRequired().nullable(),
    });

    const handleSubmit = (values: any) => {
        if (!anuncios?.id) return;
        setLoading(true)

        let editAnuncio = {}
        Object.entries(values)?.forEach(([key, value]) => {
            if (value !== anuncios[key as keyof AnunciosInt]) {
                editAnuncio = {
                    ...editAnuncio,
                    [key]: value
                };
            }
        });

        updateAnuncio({
            id: anuncios?.id,
            data: editAnuncio,
            client: client
        })
            .then(() => Refresh())
            .catch((error: any) => {
                console.log(error)
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="40vw"
                minW="40vw"
                w="40vw"
            >
                <DrawerBody
                    className='scroll-bar'
                    py="20px"
                    px="30px"
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <Text
                                        mb="20px"
                                        fontSize="20px"
                                        fontWeight="600"
                                        color="font"
                                    >
                                        Previsualiza y edita tu anuncio
                                    </Text>

                                    <Flex
                                        alignItems="center"
                                        mb="20px"
                                    >
                                        <Flex
                                            direction="column"
                                            flex="1"
                                        >
                                            <Text
                                                fontSize="12px"
                                                fontWeight="600"
                                                color="font"
                                                textTransform="uppercase"
                                            >
                                                Destino
                                            </Text>

                                            <Text
                                                fontSize="16px"
                                                fontWeight="400"
                                                color="font"
                                            >
                                                {anuncios?.general ? 'General' : anuncios?.grupo?.nombre}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            direction="column"
                                            flex="1"
                                        >
                                            <Text
                                                fontSize="12px"
                                                fontWeight="600"
                                                color="font"
                                                textTransform="uppercase"
                                            >
                                                Creacion
                                            </Text>

                                            <Text
                                                fontSize="16px"
                                                fontWeight="400"
                                                color="font"
                                            >
                                                {anuncios?.createdAt && formatTimezone({ date: anuncios?.createdAt as string, timeZone, customFormat: "dd/MM/yyyy" })}
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <FormInput
                                        name="titulo"
                                        label="Titulo"
                                        placeholder='Titulo del anuncio'
                                        isRequired={!values?.titulo ? true : false}
                                        ui={{
                                            styleInput: {},
                                            styleLabel: {
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "font",
                                                textTransform: "uppercase",
                                            }
                                        }}
                                    />

                                    {anuncios?.imagen &&
                                        <Flex
                                            direction="column"
                                            mb="20px"
                                        >
                                            <FormLabel
                                                fontSize="12px"
                                                fontWeight="600"
                                                color="font"
                                                textTransform="uppercase"
                                            >
                                                Portada
                                            </FormLabel>

                                            <Image
                                                src={anuncios?.imagen?.url}
                                                alt={anuncios?.titulo}
                                                h="250px"
                                                w="100%"
                                                rounded={"4px"}
                                                shadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                                mb="10px"
                                            />

                                            <InformationFilepond
                                                noHead={true}
                                                name="imagen"
                                                label="Portada"
                                                putEP={`${client}/anuncios/${anuncios?.id}`}
                                                isDisabled={!anuncios?.id}
                                                setRefresh={Refresh}
                                            />

                                            <Flex
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="fail"
                                                direction="column"
                                                alignContent="center"
                                                justifyContent="center"
                                                mt="10px"
                                            >
                                                <Text
                                                    as="strong"
                                                    fontSize="12px"
                                                    textTransform="uppercase"
                                                    textDecoration="underline"
                                                >
                                                    ATENCION
                                                </Text>

                                                <Text>
                                                    La previsualización de la portada es como se mostrará en el campus, seleccione los tamaños adecuados.
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    }

                                    <Flex
                                        mb="10px"
                                        direction="column"
                                        gap="10px"
                                    >
                                        <FormTextarea
                                            name="contenido"
                                            label="Contenido"
                                            ui={{
                                                minHeigh: '150px',
                                                styleLabel: {
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "font",
                                                    textTransform: "uppercase",
                                                },
                                                styles: {
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    borderColor: "#EAEAEA",
                                                    padding: "7px 10px"
                                                },
                                            }}
                                        />


                                        <FormDateInput
                                            locale="es"
                                            name="fechaExpiracion"
                                            label="Fecha Expiración"
                                            onChange={formik.setFieldValue}
                                            placeholder="Día/Mes/Año"
                                            ui={{
                                                styleLabel: {
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "font",
                                                    textTransform: "uppercase",
                                                },
                                            }}
                                        />
                                    </Flex>

                                    <Flex>
                                        <Button
                                            isLoading={loading}
                                            bg="secondary"
                                            color="purewhite"
                                            h="fit-content"
                                            w="fit-content"
                                            py="10px"
                                            px="25px"
                                            fontSize="16px"
                                            fontWeight="600"
                                            type='submit'
                                        >
                                            Guardar
                                        </Button>
                                    </Flex>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}
