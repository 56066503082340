import { ComponentConfig, DropZone } from "@measured/puck";
import styles from "./styles.module.css";
import getClassNameFactory from "../../../../../shared/utils/functions/getClassNameFactory";
import { backgroundColors, textColors } from "../Table/constants";

const getClassName = getClassNameFactory("ContainerSection", styles);

export type ContainerProps = {
    padding: string;
    align: "left" | "center" | "right";
    backgroundColor: string;
    border: string;
    borderColor: string;
    borderRadius: string;
    backgroundImage: string | null;
};

export const Container: ComponentConfig<ContainerProps> = {
    fields: {
        backgroundImage: {
            type: "text",
            label: "Imagen de fondo",
        },
        backgroundColor: {
            type: "select",
            label: "Color de fondo",
            options: backgroundColors,
        },
        padding: {
            type: "select",
            label: "Padding",
            options: [
                { label: "0", value: "0" },
                { label: "8px", value: "8px" },
                { label: "12px", value: "12px" },
                { label: "16px", value: "16px" },
                { label: "20px", value: "20px" },
                { label: "24px", value: "24px" },
                { label: "28px", value: "28px" },
            ],
        },
        align: {
            type: "select",
            label: "Alineación",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ],
        },
        border: {
            type: "select",
            label: "Borde",
            options: [
                { label: "Ninguno", value: "none" },
                { label: "XS", value: "1px solid" },
                { label: "S", value: "2px solid" },
                { label: "M", value: "3px solid" },
                { label: "L", value: "4px solid" },
                { label: "XL", value: "5px solid" },
                { label: "XXL", value: "6px solid" },
                { label: "XXXL", value: "8px solid" },
            ],
        },
        borderColor: {
            type: "select",
            label: "Color de borde",
            options: textColors,
        },
        borderRadius: {
            type: "select",
            label: "Borde redondeado",
            options: [
                { label: "0", value: "0" },
                { label: "4px", value: "4px" },
                { label: "8px", value: "8px" },
                { label: "12px", value: "12px" },
                { label: "14px", value: "14px" },
                { label: "16px", value: "16px" },
                { label: "18px", value: "18px" },
                { label: "20px", value: "20px" },
                { label: "22px", value: "22px" },
                { label: "24px", value: "24px" },
                { label: "26px", value: "26px" },
                { label: "28px", value: "28px" },
                { label: "30px", value: "30px" },
                { label: "32px", value: "32px" },
            ],
        },
    },
    defaultProps: {
        backgroundColor: "transparent",
        border: "none",
        borderColor: "transparent",
        borderRadius: "0",
        padding: "8px",
        align: "center",
        backgroundImage: null,
    },
    render: ({ padding, backgroundColor, border, borderColor, borderRadius, backgroundImage, align }) => {
        const borderWidth = border === 'none' ? 0 : parseInt(border);
        const totalBorderWidth = borderWidth * 2;
        const paddingValue = padding === "0" ? 0 : parseInt(padding) * 2;

        const calculateDimension = (baseSize: number) => {
            if (border === 'none' && padding === "0") return `${baseSize}px`;
            if (border === 'none') return `calc(${baseSize}px - ${paddingValue}px)`;
            if (padding === "0") return `calc(${baseSize}px - ${totalBorderWidth}px)`;
            return `calc(${baseSize}px - ${paddingValue}px - ${totalBorderWidth}px)`;
        };

        return (
            <div
                style={
                    backgroundImage ? {
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: calculateDimension(790),
                        width: calculateDimension(1123),
                        padding: padding,
                        backgroundColor: backgroundColor,
                        margin: "0 auto",
                    } : {
                        height: calculateDimension(790),
                        width: calculateDimension(1123),
                        padding: padding,
                        backgroundColor: backgroundColor,
                        margin: "0 auto",
                    }
                }
            >
                <div style={{
                    margin: "0 auto",
                    height: "100%",
                    width: "100%",
                    border,
                    borderColor,
                    borderRadius
                }}>
                    <DropZone
                        zone={`diploma-content`}
                        disallow={["Hero", "Logos", "Stats"]}
                    />
                </div>
            </div>
        );
    },
};
