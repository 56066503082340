import { Flex, Text } from "@chakra-ui/react";
import { BiBookReader } from "react-icons/bi";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../shared/context/auth.context";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { MatriculasTable } from "./Matriculas/Table/MatriculasTable";
import { InscripcionesTable } from "./Inscripciones/Table/InscripcionesTable";
import { MdOutlineAppRegistration } from "react-icons/md";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const TabsTableMatriculas = () => {
    const { user } = useAuthContex();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            "/matriculas/matriculas",
            "/matriculas/inscripciones"
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("matriculas");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Matrículas",
            path: "matriculas",
            icon: BiBookReader,
            content: <MatriculasTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Inscripciones",
            path: "inscripciones",
            icon: MdOutlineAppRegistration,
            content: <InscripcionesTable />
        },
    ]

    return (
        <>
            <Helmet>
                <title>Matrículas </title>
            </Helmet>

            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        {
                            location?.pathname?.includes("inscripciones") ? "Inscripciones"
                                : "Matrículas"
                        }
                    </Text>
                }
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Topbar>
        </>
    );
};
