import { Flex, Text, useToast, useDisclosure, Image } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { BiBookContent, BiBookOpen, BiGroup, BiInfoSquare, BiListOl, BiListPlus, BiPlusCircle } from "react-icons/bi"
import { IconBox, defaultIcon } from "../../../../shared/components/Icons/IconBox"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { updateCurso } from "../../../../shared/middlewares/cursos.middleware"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { TabPerfilCurso } from "./TabItem/TabPerfil"
import { TabTableCurso } from "./TabItem/TabTable"
import { TabContenidoCurso } from "./TabItem/TabContenido"
import { Skeleton } from "primereact/skeleton"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { useClient } from "../../../../shared/context/client.context"
import { TabGuiaDidactica } from "./TabItem/TabGuiaDidactica"
import { HiOutlineClipboardList, HiOutlineEye } from "react-icons/hi"
import { AiOutlineNotification } from "react-icons/ai"
import { TabAnuncios } from "./TabItem/TabAnuncios"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy"
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae"
import { TabTemario } from "./TabItem/TabTemario"
import { Helmet } from "react-helmet"
import { LuSettings } from "react-icons/lu"
import { getKey } from "../../../../shared/middlewares/config.middleware"
import { loginViaId } from "../../../../shared/middlewares/token.middleware"
import { TabsRouterInt } from "../../../../interfaces/ComponentsInt";
import { TabsRouter } from "../../../../shared/components/TabsRouter/TabsRouter";

export const TabsInformationCursos = () => {
    const moduloFUNDAE = useModuloFundae();
    const client = useClient();
    const { gestion } = useUserPolicy();
    const { user } = useAuthContex();
    const { id } = useParams();
    const toast = useToast();
    const { isOpen: isOpenAnuncio, onOpen: onOpenAnuncio, onClose: onCloseAnuncio } = useDisclosure();
    const { data: curso, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.CURSOS,
        client: client
    });
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [editIndice, setEditIndice] = useState<boolean>(false);

    useEffect(() => {
        const subPaths = [
            `/cursos/${id}/configuracion`,
            `/cursos/${id}/contenido`,
            `/cursos/${id}/grupos`,
            `/cursos/${id}/anuncios`,
            `/cursos/${id}/fundae`,
            `/cursos/${id}/temario`
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate(`configuracion`);
        }
    }, [location.pathname]);

    const updateValue = async (value: any) => {
        if (!id) return;

        return updateCurso({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const handleCursoPreview = async () => {
        const { data } = await getKey("userURL", client);
        setIsLoggingIn(true);

        loginViaId({
            userId: user?.id as string,
            client: client
        })
            .then((response: any) => {
                const token = response?.data?.token?.token;

                const newWindow = window.open(`${data.data}/login?loginById=true&curso=${curso?.slug}&token=${token}`, "_blank");
                if (newWindow) {
                    newWindow.opener = null;
                }
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                );

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message));
            })
            .finally(() => setIsLoggingIn(false));
    }

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Configuración",
            path: "configuracion",
            icon: LuSettings,
            content:
                <TabPerfilCurso
                    curso={curso}
                    updateValue={updateValue}
                    setRefresh={Refresh}
                    loading={loading}
                />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion),
            label: "Temario",
            path: "contenido",
            icon: BiBookContent,
            content:
                <TabContenidoCurso
                    showIndice={editIndice}
                    curso={curso}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Grupos",
            path: "grupos",
            icon: BiGroup,
            content:
                <TabTableCurso
                    cursoId={curso?.id}
                />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion),
            label: "Anuncios",
            path: "anuncios",
            icon: AiOutlineNotification,
            content: <TabAnuncios
                cursoId={curso?.id}
                isOpenAnuncio={isOpenAnuncio}
                onCloseAnuncio={onCloseAnuncio}
            />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion && moduloFUNDAE),
            label: "FUNDAE",
            path: "fundae",
            icon: BiInfoSquare,
            content:
                <TabGuiaDidactica
                    loadingCurso={loading}
                    curso={curso}
                    Refresh={Refresh}
                    updateValue={updateValue}
                />
        }


        // TODO: Proxima implementación
        // {
        //     isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion),
        //     label: "Feedback",
        //     icon: VscFeedback,
        //     content: <TabFeedback
        //         curso={curso}
        //         Refresh={Refresh}
        //         onClose={onCloseFeedback}
        //         isOpen={isOpenFeedback}
        //         updateValue={updateValue}
        //     />
        // },
        // {
        //     isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && !gestion),
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content: <TabAudits
        //         tipo={AuditsTipoEnum.CURSO}
        //     />
        // }
    ]

    return (
        <>
            <Helmet>
                <title>Cursos </title>
            </Helmet>

            <Topbar
                title={
                    loading ?
                        <Flex
                            gap="15px"
                            alignItems="center"
                            ml="-20px"
                            w="100%"
                        >
                            <Skeleton
                                shape="circle"
                                size="45px"
                            />

                            <Skeleton
                                height="25px"
                                width="300px"
                            />
                        </Flex>
                        :
                        <Flex
                            gap="15px"
                            alignItems="center"
                            ml="-20px"
                        >
                            {curso?.icono?.url ?
                                <Image
                                    src={curso?.icono?.url}
                                    padding="0"
                                    boxSize="45px"
                                />
                                :
                                <IconBox
                                    icon={defaultIcon}
                                    size="45px"
                                    border="none"
                                    padding="0"
                                    bg="inherit"
                                />
                            }

                            <Text
                                color="font"
                                fontSize="21px"
                                fontWeight="700"
                                lineHeight="120%"
                            >
                                {curso?.nombre}
                            </Text>
                        </Flex>
                }
                buttonBack="/cursos"
            >
                <Flex
                    px="20px"
                    pt="20px"
                >
                    <TabsRouter
                        maxOptions={6}
                        tabs={tabs}
                        buttons={[
                            {
                                isAllowed: true,
                                paths: ["/contenido"],
                                button: {
                                    label: !editIndice ? "Editar índice" : "Mostrar contenido",
                                    isDisabled: false,
                                    onClick: () => setEditIndice(!editIndice),
                                    rightIcon: {
                                        as: !editIndice ? BiListOl : BiListPlus,
                                        boxSize: "22px"
                                    }
                                }
                            },
                            {
                                isAllowed: true,
                                paths: ["/contenido"],
                                button: {
                                    label: "Ver curso en el campus",
                                    isDisabled: false,
                                    isLoading: isLoggingIn,
                                    onClick: handleCursoPreview,
                                    rightIcon: {
                                        as: HiOutlineEye,
                                        boxSize: "22px"
                                    }
                                }
                            },
                            {
                                isAllowed: true,
                                paths: ["/anuncios"],
                                button: {
                                    label: "Nuevo anuncio",
                                    isDisabled: false,
                                    onClick: onOpenAnuncio,
                                    rightIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "22px"
                                    }
                                }
                            }
                            // {
                            //     isAllowed: true,
                            //     path: "/feedback",
                            //     button: {
                            //         label: "Nuevo feedback",
                            //         isDisabled: false,
                            //         onClick: onOpenFeedback,
                            //         rightIcon: {
                            //             as: BiPlusCircle,
                            //             boxSize: "22px"
                            //         }
                            //     }
                            // }
                        ]}
                    />
                </Flex>
            </Topbar>
        </>
    )
}