import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';
import { HiOutlineSearch } from 'react-icons/hi';
interface Props {
  name?: string;
  value: string;
  onChange: (e: any) => void;
  placeholder?: string;
  type?: "text" | "number" | "date";
  min?: number;
  max?: number;
  step?: number;
  isSearch?: boolean;
}

export const FilterInput = ({
  name,
  value,
  onChange,
  placeholder,
  type = 'text',
  min = undefined,
  max = undefined,
  step = undefined,
  isSearch = false,
}: Props) => {
  return (

    <InputGroup>
      {isSearch &&
        <InputLeftElement
          pt="15px"
          pl="16px"
          pr="6px"
        >

          <Icon
            as={HiOutlineSearch}
            boxSize="24px"
            w="20px"
            h="20px"
            color="#616675"
            mb="15px"
          />
        </InputLeftElement>
      }


      <Input
        name={name}
        min={min}
        max={max}
        type={type}
        step={step}
        value={value}
        onChange={(e: any) => onChange(e)}
        placeholder={placeholder}
        bg="#FFF"
        border="1px solid"
        borderColor="#DFE2E6"

      />

    </InputGroup>
  );
};