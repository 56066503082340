import { ReactNode } from "react";

export const PuckButton = ({
    children,
    href,
    variant = "primary",
    type,
    disabled,
    tabIndex,
    newTab,
    size = "medium",
    colorSchema,
    fontSize = "16px",
}: {
    children: ReactNode;
    href?: string;
    variant?: "primary" | "secondary";
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    tabIndex?: number;
    newTab?: boolean;
    fullWidth?: boolean;
    size?: "medium" | "large";
    colorSchema?: string;
    fontSize?: string;
}) => {
    const ElementType = href ? "a" : type ? "button" : "span";

    const el = (
        <ElementType
            style={{
                backgroundColor: variant === "primary" ? colorSchema : "transparent",
                color: variant === "secondary" ? colorSchema : "white",
                border: variant === "secondary" ? `1px solid ${colorSchema}` : "none",
                borderColor: variant === "secondary" ? colorSchema : "transparent",
                borderRadius: "14px",
                padding: size === "large" ? "16px 24px" : "12px 16px",
                fontSize,
                textDecoration: "none",
                display: "inline-block",
                lineHeight: "normal",
                verticalAlign: "middle"
            }}
            type={type}
            disabled={disabled}
            tabIndex={tabIndex}
            target={newTab ? "_blank" : undefined}
            rel={newTab ? "noreferrer" : undefined}
            href={href}
        >
            {children}
        </ElementType>
    );

    return el;
};
