import { Flex, Icon, Text } from "@chakra-ui/react"
import { ColumnProps } from "../../../shared/components/Table/Table"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { EntregableEstadoEnum } from "../../../shared/utils/Types/EntregableEstadoEnum";
import { FiEye } from "react-icons/fi";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { ColumnDate } from "../../../shared/components/CustomColumns/ColumnDate";

interface Props {
    handleClick: (data: any) => void;
}

export const CorreccionesColumns = ({ handleClick }: Props): ColumnProps[] => {
    return [
        {
            header: 'alumno',
            field: 'alumno',
            key: 'alumno',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    text={rowData?.matricula?.grupo?.nombre}
                    tooltip={{
                        label: (
                            <Flex
                                direction="column"
                                gap="10px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Comentario Alumno
                                </Text>

                                <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                >
                                    {rowData?.texto}
                                </Text>
                            </Flex>
                        )
                    }}
                />
            ),
        },
        {
            header: 'Fecha entrega',
            field: 'fechaEntrega',
            key: 'fechaEntrega',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.fechaEntrega}
                />
            )
        },
        {
            header: 'lección',
            field: 'leccion',
            key: 'leccion',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.leccion?.nombre}
                    text={rowData?.curso?.nombre}
                />
            ),
        },
        {
            header: 'tutor',
            field: 'tutorId',
            key: 'tutorId',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.matricula?.tutor?.nombre + " " + rowData?.matricula?.tutor?.apellidos}
                    text={rowData?.matricula?.tutor?.tutorFreelance ? "Colaborador" : "Contratado"}
                    tooltip={{
                        label: (
                            rowData?.correccion ?
                                <Flex
                                    direction="column"
                                    gap="10px"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        Comentario Tutor
                                    </Text>

                                    <MarkdownPreview
                                        source={rowData?.correccion?.texto || "Sin comentario"}
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            color: "purewhite",
                                            width: '100%',
                                            maxWidth: '100%',
                                            background: "transparent",
                                        }}
                                    />
                                </Flex>
                                :
                                <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Sin corrección
                                </Text>
                        )
                    }}
                />
            ),
        },
        {
            header: 'estado',
            field: 'estado',
            key: 'estado',
            sortable: true,
            body: (rowData) => (
                <ColumnBadge
                    text={
                        rowData?.estado === EntregableEstadoEnum.ERROR ? 'No superado'
                            : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'Superado'
                                : "pendiente"
                    }
                    ui={{
                        colorFont: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_main' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_main' : 'secondary',
                        backgroundColor: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg' : '#EEEDFA',
                        rounded: '14px',
                        fontSize: '10px',
                        fontWeight: 500,
                        styles: {
                            borderColor: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_variant' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant' : '#EEEDFA',
                            letterSpacing: '0.26px',
                            textTransform: 'uppercase',
                            borderWidth: '1px',
                        }
                    }}
                />
            ),
        },
        {
            header: 'puntuacion',
            field: 'puntuacion',
            key: 'puntuacion',
            style: { width: '100px' },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.correccion?.puntuacion || "--"}
                />
            ),
        },
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Icon
                    as={FiEye}
                    boxSize="22px"
                    color="secondary"
                    onClick={() => handleClick(rowData)}
                />
            )
        },
    ]
}