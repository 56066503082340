export interface HorarioLaboral {
    inicio: string;
    fin: string
}

export enum DiaTypeEnum {
    LUNES = '1',
    MARTES = '2',
    MIERCOLES = '3',
    JUEVES = '4',
    VIERNES = '5',
    SABADO = '6',
    DOMINGO = '7',
}

export interface JornadaLaboral {
    [DiaTypeEnum.LUNES]: HorarioLaboral[];
    [DiaTypeEnum.MARTES]: HorarioLaboral[];
    [DiaTypeEnum.MIERCOLES]: HorarioLaboral[];
    [DiaTypeEnum.JUEVES]: HorarioLaboral[];
    [DiaTypeEnum.VIERNES]: HorarioLaboral[];
    [DiaTypeEnum.SABADO]: HorarioLaboral[];
    [DiaTypeEnum.DOMINGO]: HorarioLaboral[];
}