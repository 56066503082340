import { Flex } from "@chakra-ui/react"
import { Area, AreaChart, Bar, BarChart, CartesianGrid, LabelList, Legend, Line, LineChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

interface Props {
    data: any;
}

export const SimpleLineChart = ({ data }: Props) => {
  
    return (
        <Flex
            w="100%"
            h={500}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 20,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="fecha" />
                    <YAxis type="number" domain={[0, 80]}/>
                    <Tooltip />

                    <Bar 
                        type="monotone" 
                        dataKey="matriculas" 
                        fill="#68D391"
                        activeBar={<Rectangle stroke="#F8F8F8" />} 
                    />
                </BarChart>
            </ResponsiveContainer>
        </Flex>
    )
}