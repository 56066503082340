import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt";
import { truncate } from "lodash";
import { useNavigate } from "react-router-dom";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar";

export const AlumnosInspectorColumns = (): ColumnProps[] => {
    const navigate = useNavigate();

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            style: { width: "250px" },
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={
                        (rowData?.matriculas && rowData?.matriculas.length === 1) ?
                            { to: `/grupos-inspector/${rowData.matriculas[0]?.grupoId}` }
                            :
                            undefined
                    }
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            style: { width: "300px" },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={
                        (rowData?.matriculas && rowData?.matriculas.length === 1) ?
                            { to: `/grupos-inspector/${rowData.matriculas[0]?.grupoId}` }
                            :
                            undefined
                    }
                />
            ),
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            style: { width: "150px" },
            body: (rowData: any) => {
                return (
                    <ColumnSimpleText
                        text={rowData?.empresa?.nombre}
                        link={
                            (rowData?.matriculas && rowData?.matriculas.length === 1) ?
                                { to: `/grupos-inspector/${rowData.matriculas[0]?.grupoId}` }
                                :
                                undefined
                        }
                    />
                )
            },
        },
        {
            header: 'grupos del alumno',
            field: 'grupos',
            key: 'grupos',
            style: { width: "calc(100% - 700px)" },
            body: (rowData: any) => (
                <Flex
                    align="center"
                    fontSize="13px"
                    fontWeight="500"
                    letterSpacing="0.26p"
                    textTransform="uppercase"
                    rounded="5px"
                    p="5px 10px"
                    color="font"
                    overflowX="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "light_grey",
                        },
                    }}
                >
                    {rowData?.matriculas?.length > 0 ? (
                        <Flex gap="5px">
                            {rowData?.matriculas?.map((matricula: MatriculasInt, index: number) => (
                                <Tooltip
                                    label={matricula?.grupo?.estado === "0" ? "Activo" : matricula?.grupo?.estado === "1" ? "Próximo" : "Inactivo"}
                                    hasArrow
                                    key={index}>
                                    <Text
                                        color={
                                            matricula?.grupo?.estado === "0" ? "success_main" :
                                                matricula?.grupo?.estado === "1" ? "secondary" :
                                                    matricula?.grupo?.estado === "2" ? "dark_grey" :
                                                        "black"
                                        }
                                        bg={
                                            matricula?.grupo?.estado === "0" ? "success_bg" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "white" :
                                                        "black"
                                        }
                                        borderColor={
                                            matricula?.grupo?.estado === "0" ? "success_variant" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "light_grey" :
                                                        "transparent"
                                        }
                                        borderWidth="1px"
                                        rounded="14px"
                                        p="5px 10px"
                                        whiteSpace="nowrap"
                                        onClick={() => navigate(`/grupos-inspector/${matricula?.grupoId}`)}
                                    >
                                        {truncate(matricula?.grupo?.nombre, { length: 10 })}
                                    </Text>
                                </Tooltip>
                            ))}
                        </Flex>
                    ) : (
                        <Text>--</Text>
                    )}
                </Flex>
            )
        }
    ]
}