import { Flex, Icon, PlacementWithLogical, StyleProps, Text, Tooltip } from "@chakra-ui/react"

interface Props {
    tooltip: {
        content: JSX.Element | string;
        hasArrow?: boolean;
        placement?: PlacementWithLogical | undefined;
        styles?: StyleProps;
        isDisabled?: boolean;
    }
    text?: string;
    icon: any;
}
export const InformationComponent = ({
    tooltip,
    text,
    icon
}: Props) => {
    return (
        <Tooltip
            label={tooltip?.content}
            hasArrow={tooltip?.hasArrow}
            placement={tooltip?.placement}
            isDisabled={tooltip?.isDisabled}
            {...tooltip?.styles}
        >
            <Flex
                alignItems="center"
                gap="5px"
            >
                {text &&
                    <Text
                        _hover={{ cursor: "pointer", textDecoration: "underline" }}
                        color={"secondary_font"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                    >
                        {text}
                    </Text>
                }

                <Icon as={icon} boxSize={"18px"} />
            </Flex>
        </Tooltip>
    )
}