import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { CursoTable } from "./views/Table/CursoTable";
import { TabsInformationCursos } from "./views/Information/TabsInformation";

export const Cursos = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Flex>
                <Routes>
                    <Route
                        index
                        element={
                            <CursoTable />
                        }
                    />

                    <Route 
                        path='/:id/*' 
                        element={
                            <TabsInformationCursos />
                        } 
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};