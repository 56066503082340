import { Box, Button, Flex, Text } from "@chakra-ui/react"

interface Props {
    border?: boolean;
    label: string;
    text?: string;
    button?: {
        label: string;
        onClick: (action: any) => void;
    };
    type?: "text" | "button"
    padding?: string;
}

export const InformationBoxItem = ({
    border = true,
    label,
    text,
    button,
    type = "text",
    padding = "15px 20px",
}: Props) => {
    return (
        <Flex
            borderBottom={border ? "1px solid #EAEAEA" : "none"}
            p={padding}
            alignItems="center"
            h="52PX"
        >
            <Text
                flex="1"
                color="font"
                fontSize="12px"
                fontWeight="500"
                letterSpacing="0.24px"
                textTransform="capitalize"
            >
                {label}
            </Text>

            {type === "text" ?
                <Text
                    textTransform="capitalize"
                    flex="1"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {text}
                </Text>
                :
                <Box
                    flex="1"
                >
                    <Button
                        onClick={button?.onClick}
                        bg="secondary"
                        color="#FFF"
                        p="5px 15px"
                        h="fit-content"
                        w="fit-content"
                        borderRadius="5px"
                        fontSize="13px"
                        fontWeight="500"
                        _hover={{ bg: "light_grey", color: "black" }}
                    >
                        {button?.label}
                    </Button>
                </Box>
            }
        </Flex>
    )
}