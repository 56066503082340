import { Flex } from "@chakra-ui/react";
import { useClient } from "../../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../../shared/components/Table/Table";
import { InspectoresColumns } from "./Columns/InspectoresColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useState } from "react";

export const InspectoresTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.INSPECTORES });
    const { data: inspectores, loading } = useData({
        client: client,
        endpoint: EndpointTypes.INSPECTOR,
        query: query
    })
    const [hasFilter, setHasFilters] = useState<boolean>();

    return (
        <Flex direction="column" gap="20px">
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={false}
                filterElements={[
                    {
                        name: FiltrosNames.USERNAME,
                        type: FiltrosTypes.INPUT,
                    },
                    {
                        name: FiltrosNames.CURSO,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={inspectores?.data || []}
                columns={InspectoresColumns()}
                isLoading={loading}
                total={inspectores?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.usuariosFiltros : EmptyMessageType.usuarios}
            />
        </Flex>
    )
};