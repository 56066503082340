import { Box, Flex, Icon, Modal, ModalContent, ModalOverlay, Spinner, useDisclosure } from "@chakra-ui/react"
import { useState } from "react";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill, BsArrowsFullscreen, BsFillDashCircleFill, BsFillPlusCircleFill } from "react-icons/bs";
import { Document, Page } from 'react-pdf';
import { LeccionInt } from "../../../interfaces/CursosInt";

interface Props {
    url: string
    isResponsive?: boolean
}

export const PDFViewer = ({ url }: Props) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(0.7);
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <Flex
            w={"100%"}
            h={"100%"}
            pos={"relative"}
        >
            <Flex
                overflow="auto"
                w={"100%"}
                h={"100%"}
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    'scrollbarWidth': 'none',
                    '-ms-overflow-style': 'none',
                    scrollBehavior: 'smooth',
                }}
            >
                <Flex
                    w={"calc(100vw - 615px)"}
                    h={"450px"}
                >
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <Spinner
                                color="font"
                                size="xl"
                            />
                        }
                    >
                        <Page
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            loading={<Box h="800px" w="calc(100vw - 750px)" />}
                            scale={scale}
                        />
                    </Document>
                </Flex>

                <Flex
                    pos={"absolute"}
                    left="0"
                    bottom="0"
                    bg="#444444"
                    w="100%"
                    justifyContent="space-between"
                    py="5px"
                    px="10px"
                    rounded="4px"
                >
                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            as={BsFillDashCircleFill}
                            boxSize="22px"
                            color="purewhite"
                            cursor={scale >= 0.7 ? "pointer" : "not-allowed"}
                            _active={scale >= 0.7 ? { transform: "scale(0.9)" } : {}}
                            opacity={scale >= 0.7 ? 1 : 0.7}
                            onClick={() => {
                                if (scale >= 0.7)
                                    setScale(scale - 0.05)
                            }}
                        />

                        <Icon
                            as={BsFillPlusCircleFill}
                            boxSize="22px"
                            color="purewhite"
                            cursor={scale <= 1.62 ? "pointer" : "not-allowed"}
                            _active={scale <= 1.62 ? { transform: "scale(1.1)" } : {}}
                            opacity={scale <= 1.62 ? 1 : 0.7}
                            onClick={() => {
                                if (scale <= 1.62)
                                    setScale(scale + 0.05)
                            }}
                        />
                    </Flex>

                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onOpenFullScreen();
                            }}
                        />
                        <Icon
                            as={BsArrowLeftSquareFill}
                            boxSize="22px"
                            color="purewhite"
                            cursor={pageNumber === 1 ? "not-allowed" : "pointer"}
                            _active={pageNumber === 1 ? {} : { transform: "scale(0.9)" }}
                            opacity={pageNumber === 1 ? 0.7 : 1}
                            onClick={() => {
                                if (pageNumber > 1) setPageNumber(pageNumber - 1)
                            }}
                        />

                        <Icon
                            as={BsArrowRightSquareFill}
                            boxSize="22px"
                            color="purewhite"
                            cursor={pageNumber === numPages ? "not-allowed" : "pointer"}
                            _active={pageNumber === numPages ? {} : { transform: "scale(1.1)" }}
                            opacity={pageNumber === numPages ? 0.7 : 1}
                            onClick={() => {
                                if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                            }}
                        />
                    </Flex>
                </Flex>

                <ModalFullScreen
                    isOpen={isOpenFullScreen}
                    onClose={onCloseFullScreen}
                    url={url}
                />
            </Flex>
        </Flex>
    )
}


interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    url: string;
}

const ModalFullScreen = ({ isOpen, onClose, url }: PropsModal) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(0.82);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w={"100%"}
                    h={"100%"}
                    pos={"relative"}
                    justifyContent="center"
                >
                    <Flex
                        h={"100vh"}
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            'scrollbarWidth': 'none',
                            '-ms-overflow-style': 'none',
                            scrollBehavior: 'smooth',
                        }}
                        overflow="auto"
                    >
                        <Document
                            file={url}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={
                                <Spinner
                                    color="font"
                                    size="xl"
                                />
                            }
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                loading={<Box h="800px" w="calc(100vw - 750px)" />}
                                scale={scale}
                            />
                        </Document>

                        <Flex
                            pos={"absolute"}
                            left="0"
                            bottom="0"
                            bg="#444444"
                            w="100%"
                            justifyContent="space-between"
                            py="5px"
                            px="10px"
                            rounded="4px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Icon
                                    as={BsFillDashCircleFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={scale >= 0.7 ? "pointer" : "not-allowed"}
                                    _active={scale >= 0.7 ? { transform: "scale(0.9)" } : {}}
                                    opacity={scale >= 0.7 ? 1 : 0.7}
                                    onClick={() => {
                                        if (scale >= 0.7)
                                            setScale(scale - 0.05)
                                    }}
                                />

                                <Icon
                                    as={BsFillPlusCircleFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={scale <= 1.62 ? "pointer" : "not-allowed"}
                                    _active={scale <= 1.62 ? { transform: "scale(1.1)" } : {}}
                                    opacity={scale <= 1.62 ? 1 : 0.7}
                                    onClick={() => {
                                        if (scale <= 1.62)
                                            setScale(scale + 0.05)
                                    }}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Icon
                                    as={BsArrowsFullscreen}
                                    boxSize="20px"
                                    mr="10px"
                                    color="purewhite"
                                    cursor="pointer"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();

                                        onClose();
                                    }}
                                />

                                <Icon
                                    as={BsArrowLeftSquareFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={pageNumber === 1 ? "not-allowed" : "pointer"}
                                    _active={pageNumber === 1 ? {} : { transform: "scale(0.9)" }}
                                    opacity={pageNumber === 1 ? 0.7 : 1}
                                    onClick={() => {
                                        if (pageNumber > 1) setPageNumber(pageNumber - 1)
                                    }}
                                />

                                <Icon
                                    as={BsArrowRightSquareFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={pageNumber === numPages ? "not-allowed" : "pointer"}
                                    _active={pageNumber === numPages ? {} : { transform: "scale(1.1)" }}
                                    opacity={pageNumber === numPages ? 0.7 : 1}
                                    onClick={() => {
                                        if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}