import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { truncate } from "lodash";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { handleLinkOpen } from "../../../../shared/utils/functions/linkOpen";

export const AlumnosColumns = (): ColumnProps[] => {

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,
                    }}
                />
            ),
        },
        {
            header: 'Teléfono',
            field: 'telefono',
            key: 'telefono',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.telefono}
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,
                    }}
                />
            ),
        },
        {
            header: 'grupos activos',
            field: 'grupoActivos',
            key: 'grupoActivos',
            body: (rowData: any) => (
                <Flex
                    align="center"
                    fontSize="13px"
                    fontWeight="500"
                    letterSpacing="0.26p"
                    textTransform="uppercase"
                    rounded="5px"
                    p="5px 10px"
                    color="font"
                    overflowX="auto"
                    maxW="300px"
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "light_grey",
                        },
                    }}
                >
                    {rowData?.matriculas?.length > 0 ?
                        <Flex gap="5px">
                            {rowData?.matriculas?.map((matricula: MatriculasInt, index: number) => (
                                <Tooltip
                                    label={matricula?.grupo?.estado === "0" ? "Activo" : matricula?.grupo?.estado === "1" ? "Próximo" : "Inactivo"}
                                    hasArrow
                                    key={index}>
                                    <Text
                                        color={
                                            matricula?.grupo?.estado === "0" ? "success_main" :
                                                matricula?.grupo?.estado === "1" ? "secondary" :
                                                    matricula?.grupo?.estado === "2" ? "dark_grey" :
                                                        "black"
                                        }
                                        bg={
                                            matricula?.grupo?.estado === "0" ? "success_bg" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "white" :
                                                        "black"
                                        }
                                        borderColor={
                                            matricula?.grupo?.estado === "0" ? "success_variant" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "light_grey" :
                                                        "transparent"
                                        }
                                        borderWidth="1px"
                                        rounded="14px"
                                        p="5px 8px"
                                        whiteSpace="nowrap"
                                    >
                                        {truncate(matricula?.grupo?.nombre, { length: 8 })}
                                    </Text>
                                </Tooltip>
                            ))}
                        </Flex>
                        :
                        <Text>--</Text>
                    }
                </Flex>
            )
        },
        {
            header: '',
            field: '',
            key: '',
            body: () => (
                <Flex justifyContent="center">
                    <Icon as={BiDotsVerticalRounded} boxSize="20px" />
                </Flex>
            )
        },
    ]
}