import { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { BiFile, BiTrash } from 'react-icons/bi';
import { motion, AnimatePresence } from 'framer-motion';
import { toastNotify } from '../../utils/functions/toastNotify';
import { StatusEnumTypes } from '../../utils/Types/StatusEnumTypes';

interface Props {
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    hasContent?: boolean;
}

const DropzoneComponent = ({ file, setFile, hasContent = false }: Props) => {
    const toast = useToast();
    const [isDragActive, setIsDragActive] = useState(false);
    const [isFileDragging, setIsFileDragging] = useState(false);

    const validateFileSize = (file: File) => {
        if (file?.type?.includes("image") || file?.type?.includes("video")) {
            if (file.size > 99999999) {
                toastNotify(toast, StatusEnumTypes.WARNING, "El archivo supera el tamaño máximo permitido de 100MB");
                return false;
            }
        } else {
            if (file.size > 20000000) {
                toastNotify(toast, StatusEnumTypes.WARNING, "El archivo supera el tamaño máximo permitido de 20MB");
                return false;
            }
        }
        return true;
    };
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0 && validateFileSize(acceptedFiles[0])) {
            setFile(acceptedFiles[0]);
        }
        setIsDragActive(false);
        setIsFileDragging(false);
    }, [setFile, toast]);

    useEffect(() => {
        let dragCounter = 0;

        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault();
            dragCounter++;
            if (!file && e.dataTransfer && e.dataTransfer.items.length > 0) {
                setIsFileDragging(true);
                setIsDragActive(true);
            }
        };

        const handleDragOver = (e: DragEvent) => {
            e.preventDefault();
        };

        const handleDrop = (e: DragEvent) => {
            e.preventDefault();
            dragCounter = 0;
            setIsFileDragging(false);
            setIsDragActive(false);
        };

        const handleDragLeave = (e: DragEvent) => {
            e.preventDefault();
            dragCounter--;
            if (dragCounter === 0) {
                setIsFileDragging(false);
                setIsDragActive(false);
            }
        };

        window.addEventListener("dragenter", handleDragEnter);
        window.addEventListener("dragover", handleDragOver);
        window.addEventListener("drop", handleDrop);
        window.addEventListener("dragleave", handleDragLeave);

        return () => {
            window.removeEventListener("dragenter", handleDragEnter);
            window.removeEventListener("dragover", handleDragOver);
            window.removeEventListener("drop", handleDrop);
            window.removeEventListener("dragleave", handleDragLeave);
        };
    }, [file, setFile, toast]);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        disabled: !!file,
    });

    return (
        <Box position="relative" {...getRootProps()}>
            <input {...getInputProps()} />
            {isFileDragging && !file && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(0, 0, 0, 0.4)"
                    zIndex={9998}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                />
            )}

            <Flex
                direction="column"
                p="15px"
                bg="#F8F8F9"
                rounded="15px"
            >
                <Flex
                    w="100%"
                    rounded="15px"
                    position="relative"
                    direction="column"
                    gap="10px"
                >
                    <AnimatePresence mode="wait">
                        {file ? (
                            <motion.div
                                key="file-uploaded"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Flex
                                    h="80px"
                                    p="20px"
                                    border="1px dashed"
                                    borderColor="#E6E6EA"
                                    rounded="10px"
                                    justifyContent="space-between"
                                >
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Icon
                                            as={BiFile}
                                            boxSize="24px"
                                            color="secondary"
                                        />

                                        <Flex
                                            direction="column"
                                            gap="4px"
                                        >
                                            <Text
                                                fontSize="14px"
                                                fontWeight="600"
                                                lineHeight="140%"
                                            >
                                                {file?.name}
                                            </Text>

                                            <Text
                                                color="#8C909C"
                                                fontSize="13px"
                                                fontWeight="500"
                                                lineHeight="16px"
                                                letterSpacing={"-0.78px"}
                                            >
                                                {file?.size} KB
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        p="6px"
                                        justifyContent="center"
                                        alignItems="center"
                                        w="fit-content"
                                        h="fit-content"
                                        bg="purewhite"
                                        rounded="8px"
                                        cursor="pointer"
                                        _active={{ transform: "scale(0.9)" }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault()

                                            setFile(null)
                                        }}
                                    >
                                        <Icon
                                            as={BiTrash}
                                            color="#8C909C"
                                            boxSize="24px"
                                        />
                                    </Flex>
                                </Flex>
                            </motion.div>
                        ) : (
                            <motion.div
                                key="no-file"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >

                                <Flex
                                    h="80px"
                                    gap="15px"
                                    alignItems="center"
                                    p="20px"
                                    border="1px dashed"
                                    borderColor="#E6E6EA"
                                    rounded="10px"
                                    cursor="pointer"
                                    backgroundColor={isDragActive ? "secondary" : 'transparent'}
                                    transition="background-color 0.2s ease"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        open();
                                    }}
                                >
                                    <Icon
                                        as={BsFillPlusCircleFill}
                                        boxSize="32px"
                                        color={isDragActive ? "purewhite" : "secondary"}
                                        transition="color 0.5s ease"
                                    />

                                    <Flex
                                        direction="column"
                                    >
                                        <Text
                                            color={isDragActive ? "purewhite" : "#8C909C"}
                                            transition="color 0.5s ease"
                                            fontSize="15px"
                                            fontWeight="700"
                                            lineHeight="140%"
                                            letterSpacing="0.075px"
                                        >
                                            Arrastra y sube tus archivos
                                        </Text>

                                        <Text
                                            color={isDragActive ? "purewhite" : "#8C909C"}
                                            transition="color 0.5s ease"
                                            fontSize="12px"
                                            fontWeight="500"
                                            lineHeight="140%"
                                            letterSpacing="0.06px"
                                        >
                                            O <Text as={"span"} color="main" textDecoration="underline">búscalos</Text> en tus documentos
                                        </Text>
                                    </Flex>
                                </Flex>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <Flex
                        direction="column"
                    >
                        <Text
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="160%"
                            color="#8C909C"
                        >
                            Num. máx de archivos: <Text as="span" fontWeight="700" color="font">1 archivo</Text>
                        </Text>

                        <Text
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="160%"
                            color="#8C909C"
                        >
                            Tamaño máx. de archivo: <Text as="span" fontWeight="700" color="font">100MB</Text> (Imagen o video) | <Text as="span" fontWeight="700" color="font">20MB</Text> (Documento)
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default DropzoneComponent;
