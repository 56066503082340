import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../shared/context/client.context";
import { useState } from "react";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { addUsuario } from "../../../shared/middlewares/users.middleware";
import { addContactoEmpresa } from "../../../shared/middlewares/empresas.middleware";
import { FormPhoneInput, phoneValidationYup } from "../../../shared/components/FormElements/FormPhoneInput";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefresh?: () => void;
    idEmpresa: string | undefined;
}

export const ContactoEmpresaModal = ({ isOpen, onClose, idEmpresa, setRefresh = () => { } }: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const toast = useToast();

    const initialValues = {
        nombre: null,
        apellidos: null,
        email: null,
        telefono: null,
        departamento: null
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        apellidos: Yup.string()
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        telefono: Yup.string()
            .test('validator-custom-phone', function (value) {
                const validation = phoneValidationYup(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            })
            .required('El teléfono es obligatorio.')
            .typeError('El teléfono es obligatorio.'),
        departamento: Yup.string()
            .required('El departamento es obligatorio.')
            .typeError('El departamento es obligatorio.'),

    });

    const handleSubmit = (values: any) => {
        if (!idEmpresa) return

        setIsLoading(true)

        const newUsuario: any = {
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            telefono: values.telefono,
            empresaId: idEmpresa,
            sepe: false,
        }

        addUsuario({
            data: newUsuario,
            client: client
        })
            .then((response: any) => {
                const id = response?.data?.data?.id;

                addContactoEmpresa({
                    data: {
                        userId: id,
                        empresaId: idEmpresa,
                        departamento: values.departamento
                    },
                    client: client
                })
                    .then(() => {
                        setRefresh();
                        onClose();

                        toastNotify(toast, StatusEnumTypes.SUCCESS, `El contacto ${newUsuario.nombre} se ha creado correctamente`)
                    })
                    .catch((error: any) => {
                        const errors = handleErrors(
                            error?.response?.data?.errors,
                            error?.response?.status
                        )

                        errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                        onClose();
                    })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                onClose();
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo contacto empresa
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex direction="column" gap="10px" mb="30px">
                                                <Text
                                                    color="color"
                                                    fontSize="18px"
                                                    fontWeight="700"
                                                    mb="5px"
                                                >
                                                    Datos del supervisor
                                                </Text>

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.nombre ? true : false}
                                                        name="nombre"
                                                        label="Nombre"
                                                        placeholder="Nombre"
                                                    />


                                                    <FormInput
                                                        isRequired={!values?.apellidos ? true : false}
                                                        name="apellidos"
                                                        label="Apellidos"
                                                        placeholder="Apellidos"
                                                    />
                                                </Flex>

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    placeholder="Email"
                                                />

                                                <Flex gap="15px">
                                                    <Box flex="1">
                                                        <FormPhoneInput
                                                            name="telefono"
                                                            label="Teléfono"
                                                            placeholder="Teléfono"
                                                            isRequired={!values?.telefono ? true : false}
                                                        />
                                                    </Box>

                                                    <Box flex="1">
                                                        <FormInput
                                                            name="departamento"
                                                            label="Departamento"
                                                            placeholder="Departamento"
                                                            isRequired={!values?.departamento ? true : false}
                                                        />
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear contacto
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}