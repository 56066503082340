import { Flex, Text } from "@chakra-ui/react";
import { CustomColumnProps } from "../../../../../../shared/components/Table/CustomGroupTable";

export const ConversacionesColumns = (): CustomColumnProps[] => {
    return [
        {
            header: 'Asunto',
            field: 'asunto',
            key: 'asunto',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.asunto}</Text>
                </Flex>
            ),
        },
        {
            header: 'Grupo',
            field: 'grupo',
            key: 'grupo',
            body: (rowData: any) => (
                <Flex
                    direction="column"
                >
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.matricula?.grupo?.nombre}</Text>
                    <Text fontWeight="300" fontSize={13}>{rowData?.matricula?.grupo?.curso?.nombre}</Text>
                </Flex>
            ),
        },
        {
            header: 'Total de mensajes',
            field: 'totalMensajes',
            key: 'totalMensajes',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.mensajes?.length || 0}</Text>
                </Flex>
            ),
        },
        {
            header: '',
            field: 'comunicacion.emisor',
            key: 'comunicacion.emisor',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.comunicacion?.emisor}</Text>
                </Flex>
            ),
        },
        {
            header: '',
            field: 'comunicacion.receptor',
            key: 'comunicacion.receptor',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.comunicacion?.receptor}</Text>
                </Flex>
            ),
        },
    ]
}