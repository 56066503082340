import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { HiOutlineInformationCircle, HiOutlineArrowSmUp } from "react-icons/hi";


interface Props {
    label: string;
    info: any;
    infoTotal?: any;
    bg?: string;
    onClick?: (e: any) => void;
    mb?: any;
    icon?: any;
    tooltip?: string;
    isLoading?: boolean;
    cambioPorcentualGrupos?: number;
    isEuros?: boolean
}


export const CardInfo = ({
    onClick = (e: any) => { },
    label,
    info,
    bg = "purewhite",
    mb,
    icon,
    tooltip,
    cambioPorcentualGrupos,
    infoTotal,
    isEuros
}: Props) => {

    return (

        <Flex
            flex="1"
            h="auto"
            p="24px"
            borderRadius="14px"
            borderWidth="1px"
            borderColor="#DFE2E6"
            bg={bg}
            cursor="pointer"
            direction="column"
            justify="space-between"
            onClick={onClick}
            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
            transition="all 0.4s ease-in-out"
            _hover={{
                transform: 'translateY(-0.1px)',
                boxShadow: 'rgba(64, 85, 128, 0.15) 0px 4px 12px',

            }}
            gap="15px"
        >

            <Flex
                justify="center"
                align="start"
                gap="12px"
            >
                {icon && (
                    <Flex
                        align="center"
                        justify="center"
                        minW="28px"
                        boxSize="28px"
                        borderRadius="4px"
                        bg="#F9FAFB"
                        lineHeight="18.15px"
                    >
                        <Icon
                            boxSize="18px"
                            color="dark_grey"
                            as={icon}
                        />
                    </Flex>
                )}
                <Text
                    w="100%"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="500"
                    mb={mb}
                    textAlign="left"
                >
                    {label}
                </Text>

                {tooltip && (
                    <Tooltip
                        label={tooltip}
                        textAlign="center"
                        maxWidth="250px"
                    >
                        <Flex
                            direction="column"
                            alignItems="center"
                        >
                            <Icon
                                boxSize="20px"
                                color="#616675"
                                as={HiOutlineInformationCircle}

                            />
                        </Flex>
                    </Tooltip>
                )}
            </Flex>

            <Flex
                align="end"
                gap="10px"
                w="100%"
                h="auto"
            >
                <Text
                    fontSize="32px"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="38.73px"
                    color={isEuros ? "success_main" : ""}
                >
                    {typeof info === 'number' ? info.toLocaleString('es-ES') : info}
                </Text>

                {infoTotal &&
                    <Text
                        fontSize="26px"
                        fontStyle="normal"
                        fontWeight="500"
                    >
                        /{typeof infoTotal === 'number' ? infoTotal.toLocaleString('es-ES') : infoTotal} {isEuros && "€"}
                    </Text>
                }

                {cambioPorcentualGrupos !== undefined && (
                    cambioPorcentualGrupos > 0 ? (
                        <Flex
                            color="success_main"
                            bg="success_bg"
                            border="1px"
                            borderColor="succes_variant"
                            borderRadius="8px"
                            px="10px"
                            fontSize="14px"
                            fontWeight="500"
                            gap="5px"
                            alignContent="center"
                        >
                            {cambioPorcentualGrupos.toFixed(1)}%
                            <Icon
                                as={HiOutlineArrowSmUp}
                                w="18px"
                                h="auto"
                                fontWeight="500"
                                transform="rotate(45deg)"
                            />
                        </Flex>
                    ) : (
                        <Flex
                            color="fail_main"
                            bg="fail_bg"
                            border="1px"
                            borderColor="fail_variant"
                            borderRadius="8px"
                            px="10px"
                            fontSize="14px"
                            fontWeight="500"
                            gap="5px"
                            alignContent="center"
                        >
                            {cambioPorcentualGrupos ? `${Math.abs(cambioPorcentualGrupos).toFixed(1)}%` : "0%"}
                            <Icon
                                as={HiOutlineArrowSmUp}
                                w="18px"
                                h="auto"
                                fontWeight="500"
                                transform="rotate(135deg)"
                            />
                        </Flex>
                    )
                )}

            </Flex>


        </Flex>
    );
};
