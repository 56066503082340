import { Flex, Text } from "@chakra-ui/react";
import { LiquidacionesTable } from "./Liquidaciones/Table/LiquidacionesTable";
import { RiBillLine } from "react-icons/ri";
import { MdPendingActions } from "react-icons/md";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { GrCompliance } from "react-icons/gr";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { AsignacionesLiquidadasTable } from "./AsignacionesLiquidadas/AsignacionesLiquidadasTable";
import { AsignacionesPendientesTable } from "./AsignacionesPendientes/AsignacionesPendientesTable";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";

export const TabsFacturacion = () => {

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "pendientes",
            label: "Matriculas Pendientes",
            icon: MdPendingActions,
            content: <AsignacionesPendientesTable />
        },
        {
            isAllowed: true,
            label: "Matriculas Facturadas",
            path: "facturadas",
            icon: GrCompliance,
            content: <AsignacionesLiquidadasTable />
        },
        {
            isAllowed: true,
            path: "liquidaciones",
            label: "Liquidaciones",
            icon: RiBillLine,
            content: <LiquidacionesTable />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Facturación
                </Text>
            }
        >
            <Flex
                direction="column"
                w="100%"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Flex>
        </Topbar>
    )
};