import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';
import axios from "axios";
import { EndpointTypes } from "../utils/Types/EndpointTypes";

const tenant = await tenantInstance();

const ENDPOINT_TENANTS = "/tenants"

export const addTenant = async ({
    data
}: {
    data: {
        nombre: string;
        slug: string;
    }
}) => {
    if (!tenant) return;

    return _axios.post(
        tenant + ENDPOINT_TENANTS,
        data,
        {}
    )
};

export const addTenantAdmin = async ({
    slug,
    data
}: {
    slug: string;
    data: {
        nombre: string;
        email: string;
        password: string;
        roleId: number;
        activo: boolean;
    }
}) => {
    if (!tenant) return;

    return _axios.post(
        `${slug}/admin/users`,
        data,
        {}
    )
};

export const forceInfoTenant = async (token: string, client: ClientInt) => {
    const tenant = await tenantInstance();

    return axios.get(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/${client}${EndpointTypes.INFO_TENANT}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    ).then((response: any) => response?.data?.data);
}


export const iconoTenant = async () => {
    const tenant = await tenantInstance();

    return axios.get(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/tenant/icono`,
        {
            headers: {
                pass: import.meta.env.VITE_ICONO_PASS
            }
        }
    ).then((response: any) => response?.data?.data);
}