import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { TabsPlantillas } from "./views/General/TabsPlantillas"
import { Helmet } from "react-helmet"
import { InformationPlantillas } from "./views/Information/InformationPlantillas"

export const Plantillas = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Plantillas </title>
            </Helmet>

            <Flex direction="column">
                <Routes>
                    <Route
                        path="/"
                        element={<TabsPlantillas />}
                    >
                        <Route path=":categoria" element={<TabsPlantillas />} />
                    </Route>

                    <Route
                        path=":categoria/:id"
                        element={
                            <InformationPlantillas />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}