import { Flex } from "@chakra-ui/react"
import { OpenPlayer } from "../OpenPlayer/OpenPlayer"
import { LeccionInt } from "../../../interfaces/CursosInt"
import { EVENTO_VIDEO_PAUSE, EVENTO_VIDEO_PLAY } from "../../utils/Types/ControllerTypes";
import { useRef, useState } from "react";

interface Props {
    leccion: LeccionInt;
}

export const VideoViewer = ({ leccion }: Props) => {
    const playerRef = useRef<any>();
    const [playedSeconds, setPlayedSeconds] = useState<number>(0);

    function onPlay() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PLAY);

        window.dispatchEvent(onPlayEvent);
    }

    function onPause() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PAUSE);

        window.dispatchEvent(onPlayEvent);
    }

    return (
        <Flex
            direction="column"
            py="20px"
            px="20px"
        >
            <OpenPlayer
                player={playerRef}
                leccion={leccion}
                maxSpeed={2}
                minSpeed={0.25}
                onPlay={onPlay}
                setPlayedSeconds={setPlayedSeconds}
                playedSeconds={playedSeconds}
                src={leccion?.contenido || undefined}
            />
        </Flex>
    )
}