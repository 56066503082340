const informationSelectStyles = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        width: "99%"
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    indicatorsContainer: (styles: any) => ({ display: 'none' }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '7px',
        backgroundColor: 'inherit',
        borderWidth: '1px',
        borderColor: 'border_grey'
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontWeight: 'medium',
        color: '#000',
        backgroundColor: '#FFFF',
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "dark_grey",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
};

const informationBoxSelectStyles = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    indicatorsContainer: (styles: any) => ({ display: 'none' }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '7px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        border: 'none',
        boxShadow: "none",
    }),
    menu: (styles: any) => ({ 
        position: "absolute",
        top: "-4px",
        left: "25%",
        zIndex: "999",
        backgroundColor: 'rgba(255, 255, 255, 1)',
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: "13px",
        fontWeight: "400",
        color: '#FFF',
        borderRadius: "5px",
        backgroundColor: 'light_grey',
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "dark_grey",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
};

export { informationSelectStyles, informationBoxSelectStyles };