import { Flex, Icon, Text } from "@chakra-ui/react";
import { BsEye } from "react-icons/bs";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { CustomColumnProps } from "../../../../../../shared/components/Table/CustomGroupTable";

interface Props {
    onEventView: (data: any) => void;
}

export const MensajesColumns = ({ onEventView }: Props): CustomColumnProps[] => {
    const timeZone = useTimeZone();

    return [
        {
            header: 'Emisor',
            field: 'emisor',
            key: 'emisor',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.emisor}</Text>
                </Flex>
            ),
        },
        {
            header: 'Receptor',
            field: 'receptor',
            key: 'receptor',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.receptor}</Text>
                </Flex>
            ),
        },
        {
            header: 'Estado',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.estado === "no_leido" ? "No leído" : "Leído"}</Text>
                </Flex>
            ),
        },
        {
            header: 'Fecha de envio',
            field: 'createdAt',
            key: 'createdAt',
            body: (rowData: any) => (
                <Flex>
                    <Text fontWeight="500" fontSize={15}>{formatTimezone({ date: rowData?.createdAt, timeZone, customFormat: 'dd/MM/yyyy HH:mm' })}</Text>
                </Flex>
            ),
        },
        {
            header: '',
            field: '',
            key: 'preview',
            body: (rowData: any) => (
                <Flex
                    cursor="pointer"
                >
                    <Icon
                        as={BsEye}
                        boxSize="20px"
                        _active={{ transform: "scale(0.9)" }}
                        transition="all 0.3s ease"
                        cursor="pointer"
                        onClick={(e) => {
                            e.stopPropagation();

                            onEventView(rowData)
                        }}
                    />
                </Flex>
            ),
            style: {
                width: "50px"
            },
        }
    ]
}