import { Box, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { updateLiquidacion } from "../../../../../shared/middlewares/facturacion.middlewares";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { CustomSwitch } from "../../../../../shared/components/CustomElements/CustomSwitch";
import { BiPencil } from "react-icons/bi";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { InformationDateInput } from "@imaginagroup/bit-components.ui.elements.information-date-input"
import { useClient } from "../../../../../shared/context/client.context";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { SkeletonLiquidacionFacturacion } from "../../../Components/utils/FacturacionLiquidacionSkeleton";
import { Table } from "../../../../../shared/components/Table/Table";
import { InformationLiquidacionesColumns } from "../../../Components/Columns/InformationLiquidacionesColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";

export const LiquidacionesInformation = () => {
    const timeZone = useTimeZone();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.LIQUIDACIONES_ID });
    const client = useClient();
    const { id } = useParams()
    const toast = useToast();
    const { data: liquidacion, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.LIQUIDACIONES,
        client: client
    })
    const [pagado, setPagado] = useState<boolean>()
    const [editFactura, setEditFactura] = useState<boolean>()
    const [editFechaPago, setEditFechaPago] = useState<boolean>()

    const updateValue = (value: any) => {
        if (!liquidacion?.id) return;

        return updateLiquidacion({
            id: liquidacion?.id,
            data: value,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente");
                Refresh();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    useEffect(() => {
        setPagado(liquidacion?.liquidada)

    }, [liquidacion])

    const changePagado = () => {
        if (
            !liquidacion?.id ||
            pagado === null ||
            pagado === undefined
        ) return

        updateValue({ liquidada: !pagado })
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Liquidación
                </Text>
            }
            buttonBack="/facturacion/liquidaciones"
        >
            <Flex
                direction="column"
                w="100%"
                p="30px"
            >
                <Flex flex="1">
                    {loading ?
                        <SkeletonLiquidacionFacturacion />
                        :
                        <InformationBox
                            title="Datos de liquidación"
                        >
                            <Box>
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onMouseEnter={() => setEditFactura(true)}
                                    onMouseLeave={() => setEditFactura(false)}
                                    gap="10px"
                                >
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="numeroFactura"
                                        label="Número de Factura"
                                        defaultValue={liquidacion?.numeroFactura}
                                        updateValue={updateValue}
                                    />

                                    <Flex boxSize="20px">
                                        {editFactura && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>

                                <Flex
                                    pr="5%"
                                    alignItems="center"
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                >
                                    <CustomSwitch
                                        label="Pagado"
                                        optionRight="No"
                                        optionLeft="Si"
                                        setValue={setPagado}
                                        value={pagado}
                                        onClick={changePagado}
                                    />
                                </Flex>


                                <Flex
                                    alignItems="center"
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    justifyContent="space-between"
                                    p="10px 20px"
                                    onMouseEnter={() => setEditFechaPago(true)}
                                    onMouseLeave={() => setEditFechaPago(false)}
                                    gap="10px"
                                >
                                    <InformationDateInput
                                        locale="es"
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="fechaPago"
                                        label="Fecha de Pago"
                                        updateValue={updateValue}
                                        ui={{
                                            labelStyles: {
                                                textTransform: "uppercase",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                letterSpacing: "0.24px"
                                            }
                                        }}
                                        defaultValue={
                                            formatTimezone({
                                                date: liquidacion?.fechaPago,
                                                timeZone
                                            })
                                        }
                                    />

                                    <Flex boxSize="20px">
                                        {editFechaPago && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Text
                                        flex="1"
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="600"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                    >
                                        Importe factura sin impuestos
                                    </Text>

                                    <Text
                                        flex="1"
                                        ml="20px"
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        {liquidacion?.meta?.importeTotal.toFixed(2)}€
                                    </Text>

                                    <Flex boxSize="20px">
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Text
                                        flex="1"
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="600"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                    >
                                        % Comisión
                                    </Text>

                                    <Text
                                        flex="1"
                                        ml="20px"
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        {liquidacion?.user?.porcentajeComision} %
                                    </Text>

                                    <Flex boxSize="20px">
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Text
                                        flex="1"
                                        color="font"
                                        fontSize="12px"
                                        fontWeight="600"
                                        letterSpacing="0.24px"
                                        textTransform="uppercase"
                                    >
                                        Tutor
                                    </Text>

                                    <Text
                                        flex="1"
                                        ml="20px"
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        {liquidacion?.user?.nombre} {liquidacion?.user?.apellidos}
                                    </Text>

                                    <Flex boxSize="20px">
                                    </Flex>
                                </Flex>
                            </Box>
                        </InformationBox>
                    }
                </Flex>

                <Flex flex="1">
                    <InformationBox
                        title="Asignaciones"
                    >
                        <Box
                            p="10px"
                        >
                            <Table
                                data={liquidacion?.asignaciones || []}
                                columns={InformationLiquidacionesColumns()}
                                isLoading={loading}
                                showFooter={false}
                                query={query}
                                setQuery={setQuery}
                            />
                        </Box>
                    </InformationBox>
                </Flex>
            </Flex>
        </Topbar>
    )
}