import { Flex, Skeleton } from '@chakra-ui/react'

export default function DashboardSkeleton( {moduloFundae} : {moduloFundae:boolean} ) {

  return (
    <Flex direction="column" boxSize="100%" gap="20px">
        <Flex
          flex="1" gap="20px" h="150px"
        >
          <Skeleton h="150px" flex="1" rounded="14px"/>
          <Skeleton h="150px" flex="1" rounded="14px"/>
          <Skeleton h="150px" flex="1" rounded="14px"/>
          <Skeleton h="150px" flex="1" rounded="14px"/>
          <Skeleton h="150px" flex="1" rounded="14px"/>
          { moduloFundae &&
            <Skeleton h="150px" flex="1" rounded="14px"/>
          }
        </Flex>
        <Flex h="450px" gap="20px"
        >
          <Skeleton w={moduloFundae ? "70%" : "full"} h="450px" rounded="14px"/>
          { moduloFundae &&
            <Skeleton w="30%" h="450px" rounded="14px"/>
          }
        </Flex>
        <Flex h="500px" gap="20px"
        >
          { moduloFundae &&
            <Skeleton w="30%" h="500px" rounded="14px"/>
          }
          <Skeleton w={moduloFundae ? "70%" : "full"} h="500px" rounded="14px"/>
        </Flex>
      </Flex>
  )
}
