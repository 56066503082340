import { Box, Image } from "@chakra-ui/react";

interface Props {
    icon: string | undefined;
    size?: string;
    border?: string;
    rounded?: string;
    padding?: string;
    bg?: string;
    maxH?: string;
}

export const IconBox = ({ 
    icon, 
    size = "75px", 
    border = "1px solid #EAEAEA",
    rounded = "10px",
    padding = "12px",
    bg = "purewhite",
    maxH = "100%"
}: Props) => {

    return (
        <Box
            bg={bg}
            w={size}
            h={maxH ? maxH : size}
            borderRadius={rounded}
            border={border}
            p={padding}
        >
            <Image
                src={`data:image/svg+xml;utf8,${icon || defaultIcon}`}
                align="icon"
            />
        </Box>
    );
}

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`