import { useEffect, useState } from 'react';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { Flex, Spinner, Icon, FormLabel } from '@chakra-ui/react';
import { TimePicker } from '../CustomElements/TimePicker';

export interface InformationInputProps {
    name: string;
    label?: string;
    noHead?: boolean;
    isDisabled?: boolean;
    defaultValue?: any;
    style?: React.CSSProperties;
    updateValue: (e?: any) => any;
    onNullValue?: (e?: any) => any;
}

export const InformationTimePicker = ({
    noHead,
    name,
    label,
    isDisabled = false,
    defaultValue,
    style,
    updateValue,
    onNullValue,
}: InformationInputProps) => {
    const [value, setValue] = useState<any>(defaultValue);

    const [timeoutkey, setTimeoutkey] = useState<any>();
    const [update, setUpdate] = useState<'idle' | 'editing' | 'loading'>('idle');

    useEffect(() => {
        if (timeoutkey) {
            clearTimeout(timeoutkey);
            setTimeoutkey(undefined);
        }

        defaultValue &&
            setValue(defaultValue);
    }, [defaultValue]);

    function onChange(event: any) {
        const _value = event.target.value;
        if (update === 'idle' && _value !== value && _value !== 0) setUpdate('editing');

        setValue(_value);
    }

    function onBlur() {
        function treatValue(_value: string) {
            if ((!_value || _value === '') && onNullValue) return onNullValue();
            else return _value;
        }

        if (value !== defaultValue) {
            setUpdate('loading');

            updateValue({ [name]: treatValue(value) })
                .then((res: any) => setUpdate('idle'))
                .catch((error: any) => {
                    console.error({ error });
                    setUpdate('idle');
                });
        }
    }

    const timeToSeconds = (
        time: {
            horas: number;
            minutos: number;
            segundos: number;
        }
    ): number => {
        const { horas, minutos, segundos } = time;
        const total = horas * 3600 + minutos * 60 + segundos;
        return total;
    }

    return (
        <Flex
            gap="20px"
            style={style}
            alignItems="center"
            w="100%"
        >
            {!noHead && (
                <Flex
                    mt="9px"
                    w="30%"
                >
                    <FormLabel
                        display="flex"
                        gap="3px"
                        color="gray"
                        fontSize="15px"
                        fontWeight="500"
                        letterSpacing="0.24px"
                        textTransform="capitalize"
                    >
                        {label}

                        {update === 'editing' ? (
                            <Icon as={AiOutlineCloudSync} ml="2" boxSize="14px" />
                        ) : update === 'loading' ? (
                            <Spinner ml="2" boxSize="14px" />
                        ) : null}
                    </FormLabel>
                </Flex>
            )}

            <Flex
                direction="column"
                gap="5px"
                w="70%"
            >
                <TimePicker
                    isDisabled={isDisabled}
                    onBlur={onBlur}
                    defaultValue={value}
                    onChange={(e: { horas: number; minutos: number; segundos: number; }) => {
                        const time = timeToSeconds(e)
                        onChange({ target: { value: time } })
                    }}
                />
            </Flex>
        </Flex>
    );
};
