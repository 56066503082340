import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Text,
    Skeleton,
} from '@chakra-ui/react'
import { LeccionInt } from '../../../interfaces/CursosInt';
import { useEffect, useState } from 'react';
import { useClient } from '../../../shared/context/client.context';
import { AccordeonContenido } from './utils/AccordeonContenido';
import { useDataId } from '../../../shared/hooks/useDataId';
import { EndpointTypes } from '../../../shared/utils/Types/EndpointTypes';
import { PreviewLeccion } from '../../Contenido/components/utils/TabsLeccion/Preview';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    cursoId: string | undefined;
}

export const ContenidoModal = ({
    isOpen,
    onClose,
    cursoId
}: Props) => {
    const client = useClient();
    const {
        data: curso,
    } = useDataId({
        client: client,
        id: cursoId,
        endpoint: EndpointTypes.CURSOS
    })

    const [expandedIndex, setExpandedIndex] = useState<any>([])
    const [leccionSelect, setLeccionSelelect] = useState<LeccionInt>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const defaultIndex: number[] = []
        if (!curso?.modulos) return

        for (let i = 0; i < curso?.modulos?.length; i++) {
            defaultIndex.push(i)
        }

        setExpandedIndex(defaultIndex)
    }, [curso])

    const handleClose = () => {
        setLeccionSelelect(undefined)
        onClose();
    }

    const handleSelectLeccion = (leccion: LeccionInt | undefined) => {
        setLoading(true)
        setLeccionSelelect(leccion)
        setTimeout(() => { setLoading(false) }, 1000)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => handleClose()}
                closeOnOverlayClick={false}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    rounded="20px"
                >
                    <ModalHeader
                        fontSize="24px"
                        fontWeight="600"
                        letterSpacing="-0.32px"
                        px="40px"
                        pt="20px"
                        pb="0"
                    >
                        Contenido
                    </ModalHeader>

                    <ModalCloseButton onClick={handleClose} />

                    <Box
                        m="10px"
                        mb="20px"
                        overflowY="scroll"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <ModalBody>
                            <Flex
                                gap="20px"
                            >
                                <Box flex="1">
                                    <AccordeonContenido
                                        curso={curso}
                                        expandedIndex={expandedIndex}
                                        setLeccionSelelect={handleSelectLeccion}
                                    />
                                </Box>

                                <Box
                                    flex="1"
                                >
                                    <Flex
                                        position="fixed"
                                        w="45%"
                                        direction="column"
                                        gap="10px"
                                    >
                                        <Flex
                                            p="20px"
                                            pb="0"
                                            textAlign="center"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Text
                                                fontSize="24px"
                                                fontWeight="600"
                                                color="secondary"
                                                letterSpacing="-0.32px"
                                            >
                                                Leccion {leccionSelect?.nombre}
                                            </Text>
                                        </Flex>


                                        {loading ?
                                            <Skeleton
                                                height="550px"
                                                width="100%"
                                                rounded="20px"
                                            />
                                            :
                                            <Box
                                                overflowY="scroll"
                                                overflowX="hidden"
                                                __css={{
                                                    '&::-webkit-scrollbar': {
                                                        w: '0px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        borderRadius: '20px',
                                                        bg: `light_grey`,
                                                    },
                                                }}
                                                h={"550px"}
                                            >
                                                <PreviewLeccion
                                                    leccion={leccionSelect}
                                                    isModal={true}
                                                />
                                            </Box>
                                        }
                                    </Flex>
                                </Box>
                            </Flex>
                        </ModalBody>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}