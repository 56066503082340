import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate"
import { ColumnProps } from "../../../../../../shared/components/Table/Table"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"

export const InspectoresColumns = (): ColumnProps[] => {
    return [
        {
            header: 'usuario',
            field: 'usuario',
            key: 'usuario',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.username}
                    link={{
                        to: `/usuarios/inspectores/${rowData?.id}/grupos`
                    }}
                />
            ),
        },
        {
            header: 'clave',
            field: 'clave',
            key: 'clave',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.claveInspectorSepe}
                    link={{
                        to: `/usuarios/inspectores/${rowData?.id}/grupos`
                    }}
                />
            ),
        },
        {
            header: 'grupos activos',
            field: 'gruposActivos',
            key: 'gruposActivos',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.grupos_activos_count || 0 + " grupos"}
                    link={{
                        to: `/usuarios/inspectores/${rowData?.id}/grupos`
                    }}
                />
            ),
        },
        {
            header: 'grupos asignados',
            field: 'gruposAsignados',
            key: 'gruposAsignados',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.grupos_count || 0 + " grupos"}
                    link={{
                        to: `/usuarios/inspectores/${rowData?.id}/grupos`
                    }}
                />
            )
        },
        {
            header: 'última conexión',
            field: 'conexion',
            key: 'conexion',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.meta?.fecha_acceso}
                    link={{
                        to: `/usuarios/inspectores/${rowData?.id}/grupos`
                    }}
                />
            )
        },
    ]
}