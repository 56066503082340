import { ClientInt } from './../services/http.service';
import { CursosInt, updateCursosInt } from "../../interfaces/CursosInt";
import _axios from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';


export const updateCurso = async ({ id, data, client }: { id: string | undefined, data: updateCursosInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/cursos/${id}`,
        data,
        {}
    )
};

export const addCurso = async ({
    curso, client
}: {
    curso: CursosInt, client: ClientInt
}) => {
    const formData = new FormData()
    formData.append('nombre', curso.nombre);
    formData.append('descripcion', curso.descripcion);
    formData.append('publicado', curso.publicado ? 'true' : 'false');
    formData.append('activo', curso.activo ? 'true' : 'false');
    if (curso?.imagen) formData.append('imagen', curso.imagen as any);
    if (curso?.icono) formData.append('icono', curso.icono as any);

    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/cursos`,
        formData,
        {}
    )
};

export const copyCurso = async ({ slug, client }: { slug: string, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/copyCurso`,
        { slug: slug },
        {}
    )
}

export const deleteCurso = async ({ cursoId, client }: { cursoId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/cursos/${cursoId}`)
};

export const uploadCursoImage = async ({ id, portada, icono, client }: { id: string, portada?: File, icono?: File, client: ClientInt }) => {
    const formData = new FormData()
    const tenant = await tenantInstance();

    if (portada) formData.append('imagen', portada);
    if (icono) formData.append('icono', icono);

    return await _axios.put(
        `${tenant}/${client}/cursos/${id}`,
        formData,
        {}
    ).then((response: any) => response);
};