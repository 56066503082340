export const afReplaceNombre = (accionFormativa: string, nombre: string) => {
    const splitName = nombre?.split("")
    const reverseName = splitName.reverse()
    const name = ["A", "F", accionFormativa, "G"]
    const codigo = []

    for (let i = 0; i < reverseName.length; i++) {
        if(reverseName[i] === "G") break
        codigo.push(reverseName[i])
    }

    name.push(...codigo.reverse())

    const newName = name.join("")

    return newName;
}

export const codigoReplaceNombre = (codigoGrupo: string, nombre: string) => {
    const splitName = nombre?.split("")
    const name = []

    for (let i = 0; i < splitName.length; i++) {
        if(splitName[i] === "G") break
        name.push(splitName[i])
    }

    name.push("G")
    name.push(codigoGrupo)

    const newName = name.join("")

    return newName;
}