import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton, Icon } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { MutableRefObject, useState } from "react";
import { UserInt } from "../../../interfaces/UserInt";
import { CustomAvatar } from "../../../shared/components/Avatar/CustomAvatar";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../shared/context/client.context";
import tenantInstance from "../../../shared/services/tenantInstance.service";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { getData } from "../../../shared/middlewares/middlewares";
import { CustomAsyncSelect } from "../../../shared/components/CustomElements/CustomAsyncSelect";
import { BiTrashAlt } from "react-icons/bi";
import { addMatriculaGrupoRuta } from "../../../shared/middlewares/rutas.middleware";
import { GruposRutaInt } from "../../../interfaces/RutasInt";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefresh: () => void;
    grupo: MutableRefObject<GruposRutaInt | null>;
}
export const NewMatriculaRutaModal = ({ isOpen, onClose, setRefresh = () => { }, grupo }: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    const [alumnosMatriculas, setAlumnosMatriculas] = useState<UserInt[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(true)

    const handleSubmit = async (values: any) => {
        if (!alumnosMatriculas || alumnosMatriculas?.length == 0) return
        setIsLoading(true)

        const matriculas: string[] = []

        alumnosMatriculas?.map((alumno: any) => matriculas.push(alumno?.value?.id))

        addMatriculaGrupoRuta({
            grupoId: grupo?.current?.id as string,
            data: {
                users: matriculas
            },
            client: client
        })
            .then(() => {
                handleClose();
                setRefresh();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const handleSelectAlumno = (e: any) => {
        setAlumnosMatriculas((prev: any) => [
            ...prev,
            e
        ])

        setRefreshData(true)
    }

    const removeAlumno = (alumnoId: string | undefined | null) => {
        if (!alumnoId) return

        const alumnosFilter = alumnosMatriculas.filter((alumno: any) => alumno?.value !== alumnoId)

        setAlumnosMatriculas(alumnosFilter)
        setRefreshData(true)
    }

    const handleClose = () => {
        setAlumnosMatriculas([]);
        grupo.current = null;
        setRefreshData(true)
        onClose();
    }

    const loadAlumnos = async (value: string) => {
        const tenant = await tenantInstance()
        let query = ""

        if (value && value !== "") query = `&nombreYemail=${value}`


        const { data } = await getData(
            tenant + "/" + client + EndpointTypes.USUARIOS + "?empresa=" + query
        )

        const alumnos = data?.data?.data.map((alumno: UserInt) => ({
            value: alumno,
            label: `${alumno?.nombre} ${alumno?.apellidos}`,
        }));

        const alumnosFilter = alumnos.filter(({ value }: { value: { id: string } }) =>
            !alumnosMatriculas?.some((alumno: any) => alumno?.value?.id === value?.id)
        )

        return alumnosFilter;
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nueva matrícula
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{}}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                boxSize="100%"
                                                gap="10px"
                                            >
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                    mb="5px"
                                                >
                                                    Alumnos
                                                </Text>

                                                <CustomAsyncSelect
                                                    placeholder="Selecciona un alumno"
                                                    loadOptions={loadAlumnos}
                                                    onClick={handleSelectAlumno}
                                                    refreshData={refreshData}
                                                    setRefreshData={setRefreshData}
                                                />

                                                <Flex
                                                    direction="column"
                                                    gap="10px"
                                                >
                                                    {alumnosMatriculas?.map((alumno: any, index: number) => (
                                                        <Flex
                                                            key={index}
                                                            borderRadius="9px"
                                                            p="10px 15px"
                                                            bg="purewhite"
                                                            gap="10px"
                                                            alignItems="center"
                                                        >
                                                            <CustomAvatar
                                                                name={alumno?.value?.nombre}
                                                                src={alumno?.value?.avatar?.url}
                                                                size="35px"
                                                            />
                                                            <Flex
                                                                ml="5px"
                                                                direction="column"
                                                            >
                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="500"
                                                                >
                                                                    {alumno?.value?.nombre} {alumno?.value?.apellidos}
                                                                </Text>

                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="500"
                                                                >
                                                                    {alumno?.value?.email}
                                                                </Text>
                                                            </Flex>

                                                            <Icon as={BiTrashAlt} boxSize="22px" ml="auto" cursor="pointer" onClick={() => removeAlumno(alumno?.value)} />
                                                        </Flex>
                                                    )
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter
                                            justifyContent="center"
                                        >
                                            <Button
                                                isLoading={isLoading}
                                                mt="20px"
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                isDisabled={alumnosMatriculas?.length === 0}
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Matricular alumnos
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />
        </>
    )
}