import { Flex, Skeleton } from '@chakra-ui/react'

export default function PerfilAlumnoSkeleton( {moduloFUNDAE}:{moduloFUNDAE:boolean}) {
    return (
        <Flex gap="20px" w="full">
            <Flex direction="column" gap="20px" w="50%">
                <Skeleton height="439px" rounded="10px"/>
                <Skeleton height="198px" rounded="10px"/>
            </Flex>

            <Flex direction="column" w="50%" gap="20px">
                { moduloFUNDAE &&
                    <Skeleton height="499.5px" rounded="10px"></Skeleton>
                }
            </Flex>
        </Flex>
    )
}
