import { Flex } from "@chakra-ui/react"
import { ExamenInt, PreguntaInt, RespuestaInt } from "../../../../../../interfaces/ExamenInt"
import { useState } from "react";
import { EditPregunta } from "../../../../components/utils/EditPregunta";
import { EditRespuesta } from "../../../../components/utils/EditRespuesta";
import { SkeletonContenidoExamenes } from "../../../../components/utils/ExmanesContenidoSkeleton";
import { AddRespuesta } from "../../../../components/utils/AddRespuesta";
import { QueryObserverResult } from "@tanstack/react-query";
import { AccordeonContenidoExamen } from "../../../../components/utils/AccordeonContenidoExamen";

export enum TipoSelect {
    PREGUNTA = "PREGUNTA",
    RESPUESTA = "RESPUESTA",
    NUEVA_RESPUESTA = "NUEVA_RESPUESTA"
}

interface Props {
    examen: ExamenInt;
    Refresh: () => Promise<QueryObserverResult>;
    loading: boolean
}

export const TabContenidoExamen = ({
    examen,
    Refresh,
    loading
}: Props) => {
    const [selected, setSelected] = useState<{
        type: TipoSelect,
        data: PreguntaInt | RespuestaInt | null
    }>()
    const [clearRespuestas, setClearRespuestas] = useState<boolean>(false)

    return (
        loading ?
            <SkeletonContenidoExamenes />
            :

            <Flex
                gap="15px"
                h={"calc(100vh - 170px)"}
            >
                <Flex
                    w="25%"
                    position={"relative"}
                    opacity={selected?.type === TipoSelect.NUEVA_RESPUESTA ? 0.5 : 1}
                    minW="300px"
                >
                    <AccordeonContenidoExamen
                        selected={selected}
                        setSelected={setSelected}
                        examen={examen}
                        refreshData={Refresh}
                        clearRespuestas={clearRespuestas}
                        setClearRespuestas={setClearRespuestas}
                    />

                    {/* <Tooltip
                        label="Primero debes guardar la lección actual."
                        hasArrow
                        placement="right-start"
                        fontSize="14px"
                        color={"purewhite"}
                        bg="pureblack"
                        rounded="6px"
                        py="10px"
                        px="15px"
                        textAlign="center"
                        isDisabled={selected?.type !== TipoSelect.NUEVA_RESPUESTA}
                    > */}
                    <Flex
                        display={selected?.type === TipoSelect.NUEVA_RESPUESTA ? "block" : "none"}
                        pos={"absolute"}
                        bottom={"0"}
                        right={"0"}
                        top={"0"}
                        left={"0"}
                        bg={"transparent"}
                        cursor={"not-allowed"}
                    />
                    {/* </Tooltip> */}
                </Flex>

                <Flex
                    w="75%"
                >
                    {selected?.type === TipoSelect.PREGUNTA &&
                        <EditPregunta pregunta={selected?.data as PreguntaInt} Refresh={Refresh} setClearRespuestas={setClearRespuestas} />
                    }

                    {selected?.type === TipoSelect.RESPUESTA &&
                        <EditRespuesta respuesta={selected?.data as RespuestaInt} Refresh={Refresh} setClearRespuestas={setClearRespuestas} />
                    }

                    {selected?.type === TipoSelect.NUEVA_RESPUESTA &&
                        <AddRespuesta
                            data={selected?.data as Partial<RespuestaInt>}
                            setSelected={setSelected}
                            refreshData={Refresh}
                            setClearRespuestas={setClearRespuestas}
                        />
                    }
                </Flex>
            </Flex>
    )
}