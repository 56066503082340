import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    control: {
        marginTop: "3px",
        borderRadius: "5px",
        borderColor: "#959596",
        borderWidth: "1px",
        width: "23px",
        height: "23px",
        _checked: {
            bg: "#959596",
            borderColor: "#959596",
            boxShadow: "none",
            outline: "none"
        },
        _hover: {
            _checked: {
                bg: "#959596",
                borderColor: "#959596",
                boxShadow: "none",
                outline: "none"
            }
        }
    },
    container: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: "30px"
    }
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })