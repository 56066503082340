import { Flex } from "@chakra-ui/react"
import { useClient } from "../../../../shared/context/client.context";
import { useDataRelationship } from "../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { Table } from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { CursosColumns } from "../../components/TabsColumns/CursosColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";

interface Props {
    userId: string | undefined;
}

export const TabCursos = ({ userId }: Props) => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.CURSOS_TUTOR });
    const client = useClient();
    const { data: cursos, loading } = useDataRelationship({
        id: userId,
        client: client,
        endpoint: EndpointTypes.TUTORES,
        relationship: RelationshipTypes.CURSOS,
        query: query
    })

    return (
        <Flex direction="column" gap="20px">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.CURSO,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                showBody={false}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={cursos?.data}
                columns={CursosColumns()}
                isLoading={loading}
                total={cursos?.meta?.total || 0}
            />
        </Flex>
    )
}