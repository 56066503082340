import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../shared/context/client.context";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { Table } from "../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { GruposColumns } from "../../../components/TabsColumns/GruposColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

interface Props {
    empresaId: string | undefined;
}

export const TabGrupos = ({ empresaId }: Props) => {
    const moduloFUNDAE = useModuloFundae();
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.GRUPOS_EMPRESA });
    const { data: grupos, loading } = useDataRelationship({
        id: empresaId,
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        relationship: RelationshipTypes.GRUPOS,
        query: query
    })

    return (
        <Flex gap="20px" direction="column">

            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.ESTADO_GRUPO,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_INICIO_SUP,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_FIN_INF,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.CURSO,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FUNDAE,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={grupos?.data || []}
                columns={GruposColumns({ moduloFUNDAE })}
                isLoading={loading}
                total={grupos?.meta?.total || 0}
            />
        </Flex >
    );
}