import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    cancelRef: any;
    onClose: () => void;
    onCloseDrawer: () => void;
}

export default function CloseDialog({ isOpen, cancelRef, onClose, onCloseDrawer }: Props) {
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
            motionPreset='slideInBottom'
            size="xl"
        >
            <AlertDialogOverlay>
                <AlertDialogContent
                    h="fit"
                >
                    <AlertDialogHeader
                        fontSize='lg'
                        fontWeight='bold'
                    >
                        Atención
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Si vas atrás perderás lo rellenado en el formulario.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            bg="secondary"
                            color="purewhite"
                            onClick={onCloseDrawer}
                            ml={3}
                        >
                            Aceptar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
