import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onCloseForm: () => void;
    active?: boolean;
}

export const ModalCloseAlert = ({ isOpen, onClose, onCloseForm, active }: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="lg"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="10px"
            >
                <ModalBody>
                    <Text
                        color="dark-blue"
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="700"
                        my="10px"
                    >
                        Tienes datos sin guardar
                    </Text>

                    <Text
                        color="font"
                        textAlign="center"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Los datos introducidos no se guardarán si cierras esta ventana. ¿Estas seguro que quieres proceder?
                    </Text>
                </ModalBody>

                <ModalFooter justifyContent="center" gap="15px">

                    <Button
                        type="submit"
                        p="10px 15px"
                        bg="purewhite"
                        _hover={{ color: "purewhite", bg: "variant", borderColor: "variant" }}
                        rounded="5"
                        color="secondary"
                        borderWidth="1px"
                        borderColor="secondary"
                        fontSize="14px"
                        fontWeight="500"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>

                    <Button
                        type="submit"
                        bg="secondary"
                        _hover={{ color: "purewhite", bg: "variant" }}
                        p="10px 15px"
                        rounded="5"
                        color="purewhite"
                        fontSize="14px"
                        fontWeight="500"
                        onClick={() => {
                            onClose();
                            onCloseForm();
                        }}
                    >
                        Proceder sin guardar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}