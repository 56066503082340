import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const aceptarInscripcion = async ({
    inscripcionId,
    data,
    client
}: {
    inscripcionId: string;
    data: {
        grupoId: string | null;
        empresaId: string | null | undefined;
        tutorId: string | null | undefined;
    },
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/inscripciones/${inscripcionId}/acepta`,
        data,
        {}
    )
};

export const rechazarInscripcion = async ({
    inscripcionId,
    client
}: {
    inscripcionId: string;
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/inscripciones/${inscripcionId}/rechaza`
    )
};