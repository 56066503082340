import { AccordionItem, Flex, Icon, AccordionButton, AccordionIcon, AccordionPanel, Button, Text, useToast } from "@chakra-ui/react"
import { BiPlus } from "react-icons/bi"
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs"
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi"
import { PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { updatePregunta } from "../../../../shared/middlewares/examenes.middleware"
import { useClient } from "../../../../shared/context/client.context"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { RespuestasModule } from "./RespuestasModule"
import { TipoSelect } from "../../views/Examenes/Information/TabItem/TabContenido"

interface Props {
    pregunta: PreguntaInt;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: PreguntaInt | RespuestaInt | null; } | undefined>>;
    selected: { type: string, data: PreguntaInt | RespuestaInt | null } | undefined;
    refreshData: () => void;
    clearRespuestas: boolean;
    setClearRespuestas: Dispatch<SetStateAction<boolean>>;
}

export const PreguntasModule = ({
    pregunta,
    setSelected,
    selected,
    refreshData,
    clearRespuestas,
    setClearRespuestas
}: Props) => {
    const client = useClient()
    const toast = useToast()
    const [respuestas, setRespuestas] = useState<RespuestaInt[]>(pregunta?.respuestas || [])

    useEffect(() => {
        if (clearRespuestas) {
            setRespuestas(pregunta?.respuestas || [])
            setClearRespuestas(false)
        }
    }, [clearRespuestas])

    const changeEstadoPregunta = async (
        event: React.MouseEvent,
    ) => {
        event.stopPropagation()

        return updatePregunta({
            id: pregunta?.id as string,
            data: {
                publicado: !pregunta?.publicado
            },
            client: client
        })
            .then(() => {
                const msg = pregunta?.publicado ? `Pregunta ocultado` : `Pregunta publicado`
                toastNotify(toast, StatusEnumTypes.SUCCESS, msg)

                refreshData()
            }
            )
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <AccordionItem
            position={"relative"}
            display="flex"
            flexDirection="column"
            bg="purewhite"
            p="25px"
            rounded="10px"
            shadow="0px 1px 4px 0px rgba(9, 51, 72, 0.15)"
            border={"1px solid"}
            borderColor={selected?.data?.id === pregunta?.id ? "secondary" : "transparent"}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation()

                setSelected({
                    type: TipoSelect.PREGUNTA,
                    data: pregunta
                })
            }}
        >
            {({ isExpanded }) => (
                <>
                    <Flex
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                        mb={isExpanded ? "20px" : "0"}
                        cursor="pointer"
                    >
                        <Flex
                            alignItems="center"
                            gap="8px"
                        >
                            <Text
                                color="pureblack"
                                fontSize="15px"
                                fontWeight="400"
                                lineHeight="100%"
                                letterSpacing="-0.16px"
                            >
                                {pregunta?.contenido}
                            </Text>

                            <Icon
                                position="absolute"
                                top="5px"
                                right="5px"
                                onClick={(event: React.MouseEvent) => changeEstadoPregunta(event)}
                                _active={{ transform: "scale(0.95)" }}
                                as={pregunta?.publicado ? HiOutlineEye : HiOutlineEyeOff}
                                color={"pureblack"}
                            />
                        </Flex>

                        <AccordionButton
                            ml="10px"
                            w="fit-content"
                            h="fit-content"
                            p="5px"
                            rounded="100%"
                            _hover={{ bg: "variant" }}
                            onClick={(event: React.MouseEvent) => { event.stopPropagation() }}
                        >
                            <AccordionIcon boxSize="19px" color="pureblack" />
                        </AccordionButton>
                    </Flex>

                    <AccordionPanel
                        display="flex"
                        flexDir="column"
                        p="0"
                        gap="5px"
                    >
                        {respuestas?.map((respuesta: RespuestaInt) => (
                            <RespuestasModule
                                respuesta={respuesta}
                                setSelected={setSelected}
                                selected={selected}
                                refreshData={refreshData}
                            />
                        ))}
                    </AccordionPanel>

                    <Flex
                        gap="20px"
                        mt="15px"
                        display={isExpanded ? "flex" : "none"}
                    >
                        <Button
                            h="fit-content"
                            w="fit-content"
                            py="10px"
                            px="10px"
                            rounded={"6px"}
                            border="0.6px solid"
                            borderColor="#AAA"
                            bg="white"
                            fontSize="14px"
                            color="pureblack"
                            fontWeight="400"
                            leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                            onClick={async (event: React.MouseEvent) => {
                                event.stopPropagation()

                                const newData = {
                                    isNew: true,
                                    preguntaId: pregunta?.id,
                                    contenido: `Nueva respuesta`,
                                    publicado: false,
                                    correcta: false
                                }

                                setSelected({
                                    type: TipoSelect.NUEVA_RESPUESTA,
                                    data: newData
                                })

                                setRespuestas((prev: any) => [...prev, newData])
                            }}
                        >
                            Añadir respuesta
                        </Button>
                    </Flex>
                </>
            )}
        </AccordionItem>
    )
}