import { Button, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { NewTutorModal } from "../../../components/Modals/NewTutorModal";
import { useClient } from "../../../../../shared/context/client.context";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../../shared/components/Table/Table";
import { TutoresColumns } from "./Columns/TutoresColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useState } from "react";
import { OnboardingStep } from "../../../../../shared/components/Cards/OnboardingStep";
import { BiPlusCircle } from "react-icons/bi";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";

export const TutoresTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.TUTORES });
    const { user } = useAuthContex();
    const { total } = useUserPolicy();
    const moduloFUNDAE = useModuloFundae();
    const client = useClient();
    const [showStepTutor, setShowStepTutor] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: usuarios, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.TUTORES,
        query: query
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    return (
        <Flex direction="column" gap="20px">
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.CONTRATACION,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.ESTADO_USUARIO,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
                button={
                    <Flex
                        pos="relative"
                        zIndex={showStepTutor ? "99" : ""}
                    >
                        <Button
                            onClick={() => {
                                onOpen()
                                showStepTutor && setShowStepTutor(false)
                            }}
                            bg="secondary"
                            color="purewhite"
                            p="8px 15px"
                            borderRadius="5px"
                            fontSize="14px"
                            fontWeight="500"
                            leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                            _hover={{ bg: "variant" }}
                        >
                            Nuevo tutor
                        </Button>

                        {(showStepTutor && (!user?.config?.tutores && total)) &&
                            <OnboardingStep
                                position={{
                                    top: "15px",
                                    right: "120px"
                                }}
                                placement="top/right"
                                title="Crea tu primer tutor"
                                description="Gestiona los grupos asignados a cada tutor, facilitando la comunicación y el seguimiento de los estudiantes."
                                onClick={() => {
                                    setShowStepTutor(false)
                                    onOpen()
                                }}
                            />
                        }
                    </Flex>
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={usuarios?.data || []}
                columns={TutoresColumns({ moduloFUNDAE })}
                isLoading={loading}
                total={usuarios?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.usuariosFiltros : EmptyMessageType.usuarios}
            />

            <NewTutorModal
                isOpen={isOpen}
                onClose={onClose}
                setRefreshTable={Refresh}
            />
        </Flex>
    )
};