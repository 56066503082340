import { Flex, FormLabel, useToast } from '@chakra-ui/react';
import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { toastNotify } from '../../utils/functions/toastNotify';
import { StatusEnumTypes } from '../../utils/Types/StatusEnumTypes';
import { useEffect, useState } from 'react';
import tenantInstance from '../../services/tenantInstance.service';
import "./FilePond.scss"

registerPlugin(FilePondPluginImagePreview);

export interface InformationFilepondProps {
    name: string;
    label: string;
    putEP: string;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    setRefresh?: () => void;
    noHead?: boolean;
    labelIdle?: string;
    method?: "POST" | "PUT" | "GET" | "DELETE" | undefined;
}

export const InformationFilepond = ({
    name,
    label,
    putEP,
    isDisabled = false,
    style = {},
    setRefresh = () => { },
    noHead = false,
    labelIdle = 'Deja caer tus archivo o <span class="filepond--label-action">impórtalo</span>',
    method = "PUT"
}: InformationFilepondProps) => {
    const toast = useToast();
    const token = localStorage.getItem('tokenPanel') ? localStorage.getItem('tokenPanel') : ''
    const [tenant, setTenant] = useState<string | null>(null);

    useEffect(() => { tenantInstance().then((res: string) => setTenant(res)) }, [])

    return (
        <Flex
            fontSize="14px"
            direction="column"
            style={style}
            w="100%"
        >
            {!noHead &&
                <Flex
                    as="label"
                >
                    <FormLabel
                        mb="10px"
                    >
                        {label}
                    </FormLabel>
                </Flex>
            }

            <FilePond
                onprocessfile={() => setRefresh()}
                name={name}
                disabled={isDisabled}
                labelIdle={labelIdle}
                server={{
                    url: import.meta.env.VITE_URL_CAMPUS_API,
                    process: {
                        url: tenant + "/" + putEP,
                        method: method,
                        headers: { Authorization: `Bearer ${token}` },
                        onerror: () => toastNotify(toast, StatusEnumTypes.ERROR, "Error al subir la imagen"),
                    },
                }}
                credits={false}
                allowImagePreview={false}
            />
        </Flex>
    );
};