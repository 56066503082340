import { Badge, Flex, Text, Link } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs"
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { handleLinkOpen } from "../../../../shared/utils/functions/linkOpen";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";

interface Props {
    moduloFUNDAE: boolean;
}

export const GruposTutores = ({ moduloFUNDAE }: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'duracion',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/grupos/${rowData?.id}`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column" alignItems="flex-start" ml="16px" py="16px">
                        <Badge
                            color={
                                rowData?.estado == 2 ? "font" :
                                    rowData?.estado == 1 ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_bg"
                            }
                            borderColor={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_variant"
                            }
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="15px"
                            p="2px 8px"
                        >
                            {
                                rowData?.estado == 2 ? "Inactivo" :
                                    rowData?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.nombre}
                    text={rowData?.curso?.nombre}
                    link={{
                        to: `/grupos/${rowData?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/grupos/${rowData?.id}`}

                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column">
                        {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                            <Text color="font"
                                fontSize="14px"
                                fontWeight="500"
                                key={index}
                            >
                                {empresa?.nombre}
                            </Text>
                        ))}
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Matrículas',
            field: 'matriculas',
            key: 'matriculas',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculas || 0) + " matrículas"}
                    link={{
                        to: `/grupos/${rowData?.id}`,

                    }}
                />
            )
        },
    ]
    const extraColumns = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            sortable: true,
            body: (rowData: any) => (
                rowData?.fundae
                    ?
                    <ColumnIcon
                        icon={BsCheckCircleFill}
                        ui={{
                            color: "#E9B418",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/grupos/${rowData?.id}`,
                        }}
                    />
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/grupos/${rowData?.id}`,
                        }}
                    />
            )
        },
    ]

    return moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns.slice(1)] : columns;
}