export const fmtSnds = (d = 0) => {
    if (d === undefined) return '-';
  
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
  
    const hDisplay = h > 0 ? h + ':' : '';
    const mDisplay = m > -1 ? (m < 10 ? '0' : '') + m + ':' : '';
    const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
  
    return hDisplay + mDisplay + sDisplay;
  };

  export const fmtSndsHourMin = (d = 0) => {
    if (d === undefined) return '-';
  
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
  
    const hDisplay = h > 0 ? h + 'h ' : '';
    const mDisplay = m > -1 ? (m < 10 ? '0' : '') + m + 'm ' : '';
  
    return hDisplay + mDisplay;
  };