import { Button, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { Formik, Form as FormikForm } from 'formik';
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { useState } from "react";
import { cambioDatos, removeAvatarUser, uploadAvatarUser } from "../../../../shared/middlewares/users.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { UserInt } from "../../../../interfaces/UserInt";
import { AvatarUploader } from "../../../../shared/components/FormElements/AvatarUploader";
import { BiCloudUpload, BiTrash } from "react-icons/bi";
export interface InputItem {
    label: string;
    name: string;
    data: string | undefined | null;
}

export default function InformationPerfil() {
    const { user, refreshUser } = useAuthContex();
    const toast = useToast();
    const [file, setFile] = useState<File[] | null>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const inputsForm: InputItem[] = [
        { label: "Nombre", name: "nombre", data: user?.nombre },
        { label: "Apellidos", name: "apellidos", data: user?.apellidos },
        { label: "Nombre de usuario", name: "username", data: user?.username },
        { label: "Teléfono", name: "telefono", data: user?.telefono },
        { label: "Email", name: "email", data: user.email }
    ]

    const initialValues = {
        ...inputsForm.reduce((acc, { name, data }) => ({
            ...acc,
            [name]: data
        }), {})
    }

    const onSubmit = (values: Record<string, string | null | undefined>) => {
        let cambios = {}

        Object.entries(values || {}).forEach(([key, value]) => {
            if (user && key in user) {
                const userValue = user[key as keyof UserInt];

                if (value !== userValue) {
                    cambios = {
                        ...cambios,
                        [key]: value
                    };
                }
            } else {
                cambios = {
                    ...cambios,
                    [key]: value
                };
            }
        });

        cambioDatos({
            data: cambios
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "¡Solicitud enviada correctamente!")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const isExistsChanges = (
        values: Record<string, string | null | undefined>
    ) => {
        if (
            values?.nombre !== user?.nombre ||
            values?.apellidos !== user?.apellidos ||
            values?.telefono !== user?.telefono ||
            values?.username !== user?.username ||
            values?.email !== user?.email
        ) return true

        return false
    }

    const handleRemove = async () => {
        if (file && file[0]) setFile(null)
        else
            if (!user?.id) return;
        setIsUploading(true)

        await removeAvatarUser()
            .then(async () => {
                refreshUser({
                    avatar: null
                })
            })
            .catch((err: Error) => console.error({ err }))
            .finally(() => setIsUploading(false))
    };

    const handleUpload = () => {
        if (!user?.id) return
        if (!file) return toastNotify(toast, StatusEnumTypes.ERROR, "Debe cargar una imagen antes de actualizar su perfil.")

        setIsUploading(true)

        uploadAvatarUser(file)
            .then((response: any) => {
                refreshUser({
                    avatar: {
                        url: response?.data?.data?.avatar?.url
                    }
                });

                setFile(null)
                toastNotify(toast, StatusEnumTypes.SUCCESS, "¡Avatar subido correctamente!")
            })
            .catch((error) => {
                console.log(error)
                toastNotify(toast, StatusEnumTypes.ERROR, "Error al subir el avatar, vuelva a intentarlo")
            })
            .finally(() => setIsUploading(false))

    };


    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Perfil
                </Text>
            }
        >
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                            style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}
                        >
                            <Flex
                                m="30px"
                                p="30px"
                                direction="column"
                                gap="20px"
                                bg="purewhite"
                                rounded="20px"
                                border="1px solid"
                                borderColor="border"
                                w="500px"
                            >
                                <Flex
                                    alignItems="center"
                                    gap="20px"
                                >
                                    <AvatarUploader
                                        name={`${user?.nombre} ${user?.apellidos}`}
                                        src={(file && file.length > 0 ? file[0] : user?.avatar?.url) || ""}
                                        onUpload={setFile}
                                    />

                                    <Tooltip
                                        label="Primero debes cargar tu imagen"
                                        hasArrow
                                        placement="bottom"
                                        isDisabled={(file && file?.length > 0) ? true : false}
                                        rounded="6px"
                                        color="purewhite"
                                        fontSize="14px"
                                        fontWeight="400px"
                                        px="10px"
                                        py="5px"
                                        bg="pureblack"
                                    >
                                        <Button
                                            py="10px"
                                            px="16px"
                                            h="fit-content"
                                            w="fit-content"
                                            rounded="10px"
                                            bg="secondary"
                                            color="purewhite"
                                            fontSize="16px"
                                            fontWeight="700"
                                            isLoading={isUploading}
                                            isDisabled={!file || file?.length === 0}
                                            rightIcon={
                                                <Icon as={BiCloudUpload} boxSize="24px" />
                                            }
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()

                                                handleUpload()
                                            }}
                                        >
                                            Subir foto nueva
                                        </Button>
                                    </Tooltip>

                                    <Button
                                        py="9px"
                                        px="32px"
                                        h="fit-content"
                                        w="fit-content"
                                        rounded="10px"
                                        bg="purewhite"
                                        border="1px solid"
                                        borderColor="#A5A8B3"
                                        isDisabled={!user?.avatar?.url && (!file || file?.length === 0)}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            handleRemove();
                                        }}
                                    >
                                        <Icon
                                            as={BiTrash}
                                            boxSize="24px"
                                        />
                                    </Button>
                                </Flex>

                                <Flex
                                    direction="column"
                                    gap="10px"
                                >
                                    {inputsForm?.map((i: InputItem) => (
                                        <FormInput
                                            name={i?.name}
                                            label={i?.label}
                                            defaultValue={user?.nombre}
                                        />
                                    ))}
                                </Flex>
                                <Flex
                                    alignItems="center"
                                    gap="17px"
                                >
                                    <Button
                                        h="fit-content"
                                        w="fit-content"
                                        px="16px"
                                        py="10px"
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="22px"
                                        letterSpacing="-0.408px"
                                        bg="transparent"
                                        _hover={{ bg: "variant", color: "purewhite" }}
                                        isDisabled={!isExistsChanges(values)}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            formik.resetForm()
                                        }}
                                    >
                                        Descartar cambios
                                    </Button>

                                    <Button
                                        h="fit-content"
                                        w="fit-content"
                                        px="16px"
                                        py="10px"
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="22px"
                                        letterSpacing="-0.408px"
                                        color="purewhite"
                                        bg="main"
                                        type="submit"
                                        isDisabled={!isExistsChanges(values)}
                                    >
                                        Guardar cambios
                                    </Button>
                                </Flex>
                            </Flex>
                        </FormikForm>
                    )
                }}
            </Formik>
        </Topbar>
    )
}
