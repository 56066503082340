import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonTabResumen = () => {
    return (
        <Flex
            direction="column"
            gap="30px"
        >
            <Flex
                gap="30px"
            >
                <Skeleton h="128px" w="100%" rounded="14px" />
                <Skeleton h="128px" w="100%" rounded="14px" />
                <Skeleton h="128px" w="100%" rounded="14px" />
            </Flex>

            <Flex
                gap="30px"
            >
                <Skeleton height="924px" w="100%" rounded="20px" flex="1" />
                <Skeleton height="924px" w="100%" rounded="20px" flex="1" />
            </Flex>
        </Flex>
    )
}