import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { EjecutarTarea } from "../../../../shared/middlewares/tareas.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { Actions } from "../../../../shared/components/TableElements/Actions";
import { useClient } from "../../../../shared/context/client.context";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";

interface Props {
    onEventRemove: (event: any) => void;
    Refresh: () => void;
}

export const TareasColumns = ({ onEventRemove, Refresh }: Props): ColumnProps[] => {
    const toast = useToast();
    const client = useClient();
    const timeZone = useTimeZone();

    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.user?.nombre + rowData?.user?.apellidos} src={rowData?.user?.avatar?.url} size="35px" />
                    }
                />
            ),
        },
        {
            header: 'destinatario',
            field: 'destinatario',
            key: 'destinatario',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.email}
                />
            ),
        },
        {
            header: 'Grupo',
            field: 'grupo.nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.grupo ? rowData?.grupo?.curso?.nombre : rowData?.matricula?.grupo?.curso?.nombre || "---"}
                    text={rowData?.grupo ? rowData?.grupo?.nombre : rowData?.matricula?.grupo?.nombre || "---"}
                    tooltip={{
                        isDisabled: !rowData?.matricula?.fechaInicio || !rowData?.matricula?.fechaFin,
                        label: (
                            <Flex
                                direction="column"
                                alignItems="center"
                                gap="5px"
                                color="purewhite"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {(rowData?.matricula?.fechaInicio && rowData?.matricula?.fechaFin) &&
                                    <>
                                        <Text>Fecha inicio: {formatTimezone({ date: rowData?.matricula?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                                        <Text>Fecha fin: {formatTimezone({ date: rowData?.matricula?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                                    </>
                                }
                            </Flex>
                        )
                    }}
                />
            )
        },
        {
            header: 'Ejecucion',
            field: 'fechaEjecucion',
            key: 'fechaEjecucion',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    isDateTime
                    date={rowData?.fechaEjecucion}
                />
            )
        },
        {
            header: 'Plantilla',
            field: 'plantilla',
            key: 'plantilla',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.plantilla}
                />
            )
        },
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Button
                    bg="secondary"
                    color="white"
                    h="fit-content"
                    p="7px 15px"
                    rounded="5px"
                    fontSize="14px"
                    fontWeight="500"
                    _hover={{ bg: "variant" }}
                    onClick={() => {
                        EjecutarTarea({
                            id: rowData?.id,
                            client: client,
                        })
                            .then(() => {
                                Refresh()
                                toastNotify(toast, StatusEnumTypes.SUCCESS, "Tarea ejecutada correctamente")
                            })
                            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al ejecutar la tarea"))
                    }}
                >
                    Ejecutar
                </Button>
            )
        },
        {
            header: "",
            field: "",
            key: "",
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar tarea",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        }
    ]
}