import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum"
import { useClient } from "../../../../shared/context/client.context"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy"
import { EmpresasInt } from "../../../../interfaces/EmpresasInt"
import { defaultIcon } from "../../../Onboarding/components/OnboardingForm"
import { StatusItem } from "../../components/StatusItem"
import { EditEmpresaDrawer } from "../../components/EditEmpresaDrawer"
import { useData } from "../../../../shared/hooks/useData"
import { updateUsuario } from "../../../../shared/middlewares/users.middleware"
import { NewEmpresaModal } from "../../../Empresas/components/NewEmpresaModal"
import { OnboardingStep } from "../../../../shared/components/Cards/OnboardingStep"
import { ModalFinalConfig } from "../../../../shared/components/Modals/ModalFinalConfig"
import { useNavigate } from "react-router-dom"

interface Props {
    isOpenNew: boolean
    onCloseNew: () => void
}

export const EmpresasOrganizadoras = ({
    isOpenNew,
    onCloseNew,
}: Props) => {
    const navigate = useNavigate();
    const client = useClient()
    const { user, refreshUser } = useAuthContex();
    const { gestion, contenido, total } = useUserPolicy()
    const [showStep, setShowStep] = useState<boolean>(true)
    const { onOpen: onOpenFinalConfig, onClose: onCloseFinalConfig, isOpen: isOpenFinalConfig } = useDisclosure();
    const { data: empresas, Refresh } = useData({
        client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const onEventEdit = (empresa: EmpresasInt | null) => navigate(`/configuracion/mis-empresas/${empresa?.id}`)

    const checkEmpresasCompleted = (empresas: EmpresasInt[]) => {
        if (!empresas || empresas?.length === 0) return false

        const totalEmpresas = empresas?.length
        const completed = empresas?.filter(
            (empresa: EmpresasInt) =>
                empresa?.cif &&
                empresa?.email &&
                empresa?.telefono &&
                (
                    empresa?.extraInfo?.direccion &&
                    empresa?.extraInfo?.codigoPostal &&
                    empresa?.extraInfo?.localidad
                ) &&
                (
                    empresa?.extraInfo?.horario?.inicioTramo1 &&
                    empresa?.extraInfo?.horario?.finTramo1 &&
                    empresa?.extraInfo?.horario?.inicioTramo2 &&
                    empresa?.extraInfo?.horario?.finTramo2 &&
                    empresa?.extraInfo?.horario?.dias
                ) &&
                (
                    empresa?.extraInfo?.responsable &&
                    empresa?.extraInfo?.telefonoContacto
                ) &&
                empresa?.icono?.url &&
                empresa?.imagen?.url
        ).length

        return totalEmpresas === completed ? true : false
    }

    useEffect(() => {
        if (!user?.config?.empresasOrganizadoras && total) {
            const completedStep = checkEmpresasCompleted(empresas?.data)

            if (completedStep) {
                updateUsuario({
                    id: user?.id as string,
                    client: client,
                    data: {
                        config: {
                            ...user?.config,
                            empresasOrganizadoras: true
                        }
                    }
                })
                    .then(() => {
                        refreshUser({
                            config: {
                                ...user?.config,
                                empresasOrganizadoras: true
                            }
                        })
                    })
                    .finally(() => onOpenFinalConfig())
            }
        }
    }, [empresas])

    return (
        <Flex
            w="100%"
            direction="column"
            pt="10px"
            gap="20px"
            pos={"relative"}
        >
            {empresas?.data?.map((empresa: EmpresasInt) => (
                <Box
                    cursor="pointer"
                    borderRadius="14px"
                    borderWidth="1px"
                    borderColor="#DFE2E6"
                    bg="purewhite"
                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
                    transition="all 0.4s ease-in-out"
                    _hover={{
                        transform: 'translateY(-0.1px)',
                        boxShadow: 'rgba(64, 85, 128, 0.15) 0px 4px 12px',

                    }}
                    onClick={() => onEventEdit(empresa)}
                >
                    <Flex
                        w="100%"
                        alignItems="center"
                        p="20px"
                    >
                        <Flex
                            gap="10px"
                            alignItems="center"
                            borderRight="1px solid"
                            borderColor="light_grey"
                            w="20%"
                        >
                            {empresa?.icono?.url ?
                                <Image
                                    src={empresa?.icono?.url}
                                    boxSize="45px"
                                    border="none"
                                    padding="0"
                                    objectFit="cover"
                                />
                                :
                                <Image
                                    src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                    boxSize="45px"
                                    border="none"
                                    padding="0"
                                    objectFit="cover"
                                />
                            }

                            <Flex
                                direction="column"
                            >
                                <Text
                                    color="font"
                                    fontSize="16px"
                                    fontWeight="600"
                                >
                                    {empresa.nombre}
                                </Text>

                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="500"
                                >
                                    Status de la empresa
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            w="80%"
                        >
                            <StatusItem
                                status={empresa?.cif ? true : false}
                                text="CIF"
                            />

                            <StatusItem
                                status={empresa?.email ? true : false}
                                text="Email"
                            />

                            <StatusItem
                                status={empresa?.telefono ? true : false}
                                text="Teléfono"
                            />

                            <StatusItem
                                status={
                                    (
                                        empresa?.extraInfo?.direccion &&
                                        empresa?.extraInfo?.codigoPostal &&
                                        empresa?.extraInfo?.localidad
                                    ) ? true : false
                                }
                                text="Dirección"
                            />

                            <StatusItem
                                status={
                                    (
                                        empresa?.extraInfo?.horario?.inicioTramo1 &&
                                        empresa?.extraInfo?.horario?.finTramo1 &&
                                        empresa?.extraInfo?.horario?.inicioTramo2 &&
                                        empresa?.extraInfo?.horario?.finTramo2 &&
                                        empresa?.extraInfo?.horario?.dias
                                    ) ? true : false
                                }
                                text="Horario"
                            />

                            <StatusItem
                                status={(empresa?.extraInfo?.responsable && empresa?.extraInfo?.telefonoContacto) ? true : false}
                                text="Responsable"
                            />

                            <StatusItem
                                status={empresa?.icono?.url ? true : false}
                                text="Icono"
                            />

                            <StatusItem
                                status={empresa?.imagen?.url ? true : false}
                                text="Firma"
                            />
                        </Flex>
                    </Flex>
                </Box>
            ))}

            {(showStep && (!user?.config?.empresasOrganizadoras && total)) &&
                <OnboardingStep
                    position={{
                        top: `${(empresas?.meta?.total | 0) * 94}px`,
                        left: "-25px"
                    }}
                    title="Completa los datos de tus empresas"
                    description="Indica la informacion necesaria de tus empresas hasta que todos esten confirmados: CIF, Email, Teléfono, Dirección, Horario, Icono y Firma para poder completar la configuracion del campus. Este indicador desaparecerá cuando todos los campos estén completados."
                    placement="top/left"
                    onClick={async () => {
                        setShowStep(false)
                    }}
                />
            }

            <NewEmpresaModal
                isOpen={isOpenNew}
                onClose={onCloseNew}
                setRefreshTable={Refresh}
                interna={true}
            />

            <ModalFinalConfig
                isOpen={isOpenFinalConfig}
                onClose={onCloseFinalConfig}
            />
        </Flex>
    )
}