import { Button, Icon, PlacementWithLogical, StyleProps, Tooltip } from "@chakra-ui/react"

interface Props {
    tooltip?: {
        label: string;
        hasArrow?: boolean;
        isDisabled: boolean;
        placement?: PlacementWithLogical;
    }
    button: {
        label?: string;
        isDisabled: boolean;
        onClick: (event?: React.MouseEvent<HTMLElement>) => void;
        isLoading?: boolean;
        leftIcon?: {
            as: any;
            boxSize: string | number;
        };
        rightIcon?: {
            as: any;
            boxSize: string | number;
        }
    }
    styles?: StyleProps;
}

export const CustomButton = ({
    styles = {
        h: "fit-content",
        w: "fit-content",
        p: "10px 15px"
    },
    tooltip = {
        label: "",
        hasArrow: true,
        isDisabled: true,
        placement: "bottom",
    },
    button = {
        isDisabled: false,
        isLoading: false,
        onClick: (e?: React.MouseEvent<HTMLElement>) => { },
        leftIcon: {
            as: undefined,
            boxSize: "20px",
        },
        rightIcon: {
            as: undefined,
            boxSize: "20px",
        },
    },
}: Props) => {
    return (
        <Tooltip
            label={tooltip?.label}
            hasArrow={true}
            isDisabled={tooltip?.isDisabled}
            placement={tooltip?.placement}
            bg="pureblack"
            color="purewhite"
            fontSize={"14px"}
            fontWeight={"400"}
            rounded={"6px"}
            p={"10px"}
            textAlign="center"
        >
            <Button
                {...styles}
                isDisabled={button?.isDisabled}
                onClick={button?.onClick}
                isLoading={button?.isLoading}
                bg="secondary"
                color="white"
                rounded="5px"
                fontSize="14px"
                fontWeight="500"
                _hover={{ bg: "secondary_variant" }}
                leftIcon={(button?.label && button?.leftIcon?.as) ? <Icon as={button?.leftIcon?.as} boxSize={button?.leftIcon?.boxSize} /> : undefined}
                rightIcon={(button?.label && button?.rightIcon?.as) ? <Icon as={button?.rightIcon?.as} boxSize={button?.rightIcon?.boxSize} /> : undefined}
            >
                {button?.label
                    ? button?.label
                    : button?.leftIcon?.as ? <Icon as={button?.leftIcon?.as} boxSize={button?.leftIcon?.boxSize} />
                        : button?.rightIcon?.as ? <Icon as={button?.rightIcon?.as} boxSize={button?.rightIcon?.boxSize} />
                            : undefined

                }
            </Button>
        </Tooltip>
    )
}