import {
    Box,
    Flex,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Icon,
} from '@chakra-ui/react'
import { CursosInt, LeccionInt, ModulosInt } from '../../../../interfaces/CursosInt';
import { truncate } from 'lodash';
import { useEffect } from 'react';
import { BsDot } from 'react-icons/bs';

interface Props {
    expandedIndex: number[];
    curso: CursosInt;
    setLeccionSelelect: (leccion: LeccionInt | undefined) => void;
}

export const AccordeonContenido = ({
    expandedIndex,
    curso,
    setLeccionSelelect,
}: Props) => {
    const sortModulosByOrder = (Modulos: any[] | undefined) => {
        if (!Modulos) return []

        const sortedLecciones = Modulos.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

        return sortedLecciones
    };

    const sortLeccionesByOrder = (lecciones: any[] | undefined) => {
        if (!lecciones) return []

        const sortedLecciones = lecciones.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

        return sortedLecciones
    };

    useEffect(() => {
        if(!curso?.modulos) return
        const modulos = sortModulosByOrder(curso?.modulos)
        if(!modulos[0]?.lecciones) return
        const lecciones = sortLeccionesByOrder(modulos[0]?.lecciones)

        setLeccionSelelect(lecciones[0])
    }, [curso])
    
    return (
        <Flex
            direction="column"
            border="1px solid"
            borderColor="light_grey"
            rounded="20px"
            overflow="hidden"
            w="100%"
            height="max-content"
        >
            {expandedIndex?.length > 0 &&
                <Accordion
                    overflow="auto"
                    className="scroll-bar"
                    defaultIndex={expandedIndex}
                    allowMultiple
                >
                    {sortModulosByOrder(curso?.modulos)?.map((modulo: ModulosInt, index: any) => (
                        <AccordionItem
                            borderBottom={curso?.modulos?.length === (index + 1) ? "none" : ""}
                            borderTop={(index === 0) ? "none" : ""}
                        >
                            <>
                                <Flex>
                                    <AccordionButton
                                        bgColor="white"
                                        display="flex"
                                        p="15px 20px"
                                        justifyContent="space-between"
                                        _hover={{ bg: '#EBF4FF' }}
                                    >
                                        <Flex
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <Text
                                                color="dark_blue"
                                                fontSize="16px"
                                                fontWeight="500"
                                                lineHeight="100%"
                                                letterSpacing="-0.16px"
                                            >
                                                {truncate(modulo?.nombre, { length: 33 })}
                                            </Text>
                                        </Flex>


                                        <AccordionIcon />
                                    </AccordionButton>
                                </Flex>

                                <AccordionPanel
                                    display="flex"
                                    flexDir="column"
                                    p="0"
                                >
                                    <Flex
                                        flexDirection="column"
                                        w="100%"
                                    >
                                        {sortLeccionesByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                            <Box>
                                                <Flex
                                                    onClick={() => {
                                                        setLeccionSelelect(leccion)
                                                    }}
                                                    key={index}
                                                    justifyContent="space-between"
                                                    p="15px 20px"
                                                    cursor="pointer"
                                                    borderBottom={
                                                        (modulo?.lecciones?.length) === (index + 1)
                                                            ? "none"
                                                            : "1px solid #EAEAEA"
                                                    }
                                                    borderTop={
                                                        index === 0
                                                            ? "1px solid #EAEAEA"
                                                            : "none"
                                                    }
                                                    _hover={{ bg: '#EBF4FF' }}
                                                >

                                                    <Flex
                                                        color="#6E727E"
                                                        fontSize="14px"
                                                        fontWeight="500"
                                                        letterSpacing="-0.14px"
                                                        pl="5px"
                                                        alignItems="center"
                                                        gap="5px"
                                                    >
                                                        <Icon as={BsDot} />

                                                        {leccion?.nombre}
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        ))}
                                    </Flex>
                                </AccordionPanel>
                            </>
                        </AccordionItem>
                    ))}
                </Accordion>}
        </Flex>
    );
}