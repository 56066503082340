import { useNavigate } from "react-router-dom";
import { Button, Flex, Text, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { NewEmpresaModal } from "../../components/NewEmpresaModal";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { deleteEmpresa } from "../../../../shared/middlewares/empresas.middleware";
import { BiPlusCircle } from "react-icons/bi";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors, handleUnauthorized } from "../../../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { EmptyMessageType } from "../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { EmpresasColumns } from "./Columns/EmpresasColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";

export const EmpresasTable = () => {
    const client = useClient();
    const { logout } = useAuthContex();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.EMPRESAS });
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { data: empresas, loading, Refresh, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            ...query,
            interna: "false",
        }
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const empresaRef = useRef<EmpresasInt | null>(null)

    const onEventRemove = (event: EmpresasInt) => {
        empresaRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        deleteEmpresa({ empresaId: empresaRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Empresa eliminada correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!empresaRef.current?.nombre) return defaultWord

        const keyword = empresaRef.current?.nombre;

        return keyword
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Empresas
                </Text>
            }
        >
            <Flex
                direction="column"
                gap="20px"
                p="30px"
            >
                <Filters
                    setHasFilters={setHasFilters}
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                    showBody={false}
                    button={
                        <Button
                            onClick={onOpen}
                            bg="secondary"
                            color="#FFF"
                            p="8px 15px"
                            borderRadius="5px"
                            fontSize="14px"
                            fontWeight="500"
                            leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                            _hover={{ bg: "variant" }}
                        >
                            Nueva empresa
                        </Button>
                    }
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={empresas?.data || []}
                    columns={EmpresasColumns({ onEventRemove })}
                    isLoading={loading}
                    total={empresas?.meta?.total || 0}
                    emptyMessage={hasFilter ? EmptyMessageType.empresasFiltros : EmptyMessageType.empresas}
                />

                <NewEmpresaModal
                    isOpen={isOpen}
                    onClose={onClose}
                    setRefreshTable={Refresh}
                />

                <ModalDelete
                    isOpen={isOpenDelete}
                    onClose={onCloseDelete}
                    onDelete={onDelete}
                    label={getKeyWord("empresa")}
                />
            </Flex>
        </Topbar >
    )
};