import { Avatar, CircularProgress, CircularProgressLabel, Flex, Icon, Image, Progress, Text } from "@chakra-ui/react";
import imgPortadaTesting from "../../../assets/img/portadaCursoTesting.png"
import { HiOutlineBookOpen, HiOutlineBriefcase, HiOutlineChartSquareBar, HiOutlineChat, HiOutlineClock, HiOutlineDocumentDuplicate, HiOutlineUserGroup, HiTrendingUp } from "react-icons/hi";
import { GrCertificate } from "react-icons/gr";
import { BiNote } from "react-icons/bi";

interface Props {
    mainColor: string;
    secondaryColor: string;
    variantColor: string;
}

export default function CampusScreen({ mainColor, secondaryColor, variantColor }: Props) {
   
    return (
        <Flex
            bg="purewhite"
            maxH="750px"
            rounded="10px"
            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.20)"
            border="1px solid"
            borderColor="border"
        >
            <Flex
                h="100vh"
                maxH="750px"
                borderRight="1px solid"
                borderColor="border"
            >
                <Flex
                    direction="column"
                >
                    <Text
                        color="font"
                        ml="20px"
                        fontSize="14px"
                        mt="20px"
                    >
                        Logotipo
                    </Text>

                    <Flex
                        direction="column"
                        pr="20px"
                        w="100%"
                        mt="20px"
                    >
                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                        >
                            <Icon
                                as={HiTrendingUp}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Resumen
                            </Text>
                        </Flex>

                        <Flex
                            py="8px"
                            px="10px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            borderLeft="2px solid"
                            borderColor={mainColor}
                        >
                            <Icon
                                as={HiOutlineBookOpen}
                                boxSize="12px"
                                color={mainColor}
                            />

                            <Text
                                color={mainColor}
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Cursos
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineDocumentDuplicate}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Entregas
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineUserGroup}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Foro
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineChat}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Mensajería
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={GrCertificate}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Evaluaciones
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            px="12px"
                            gap="5px"
                            color="font"
                            direction="row"
                            alignItems="center"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={BiNote}
                                boxSize="12px"
                                color="font"
                            />

                            <Text
                                color="font"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Notas
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                direction="column"
            >
                <Flex
                    borderBottom="1px solid"
                    borderColor="border"
                >
                    <Text
                        fontWeight="500"
                        p="10px"
                        fontSize="14px"
                    >
                        Resumen
                    </Text>

                </Flex>

                <Flex
                    px="20px"
                    bg="#F8F8F9"
                    h="full"
                >
                    <Flex
                        direction="column"
                        pt="10px"
                    >
                        <Flex
                            direction="column"
                            gap="20px"
                        >
                            <Text
                                mt="10px"
                                fontSize="14px"
                            >
                                Cursos Activos
                            </Text>

                            <Flex
                                gap="20px"
                                direction="column"
                            >
                                <Flex
                                    direction="row"
                                    bg="purewhite"
                                    p="10px"
                                    rounded="10px"
                                    h="fit-content"
                                    gap="15px"
                                    border="1px solid"
                                    borderColor="border"
                                >
                                    <Flex
                                        w="180px"
                                        h="80px"
                                        overflow="hidden"
                                        rounded="5px"
                                    >
                                        <Image
                                            src={imgPortadaTesting}
                                            alt="portadaCursoTesting"
                                            objectFit="cover"
                                            w="100%"
                                            h="100%"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        h="100%"
                                    >
                                        <Flex
                                            alignItems="start"
                                            justifyContent="space-between"
                                            direction="column"
                                            gap="5px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                            >

                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="700"
                                                >
                                                    React
                                                </Text>

                                            </Flex>

                                            <Flex
                                                alignItems="center"
                                                gap="12px"
                                                w="100%"
                                            >
                                                <Text
                                                    color="font"
                                                    fontSize="10px"
                                                    fontWeight="400"
                                                >
                                                    60h
                                                </Text>

                                            </Flex>
                                        </Flex>

                                        <Text
                                            color={"#202020"}
                                            fontSize={{ xl: "8px", lg: "6px" }}
                                            fontWeight="400"
                                        >
                                            React JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario.
                                        </Text>

                                        <Progress
                                            value={75}
                                            h="7px"
                                            bg={variantColor}
                                            rounded="full"
                                            mt="10px"
                                            sx={{
                                                '& > div': {
                                                    background: mainColor
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex
                                    direction="row"
                                    bg="purewhite"
                                    p="10px"
                                    rounded="5px"
                                    h="fit-content"
                                    gap="15px"
                                    border="1px solid"
                                    borderColor="border"
                                >
                                    <Flex
                                        w="180px"
                                        h="80px"
                                        overflow="hidden"
                                        rounded="5px"
                                    >
                                        <Image
                                            src={imgPortadaTesting}
                                            alt="portadaCursoTesting"
                                            objectFit="cover"
                                            w="100%"
                                            h="100%"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        h="100%"
                                    >
                                        <Flex
                                            alignItems="start"
                                            justifyContent="space-between"
                                            direction="column"
                                            gap="5px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                            >

                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="700"
                                                >
                                                    Next JS
                                                </Text>

                                            </Flex>

                                            <Flex
                                                alignItems="center"
                                                gap="12px"
                                                w="100%"
                                            >
                                                <Text
                                                    color="font"
                                                    fontSize="10px"
                                                    fontWeight="400"
                                                >
                                                    30h
                                                </Text>

                                            </Flex>
                                        </Flex>

                                        <Text
                                            color={"#202020"}
                                            fontSize={{ xl: "8px", lg: "6px" }}
                                            fontWeight="400"
                                        >
                                            Next JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario.
                                        </Text>

                                        <Progress
                                            value={75}
                                            h="7px"
                                            bg={variantColor}
                                            rounded="full"
                                            mt="10px"
                                            colorScheme="blue"
                                            sx={{
                                                '& > div': {
                                                    background: mainColor
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        ml="20px"
                        mt="54px"
                    >
                        <Flex
                            direction="column"
                            gap="10px"
                        >
                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="600"
                            >
                                Tu progreso
                            </Text>

                            <Flex
                                direction="column"
                                gap="10px"
                                bg="purewhite"
                                p="10px"
                                rounded="10px"
                                border="1px solid"
                                borderColor="border"
                                h="fit"
                            >
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Flex
                                        gap="8px"
                                        alignItems="center"
                                    >
                                        <Flex
                                            bg={variantColor}
                                            p="8px"
                                            rounded="full"
                                        >
                                            <Icon
                                                boxSize="10px"
                                                color={mainColor}
                                                as={HiOutlineBookOpen}
                                            />
                                        </Flex>

                                        <Text
                                            fontSize="9px"
                                            fontWeight="400"
                                            color="font_variant"
                                        >
                                            Cursos Activos
                                        </Text>
                                    </Flex>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        1
                                    </Text>
                                </Flex>

                                <Flex
                                    h="1px"
                                    bg="border"
                                />

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Flex
                                        gap="8px"
                                        alignItems="center"
                                    >
                                        <Flex
                                            bg={variantColor}
                                            p="8px"
                                            rounded="full"
                                        >
                                            <Icon
                                                boxSize="10px"
                                                color={mainColor}
                                                as={HiOutlineBriefcase}
                                            />
                                        </Flex>

                                        <Text
                                            fontSize="9px"
                                            fontWeight="400"
                                            color="font_variant"
                                        >
                                            Cursos Terminados
                                        </Text>
                                    </Flex>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        2
                                    </Text>
                                </Flex>

                                <Flex
                                    h="1px"
                                    bg="border"
                                />

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Flex
                                        gap="8px"
                                        alignItems="center"
                                    >
                                        <Flex
                                            bg={variantColor}
                                            p="8px"
                                            rounded="full"
                                        >
                                            <Icon
                                                boxSize="10px"
                                                color={mainColor}
                                                as={HiOutlineClock}
                                            />
                                        </Flex>

                                        <Text
                                            fontSize="9px"
                                            fontWeight="400"
                                            color="font_variant"
                                            whiteSpace="nowrap"
                                        >
                                            Horas Dedicadas
                                        </Text>
                                    </Flex>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        20
                                    </Text>
                                </Flex>

                                <Flex
                                    h="1px"
                                    bg="border"
                                />

                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Flex
                                        gap="8px"
                                        alignItems="center"
                                    >
                                        <Flex
                                            bg={variantColor}
                                            p="8px"
                                            rounded="full"
                                        >
                                            <Icon
                                                boxSize="10px"
                                                color={mainColor}
                                                as={HiOutlineChartSquareBar}
                                            />
                                        </Flex>

                                        <Text
                                            fontSize="9px"
                                            fontWeight="400"
                                            color="font_variant"
                                        >
                                            Nota Media general
                                        </Text>
                                    </Flex>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        32
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="10px"
                                mt="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="600"
                                >
                                    Actividad foro
                                </Text>

                                <Flex
                                    gap="10px"
                                    bg="purewhite"
                                    px="10px"
                                    py="5px"
                                    rounded="10px"
                                    border="1px solid"
                                    borderColor="border"
                                    align="center"
                                >
                                    <Avatar
                                        boxSize="28px"
                                    />

                                    <Flex
                                        direction="column"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="9px"
                                            fontWeight="700"
                                        >
                                            Alumno
                                        </Text>

                                        <Text
                                            color="font"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Curso
                                        </Text>

                                        <Text
                                            color="#8C909C"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Como se crea el pro...
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    gap="10px"
                                    bg="purewhite"
                                    px="10px"
                                    py="5px"
                                    rounded="10px"
                                    border="1px solid"
                                    borderColor="border"
                                    align="center"
                                >
                                    <Avatar
                                        boxSize="28px"
                                    />

                                    <Flex
                                        direction="column"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="9px"
                                            fontWeight="700"
                                        >
                                            Alumno
                                        </Text>

                                        <Text
                                            color="font"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Curso
                                        </Text>

                                        <Text
                                            color="#8C909C"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Como se crea el pro...
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    gap="10px"
                                    bg="purewhite"
                                    px="10px"
                                    py="5px"
                                    rounded="10px"
                                    border="1px solid"
                                    borderColor="border"
                                    align="center"
                                >
                                    <Avatar
                                        boxSize="28px"
                                    />

                                    <Flex
                                        direction="column"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="9px"
                                            fontWeight="700"
                                        >
                                            Alumno
                                        </Text>

                                        <Text
                                            color="font"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Curso
                                        </Text>

                                        <Text
                                            color="#8C909C"
                                            fontSize="8px"
                                            fontWeight="400"
                                        >
                                            Como se crea el pro...
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

        </Flex>
    )
}