import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { Topbar } from "../../../../../shared/components/Topbar/Topbar"
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum"
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../../shared/context/auth.context"
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton"
import { BiPlusCircle } from "react-icons/bi"
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes"
import { useData } from "../../../../../shared/hooks/useData"
import { useClient } from "../../../../../shared/context/client.context"
import { NewExamenModal } from "../../../components/Modals/NewExamenModal"
import { Table } from "../../../../../shared/components/Table/Table"
import { Filters } from "../../../../../shared/components/Filters/Filters"
import { ExamenesColumns } from "./Columns/ExamenesColumns"
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession"
import { useRef } from "react"
import { ExamenInt } from "../../../../../interfaces/ExamenInt"
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete"
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes"
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors"
import { deleteExamen } from "../../../../../shared/middlewares/examenes.middleware"

export const ExamenesTable = () => {
    const toast = useToast();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.EXAMENES });
    const { user } = useAuthContex()
    const client = useClient()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: examenes, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.EXAMENES,
        query: query
    });

    const examenRef = useRef<ExamenInt | null>(null);

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const onEventRemove = (event: any) => {
        examenRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        deleteExamen({ examenId: examenRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Exámen eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!examenRef.current?.nombre) return defaultWord

        const keyword = examenRef.current?.nombre

        return keyword
    }

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Filters
                query={query}
                setQuery={setQuery}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE,
                        type: FiltrosTypes.INPUT,
                    }
                ]}
                showBody={false}
                button={
                    isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                        <CustomButton
                            button={{
                                label: "Nuevo examen",
                                onClick: onOpen,
                                isDisabled: false,
                                leftIcon: {
                                    as: BiPlusCircle,
                                    boxSize: "20px"
                                }
                            }}
                            tooltip={{
                                label: "Crear nuevo examen",
                                isDisabled: false
                            }}
                        />
                        : undefined
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={examenes?.data || []}
                columns={ExamenesColumns({ onEventRemove })}
                isLoading={loading}
                total={examenes?.meta?.total || 0}
            />

            <NewExamenModal
                isOpen={isOpen}
                onClose={onClose}
                Refresh={Refresh}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("exámen")}
            />
        </Flex>
    )
}