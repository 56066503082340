import { ComponentConfig, DropZone } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { TextVariable } from "../../CustomElements/Fields/TextVariable";

const getClassName = getClassNameFactory("Bucle", styles);

export type BucleProps = {
    content?: any;
    variable?: string;
};

export const Bucle: ComponentConfig<BucleProps> = {
    fields: {
        variable: {
            type: "custom",
            label: "Variable",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector(`textarea[name="${name}"]`) as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                        minHeight="40px"
                    />
                )
            }
        },
    },
    defaultProps: {
    },
    render: ({ variable }) => {
        return (
            <table
                className={getClassName()}
                width={"100%"}
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
            >
                <tbody>
                    <tr>
                        <td>
                            <span>
                                {"\n"} @each(item in {variable?.trim().replaceAll("{{", "").replaceAll("}}", "").trim()}) {"\n"}
                            </span>
                        </td>
                    </tr>

                    <tr >
                        <td
                            className={getClassName("cell")}
                        >
                            <DropZone
                                zone={`column-content`}
                                disallow={["Hero", "Logos", "Stats"]}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <span>
                                {"\n"} @end {"\n"}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table >
        );
    },
}; 