import { CustomTable } from "@imaginagroup/bit-components.ui.custom-table";
import { useState, useEffect, useRef } from 'react';
import { Flex, Image, Text } from "@chakra-ui/react";
import sindata from '../../../assets/img/NoContent5.png';
import "./Table.scss";

export interface ColumnProps {
    key: string;
    field: string;
    header: string | JSX.Element;
    sortable?: boolean;
    body?: (data: any) => JSX.Element | undefined;
    style?: React.CSSProperties;
    selectionMode?: "multiple" | "single" | undefined;
    expander?: (data: any) => boolean;
}

interface Props {
    columns: ColumnProps[];
    data: any[];
    total?: number;
    isLoading: boolean;
    selection?: any;
    onSelectionChange?: (e: any) => void;
    emptyMessage?: {
        title: string;
        src: string;
    };
    showHeaders?: boolean;
    showFooter?: boolean;
    autoWidth?: boolean;
    onRowClick?: (e: any) => void;
    lessWidthSize?: number;
    setQuery: (query: Record<string, string | number | string[]>) => void;
    query: Record<string, string | number | string[]>;
}

export const Table = ({
    columns,
    data,
    total,
    isLoading,
    selection,
    onSelectionChange = () => { },
    emptyMessage = {
        title: "No hay datos para mostrar",
        src: sindata
    },
    showHeaders,
    showFooter,
    autoWidth = true,
    onRowClick,
    lessWidthSize = 130,
    setQuery,
    query,
}: Props) => {
    /*
    **  Obtenemos el tamaño de la ventana para ajustar el ancho de la tabla
    */
    const isClient = typeof window === 'object';
    const lastWidth = useRef<any>();
    const getSize = () => isClient ? window.innerWidth : undefined;
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) return

        const handleResize = () => {
            if (window?.innerWidth !== lastWidth.current) {
                const width = getSize();
                lastWidth.current = width;
                setWindowSize(width)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    /*
    **  Obtenemos los valores de la query y los actualizamos para manejar la paginación y ordenamiento de la tabla
    */
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25>(10);

    useEffect(() => {
        let options = Object.fromEntries(Object.entries(query).filter(q => q[0] !== "page" && q[0] !== "limit" && q[0] !== "sortBy" && q[0] !== "order"))
        options = sortOrder === 1 ? { order: "asc", ...options } : sortOrder === -1 ? { order: "desc", ...options } : { ...options };
        options = sortBy !== undefined ? { sortBy: sortBy, ...options } : { ...options };

        setQuery({
            page: page,
            limit: limit,
            ...options
        });

    }, [page, sortBy, sortOrder, limit]);

    return (
        <Flex>
            <CustomTable
                columns={columns}
                data={data}
                total={total}
                isLoading={isLoading}
                tableWidth={autoWidth ? (lessWidthSize && windowSize ? `${windowSize - lessWidthSize}px` : '100%') : '100%'}
                selection={selection}
                onSelectionChange={onSelectionChange}
                page={page}
                setPage={setPage}
                sortBy={sortBy}
                setSortBy={setSortBy}
                order={sortOrder}
                setOrder={setSortOrder}
                limit={limit}
                setLimit={setLimit}
                emptyMessage={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                        py="100px"
                    >
                        <Image src={emptyMessage?.src} alt="Sindata" w="300px" h="250px" objectFit="contain" />

                        <Text
                            fontSize="30px"
                            color="font"
                            fontWeight="600"
                            maxW="300px"
                            textAlign="center"
                        >
                            {emptyMessage?.title}
                        </Text>
                    </Flex>
                }
                showHeaders={showHeaders}
                showFooter={showFooter}
                onRowClick={onRowClick ? onRowClick : undefined}
            />
        </Flex>
    )
}
