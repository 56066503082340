import { Flex, Spinner, Icon, Box, FormLabel, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { BiCopy } from "react-icons/bi";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


interface InformationInputProps {
    name: string;
    label?: string;
    noHead?: boolean;
    isDisabled?: boolean;
    defaultValue?: any;
    placeholder?: string;
    onChange?: (e: any) => void;
    isRequired?: boolean;
    updateValue: (e?: any) => any;
    onNullValue?: (e?: any) => any;
    allowToEdit?: boolean;
    notValidNumber?: () => void;
    onSuccessCopy?: () => void;
}

export function InformationPhoneInput({
    noHead,
    name,
    label,
    isDisabled = false,
    defaultValue,
    placeholder = '',
    onChange = () => { },
    updateValue,
    onNullValue,
    isRequired = false,
    notValidNumber = () => { },
    allowToEdit = true,
    onSuccessCopy = () => { },
}: InformationInputProps) {
    const [value, setValue] = useState<any>();
    const [timeoutkey, setTimeoutkey] = useState<any>();
    const [update, setUpdate] = useState<'idle' | 'editing' | 'loading'>('idle');
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        if (timeoutkey) {
            clearTimeout(timeoutkey);
            setTimeoutkey(undefined);
        }

        if (defaultValue) setValue(defaultValue);
    }, [defaultValue]);

    function onBlur() {
        function treatValue(_value: string) {
            if ((!_value || _value === '') && onNullValue) return onNullValue();
            else return _value;
        }

        if (value !== defaultValue) {
            if (value && !isValidPhoneNumber(value)) {
                return notValidNumber()
            }
            setUpdate('loading');

            updateValue({ [name]: treatValue(value) })
                .then(() => setUpdate('idle'))
                .catch((error: any) => {
                    console.error({ error });
                    setUpdate('idle');
                });
        }
    }

    const onClick = () => {
        if (value) {
            const tempInput = document.createElement('input');
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);

            onSuccessCopy();
        }
    };

    return (
        <Flex
            gap="20px"
            alignItems="center"
            w="100%"
            minW={"600px"}
        >
            {!noHead && (
                <Flex mt="9px" flex="1">
                    <FormLabel
                        display="flex"
                        gap="3px"
                        color="font"
                        fontSize="12px"
                        fontWeight="600"
                        letterSpacing="0.24px"
                        textTransform="uppercase"
                    >
                        {label}
                        {isRequired && <Box>*</Box>}

                        {update === 'editing' ? (
                            <Icon as={AiOutlineCloudSync} ml="2" boxSize="14px" />
                        ) : update === 'loading' ? (
                            <Spinner ml="2" boxSize="14px" />
                        ) : null}
                    </FormLabel>
                </Flex>
            )}

            <Flex
                direction="column"
                gap="5px"
                flex="1"
                pos={"relative"}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                transition={"all 0.3s ease"}
                opacity={isDisabled || !allowToEdit ? 0.5 : 1}
            >
                <PhoneInput
                    placeholder={placeholder}
                    defaultCountry="ES"
                    value={value}
                    onChange={(e) => {
                        setValue(e);
                        onChange(e);
                    }}
                    disabled={isDisabled || !allowToEdit}
                    onBlur={onBlur}
                    style={{
                        color: "#3D3F65",
                        fontSize: "14px",
                        fontWeight: "400",
                        border: "1px solid",
                        borderColor: "#EAEAEA",
                        borderRadius: "6px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "9px",
                        paddingBottom: "9px",
                    }}
                />


                <Flex
                    display={(hover && value) ? "flex" : "none"}
                    transition={"all 0.3s ease"}
                    position="absolute"
                    bottom="12px"
                    top="12px"
                    right="10px"
                >
                    <Icon as={BiCopy} boxSize="20px" cursor="copy" onClick={onClick} />
                </Flex>
            </Flex>
        </Flex >
    );
}
