import { Badge, Box, Flex, Icon, Image, Text, useToast } from "@chakra-ui/react";
import { UserInt } from "../../../../interfaces/UserInt";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { BiPencil } from "react-icons/bi";
import { useState } from "react";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import { HorariosItem } from "../HorariosItem";
import { DiaTypeEnum } from "../../../../shared/utils/Types/HorarioLaboral";
import PerfilAlumnoSkeleton from "../utils/PerfilAlumnoSkeleton";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { informationSelectStyles } from "../../../../ui/informationSelectStyles";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { InformationPhoneInput } from "../../../../shared/components/Information/InformationPhoneInput";

interface Props {
    alumno: UserInt | undefined;
    updateValue: (action: any) => void;
    loading: boolean;
}

export const PerfilAdministrador = ({ alumno, updateValue, loading }: Props) => {
    const toast = useToast();
    const { user } = useAuthContex();
    const moduloFUNDAE = useModuloFundae();
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editApellidos, setEditApellidos] = useState<boolean>(false)
    const [editTelefono, setEditTelefono] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<boolean>(false)

    return (
        loading ?
            <PerfilAlumnoSkeleton moduloFUNDAE={moduloFUNDAE} />

            :

            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos del alumno"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>

                                <Badge
                                    color={alumno?.activo ? "success_main" : "font"}
                                    bg={alumno?.activo ? "success_bg" : "light_grey"}
                                    borderColor={alumno?.activo ? "success_variant" : "light_grey"}
                                    borderWidth="1px"
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    p="5px 8px"
                                >
                                    {
                                        alumno?.activo ? "Activo" : "Inactivo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={alumno?.nombre ? alumno?.nombre : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditApellidos(true)}
                                onMouseLeave={() => setEditApellidos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="apellidos"
                                    label="Apellidos"
                                    defaultValue={alumno?.apellidos ? alumno?.apellidos : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editApellidos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditTelefono(true)}
                                onMouseLeave={() => setEditTelefono(false)}
                                gap="10px"
                            >
                                <InformationPhoneInput
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={alumno?.telefono ? alumno?.telefono : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                    notValidNumber={() => toastNotify(toast, StatusEnumTypes.ERROR, "El numero no tiene un formato valido.")}
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                />

                                <Flex boxSize="20px">
                                    {editTelefono && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <InformationBox title="Credenciales de acceso">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditEmail(true)}
                                onMouseLeave={() => setEditEmail(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={alumno?.email ? alumno?.email : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editEmail && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="username"
                                    label="Username"
                                    defaultValue={alumno?.username ? alumno?.username : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px" />
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1" gap="20px" />
            </Flex >
    );
}