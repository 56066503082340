import { useQuery } from "@tanstack/react-query"
import queryString from 'query-string';
import _axios, { ClientInt } from "../services/http.service";
import { EndpointTypes } from "../utils/Types/EndpointTypes";
import { getData } from "../middlewares/middlewares";
import tenantInstance from "../services/tenantInstance.service";
import { omit } from "lodash";

const tenant = await tenantInstance();

type AdonisErrorInt = {
    field: string;
    message: string;
    rule: string;
};

declare module '@tanstack/react-query' {
    interface Register {
        defaultError: { response: { status: number, data: { errors: any[] } } };
    }
}
interface UseDataIdProps {
    id: string | undefined;
    client: ClientInt;
    endpoint: EndpointTypes;
    specificQuery?: Record<string, string | number | string[]> | undefined;
    ignoreRequest?: boolean;
    query?: Record<string, string | string[] | number>;
}

export const useDataId = ({
    id,
    client,
    endpoint,
    specificQuery,
    ignoreRequest = false,
    query = {},
}: UseDataIdProps) => {
    const cleanQuery = omit(query, ['tabIndex']);

    let finalEndpoint = tenant + "/" + client + endpoint + "/" + id;

    if (specificQuery) finalEndpoint += `?${queryString.stringify({ ...specificQuery, ...cleanQuery })}`;
    else if (Object.keys(cleanQuery).length) {
        finalEndpoint += `?${queryString.stringify(cleanQuery)}`;
    }

    const queryKey = [endpoint, client, id, { ...query, ...specificQuery }];

    const {
        data,
        isLoading,
        error,
        refetch,
    } = useQuery({
        queryKey,
        queryFn: () => getData(finalEndpoint),
        enabled: !!id && !ignoreRequest,
        refetchOnWindowFocus: true,
        retry: 1,
        staleTime: 1000 * 60 * 5,
    })

    return {
        data: data?.data?.data,
        loading: isLoading,
        error: error,
        Refresh: refetch,
        status: !error ? data?.status : error?.response?.status,
        errorsList: error?.response?.data?.errors as AdonisErrorInt[],
    }
}
