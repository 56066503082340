import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../../shared/context/client.context";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { MatriculasInt } from "../../../../../interfaces/MatriculasInt";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { RedirectModal } from "../../../components/RedirectModal";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { Table } from "../../../../../shared/components/Table/Table";
import { AlumnosInspectorColumns } from "./Columns/AlumnosInpectorColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

export const AlumnosInspector = () => {
    const client = useClient();
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.INSPECTOR_ALUMNOS });
    const { onOpen: onOpenRedirect, isOpen: isOpenRedirect, onClose: onCloseRedirect } = useDisclosure();
    const { data: usuarios, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.USUARIOS,
        query: query,
    });
    const matriculasRef = useRef<MatriculasInt[]>([]);

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const onRowClick = ({ data }: any) => {
        if (data?.matriculas && data?.matriculas?.length > 1) {
            matriculasRef.current = data?.matriculas;
            onOpenRedirect()
        } else return
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Alumnos
                </Text>
            }
        >
        <Flex p="20px" direction="column" gap="20px">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={false}
                filterElements={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={usuarios?.data || []}
                columns={AlumnosInspectorColumns()}
                isLoading={loading}
                total={usuarios?.meta?.total || 0}
                onRowClick={onRowClick}
            />

            <RedirectModal
                isOpen={isOpenRedirect}
                onClose={onCloseRedirect}
                matriculas={matriculasRef.current}
            />
        </Flex>
        </Topbar>
    )
}