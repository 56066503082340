import { Badge, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { BiError, BiErrorAlt, BiHelpCircle } from "react-icons/bi";
import { CursosInt } from "../../../interfaces/CursosInt";

interface Puntuacion {
    alert: 'Critical' | 'Warning' | 'Notice';
    description: string;
    isActive: boolean;
}

interface Props {
    curso: CursosInt;
}

export const AlertsCurso = ({ curso }: Props) => {

    const auditGuiaDidactiva = (curso: CursosInt, type: "dirigido" | "datos" | "requisitos" | "objetivos") => {
        if (
            curso?.infoGuia &&
            (
                curso?.infoGuia[type] === null ||
                curso?.infoGuia[type] === "" ||
                curso?.infoGuia[type] === "<p><br></p>"
            )
        ) return true

        return false
    }

    const auditGrupo = (curso: CursosInt) => {
        const reduceChecks = (alert: 'Critical' | 'Warning' | 'Notice') =>
            getGrupoCheck(curso)
                ?.filter((c) => c.isActive && c.alert === alert)
                ?.reduce(
                    (acc, c) => {
                        acc.total++;
                        acc.tooltipText += '• ' + c.description + "=";

                        return acc;
                    },
                    { total: 0, tooltipText: '' }
                );

        return [
            { type: 'Critical', style: { backgroundColor: "#FEEAED", color: "#F44261" }, ...reduceChecks('Critical') },
            { type: 'Warning', style: { backgroundColor: '#F0E2CD', color: '#FD891F' }, ...reduceChecks('Warning') },
            { type: 'Notice', style: { backgroundColor: '#C7C8CD', color: '#FFFF' }, ...reduceChecks('Notice') },
        ].filter((x) => x.total > 0);
    };

    const getGrupoCheck = (curso: CursosInt): Puntuacion[] => {
        return [
            {
                alert: "Critical",
                description: "Curso sin inspectores",
                isActive: !curso?.meta?.totalInspectores || curso?.meta?.totalInspectores === "0" ? true : false
            },
            {
                alert: "Critical",
                description: "Curso inactivo para crear grupos",
                isActive: curso?.activo === false
            },
            {
                alert: "Critical",
                description: "Curso sin guia didactica",
                isActive: curso?.infoGuia === null
            },
            {
                alert: "Critical",
                description: "El tutor del curso no tiene CV",
                isActive: (curso?.meta?.totalTutores && curso?.meta?.totalTutores !== "0") && (curso?.meta?.totalTutoresSinCv !== "0")
                    ? true
                    : false
            },
            {
                alert: "Warning",
                description: "Curso sin tutores",
                isActive: !curso?.meta?.totalTutores || curso?.meta?.totalTutores === "0" ? true : false
            },
            {
                alert: "Warning",
                description: "Curso sin portada",
                isActive: !curso?.imagen || !curso?.imagen?.url
            },
            {
                alert: "Warning",
                description: "Curso sin campo datos en guia didáctica",
                isActive: auditGuiaDidactiva(curso, "datos")
            },
            {
                alert: "Warning",
                description: "Curso sin campo dirigido en guia didáctica",
                isActive: auditGuiaDidactiva(curso, "dirigido")
            },
            {
                alert: "Warning",
                description: "Curso sin campo objetivos en guia didáctica",
                isActive: auditGuiaDidactiva(curso, "objetivos")
            },
            {
                alert: "Warning",
                description: "Curso sin campo requisitos en guia didáctica",
                isActive: auditGuiaDidactiva(curso, "requisitos")
            },
            {
                alert: "Notice",
                description: "Curso asignado a plataforma",
                isActive: curso?.tutorizado === false
            }
        ];
    };

    const formatBadgeText = (text: string) => {
        const list = text.split("=")

        return (
            <Flex
                direction="column"
            >
                {list?.map((item: string, index: number) => (
                    <Text
                        key={index}
                    >
                        {item}
                    </Text>
                ))}
            </Flex>
        )
    }

    return (
        auditGrupo(curso).map((badge: any, index: number) => (
            <Tooltip
                aria-label="A tooltip"
                className="content-tooltip"
                label={formatBadgeText(badge.tooltipText)}
                key={index}
            >
                <Badge
                    mr="5px"
                    rounded="5px"
                    p="3px 5px"
                    style={badge.style}
                    _last={{ mr: "0px" }}
                >
                    <Flex
                        gap="3px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Icon
                            as={
                                badge.type === "Critical"
                                    ? BiErrorAlt
                                    : badge.type === "Warning"
                                        ? BiError
                                        : BiHelpCircle
                            }
                            boxSize="15px"
                        />

                        <Text
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {badge.total}
                        </Text>
                    </Flex>
                </Badge>
            </Tooltip>
        ))
    );
}