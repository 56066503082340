import { Badge, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { BiError, BiErrorAlt, BiHelpCircle } from "react-icons/bi";
import { GruposInt } from "../../../interfaces/GruposInt";
import { useEffect, useState } from "react";

interface Puntuacion {
    alert: 'Critical' | 'Warning' | 'Notice';
    description: string;
    isActive: boolean;
}

interface Props {
    grupo: GruposInt
}

export const AlertsGrupo = ({ grupo }: Props) => {
    
    const auditGrupo = (grupo: GruposInt) => {
        const reduceChecks = (alert: 'Critical' | 'Warning' | 'Notice') =>
            getGrupoCheck(grupo)
                ?.filter((c) => c.isActive && c.alert === alert)
                ?.reduce(
                    (acc, c) => {
                        acc.total++;
                        acc.tooltipText += '• ' + c.description + "=";

                        return acc;
                    },
                    { total: 0, tooltipText: '' }
                );

        return [
            { type: 'Critical', style: { backgroundColor: "#FEEAED", color: "#F44261" }, ...reduceChecks('Critical') },
            { type: 'Warning', style: { backgroundColor: '#F0E2CD', color: '#FD891F' }, ...reduceChecks('Warning') },
            { type: 'Notice', style: { backgroundColor: '#C7C8CD', color: '#FFFF' }, ...reduceChecks('Notice') },
        ].filter((x) => x.total > 0);
    };

    const getGrupoCheck = (grupo: GruposInt): Puntuacion[] => {
        return [
            {
                alert: "Critical",
                description: "Falta completar ID FUNDAE",
                isActive: !grupo?.idFundae
            },
            {
                alert: "Critical",
                description: "Falta completar Acción formativa",
                isActive: !grupo?.accionFormativa
            },
            {
                alert: "Critical",
                description: "Falta completar Número de expediente",
                isActive: !grupo?.numeroExpediente
            },
            {
                alert: "Critical",
                description: "Falta completar código grupo",
                isActive: !grupo?.codigoGrupo
            },
            {
                alert: "Critical",
                description: "Falta asignar un tutor",
                isActive: Number(grupo?.meta?.totalTutores) == 0
            },
            {
                alert: "Warning",
                description: "El grupo tiene más de un tutor",
                isActive: Number(grupo?.meta?.totalTutores) > 1 ? true : false
            },
            {
                alert: "Warning",
                description: "El grupo tiene más de una empresa",
                isActive: grupo?.empresas && grupo?.empresas?.length > 1 ? true : false
            },
            {
                alert: "Warning",
                description: "El grupo no tiene supervisor empresa asociado",
                isActive: Number(grupo?.meta?.totalSupervisores) == 0 ? true : false
            },
            {
                alert: "Warning",
                description: "El grupo no tiene alumnos matriculados",
                isActive: Number(grupo?.meta?.totalMatriculas) == 0 ? true : false
            }
        ];
    };

    const formatBadgeText = (text: string) => {
        const list = text.split("=")

        return(
            <Flex
                direction="column"
            >
                {list?.map((item: string, index: number) => (
                    <Text
                        key={index}
                    >
                        {item}
                    </Text>
                ))}
            </Flex>
        )
    }

    return (
        auditGrupo(grupo).map((badge: any, index: number) => (
            <Tooltip
                aria-label="A tooltip"
                className="content-tooltip"
                label={formatBadgeText(badge.tooltipText)}
                key={index}
            >
                <Badge
                    mr="5px"
                    rounded="5px"
                    p="3px 5px"
                    style={badge.style}
                    _last={{ mr: "0px" }}
                >
                    <Flex  
                        gap="3px"
                        alignItems="center" 
                        justifyContent="center"
                    >
                        <Icon
                            as={
                                badge.type === "Critical"
                                    ? BiErrorAlt
                                    : badge.type === "Warning"
                                        ? BiError
                                        : BiHelpCircle
                            }
                            boxSize="15px"
                        />
                        
                        <Text
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {badge.total}
                        </Text>
                    </Flex>
                </Badge>
            </Tooltip>
        ))
    );
}