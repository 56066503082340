import { Button, Flex, Icon, Text, useToast, Tooltip } from "@chakra-ui/react"
import { useClient } from "../../../../shared/context/client.context";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { Table } from "../../../../shared/components/Table/Table";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";
import { AsignacionesPendientesColumns } from "./Colums/AsignacionesPendientesColumns";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { useEffect, useState } from "react";
import { addLiquidacion } from "../../../../shared/middlewares/facturacion.middlewares";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { useNavigate } from "react-router-dom";
import { RiBillLine } from "react-icons/ri";

export const AsignacionesPendientesTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ASIGNACIONES_PENDIENTES });
    const client = useClient();
    const toast = useToast();
    const navigate = useNavigate();
    const [selectedAsignaciones, setSelectedAsignaciones] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [availableSelect, setAvailableSelect] = useState<boolean>(false)
    const { data, loading } = useData({
        client: client,
        endpoint: EndpointTypes.ASIGNACIONES,
        specificQuery: {
            conLiquidacion: "false",
            sortBy: "matriculas.fecha_inicio",
            order: "asc"
        },
        query: query
    });

    const crearLiquidacion = () => {
        if (!selectedAsignaciones) return;
        setIsLoading(true)

        const asignacionesList: string[] = []

        selectedAsignaciones?.map((asignacion: any) => {
            return asignacionesList.push(asignacion?.id)
        })

        const liquidacion = {
            userId: selectedAsignaciones[0]?.user?.id,
            asignaciones: asignacionesList,
            empresaFacturaId: selectedAsignaciones[0]?.empresaFacturaId
        }

        addLiquidacion({
            data: liquidacion,
            client: client
        })
            .then((response: any) => {
                const id = response?.data?.data?.id
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Se ha creado liquidacion")
                setAvailableSelect(false)
                setSelectedAsignaciones([])

                navigate(`/facturacion/liquidaciones/${id}`)
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al crear liquidacion"))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (query?.tutor) delete query.tutor
        if (query?.asignacionEmpresaFactura) delete query.asignacionEmpresaFactura

        setQuery(query)
    }, [])

    useEffect(() => {
        if (query?.tutor && query?.asignacionEmpresaFactura) {
            setAvailableSelect(true)
        } else {
            setSelectedAsignaciones([])
            setAvailableSelect(false)
        }

    }, [query])

    return (
        <Flex direction="column" w="100%" gap="5px">
            <Text
                color="font"
                fontWeight="400"
                fontSize="13px"
                pl="5px"
            >
                Para seleccionar filas y crear liquidaciones debes filtrar por tutor y formación
            </Text>

            <Flex direction="column" gap="20px" w="100%">
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    bgSelects="purewhite"
                    filterHeaders={[
                        {
                            name: FiltrosNames.TUTOR,
                            type: FiltrosTypes.ASYNC_SELECT,
                            label: "Tutor"
                        },
                        {
                            name: FiltrosNames.ASIGNACION_EMPRESA,
                            type: FiltrosTypes.SELECT,
                            label: "Empresa"
                        },
                        {
                            name: FiltrosNames.ESTADO_GRUPO,
                            type: FiltrosTypes.SELECT,
                            label: "Estado"
                        }
                    ]}
                    filterElements={[
                        {
                            name: FiltrosNames.NOMBRE_EMAIL,
                            type: FiltrosTypes.INPUT,
                        },
                        {
                            name: FiltrosNames.CURSO,
                            type: FiltrosTypes.ASYNC_SELECT,
                        },
                        {
                            name: FiltrosNames.FECHA_INICIO_INF,
                            type: FiltrosTypes.DATE,
                        },
                        {
                            name: FiltrosNames.FECHA_INICIO_SUP,
                            type: FiltrosTypes.DATE,
                        },
                        {
                            name: FiltrosNames.FECHA_FIN_INF,
                            type: FiltrosTypes.DATE,
                        },
                        {
                            name: FiltrosNames.FECHA_FIN_SUP,
                            type: FiltrosTypes.DATE,
                        }
                    ]}
                    button={
                        <Tooltip
                            label={"Debes filtrar por tutor y formación para crear liquidaciones"}
                            hasArrow
                            placement="bottom"
                            rounded="4px"
                            px="10px"
                            py="5px"
                            textAlign="center"
                        >
                            <Button
                                onClick={crearLiquidacion}
                                isLoading={isLoading}
                                bg="black"
                                w="530px"
                                mt="auto"
                                color="#FFF"
                                p="8px 15px"
                                borderRadius="5px"
                                fontSize="14px"
                                fontWeight="500"
                                leftIcon={<Icon as={RiBillLine} boxSize="20px" />}
                                _hover={{ bg: "light_grey", color: "black" }}
                                isDisabled={!availableSelect || selectedAsignaciones?.length == 0}
                            >
                                Crear Liquidación
                            </Button>
                        </Tooltip>
                    }
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={data?.data || []}
                    columns={AsignacionesPendientesColumns({ availableSelect })}
                    isLoading={loading}
                    total={data?.meta?.total || 0}
                    selection={selectedAsignaciones}
                    onSelectionChange={(e: any) => setSelectedAsignaciones(e?.value)}
                    onRowClick={({ data }) => navigate(`/usuarios/tutores/${data?.user?.id}/perfil`)}
                />
            </Flex>
        </Flex>
    )
}