export const getFields = ({
    isTutorUnico,
    isEmpresaUnica,
    tutores,
    empresas = [],
}: {
    isTutorUnico: boolean | undefined;
    isEmpresaUnica: boolean | undefined;
    tutores: { value: string, label: string }[],
    empresas: { value: string, label: string }[],
}) => {
    const fields = [
        {
            label: "Nombre",
            key: "nombre",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Apellidos",
            key: "apellidos",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Email",
            key: "email",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Teléfono",
            key: "telefono",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
        {
            label: "Nif o Nie",
            key: "dni",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
    ]

    if (!isTutorUnico) {
        fields.push({
            label: "Tutor",
            key: "tutorId",
            fieldType: {
                type: "select",
                options: tutores
            } as { type: "select"; options: any[] },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El tutor es obligatorio",
                    level: "error",
                },
            ],
        })
    }

    if (!isEmpresaUnica) {
        fields.push(
            {
                label: "Empresa",
                key: "empresaId",
                fieldType: {
                    type: "select",
                    options: empresas
                } as { type: "select"; options: any[] },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "La empresa es obligatoria",
                        level: "error",
                    },
                ],
            })
    }

    return fields;
}