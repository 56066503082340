export const htmlCustomDiploma = `<!DOCTYPE html>
        <html lang="es">
        <head>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.min.css" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            
                <style>
                    @page {
                        size: A4 landscape;
                        margin: 0;
                    }
                    
                    body {
                        overflow-x: hidden;
                        width: 1123px;
                        height: 794px;
                    }

                    /* Aplicar Poppins a todos los elementos de texto */
                    body, p, h1, h2, h3, h4, h5, h6, span, div {
                        font-family: 'Poppins', sans-serif !important;
                    }
                </style>
            
        </head>

        <body>
                <div style="height:calc(790px - 40px - 2px);width:calc(1123px - 40px - 2px);padding:20px;background-color:transparent;margin:0 auto">
                    <div style="margin:0 auto;height:100%;width:100%;border:1px solid;border-color:#343a40;border-radius:30px">
                        <table class="_EmailColumn_1lelv_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent;border:none;border-radius:0;box-shadow:none">
                            <tbody>
                                <tr>
                                    <td class="_EmailColumn-cell_1lelv_7" style="padding:0;height:auto">
                                        <div style="height:48px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:20px;font-weight:500;margin-left:auto;margin-right:auto;justify-content:center">Diploma acreditativo de</span>
                                        <span style="color:#007bff;display:flex;text-align:center;width:100%;font-size:64px;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">{{ curso.nombre }}</span>
                                        <div style="height:40px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:28px;font-weight:500;margin-left:auto;margin-right:auto;justify-content:center">{{ user.nombre }} {{ user.apellidos }}</span>
                                        <div style="height:16px;width:100%;max-width:20%;position:relative;margin:0 auto">
                                            <div style="position:absolute;top:50%;left:0;right:0;border-top:1px solid #B9B9B9"></div>
                                        </div>
                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">NIF: {{ user.dni }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="height:64px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                        <table class="_EmailColumn_1lelv_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent;border:none;border-radius:0;box-shadow:none">
                            <tbody>
                                <tr>
                                    <td class="_EmailColumn-cell_1lelv_7" style="padding:0;height:auto">
                                        <table class="_EmailRow_jyq0b_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent">
                                            <tbody>
                                                <tr>
                                                    <td class="_EmailRow-cell_jyq0b_7" width="33.333333333333336%" valign="top" style="padding-left:0">
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Modalidad: {{ grupo.modalidad }}</span>
                                                        <div style="height:24px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Duración: {{ curso.duracion }}</span>
                                                    </td>
                                                    <td class="_EmailRow-cell_jyq0b_7" width="33.333333333333336%" valign="top" style="padding-left:16px">
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Fecha de inicio: {{ grupo.fechaInicioString }}</span>
                                                        <div style="height:24px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Fecha de fin: {{ grupo.fechaFinString }}</span>
                                                    </td>
                                                    <td class="_EmailRow-cell_jyq0b_7" width="33.333333333333336%" valign="top" style="padding-left:16px">
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Código Grupo: {{ grupo .codigoGrupo }}</span>
                                                        <div style="height:24px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Código AF: {{ grupo.accionFormativa }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="height:48px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Al servicio de la empresa: {{ empresa.nombre }} con CIF:  {{ empresa.cif }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="height:64px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                        <table class="_EmailRow_jyq0b_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent">
                            <tbody>
                                <tr>
                                    <td class="_EmailRow-cell_jyq0b_7" width="50%" valign="top" style="padding-left:0">
                                        <table class="_EmailColumn_1lelv_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent;border:none;border-radius:0;box-shadow:none">
                                            <tbody>
                                                <tr>
                                                    <td class="_EmailColumn-cell_1lelv_7" style="padding:0;height:auto">
                                                        <div class="_Section_1rmej_1" style="padding-top:0px;padding-bottom:0px">
                                                            <div class="_Section-inner_1rmej_13" style="max-width:1280px">
                                                                <div style="width:100%">
                                                                    <img alt="Firma" src="{{ empresaInterna.imagen.url }}" style="object-fit:cover;height:auto;width:40%;max-width:40%;border-radius:none;margin-left:auto;margin-right:auto"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style="height:24px;width:100%;max-width:200px;position:relative;margin:0 auto">
                                                            <div style="position:absolute;top:50%;left:0;right:0;border-top:1px solid #B9B9B9"></div>
                                                        </div>
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:14px;font-weight:400;max-width:200px;margin-left:auto;margin-right:auto;justify-content:center">Firma y sello de la entidad responsable de impartir la formación</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="_EmailRow-cell_jyq0b_7" width="50%" valign="top" style="padding-left:16px">
                                        <div style="height:56px;width:100%;max-width:100%;position:relative;margin:0 auto"></div>
                                        <table class="_EmailColumn_1lelv_1" width="100%" cellPadding="0" cellSpacing="0" role="presentation" style="background-color:transparent;border:none;border-radius:0;box-shadow:none">
                                            <tbody>
                                                <tr>
                                                    <td class="_EmailColumn-cell_1lelv_7" style="padding:0;height:auto">
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:m;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">12/11/2024</span>
                                                        <div style="height:24px;width:100%;max-width:200px;position:relative;margin:0 auto">
                                                            <div style="position:absolute;top:50%;left:0;right:0;border-top:1px solid #B9B9B9"></div>
                                                        </div>
                                                        <span style="color:default;display:flex;text-align:center;width:100%;font-size:14px;font-weight:400;margin-left:auto;margin-right:auto;justify-content:center">Fecha de expedición</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        </body>
        </html>
        
`

export const jsonPuckCustomDiploma = {
    "root": {
        "props": {
            "title": "diplomaGeneral"
        }
    },
    "content": [
        {
            "type": "ContenedorPrincipal",
            "props": {
                "backgroundColor": "transparent",
                "border": "1px solid",
                "borderColor": "#343a40",
                "borderRadius": "30px",
                "padding": "20px",
                "align": "center",
                "backgroundImage": null,
                "id": "ContenedorPrincipal-bae8f6d0-5566-4e8a-8509-f71003bde9b7"
            }
        }
    ],
    "zones": {
        "Columna-d13733c1-25e4-4946-aa3c-4e61457f95ff:column-content-0": [
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "48px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-abf5f9d7-0537-42d4-af23-3ca49aa35889"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Diploma acreditativo de",
                    "padding": "24px",
                    "size": "20px",
                    "color": "default",
                    "id": "VarianteTexto-6de96538-1f1c-4e83-925d-1741165682ee",
                    "weight": "medium"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "{{ curso.nombre }}",
                    "padding": "24px",
                    "size": "64px",
                    "color": "#007bff",
                    "id": "VarianteTexto-d3a61112-54fc-41ec-877b-d353e5dd1fb2"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "40px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-07683f73-74a3-4970-a5d9-c60891143e98"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "{{ user.nombre }} {{ user.apellidos }}",
                    "padding": "34px",
                    "size": "28px",
                    "color": "default",
                    "id": "VarianteTexto-f4abc06f-4514-41ee-97dd-935106a85edc",
                    "weight": "medium"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "16px",
                    "hasDivider": true,
                    "maxWidth": "20%",
                    "id": "EspaciosVerticales-7d2936a5-c422-4ee2-a392-ba25e4916bab"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "NIF: {{ user.dni }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-95f470f1-3000-40b1-a3cc-3660cf67534e"
                }
            }
        ],
        "Fila-be439866-fe20-462c-b2c6-189449213bfb:row-content-0": [
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Modalidad: {{ grupo.modalidad }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-b0df9f14-32b4-4cd1-900a-5cd256f20e42"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "24px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-53ab6b25-b385-425d-855b-88d44566ed44"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Duración: {{ curso.duracion }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-d14666f7-6cf0-44c0-8f59-e3e07288d6c2"
                }
            }
        ],
        "Fila-be439866-fe20-462c-b2c6-189449213bfb:row-content-1": [
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Fecha de inicio: {{ grupo.fechaInicioString }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-8afb1aeb-362d-4bfb-99ed-95569d3302b5"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "24px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-e67af44d-0b1c-4b76-b9bb-a2b3f6136892"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Fecha de fin: {{ grupo.fechaFinString }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-8a17d37d-924d-42c4-9e97-3fbfff98ed55"
                }
            }
        ],
        "Fila-be439866-fe20-462c-b2c6-189449213bfb:row-content-2": [
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Código Grupo: {{ grupo .codigoGrupo }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-d7e5b737-3f41-4a9a-8c20-c64411698f62"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "24px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-91e86ba9-63c4-4bac-9a83-85aee985841c"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Código AF: {{ grupo.accionFormativa }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-71518037-740b-49be-869f-9c5eb7bdcdeb"
                }
            }
        ],
        "Columna-e59a669c-49e8-4fad-83b2-388fafecbfe0:column-content-0": [
            {
                "type": "Fila",
                "props": {
                    "items": [{}, {}, {}],
                    "backgroundColor": "transparent",
                    "spacing": "16px",
                    "id": "Fila-be439866-fe20-462c-b2c6-189449213bfb"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "48px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-f2f6322b-ed00-48d2-86cd-ea5e553c507d"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Al servicio de la empresa: {{ empresa.nombre }} con CIF:  {{ empresa.cif }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-5101c0ba-614a-472c-8366-1a62bc7d8198"
                }
            }
        ],
        "Columna-c5791aa9-d7b4-40a3-9aef-ca0c75be005d:column-content-0": [
            {
                "type": "Imagen",
                "props": {
                    "alt": "Firma",
                    "imageUrl": "{{ empresaInterna.imagen.url }}",
                    "width": "40%",
                    "height": "auto",
                    "rounded": "none",
                    "shadow": "none",
                    "objectFit": "cover",
                    "alignContent": "center",
                    "id": "Imagen-e384d644-6e72-4bed-a058-a2de3bc62e68"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "24px",
                    "hasDivider": true,
                    "maxWidth": "200px",
                    "id": "EspaciosVerticales-f020d0e1-8e41-472d-aa09-a8c1ed238f41"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Firma y sello de la entidad responsable de impartir la formación",
                    "padding": "24px",
                    "size": "14px",
                    "color": "default",
                    "id": "VarianteTexto-5bad9b9c-673d-45e5-9581-5f09c1c1499b",
                    "maxWidth": "200px"
                }
            }
        ],
        "Fila-a7fb5938-5659-4820-87d8-30d4311e1688:row-content-0": [
            {
                "type": "Columna",
                "props": {
                    "width": "100%",
                    "backgroundColor": "transparent",
                    "border": "none",
                    "borderRadius": "0",
                    "padding": "10px",
                    "boxShadow": "none",
                    "items": [
                        {
                            "height": "auto",
                            "padding": "0"
                        }
                    ],
                    "id": "Columna-c5791aa9-d7b4-40a3-9aef-ca0c75be005d"
                }
            }
        ],
        "Columna-486efae0-4f15-4b5e-8591-e95704bf111a:column-content-0": [
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "{{ fechaExpedicion }}",
                    "padding": "24px",
                    "size": "m",
                    "color": "default",
                    "id": "VarianteTexto-8cf93399-12a5-4858-8ae7-5b3c89774908"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "24px",
                    "hasDivider": true,
                    "maxWidth": "200px",
                    "id": "EspaciosVerticales-428743d5-478c-43f5-a6b8-dbade9489942"
                }
            },
            {
                "type": "VarianteTexto",
                "props": {
                    "align": "center",
                    "text": "Fecha de expedición",
                    "padding": "24px",
                    "size": "14px",
                    "color": "default",
                    "id": "VarianteTexto-f19ea7e8-7b7c-4d29-8e6f-5c8b2ae26d82"
                }
            }
        ],
        "Fila-a7fb5938-5659-4820-87d8-30d4311e1688:row-content-1": [
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "56px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-ec394bd4-96b6-4f37-a380-c2d0450c0191"
                }
            },
            {
                "type": "Columna",
                "props": {
                    "width": "100%",
                    "backgroundColor": "transparent",
                    "border": "none",
                    "borderRadius": "0",
                    "padding": "10px",
                    "boxShadow": "none",
                    "items": [
                        {
                            "height": "auto",
                            "padding": "0"
                        }
                    ],
                    "id": "Columna-486efae0-4f15-4b5e-8591-e95704bf111a"
                }
            }
        ],
        "ContenedorPrincipal-bae8f6d0-5566-4e8a-8509-f71003bde9b7:diploma-content": [
            {
                "type": "Columna",
                "props": {
                    "width": "100%",
                    "backgroundColor": "transparent",
                    "border": "none",
                    "borderRadius": "0",
                    "padding": "10px",
                    "boxShadow": "none",
                    "items": [
                        {
                            "height": "auto",
                            "padding": "0"
                        }
                    ],
                    "id": "Columna-d13733c1-25e4-4946-aa3c-4e61457f95ff"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "64px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-5a633110-7e1d-4a1e-ad86-9260920ca64e"
                }
            },
            {
                "type": "Columna",
                "props": {
                    "width": "100%",
                    "backgroundColor": "transparent",
                    "border": "none",
                    "borderRadius": "0",
                    "padding": "10px",
                    "boxShadow": "none",
                    "items": [
                        {
                            "height": "auto",
                            "padding": "0"
                        }
                    ],
                    "id": "Columna-e59a669c-49e8-4fad-83b2-388fafecbfe0"
                }
            },
            {
                "type": "EspaciosVerticales",
                "props": {
                    "size": "64px",
                    "hasDivider": false,
                    "maxWidth": "100%",
                    "id": "EspaciosVerticales-00ce4063-232b-47ac-a42e-267fa57f3b96"
                }
            },
            {
                "type": "Fila",
                "props": {
                    "items": [{}, {}],
                    "backgroundColor": "transparent",
                    "spacing": "16px",
                    "id": "Fila-a7fb5938-5659-4820-87d8-30d4311e1688"
                }
            }
        ]
    }
}