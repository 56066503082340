function filterNonEmptyItems(data: any[]): any[] {
    return data.filter(item => {
        for (const key in item) {
            if (key !== "__index" && (item[key] !== null && item[key] !== undefined && item[key].trim() !== "")) {
                return true
            }
        }
        return false
    });
}

export default filterNonEmptyItems