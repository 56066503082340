import React, { useState } from 'react';

import { Field } from 'formik';
import { FormLabel, FormControl, Icon, Input, InputGroup, InputRightElement, Box, Text, Flex } from '@chakra-ui/react';
import { BiHide, BiLock, BiShow, BiSolidInfoCircle } from 'react-icons/bi';
import { TimePicker } from '../CustomElements/TimePicker';

export const FormTimeInput = ({
    name,
    label,
    controlStyle = {},
    isDisabled = false,
    isRequired = false,
}: {
    label?: string;
    name: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    controlStyle?: React.CSSProperties;
}) => {

    const timeToSeconds = (
        time: { 
            horas: number;
            minutos: number; 
            segundos: number;
        }
    ): number => {
        const { horas, minutos, segundos } = time;
        const total = horas * 3600 + minutos * 60 + segundos;
        return total;
    }

    return (
        <Field name={name}>
            {({ form }: { field: any; form: any }) => (
                <FormControl style={controlStyle} isInvalid={form.errors[name] && form.touched[name]}>
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {label}
                        </FormLabel>
                    }

                    <Flex
                        w="100%"
                        pos="relative"
                    >
                        <TimePicker
                            isDisabled={isDisabled}
                            onChange={(e: { horas: number; minutos: number; segundos: number; }) => {
                                const time = timeToSeconds(e)
                                form.setFieldValue(name, time)
                            }}
                        />

                        {isRequired &&
                            <Icon
                                zIndex="999"
                                as={BiSolidInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "font"}
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </Flex>

                    <Box h="18px" w="100%" mt="2px">
                        <Text
                            color="fail"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {form.touched[name] && form.errors[name] ? form.errors[name] : ""}
                        </Text>
                    </Box>
                </FormControl>
            )}
        </Field>
    );
};
