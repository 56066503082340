import { UserInt } from "../../interfaces/UserInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addUsuario = async ({ data, client }: { data: UserInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/users`,
        data,
        {}
    )
};

export const updateUsuario = async ({
    id,
    data,
    client
}: {
    id: string,
    data: UserInt,
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/users/${id}`,
        data,
        {}
    )
};

export const validateDatos = async ({
    id,
    validate,
    client
}: {
    id: string,
    validate: boolean,
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/cambioDatos/${id}`,
        {
            aceptado: validate,
        },
        {}
    )
};

export const deleteUser = async ({ userId, client }: { userId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/users/${userId}`)
};

export const cambioDatos = async ({
    data
}: {
    data: Partial<UserInt>
}) => {
    const tenant = await tenantInstance()

    return await _axios.post(
        `${tenant}/users/cambioDatos`,
        data,
        {}
    ).then((response: any) => response);
};

export const uploadAvatarUser = async (files: File[]) => {
    const tenant = await tenantInstance()

    const formData = new FormData()

    formData.append('avatar', files[0]);

    return await _axios.put(
        `${tenant}/users/update`,
        formData,
        {}
    ).then((response: any) => response);
};

export const removeAvatarUser = async () => {
    const tenant = await tenantInstance()

    return await _axios.put(
        `${tenant}/users/update?borraAvatar=true`,
        {},
        {}
    ).then((response: any) => response);
};