import {
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Portal,
    Tooltip,
} from "@chakra-ui/react";

import {
    BiDownload,
    BiDuplicate,
    BiTrash,
    BiPencil,
    BiDotsVerticalRounded,
    BiPlusCircle,
} from "react-icons/bi";
import { CgReorder } from "react-icons/cg";
import { TbAlertOctagon } from "react-icons/tb";

export interface ActionProps {
    create?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    edit?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    download?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    remove?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    duplicate?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    extra?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    sort?: {
        title?: string;
        isDisabled?: boolean;
        disableAlert?: string;
        onClick: (e?: any) => any;
    };
    size?: string;
}

export const Actions = ({
    create,
    sort,
    edit,
    download,
    remove,
    duplicate,
    extra,
    size = "25px",
}: ActionProps) => {
    return (
        <Menu isLazy>
            <MenuButton
                as={IconButton}
                w="fit-content"
                h="fit-content"
                outline={"none"}
                bg={"transparent"}
                className="action-icon"
                onClick={(e) => e.stopPropagation()}
                _focus={{ bg: "transparent" }}
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _expanded={{ bg: "transparent" }}
                icon={
                    <Icon
                        color="font"
                        as={BiDotsVerticalRounded}
                        _hover={{ color: "main" }}
                        w={size}
                        h={size}
                    />
                }
            />

            <Portal>
                <MenuList
                    p="0px"
                    maxH="380px"
                    w="fit-content"
                    overflowY="auto"
                    rounded="8px"
                    boxShadow={"0px 10px 30px rgba(1, 20, 52, 0.2)"}
                >
                    {edit && (
                        <Tooltip label={edit?.disableAlert} isDisabled={!edit?.isDisabled}>
                            <MenuItem
                                icon={<BiPencil />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "main" }}
                                bg="purewhite"
                                isDisabled={edit.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    if (edit.onClick) edit.onClick(e);
                                }}
                            >
                                <Box color="font" fontSize="16px">
                                    {edit.title || "Editar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {download && (
                        <Tooltip label={download?.disableAlert} isDisabled={!download?.isDisabled}>
                            <MenuItem
                                icon={<BiDownload />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                isDisabled={download.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (download.onClick) download.onClick(e);
                                }}
                            >
                                <Box color="font" fontSize="16px">
                                    {download.title || "Descargar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {duplicate && (
                        <Tooltip label={duplicate?.disableAlert} isDisabled={!duplicate?.isDisabled}>
                            <MenuItem
                                icon={<BiDuplicate />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                isDisabled={duplicate.isDisabled}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (duplicate.onClick) duplicate.onClick(e);
                                }}
                            >
                                <Box color="font" fontSize="16px">
                                    {duplicate.title || "Duplicar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {sort && (
                        <Tooltip label={extra?.disableAlert} isDisabled={!extra?.isDisabled}>
                            <MenuItem
                                icon={<CgReorder />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                isDisabled={sort.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (sort.onClick) sort.onClick(e);
                                }}
                            >

                                <Box color="font" fontSize="16px">
                                    {sort.title || "Ordenar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {create && (
                        <Tooltip label={extra?.disableAlert} isDisabled={!extra?.isDisabled}>
                            <MenuItem
                                icon={<BiPlusCircle />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                isDisabled={create.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (create.onClick) create.onClick(e);
                                }}
                            >

                                <Box color="font" fontSize="16px">
                                    {create.title || "Crear"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {remove && (
                        <Tooltip label={remove?.disableAlert} isDisabled={!remove?.isDisabled}>
                            <MenuItem
                                icon={<BiTrash />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                isDisabled={remove.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (remove.onClick) remove.onClick(e);
                                }}
                            >

                                <Box color="font" fontSize="16px">
                                    {remove.title || "Eliminar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}

                    {extra && (
                        <Tooltip label={extra?.disableAlert} isDisabled={!extra?.isDisabled}>
                            <MenuItem
                                icon={<TbAlertOctagon />}
                                mb="5px"
                                p="10px 20px"
                                _last={{ mb: "0px" }}
                                _hover={{ bg: "variant" }}
                                bg="purewhite"
                                isDisabled={extra.isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (extra.onClick) extra.onClick(e);
                                }}
                            >

                                <Box color="font" fontSize="16px">
                                    {extra.title || "Eliminar"}
                                </Box>
                            </MenuItem>
                        </Tooltip>
                    )}
                </MenuList>
            </Portal>
        </Menu>
    );
};