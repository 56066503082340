import { Box, Button, Flex, Icon, Link, useToast } from "@chakra-ui/react";
import { FileUploader } from "../../../shared/components/CustomElements/FileUploader";
import { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { addCVTutor, removeCVTutor } from "../../../shared/middlewares/tutores.middleware";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { BiFile, BiSave, BiTrashAlt } from "react-icons/bi";
import { useClient } from "../../../shared/context/client.context";

interface Props {
    files: File[];
    setFiles: (files: any) => void;
    tutorId: string | undefined | null;
    cv: {
        url: string;
        name: string;
    } | undefined;
    setRefresh?: (action: boolean) => void;
}

export const LoadCV = ({ files, setFiles, tutorId, cv, setRefresh }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleEliminarCV = () => {
        if (!tutorId) return

        removeCVTutor({
            id: tutorId,
            client: client
        })
        .then(() => {
            toastNotify(toast, StatusEnumTypes.SUCCESS, "El CV se ha borrado con exito")
            setRefresh && setRefresh(true);
        })
        .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al borrar el CV, vuelva a intentarlo"))

        // updateEntregables(entregableData);
    };

    const handleOnDeleteFile = (file: File) => {
        setFiles(files.filter((f) => f.name !== file.name && f.size !== file.size && f.type !== file.type));
    };

    const handleOnLoadedFiles = (files: File[]) => {
        setFiles(files);
    };

    const handleGuardarCambios = async () => {
        if (!tutorId) return;

        setIsUploading(true);
        addCVTutor({
            id: tutorId,
            cv: files[0],
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "El CV se ha subido con exito")
                setFiles([]);
                setRefresh && setRefresh(true)
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al subir el CV, verifique que el tamaño sea inferior a 26MB."))

        setIsUploading(false);
    };

    return (
        !cv?.url ?
            <Flex
                alignItems="center"
                gap="20px"
                w="100%"
            >
                <Box flex="1">
                    <FileUploader
                        files={files}
                        maxFiles={1}
                        maxSize="27MB"
                        isUploading={isUploading}
                        supportedFormats={['.png', '.jpg', '.jpeg', '.pdf']}
                        hideDropZone={files.length > 0}
                        onDeleteFile={handleOnDeleteFile}
                        onLoadedFiles={handleOnLoadedFiles}
                    />
                </Box>

                <Button
                    onClick={handleGuardarCambios}
                    isDisabled={files.length === 0}
                    bg={"inherit"}
                    _hover={{ bg: "inherit"}}
                >
                    <Icon as={BiSave} boxSize="22px" />
                </Button>

            </Flex>
            :
            <Flex
                alignItems="center"
                gap="20px"
                w="100%"
            >
                <Flex w="100%" p="10px 15px" bg="light_grey" borderRadius="10px" alignItems="center">
                    <Icon color="black" boxSize="22px" as={BiFile} />

                    <Flex w="100%">
                        <Flex
                            direction="column"
                            w="100%"
                            px="10px"
                            color="black"
                            fontSize="12px"
                            fontWeight="500"
                        >
                            <Link as={LinkRouter} to={cv?.url} p="0px">
                                {cv?.name}
                            </Link>
                        </Flex>
                    </Flex>
                </Flex>


                <Button
                    bg={"inherit"}
                    _hover={{ bg: "inherit"}}
                    onClick={handleEliminarCV}
                >
                    <Icon as={BiTrashAlt} boxSize="22px" />
                </Button>
            </Flex>
    );
}