import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { TenantTable } from "./views/Table/TenantTable";

export const Tenant = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <TenantTable />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};