import { GruposInt, GruposUpdateInt } from "../../interfaces/GruposInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const updateGrupo = async ({
    id,
    data,
    client
}: {
    id: string,
    data: GruposUpdateInt,
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/grupos/${id}`,
        data,
        {}
    )
};

export const addGrupo = async ({ grupo, client }: { grupo: GruposInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/grupos`,
        grupo,
        {}
    )
};

export const deleteGrupo = async ({ grupoId, client }: { grupoId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/grupos/${grupoId}`)
};