import { Flex, Icon, Text } from "@chakra-ui/react"
import { MdCancel, MdCheckCircle } from "react-icons/md"

interface Props {
    status: boolean,
    text: string
}

export const StatusItem = ({ status, text }: Props) => {
    return (
        <Flex
            w="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="5px"
        >
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                {text}
            </Text>

            <Icon 
                as={status ? MdCheckCircle : MdCancel}
                color={status ? "success" : "fail"}
                boxSize="20px"
            />
        </Flex>
    )
}