import { ComponentConfig, DropZone } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";

const getClassName = getClassNameFactory("EmailSection", styles);

export type EmailSectionProps = {
    items: {
        width?: string;
        height?: string;
    }[];
    layout: "vertical" | "horizontal";
    padding: string;
    maxWidth: string;
    align: "left" | "center" | "right";
};

export const EmailSection: ComponentConfig<EmailSectionProps> = {
    fields: {
        items: {
            type: "array",
            getItemSummary: (item, id = -1) =>
                `Contenido ${id + 1}${item.width ? ` (${item.width})` : ''}`,
            arrayFields: {
                width: {
                    type: "select",
                    label: "Ancho",
                    options: [
                        { label: "Auto", value: "" },
                        { label: "25%", value: "25%" },
                        { label: "33%", value: "33.33%" },
                        { label: "40%", value: "40%" },
                        { label: "50%", value: "50%" },
                        { label: "60%", value: "60%" },
                        { label: "66%", value: "66.66%" },
                        { label: "75%", value: "75%" },
                    ],
                },
                height: {
                    type: "text",
                    label: "Altura",
                },
            },
        },
        layout: {
            type: "radio",
            options: [
                { value: "vertical", label: "Vertical" },
                { value: "horizontal", label: "Horizontal" },
            ],
        },
        padding: {
            type: "select",
            options: [
                { label: "0", value: "0" },
                { label: "8px", value: "8px" },
                { label: "16px", value: "16px" },
                { label: "20px", value: "20px" },
                { label: "24px", value: "24px" },
                { label: "32px", value: "32px" },
                { label: "40px", value: "40px" },
            ],
        },
        maxWidth: {
            type: "select",
            options: [
                { label: "600px", value: "600px" },
                { label: "800px", value: "800px" },
                { label: "100%", value: "100%" },
            ],
        },
        align: {
            type: "radio",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ],
        },
    },
    defaultProps: {
        items: [
            {
                height: "auto",
            }
        ],
        layout: "horizontal",
        padding: "16px",
        maxWidth: "600px",
        align: "center"
    },
    render: ({ items, layout, padding, maxWidth, align }) => {
        // Calcula los anchos automáticamente
        const calculateWidths = () => {
            const definedWidths = items.filter(item => item.width);
            const totalDefinedWidth = definedWidths.reduce((acc, item) =>
                acc + (parseFloat(item.width!) || 0), 0);

            const remainingItems = items.length - definedWidths.length;
            const remainingWidth = Math.max(0, 100 - totalDefinedWidth);
            const autoWidth = remainingItems > 0 ?
                `${remainingWidth / remainingItems}%` : "100%";

            return items.map(item => item.width || autoWidth);
        };

        const itemWidths = layout === "horizontal" ? calculateWidths() : Array(items.length).fill("100%");

        return (
            <table
                className={getClassName()}
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{
                    maxWidth,
                    margin: "0 auto",
                }}
            >
                <tbody>
                    {layout === "vertical" ? (
                        items.map((s, idx) => (
                            <tr key={idx}>
                                <td
                                    width={itemWidths[idx]}
                                    style={{
                                        padding,
                                        textAlign: align,
                                        height: s.height,
                                    }}
                                >
                                    <DropZone
                                        zone={`section-content-${idx}`}
                                        disallow={["Hero", "Logos", "Stats"]}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            {items.map((s, idx) => (
                                <td
                                    key={idx}
                                    width={itemWidths[idx]}
                                    style={{
                                        padding,
                                        textAlign: align,
                                        height: s.height,
                                    }}
                                >
                                    <DropZone
                                        zone={`section-content-${idx}`}
                                        disallow={["Hero", "Logos", "Stats"]}
                                    />
                                </td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    },
};
