import { Flex, Icon, Image, Text, useToast } from "@chakra-ui/react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import { AiOutlinePoweroff, AiOutlineUser } from "react-icons/ai"
import { useAuthContex } from "../../../context/auth.context";
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import userProfile from "../../../../assets/img/Avatar.png";
interface Modal {
  open: boolean;
  rotate: number;
}

export const UserActions = () => {
  const { user, logout } = useAuthContex();
  const navigate = useNavigate();
  const toast = useToast();
  const [modal, setModal] = useState<Modal>({ open: false, rotate: 0 });
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {

        setModal({ open: false, rotate: 0 });
      }
    };

    document.addEventListener('click', handleClickOutside);


    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setModal((prevModal) => ({
      open: !prevModal.open,
      rotate: prevModal.open ? 0 : 180,
    }));
  };

  return (
    <Flex>
      <Flex
        ref={modalRef}
        gap="12px"
        alignItems="center"
        pos="relative"
        onClick={handleClick}
        cursor="pointer"
      >
        <Image
          alt={`${user?.username}`}
          src={`${user?.avatar?.url}` || userProfile}
          width="40px"
          height="40px"
          rounded="16px"
        />

        <Flex alignItems="center" gap="12px">
          <Text
            fontSize="14px"
            fontWeight="400"
            color="font"
          >
            {user?.username}
          </Text>

          <Icon
            color="font"
            w="24px"
            h="24px"
            transition="all 0.2s ease 0s"
            transform={`rotate(${modal.rotate}deg)`}
            as={MdOutlineKeyboardArrowDown}
          />
        </Flex>

        <Flex
          pos="absolute"
          bg="purewhite"
          border={"1px solid #f0f0f3"}
          w={"175px"}
          top="45px"
          right="0px"
          borderRadius="8px"
          boxShadow="rgba(0, 0, 0, 0.25) 0px 4px 29px 0px"
          zIndex="dropdown"
          opacity={modal.open ? "1" : "0"}
          visibility={modal.open ? "visible" : "hidden"}
          transform={modal.open ? "none" : "scale(0.8) translateZ(0px)"}
          flexDirection="column"
        >
          <Flex
            flex="1"
            flexDirection="column"
            p="22px 18px"
            gap="30px"
          >
            <Flex
              alignItems="center"
              gap="10px"
              cursor="pointer"
              onClick={() => navigate("/perfil")}
            >
              <Icon
                as={AiOutlineUser}
                color="dark_grey"
                w="16px"
                h="16px"
              />
              <Text
                fontWeight="normal"
                fontSize="16px"
              >
                Perfil
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              gap="10px"
              cursor="pointer"
              onClick={() => logout("Sesión Finalizada", navigate, toast)}
              mt="-15px"
            >
              <Icon
                as={AiOutlinePoweroff}
                color="dark_grey"
                w="16px" h="16px"
              />
              <Text
                fontWeight="normal"
                fontSize="16px"
              >
                Cerrar sesión
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
