import { useParams } from "react-router-dom";
import { getVariablesByTemplateId } from "../../Table/constants";

interface Props {
    textarea: HTMLTextAreaElement;
    value: string;
    name: string;
    onChange: (value: string) => void;
    minHeight?: string;
}

export const TextVariable = ({
    textarea,
    value,
    name,
    onChange,
    minHeight = "150px",
}: Props) => {
    const { id } = useParams();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                }}
            >
                <label
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#5A5A5A",
                    }}
                >
                    Texto
                </label>

                <textarea
                    className="_Input-input_3pq3z_47"
                    style={{
                        minHeight: minHeight
                    }}
                    defaultValue={value}
                    name={name}
                    onChange={(e) =>
                        onChange(e.currentTarget.value)
                    }
                />
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                }}
            >
                <label
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#5A5A5A",
                    }}
                >
                    Variables
                </label>

                <select
                    className="_Input-input_3pq3z_47"
                    name={name}
                    value={""}
                    onChange={(e) => {
                        const value = e.target.value;
                        let newValue = value;

                        if (textarea && value) {
                            newValue = textarea.value + " " + value;

                            textarea.value = newValue;
                            onChange(newValue);
                        }
                    }}
                >
                    <option value="" disabled>Insertar variable</option>
                    {getVariablesByTemplateId(id!)?.map((v, i) => (
                        <option key={i} value={v.value}>{v.label}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}