import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { addUsuario } from "../../../../shared/middlewares/users.middleware";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { addSupervisorGrupo } from "../../../../shared/middlewares/supervisores.middleware";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { FormPhoneInput, phoneValidationYup } from "../../../../shared/components/FormElements/FormPhoneInput";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable?: () => void;
    idEmpresa?: string | null;
    idGrupo?: string | null;
}

export const NewSupervisorModal = ({
    isOpen,
    onClose,
    setRefreshTable = () => { },
    idEmpresa = null,
    idGrupo = null
}: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const toast = useToast();

    const initialValues = {
        fromEmpresa: idEmpresa === null ? false : true,
        nombre: null,
        apellidos: null,
        email: null,
        telefono: null,
        empresaId: idEmpresa,
    };

    const validationSchema = Yup.object({
        fromEmpresa: Yup.boolean(),
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        apellidos: Yup.string()
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        telefono: Yup.string()
            .test('validator-custom-phone', function (value) {
                const validation = phoneValidationYup(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            })
            .notRequired(),
        empresaId: Yup.string().when("fromEmpresa", {
            is: true,
            then: (validationScheme) => validationScheme.notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('Debe asociar una empresa al alumno.')
                .typeError('Debe asociar una empresa al alumno.'),

        }),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const newUsuario: any = {
            nombre: values.nombre,
            apellidos: values.apellidos,
            email: values.email,
            telefono: values.telefono,
            empresaId: values.empresaId,
            sepe: false,
            roleId: 3
        }

        addUsuario({
            data: newUsuario,
            client: client
        })
            .then((response: any) => {
                if (idGrupo) {
                    addSupervisorGrupo({
                        data: {
                            userId: response?.data?.data?.id,
                            grupoId: idGrupo
                        },
                        client: client
                    })
                        .then(() => {
                            toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado correctamente y se ha asociado al grupo`)
                            setRefreshTable();
                        })
                        .catch((error: any) => {
                            toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado, pero no se ha asociado al grupo`)

                            const errors = handleErrors(
                                error?.response?.data?.errors,
                                error?.response?.status
                            )

                            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                        })
                } else {
                    toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado correctamente`)
                }

                setRefreshTable();
                onClose();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const loadEmpresas = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: QueryTypes.NOMBRE,
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo supervisor empresa
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex direction="column" gap="10px" mb="30px">
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="700"
                                                    mb="5px"
                                                >
                                                    Datos del supervisor
                                                </Text>

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.nombre ? true : false}
                                                        name="nombre"
                                                        label="Nombre"
                                                        placeholder="Nombre del supervisor"
                                                    />

                                                    <FormInput
                                                        isRequired={!values?.apellidos ? true : false}
                                                        name="apellidos"
                                                        label="Apellidos"
                                                        placeholder="Apellidos del supervisor"
                                                    />
                                                </Flex>

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    placeholder="Email del supervisor"
                                                />

                                                <Flex gap="15px">
                                                    <Box flex="1">
                                                        <FormPhoneInput name="telefono" label="Teléfono" placeholder="Teléfono del supervisor" />
                                                    </Box>

                                                    <Box flex="1" />
                                                </Flex>
                                            </Flex>

                                            {idEmpresa === null &&
                                                <FormAsyncSelect
                                                    isRequired={!values?.empresaId ? true : false}
                                                    name="empresaId"
                                                    label="Empresa"
                                                    loadOptions={loadEmpresas}
                                                    defaultOptions={true}
                                                    placeholder="Empresa del supervisor"
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                        styleLabel: { fontSize: "18px", fontWeight: "700" }
                                                    }}
                                                />
                                            }
                                        </ModalBody>

                                        <ModalFooter justifyContent="center" mt={idEmpresa === null ? "30px" : "0"}>
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "black", backgroundColor: "light_grey" }}
                                            >
                                                Crear supervisor
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}