import { Badge } from "@chakra-ui/react"
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar";

interface Props {
    moduloFUNDAE: boolean;
}

export const TutoresColumns = ({ moduloFUNDAE }: Props): ColumnProps[] => {

    const columns = [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Teléfono',
            field: 'telefono',
            key: 'telefono',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.telefono}
                    link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,

                    }}
                />
            ),
        },
        {
            header: 'Contratación',
            field: 'contratacion',
            key: 'contratacion',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.tutorFreelance ? "Colaborador" : "Contratado"}  /* Colaborador = freelance */ link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,

                    }}
                />
            )
        },
        {
            header: 'matriculas activas',
            field: 'matriculas',
            key: 'matriculas',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculasNoFundae || 0) + " matrículas"}
                    link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,
                    }}
                />
            )
        },
        {
            header: 'Estado',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <Badge
                    color={rowData?.activo ? "success_main" : "font"}
                    bg={rowData?.activo ? "success_bg" : "light_grey"}
                    borderColor={rowData?.activo ? "success_variant" : "font"}
                    border="1px"
                    fontSize="10px"
                    fontWeight="500"
                    letterSpacing="0.2px"
                    textTransform="uppercase"
                    borderRadius="14px"
                    p="5px 10px"
                >
                    {
                        rowData?.activo ? "Activo" : "Inactivo"
                    }
                </Badge>
            )
        },
    ]

    const extraColumns = [
        {
            header: 'matriculas activas fundae',
            field: 'totalMatriculasFundae',
            key: 'totalMatriculasFundae',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculasFundae || 0) + " matrículas"}
                    link={{
                        to: `/usuarios/tutores/${rowData.id}/perfil`,
                    }}
                />
            )
        },
    ]

    return moduloFUNDAE ? [...columns.slice(0, 4), ...extraColumns, ...columns.slice(4)] : columns;
}