import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton, Tooltip, Icon, Link } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { useEffect, useState } from "react";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { UserInt } from "../../../../interfaces/UserInt";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { addMatriculaToGrupo } from "../../../../shared/middlewares/matriculas.middlewate";
import { NewAlumnoModal } from "../../../Usuarios/components/Modals/NewAlumnoModal";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import tenantInstance from "../../../../shared/services/tenantInstance.service";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { getData } from "../../../../shared/middlewares/middlewares";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { CustomAsyncSelect } from "../../../../shared/components/CustomElements/CustomAsyncSelect";
import { BiTrashAlt } from "react-icons/bi";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { Link as RouterLink } from "react-router-dom";
import { aceptarInscripcion } from "../../../../shared/middlewares/inscripciones.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    Refresh: () => void;
    inscripcionId: string;
}

export const ConvertirInscripcionModal = ({
    isOpen,
    onClose,
    Refresh,
    inscripcionId
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [empresasOptions, setEmpresasOptions] = useState<EmpresasInt[]>([]);
    const [tutoresOptions, setTutoresOptions] = useState<UserInt[]>([]);
    const [empresasState, setEmpresasState] = useState<{
        exists: boolean;
        isUnique: boolean;
        empresa: EmpresasInt | null;
    }>({
        exists: false,
        isUnique: false,
        empresa: null,
    });

    const [tutorState, setTutorState] = useState<{
        exists: boolean;
        isUnique: boolean;
        tutor: UserInt | null;
    }>({
        exists: false,
        isUnique: false,
        tutor: null,
    });

    useEffect(() => {
        if (empresasOptions?.length === 0) {
            setEmpresasState({
                exists: false,
                isUnique: false,
                empresa: null
            })
        } else if (empresasOptions?.length === 1) {
            setEmpresasState({
                exists: true,
                isUnique: true,
                empresa: empresasOptions[0]
            })
        } else {
            setEmpresasState({
                exists: true,
                isUnique: false,
                empresa: null
            })
        }
    }, [empresasOptions])

    useEffect(() => {
        if (tutoresOptions?.length === 0) {
            setTutorState({
                exists: false,
                isUnique: false,
                tutor: null
            })
        } else if (tutoresOptions?.length === 1) {
            setTutorState({
                exists: true,
                isUnique: true,
                tutor: tutoresOptions[0]
            })
        } else {
            setTutorState({
                exists: true,
                isUnique: false,
                tutor: null
            })
        }
    }, [tutoresOptions])

    const initialValues = {
        grupoId: null,
        tutorId: null,
        empresaId: null,
    }

    const validationSchema = Yup.object().shape({
        grupoId: Yup.string()
            .required('El grupo es obligatorio.')
            .typeError('El grupo es obligatorio.'),
        tutorId: Yup.string()
            .notRequired(),
        empresaId: Yup.string()
            .notRequired()
    })

    const onSubmit = (
        values: {
            grupoId: string | null;
            tutorId: string | null;
            empresaId: string | null
        }) => {
        if (
            !values?.grupoId ||
            (tutorState?.isUnique && !tutorState?.tutor) ||
            (empresasState?.isUnique && !empresasState?.empresa)
        ) return

        if (!tutorState?.isUnique && !values?.tutorId) return toastNotify(toast, StatusEnumTypes.WARNING, "Debes seleccionar un tutor para la matrícula.")
        if (!empresasState?.isUnique && !values?.empresaId) return toastNotify(toast, StatusEnumTypes.WARNING, "Debes seleccionar una empresa para el alumno.")

        setLoading(true)

        const incripcionToMatricula = {
            grupoId: values?.grupoId,
            empresaId: empresasState?.isUnique ? empresasState?.empresa?.id : values?.empresaId,
            tutorId: tutorState?.isUnique ? tutorState?.tutor?.id : values?.tutorId
        }

        aceptarInscripcion({
            inscripcionId: inscripcionId,
            data: incripcionToMatricula,
            client: client
        }).then(() => {
            Refresh()
            onClose()
        }).catch((error: any) => {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            )

            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
        }).finally(() => setLoading(false))
    }

    const loadGrupos = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        query: QueryTypes.NOMBRE,
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <ModalHeader
                    textAlign="start"
                    fontSize="24px"
                    fontWeight="600"
                    px="30px"
                    py="15px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text
                        fontSize="22px"
                        fontWeight="600"
                        color="font"
                    >
                        Convertir inscripción en matrícula
                    </Text>

                    <ModalCloseButton />
                </ModalHeader>

                <Formik
                    onSubmit={onSubmit}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit, values } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <ModalBody
                                    px="30px"
                                >
                                    <FormAsyncSelect
                                        name="grupoId"
                                        label="Grupo de la matrícula"
                                        loadOptions={loadGrupos}
                                        placeholder="Seleccione el grupo de la matrícula"
                                        isRequired={!values.grupoId}
                                        defaultOptions={true}
                                        onChangeCallback={(values: { value: string; label: string; data: GruposInt }) => {
                                            setEmpresasOptions(values.data?.empresas || [])
                                            setTutoresOptions(values.data?.tutores || [])
                                        }}
                                        ui={{
                                            formSelectStyles: formSelectStyles
                                        }}
                                    />

                                    <Text
                                        display={values?.grupoId && !tutorState?.exists ? "block" : "none"}
                                        mt="10px"
                                        fontSize="14px"
                                        color="fail"
                                        fontWeight="500"
                                    >
                                        * No hay tutores asignados a este grupo para matricular.
                                    </Text>

                                    <Text
                                        display={values?.grupoId && !empresasState?.exists ? "block" : "none"}
                                        mt="10px"
                                        fontSize="14px"
                                        color="fail"
                                        fontWeight="500"
                                    >
                                        * No hay empresas asignadas a este grupo para matricular.
                                    </Text>

                                    {(values?.grupoId && tutorState?.exists && !tutorState?.isUnique) &&
                                        <FormSelect
                                            name="tutorId"
                                            placeholder="Seleccione el tutor de la matrícula"
                                            label="Tutor de la matrícula"
                                            options={tutoresOptions?.map((tutor: UserInt) => ({ value: tutor?.id, label: tutor?.nombre }))}
                                            isRequired={!values.tutorId}
                                            ui={{
                                                formSelectStyles: formSelectStyles
                                            }}
                                        />
                                    }

                                    {(values?.grupoId && empresasState?.exists && !empresasState?.isUnique) &&
                                        <FormSelect
                                            name="empresaId"
                                            placeholder="Seleccione la empresa del alumno"
                                            label="Empresa del alumno"
                                            options={empresasOptions?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))}
                                            isRequired={!values.empresaId}
                                            ui={{
                                                formSelectStyles: formSelectStyles
                                            }}
                                        />
                                    }

                                    <Link
                                        display={values?.grupoId && (!tutorState?.exists || !empresasState?.exists) ? "block" : "none"}
                                        as={RouterLink}
                                        to={`/grupos/${values.grupoId}/configuracion`}
                                        fontSize="14px"
                                        color="main"
                                        fontWeight="500"
                                        ml="11px"
                                    >
                                        Ir al grupo.
                                    </Link>
                                </ModalBody>

                                <ModalFooter
                                    justifyContent="center"
                                >
                                    <Tooltip
                                        label={
                                            !values?.grupoId
                                                ? "Debes seleleccionar un grupo."
                                                : (!empresasState?.isUnique && !values?.empresaId) || (!tutorState?.isUnique && !values?.tutorId)
                                                    ? "Debes seleccionar toda la información de la matrícula."
                                                    : "Debes tener tutores y empresas asignadas al grupo."
                                        }
                                        placement="bottom"
                                        textAlign="center"
                                        hasArrow
                                        py="5px"
                                        px="10px"
                                        bg="pureblack"
                                        color="purewhite"
                                        rounded="4px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        isDisabled={
                                            (
                                                values?.grupoId &&
                                                (tutorState?.exists && empresasState?.exists) &&
                                                (!empresasState?.isUnique && values?.empresaId) &&
                                                (!tutorState?.isUnique && values?.tutorId)
                                            )
                                                ? true
                                                : false
                                        }
                                    >
                                        <Button
                                            isLoading={loading}
                                            isDisabled={
                                                (loading || !values?.grupoId) ||
                                                (!tutorState?.exists || !empresasState?.exists) ||
                                                (!empresasState?.isUnique && !values?.empresaId) ||
                                                (!tutorState?.isUnique && !values?.tutorId)
                                            }
                                            mt="20px"
                                            type="submit"
                                            bg="main"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="#FFF"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "secondary" }}
                                        >
                                            Convertir
                                        </Button>
                                    </Tooltip>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal >
    )
}