import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addInspectorCurso = async ({
    client,
    data,
}: {
    client: ClientInt,
    data: {
        cursoId: string | undefined,
        empresaId: string,
    }
}) =>{
    if(!client) return;
    const tenant = await tenantInstance();

    return await _axios.post(
        `${tenant}/${client}/inspectores`,
        data,
        {}
    )
    .then((response: any) => response)
}