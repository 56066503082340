import { Button, Flex } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";

interface Props {
    selectedTenantRef: any;
    onOpenAdmin: () => void;
}

export const TenantColumns = ({
    selectedTenantRef,
    onOpenAdmin
}: Props): ColumnProps[] => {
    return [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.nombre}
                />
            )
        },
        {
            header: 'slug',
            field: 'slug',
            key: 'slug',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.slug}
                />
            )
        },
        {
            header: '',
            field: 'button',
            key: 'button',
            style: { width: "20%" },
            body: (rowData: any) => (
                <Flex>
                    <Button
                        mr="16px"
                        my="16px"
                        onClick={() => {
                            selectedTenantRef.current = rowData?.slug;
                            onOpenAdmin();
                        }}
                        bg="black"
                        color="white"
                        p="5px 10px"
                        h="fit-content"
                        w="fit-content"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="500"
                        _hover={{ bg: "light_grey", color: "black" }}
                    >
                        Crear Administrador
                    </Button>
                </Flex>
            )
        },
    ];
}