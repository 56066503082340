import { ComponentConfig } from "@measured/puck";
import { TextVariable } from "../../CustomElements/Fields/TextVariable";
import { textColors } from "../constants";

export type EnlaceProps = {
    align: "left" | "center" | "right";
    label: string;
    href: string;
    padding?: string;
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
    color: "default" | "muted";
    maxWidth?: string;
    weight?: "light" | "normal" | "medium" | "semibold" | "bold";
};

export const Enlace: ComponentConfig<EnlaceProps> = {
    fields: {
        label: {
            type: "custom",
            label: "Label",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector('textarea[name="label"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                )
            }
        },
        href: {
            type: "custom",
            label: "URL",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector('textarea[name="href"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                )
            }
        },
        size: {
            type: "select",
            options: [
                { label: "XS", value: "xs" },
                { label: "S", value: "s" },
                { label: "M", value: "m" },
                { label: "L", value: "l" },
                { label: "XL", value: "xl" },
                { label: "XXL", value: "xxl" },
                { label: "XXXL", value: "xxxl" },
            ],
        },
        weight: {
            type: "select",
            options: [
                { label: "Light", value: "light" },
                { label: "Normal", value: "normal" },
                { label: "Medium", value: "medium" },
                { label: "Semi Bold", value: "semibold" },
                { label: "Bold", value: "bold" },
            ],
        },
        align: {
            type: "radio",
            options: [
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Right", value: "right" },
            ],
        },
        color: {
            type: "select",
            options: textColors,
        },
        padding: { type: "text" },
        maxWidth: { type: "text" },
    },
    defaultProps: {
        label: "Ir aqui",
        href: "https://proacademy.app",
        align: "left",
        padding: "24px",
        size: "m",
        color: "default",
    },
    render: ({ align, color, label, href, size, padding, maxWidth, weight }) => {
        return (
            <a
                href={href}
                style={{
                    textDecoration: "none",
                    color: color,
                    display: "flex",
                    textAlign: align,
                    width: "100%",
                    fontSize: size === "xs" ? "12px" : size === "s" ? "14px" : size === "m" ? "16px" : size === "l" ? "18px" : size === "xl" ? "20px" : size === "xxl" ? "24px" : size === "xxxl" ? "32px" : "16px",
                    fontWeight: weight === "light" ? 300 : weight === "normal" ? 400 : weight === "medium" ? 500 : weight === "semibold" ? 600 : weight === "bold" ? 700 : 400,
                    maxWidth,
                    padding
                }}
            >
                <span
                    style={{
                        marginLeft: align === "center" ? "auto" : align === "left" ? "0" : "auto",
                        marginRight: align === "center" ? "auto" : align === "right" ? "0" : "auto",
                        justifyContent:
                            align === "center"
                                ? "center"
                                : align === "right"
                                    ? "flex-end"
                                    : "flex-start",
                    }}
                >
                    {label}
                </span>
            </a>
        );
    },
};