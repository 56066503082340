import { useEffect, useState } from 'react';
import { Flex, Icon, Image } from '@chakra-ui/react';
import { BiChevronLeft } from 'react-icons/bi';
import { IconBox } from '../../../../shared/components/Icons/IconBox';
import { CursosInt } from '../../../../interfaces/CursosInt';
import './SelectorMatricula.scss'
import { defaultIcon } from '../../../Onboarding/components/OnboardingForm';

interface Props {
  onSelectCurso: (curso: CursosInt | undefined) => void;
  selectedCurso: CursosInt | undefined;
  cursos: CursosInt[] | undefined;
}

const SelectorCurso = ({ onSelectCurso, selectedCurso, cursos }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = (curso: CursosInt | undefined) => {
    onSelectCurso(curso);
    setIsActive(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedCurso && cursos) {
          onSelectCurso(cursos[0]);
        }
      } catch (error) {
        console.error('Error al cargar cursos:', error);
      }
    };

    fetchData();
  }, [selectedCurso, onSelectCurso]);

  return (
    <div className={`custom-select ${isActive ? 'active' : ''}`}>

      <Flex className="select-button"
        gap={"6px"}
        onClick={() => setIsActive(!isActive)}
      >

        <Flex gap="8px">

          <span className="selected-value">{selectedCurso ? selectedCurso.nombre : ""}</span>

        </Flex>

        <Icon className='arrow' as={BiChevronLeft} boxSize="24px" />

      </Flex>

      <ul className="select-dropdown">
        <li>
          <label onClick={() => handleSelect({
            nombre: "Todas las matrículas",
            icono: undefined,
            descripcion: "",
            publicado: true,
            id: undefined,
          })}>
            <IconBox icon={undefined} padding="0" border="none" size="28px" bg='none' />
            <input type="radio" name="label" />
            Todas las matrículas
          </label>
        </li>

        {cursos?.map((curso: CursosInt, index: number) => (
          <li key={index} onClick={() => handleSelect(curso)}>
            <label>
              {curso?.icono?.url ?
                <Image
                  src={curso?.icono?.url}
                  padding="0"
                  boxSize="28px"
                />
                :
                <IconBox icon={defaultIcon} padding="0" border="none" size="28px" bg='none' />
              }
              <input type="radio" name="label" />
              {curso.nombre}
            </label>
          </li>
        ))}
      </ul>

    </div>
  );
};

export default SelectorCurso;
