
import { GruposRutaInt, RutasInt } from '../../interfaces/RutasInt';
import { ClientInt } from '../services/http.service';
import _axios from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';


export const updateRuta = async ({ id, data, client }: { id: string, data: Partial<RutasInt>, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/rutas/${id}`,
        data,
        {}
    )
};

export const addRuta = async ({ ruta, client }: { ruta: Partial<RutasInt>, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/rutas`,
        ruta,
        {}
    )
};

export const addGrupoRuta = async ({ ruta, client }: { ruta: Partial<GruposRutaInt>, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/rutaGrupos`,
        ruta,
        {}
    )
};

export const addMatriculaGrupoRuta = async ({
    grupoId,
    data,
    client
}: {
    grupoId: string,
    data: {
        users: string[]
    },
    client: ClientInt
}) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/rutaGrupos/matricular/${grupoId}`,
        data,
        {}
    )
}