export const getAlumnosFields = () => {
    return [
        {
            label: "Nombre",
            key: "nombre",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Apellidos",
            key: "apellidos",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Email",
            key: "email",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Teléfono",
            key: "telefono",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
        {
            label: "DNI",
            key: "dni",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
    ]
}
