import { Flex } from "@chakra-ui/react"
import { useParams } from "react-router-dom";
import { useData } from "../../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../../shared/context/client.context";
import { checkQuerys } from "../../../../../../shared/utils/functions/checkQuerys";
import { Table } from "../../../../../../shared/components/Table/Table";
import { SesionesColumns } from "../../../../components/TabsColumns/SesionesColumns";
import { EntityTypes, useQuerySession } from "../../../../../../shared/hooks/useQuerySession";

export const TabSesiones = () => {
    const { id } = useParams();
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.SESIONES_MATRICULA })

    const { data: sesiones, loading } = useData({
        endpoint: EndpointTypes.SESIONES,
        client: client,
        specificQuery: {
            matricula: id!
        },
        query: query,
        ignoreRequest: !id
    })

    return (
        <Flex direction="column" gap="20px">
            <Table
                query={query}
                setQuery={setQuery}
                data={sesiones?.data || []}
                columns={SesionesColumns()}
                isLoading={loading}
                total={sesiones?.meta?.total || 0}
            />
        </Flex>
    )
}