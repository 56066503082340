import { Flex, Text } from "@chakra-ui/layout"
import { TabInformes } from "../../Grupos/views/Information/TabItems/TabInformes"
import { useParams } from "react-router"
import { IconBox } from "../../../shared/components/Icons/IconBox"
import { Skeleton } from "primereact/skeleton"
import { useClient } from "../../../shared/context/client.context"
import { useDataId } from "../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { Image } from "@chakra-ui/react"
import { formatTimezone } from "../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../shared/hooks/useTimeZone"

export const InformationInformes = () => {
    const { id } = useParams();
    const timeZone = useTimeZone();
    const client = useClient();
    const { data: grupo, loading } = useDataId({
        id: id,
        endpoint: EndpointTypes.GRUPOS,
        client: client
    });

    return (
        <Topbar
            title={
                loading ?
                    <Flex gap="15px" alignItems="center">
                        <Skeleton size="55px"></Skeleton>

                        <Flex direction="column" justifyContent="space-between" gap="5px">
                            <Skeleton height="20px" width="300px"></Skeleton>
                            <Skeleton height="20px" width="300px"></Skeleton>
                            <Skeleton height="15px" width="300px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px" alignItems="center">
                        {grupo?.curso?.icono?.url ?
                            <Image
                                src={grupo?.curso?.icono?.url}
                                padding="0"
                                boxSize="40px"
                            />
                            :
                            <IconBox
                                icon={undefined}
                                size="40px"
                                border="none"
                                padding="0"
                                bg="inherit"
                            />
                        }

                        <Flex direction="column">
                            <Flex
                                alignItems="center"
                                gap="15px"
                            >
                                <Text
                                    color="font"
                                    fontSize="17px"
                                    fontWeight="600"
                                    lineHeight="128%"
                                    textTransform="uppercase"
                                >
                                    {grupo?.nombre}
                                </Text>

                                <Text
                                    color="font"
                                    fontSize="13px"
                                    fontWeight="500"
                                >
                                    {grupo?.curso?.nombre}
                                </Text>
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="5px"
                                color="font"
                                fontSize="12px"
                                fontWeight="500"
                            >
                                <Text>{grupo?.fechaInicio && formatTimezone({ date: grupo?.fechaInicio, timeZone })}</Text>
                                <Text mb="2px">-</Text>
                                <Text>{grupo?.fechaFin && formatTimezone({ date: grupo?.fechaFin, timeZone })}</Text>
                            </Flex>
                        </Flex>

                        {grupo?.fundae &&
                            <Text
                                textTransform="uppercase"
                                fontSize="11px"
                                fontWeight="500"
                                letterSpacing="0.26px"
                                color="purewhite"
                                p="3px 7px"
                                borderRadius="5px"
                                bg="secondary"
                            >
                                FUNDAE
                            </Text>
                        }
                    </Flex>
            }
        >
            <Flex p="20px 50px" w="100%">
                <TabInformes />
            </Flex>
        </Topbar>
    )
}