import { Flex, useDisclosure } from "@chakra-ui/react";
import { useClient } from "../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../shared/hooks/useData";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { checkQuerys } from "../../../../shared/utils/functions/checkQuerys";
import { useRef } from "react";
import { EmailDrawer } from "../../components/EmailDrawer";
import { EmailsSectionTypes } from "../../../../shared/utils/Types/EmailsSectionTypes";
import { Table } from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { LogsColumns } from "../../components/TabsColumns/LogsColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";
import { sortBy } from "lodash";

interface Props {
    id?: string | undefined;
    tipo?: EmailsSectionTypes;
    hideFilters?: boolean;
}

export const TabLogs = ({
    id,
    tipo = EmailsSectionTypes.EMAILS,
    hideFilters
}: Props) => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({
        entity:
            EmailsSectionTypes.MATRICULAS ? EntityTypes.MATRICULAS_EMAILS_LOGS :
                EmailsSectionTypes.GRUPOS ? EntityTypes.GRUPOS_EMAILS_LOGS :
                    EmailsSectionTypes.USUARIOS ? EntityTypes.USUARIOS_EMAILS_LOGS :
                        EntityTypes.EMAILS_LOGS
    });

    const { data: emails, loading } = useData({
        client: client,
        endpoint: EndpointTypes.EMAILS_LOGS,
        specificQuery: id ? { [tipo]: id } : undefined,
        query: {
            sortBy: "fechaEnvio",
            order: "desc",
            ...query
        }
    })

    const { onOpen: onOpenEmail, onClose: onCloseEmail, isOpen: isOpenEmail } = useDisclosure();
    const emailRef = useRef<null>(null);

    const onRowClick = ({ data }: any) => {
        emailRef.current = data

        onOpenEmail();
    }

    return (
        <Flex gap="20px" direction="column">
            {!hideFilters &&
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE_EMAIL,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                    filterElements={
                        tipo === EmailsSectionTypes.EMAILS ?
                            [
                                { name: FiltrosNames.GRUPO_ID, type: FiltrosTypes.ASYNC_SELECT },
                                { name: FiltrosNames.FECHA_ENVIO_SUP, type: FiltrosTypes.DATE, },
                                { name: FiltrosNames.FECHA_ENVIO_INF, type: FiltrosTypes.DATE, },
                            ]
                            :
                            [
                                { name: FiltrosNames.FECHA_ENVIO_SUP, type: FiltrosTypes.DATE, },
                                { name: FiltrosNames.FECHA_ENVIO_INF, type: FiltrosTypes.DATE, },
                            ]
                    }
                />
            }

            <Table
                query={query}
                setQuery={setQuery}
                data={emails?.data || []}
                columns={LogsColumns()}
                isLoading={loading}
                total={emails?.meta?.total || 0}
                onRowClick={onRowClick}
            />

            <EmailDrawer
                isOpen={isOpenEmail}
                onClose={onCloseEmail}
                email={emailRef.current}
            />
        </Flex>
    );
}