import { useAuthContex } from '../context/auth.context';
import { UserSubTypeEnum } from '../utils/Types/UserRolEnum';

interface UserSubTypes {
    [UserSubTypeEnum.TOTAL]: boolean;
    [UserSubTypeEnum.GESTION]: boolean;
    [UserSubTypeEnum.CONTENIDO]: boolean;
}
export const useUserPolicy = (): UserSubTypes => {
    const { user } = useAuthContex();

    const checkRole = (role: UserSubTypeEnum): boolean => {
        return user?.extraData?.subType === role;
    };

    const roles: UserSubTypes = {
        [UserSubTypeEnum.TOTAL]: checkRole(UserSubTypeEnum.TOTAL),
        [UserSubTypeEnum.GESTION]: checkRole(UserSubTypeEnum.GESTION),
        [UserSubTypeEnum.CONTENIDO]: checkRole(UserSubTypeEnum.CONTENIDO)
    };

    return roles;
};