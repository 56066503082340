import { Flex, Text } from "@chakra-ui/react"
import { HorarioLaboral } from "../../../shared/utils/Types/HorarioLaboral";

interface Props {
    label: string;
    horarios: HorarioLaboral[];
    borderBottom?: string;
}

export const HorariosItem = ({
    label,
    horarios,
    borderBottom = "1px solid #EAEAEA"
}: Props) => {

    return (
        <Flex
            borderBottom={borderBottom}
            p="20px"
            alignItems="center"
            gap="10px"
        >
            <Text
                color="font"
                fontSize="12px"
                fontWeight="600"
                letterSpacing="0.24px"
                textTransform="uppercase"
                flex="1"
            >
                {label}
            </Text>

            {(horarios === null || horarios === undefined) ?
                <Text
                    fontSize="13px"
                    fontWeight="500"
                    color="fail"
                    flex="1"
                >
                    Aun no especificado por el alumno
                </Text>
                : horarios?.length === 0 ?
                    <Text
                        fontSize="13px"
                        fontWeight="500"
                        color="fail"
                        flex="1"
                    >
                        Día sin jornada laboral
                    </Text>
                    :
                    <Flex gap="10px" flex="1" >
                        {horarios?.map((horario: HorarioLaboral, index: number) => (
                            <Flex
                                key={index}
                                gap="10px"
                                fontSize="13px"
                                fontWeight="500"
                                color="font"
                            >
                                <Text
                                >
                                    {horario?.inicio} a {horario.fin}
                                </Text>

                                <Text>
                                    {(horarios?.length > 1 && (index !== horarios?.length - 1)) && "y"}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
            }
        </Flex>
    )
}