import { Flex, Text, useToast, useDisclosure, Badge, Image, Tooltip } from "@chakra-ui/react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { BiBarChartSquare, BiFolder, BiInfoSquare, BiPlusCircle, BiUserCircle } from "react-icons/bi";
import { TabConfiguracion } from "./TabItems/TabConfiguracion";
import { TabFundae } from "./TabItems/TabFundae";
import { TabMatriculas } from "./TabItems/TabMatriculas";
import { TabInformes } from "./TabItems/TabInformes";
import { Skeleton } from "primereact/skeleton";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { UserInt } from "../../../../interfaces/UserInt";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md";
import { TbSubtask } from "react-icons/tb";
import { TabLogs } from "../../../Emails/views/TabItems/TabLogs";
import { TabPendientes } from "../../../Emails/views/TabItems/TabPendientes";
import { TabTareas } from "../../../Emails/views/TabItems/TabTareas";
import { EmailsSectionTypes } from "../../../../shared/utils/Types/EmailsSectionTypes";
import { Helmet } from "react-helmet";
import { OnboardingStep } from "../../../../shared/components/Cards/OnboardingStep";
import { TabsRouterInt } from "../../../../interfaces/ComponentsInt";
import { parseDate } from "../../../../shared/utils/functions/parseDate";
import { DateTime } from "luxon";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { TabsRouter } from "../../../../shared/components/TabsRouter/TabsRouter";
import { NewMatriculaModal } from "../../components/Modals/NewMatriculaModal";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import filterNonEmptyItems from "../../../../shared/utils/functions/cleanImport";
import { getFields } from "../../components/Fields/Fields";
import { SpreadSheet } from "../../../../ui/SpreadSheetTheme";
import { ModaLoading } from "../../../../shared/components/Modals/ModalLoading";
import { uploadMatriculasImport } from "../../../../shared/middlewares/adjuntos.middleware";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { ModalAlert } from "../../../../shared/components/Modals/ModalAlert";
import { BsPersonFillCheck } from "react-icons/bs";
import { TabsAsistencias } from "./TabItems/TabAsistencias";

export const TabsInformationGrupos = () => {
    const timeZone = useTimeZone();
    const moduloFUNDAE = useModuloFundae();
    const { contenido, total } = useUserPolicy();
    const { id } = useParams();
    const { user, refreshUser } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const { isOpen: isOpenFinish, onOpen: onOpenFinish, onClose: onCloseFinish } = useDisclosure();
    const { isOpen: isOpenTareasGeneradas, onOpen: onOpenTareasGeneradas, onClose: onCloseTareasGeneradas } = useDisclosure();
    const { isOpen: isOpenMatricular, onOpen: onOpenMatricular, onClose: onCloseMatricular } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();
    const { data: grupo, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.GRUPOS,
        client: client
    })
    const [showStepMatricula, setShowStepMatricula] = useState(true)
    const [tutores, setTutores] = useState<{ value: string, label: string }[]>([])
    const [empresas, setEmpresas] = useState<{ value: string, label: string }[]>([]);
    const [isTutorUnico, setIsTutorUnico] = useState<boolean>()
    const [isEmpresaUnica, setIsEmpresaUnica] = useState<boolean>()
    const [refreshMatriculas, setRefreshMatriculas] = useState<boolean>(false)

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/grupos/${id}/configuracion`,
            `/grupos/${id}/fundae`,
            `/grupos/${id}/matriculas`,
            `/grupos/${id}/informes`,
            `/grupos/${id}/asistencias`,
            `/grupos/${id}/enviados`,
            `/grupos/${id}/pendientes`,
            `/grupos/${id}/tareas`
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("configuracion");
        }
    }, [location.pathname]);

    const updateValue = async (values: any) => {
        if (!id) return;
        const oldTareasGeneradas = grupo?.tareasGeneradas;

        if ("fechaInicio" in values) {
            let fechaInicio = parseDate(values.fechaInicio) + "T12:00"
            fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()

            values.fechaInicio = fechaInicio
        }

        if ("fechaFin" in values) {
            let fechaFin = parseDate(values.fechaFin) + "T12:00"
            fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

            values.fechaFin = fechaFin
        }

        return updateGrupo({
            id: id,
            data: values,
            client: client
        })
            .then((response) => {
                const tareasGeneradas = response?.data?.data?.tareasGeneradas

                if (!oldTareasGeneradas && tareasGeneradas) {
                    onOpenTareasGeneradas()
                }

                for (const [key, value] of Object.entries(values)) {
                    toastNotify(toast, StatusEnumTypes.SUCCESS, `${[key]} se ha actualizado correctamente`)
                }

                setTimeout(() => Refresh(), 100)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    useEffect(() => {
        const optionsTutores: { value: string, label: string }[] = []
        const optionsEmpresas: { value: string, label: string }[] = []

        grupo?.tutores?.map((tutor: UserInt) => {
            if (tutor?.id && tutor?.nombre)
                return optionsTutores.push({ value: tutor?.id, label: `${tutor?.nombre} ${tutor?.apellidos}` })
        })

        grupo?.empresas?.map((empresa: EmpresasInt) => {
            if (empresa?.id && empresa?.nombre)
                return optionsEmpresas.push({ value: empresa?.id, label: empresa?.nombre })
        })

        setTutores(optionsTutores)
        setEmpresas(optionsEmpresas)
    }, [grupo])

    useEffect(() => {
        if (tutores?.length > 1) {
            setIsTutorUnico(false)
        }
        if (tutores?.length === 1) {
            setIsTutorUnico(true)
        }
        if (tutores?.length === 0 || !tutores) {
            setIsTutorUnico(undefined)
        }

    }, [tutores])

    useEffect(() => {
        if (empresas?.length > 1) {
            setIsEmpresaUnica(false)
        }
        if (empresas?.length === 1) {
            setIsEmpresaUnica(true)
        }
        if (empresas?.length === 0 || !empresas) {
            setIsEmpresaUnica(undefined)
        }

    }, [empresas])

    const checkMatriculasDeBaja = (): boolean => {
        const count = grupo?.meta?.matriculasDeBaja;

        if (Number(count) > 0) return true;

        return false;
    }

    const filterRepeat = (matriculas: any[]) => matriculas.filter((value, index, self) => index === self.findIndex((t) => (t.email === value?.email)));

    const onSubmit = (data: any) => {
        onOpenLoading()

        if (!grupo?.tutores || grupo?.tutores?.length == 0) return toastNotify(toast, StatusEnumTypes.ERROR, `No hay tutor asignado al grupo`)
        if (!grupo?.empresas || grupo?.empresas?.length == 0) return toastNotify(toast, StatusEnumTypes.ERROR, `No hay empresa asignado al grupo`)

        const matriculas: any[] = [];

        for (let i = 0; i < data?.validData?.length; i++) {
            let newData = { ...data?.validData[i], grupoId: grupo?.id };

            if (isTutorUnico) { newData = { ...newData, tutorId: grupo?.tutores[0]?.id } }

            if (isEmpresaUnica) { newData = { ...newData, empresaId: grupo?.empresas[0]?.id } }

            if (!grupo?.gestionaEmpresa) { newData = { ...newData, empresaFacturaId: grupo?.empresaOrganizadoraId } }

            matriculas.push(newData)
        }

        uploadMatriculasImport({
            data: filterRepeat(matriculas),
            client: client
        })
            .then(() => {
                Refresh();
                setRefreshMatriculas(true)
                toastNotify(toast, StatusEnumTypes.SUCCESS, `Alumnos matriculados correctamente`)
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, `Error en la carga masiva, vuelva a intentarlo`))
            .finally(async () => {
                onCloseLoading()
                !grupo?.tareasGeneradas && onOpenFinish()

                if (!user?.config?.matriculas && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                matriculas: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    matriculas: true
                                }
                            })
                        })
                }
            })
    }

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Configuración",
            path: "configuracion",
            icon: BiUserCircle,
            content:
                <TabConfiguracion
                    grupo={grupo}
                    updateValue={updateValue}
                    loading={loading}
                    Refresh={Refresh}
                    isEmpresaUnica={isEmpresaUnica}
                />
        },
        {
            isAllowed: (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && (grupo?.fundae && !contenido && moduloFUNDAE)),
            label: "FUNDAE",
            path: "fundae",
            icon: BiInfoSquare,
            content:
                <TabFundae
                    grupo={grupo}
                    updateValue={updateValue}
                    Refresh={Refresh}
                />
        },
        {
            isAllowed: true,
            label: "Matrículas",
            path: "matriculas",
            icon: BiFolder,
            content:
                <TabMatriculas
                    hasMatriculasDeBaja={checkMatriculasDeBaja()}
                    refreshGrupo={Refresh}
                    refreshMatriculas={refreshMatriculas}
                    setRefreshMatriculas={setRefreshMatriculas}
                />
        },
        {
            isAllowed: true,
            label: "Informes",
            path: "informes",
            icon: BiBarChartSquare,
            content:
                <TabInformes />
        },
        {
            isAllowed: true,
            path: "asistencias",
            label: "Asistencias",
            icon: BsPersonFillCheck,
            content:
                <TabsAsistencias
                    grupo={grupo}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Enviados",
            path: "enviados",
            icon: MdOutlineMarkEmailRead,
            content:
                <TabLogs
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Pendientes",
            path: "pendientes",
            icon: MdOutlineMarkEmailUnread,
            content:
                <TabPendientes
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Tareas",
            path: "tareas",
            icon: TbSubtask,
            content:
                <TabTareas
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },


        // TODO: Proxima implementación
        // {
        //     isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.GRUPO}
        //         />
        // }
    ]

    return (
        <>
            <Helmet>
                <title>Grupos </title>
            </Helmet>
            <Topbar
                title={
                    loading ?
                        <Flex gap="15px" alignItems="center" ml="-20px" w="100%">
                            <Skeleton shape="circle" size="45px" />
                            <Skeleton height="25px" width="300px" />
                        </Flex>
                        :
                        <Flex gap="15px" alignItems="center" ml="-20px">
                            {grupo?.curso?.icono?.url ?
                                <Image
                                    src={grupo?.curso?.icono?.url}
                                    padding="0"
                                    boxSize="45px"
                                />
                                :
                                <IconBox
                                    icon={undefined}
                                    size="45px"
                                    border="none"
                                    padding="0"
                                    bg="inherit"
                                />
                            }

                            <Flex
                                gap="15px"
                                alignItems="center"
                            >
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="700"
                                    lineHeight="128%"

                                >
                                    {grupo?.nombre}
                                </Text>

                                {grupo?.fundae &&
                                    <Flex
                                        alignItems="center"
                                        gap="8px"
                                    >

                                        <Text
                                            textTransform="uppercase"
                                            fontSize="12px"
                                            fontWeight="500"
                                            letterSpacing="0.26px"
                                            color="purewhite"
                                            p="3px 7px"
                                            borderRadius="5px"
                                            bg="secondary"
                                        >
                                            FUNDAE
                                        </Text>


                                        <Tooltip
                                            label={grupo?.gestionaEmpresa ? "Gestion Externa" : "Gestion Interna"}
                                            hasArrow
                                            placement="bottom"
                                            bg="pureblack"
                                            color="purewhite"
                                            p="10px"
                                            rounded="6px"
                                        >
                                            <Badge
                                                color={grupo?.gestionaEmpresa ? "main" : "purewhite"}
                                                bg={grupo?.gestionaEmpresa ? "purewhite" : "variant"}
                                                borderColor={grupo?.gestionaEmpresa ? "main" : "variant"}
                                                borderWidth="1px"
                                                fontSize="10px"
                                                fontWeight="500"
                                                letterSpacing="0.2px"
                                                textTransform="uppercase"
                                                borderRadius="14px"
                                                p="2px 5px"

                                            >
                                                {grupo?.gestionaEmpresa ? "Ext" : "Int"}
                                            </Badge>
                                        </Tooltip>
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                }
                buttonBack="/grupos"
            >
                <Flex
                    direction="column"
                    p="20px"
                    minH="calc(100vh - 70px)"
                    position="relative"
                >
                    <TabsRouter
                        tabs={tabs}
                        buttons={
                            (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                                [
                                    {
                                        isAllowed: true,
                                        paths: ["configuracion", "fundae", "matriculas", "enviados", "pendientes", "tareas", "informes", "asistencias"],
                                        button: {
                                            label: "Nueva matrícula",
                                            onClick: () => {
                                                onOpenMatricular()
                                                showStepMatricula && setShowStepMatricula(false)
                                            },
                                            leftIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px"
                                            },
                                            isDisabled: tutores?.length === 0 || contenido,
                                        },
                                        tooltip: {
                                            label: contenido ? "No tienes permisos para esta acción" : "No hay tutor asignado",
                                            isDisabled: tutores?.length > 0 && !contenido
                                        }
                                    },
                                    {
                                        isAllowed: true,
                                        paths: ["configuracion", "fundae", "matriculas", "enviados", "pendientes", "tareas", "informes", "asistencias"],
                                        button: {
                                            label: "Nueva carga masiva",
                                            onClick: () => {
                                                onOpen()
                                                setShowStepMatricula(false)
                                            },
                                            leftIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px"
                                            },
                                            isDisabled: tutores?.length === 0 || contenido,
                                        },
                                        tooltip: {
                                            label: contenido ? "No tienes permisos para esta acción" : "No hay tutor asignado",
                                            isDisabled: tutores?.length > 0 && !contenido
                                        }
                                    },
                                ]
                                : undefined
                            )}
                    />

                    {(showStepMatricula &&
                        ((!user?.config?.matriculas && user?.config?.tutores) && total)
                    ) &&
                        <OnboardingStep
                            position={{
                                top: "55px",
                                right: "395px"
                            }}
                            placement="top/right"
                            title="Matricula tus primeros alumnos"
                            description="Asocia matrículas a tus grupos para que los alumnos puedan acceder a los cursos y formaciones que ofreces."
                            isDisabled={tutores?.length === 0 || contenido}
                            disabledTooltip="Primero debes crear un tutor y asignarlo al grupo."
                            onClick={() => {
                                setShowStepMatricula(false)
                                onOpenMatricular()
                            }}
                        />
                    }

                    <NewMatriculaModal
                        isOpen={isOpenMatricular}
                        onClose={onCloseMatricular}
                        setRefresh={() => setRefreshMatriculas(true)}
                        grupo={grupo}
                        refreshGrupo={Refresh}
                        onOpenFinish={onOpenFinish}
                    />

                    <ReactSpreadsheetImport
                        isOpen={isOpen}
                        onClose={onClose}
                        onSubmit={onSubmit}
                        tableHook={(data) => { return filterNonEmptyItems(data) }}
                        fields={
                            getFields({
                                isTutorUnico: isTutorUnico,
                                isEmpresaUnica: isEmpresaUnica,
                                tutores: tutores,
                                empresas: empresas,
                            })
                        }
                        customTheme={SpreadSheet().theme}
                        translations={SpreadSheet().translations}
                    />

                    <ModaLoading
                        isOpen={isOpenLoading}
                    />

                    <ModalAlert
                        isOpen={isOpenFinish}
                        onClose={onCloseFinish}
                        title="Las tareas no se generarán y no se enviarán los emails de bienvenida y control hasta no configurar en el grupo los requerimientos de FUNDAE"
                        onlyAccept={true}
                    />

                    <ModalAlert
                        isOpen={isOpenTareasGeneradas}
                        onClose={onCloseTareasGeneradas}
                        title="Las tareas de todas las matrículas se han generado correctamente"
                        onlyAccept={true}
                    />
                </Flex>
            </Topbar>
        </>
    )
}