import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { TabsTableUsuarios } from "./views/TabsUsuarios";
import { Helmet } from "react-helmet";
import { InformationAlumno } from "./views/Alumno/Information/Information";
import { InformationTutor } from "./views/Tutor/Information/Information";
import { InformationSupervisor } from "./views/Supervisor/Information/Information";
import { InformationInspector } from "./views/Inspector/Information/Information";
import { InformationAdministradores } from "./views/Administrador/Information/Information";

export const Usuarios = () => {

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Usuarios </title>
            </Helmet>

            <Flex direction="column">
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <TabsTableUsuarios />
                        }
                    />

                    <Route
                        path='/alumnos/:id/*'
                        element={
                            <InformationAlumno
                            />
                        }
                    />

                    <Route
                        path='/tutores/:id/*'
                        element={
                            <InformationTutor
                            />
                        }
                    />

                    <Route
                        path='/supervisores/:id/*'
                        element={
                            <InformationSupervisor
                            />
                        }
                    />

                    <Route
                        path='/inspectores/:id/*'
                        element={
                            <InformationInspector
                            />
                        }
                    />

                    <Route
                        path='/administradores/:id/*'
                        element={
                            <InformationAdministradores
                            />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};