import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';
import { AnunciosInt, updateAnunciosInt } from "../../interfaces/AnunciosInt";

export const updateAnuncio = async ({ id, data, client }: { id: string, data: updateAnunciosInt, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/anuncios/${id}`,
        data,
        {}
    )  
};

export const addAnuncio = async ({ anuncio, client} : { anuncio: AnunciosInt, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/anuncios`,
        anuncio,
        {}
    )        
};

export const uploadAnuncioImage = async ({ id, file, client } : { id: string, file: File, client: ClientInt }) => {
    const formData = new FormData()
    const tenant = await tenantInstance();

    formData.append('imagen', file);

    return await _axios.put(
        `${tenant}/${client}/anuncios/${id}`,
        formData,
        {}
    ).then((response: any) => response);
};