import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)
const customXs = defineStyle({
    minW: "95vw",
    maxH: "95vh"
    
})

const customMd = defineStyle({
    minW: "95vw",
    h: "90vh"
})

const sizes = {
    xs: definePartsStyle({dialog: customXs}),
    md: definePartsStyle({dialog: customMd}),
}

export const modalCustomTheme = defineMultiStyleConfig({
    sizes,
})