import { Link as RouterLink } from 'react-router-dom'
import { Icon, Text, Flex, Link, Tooltip } from "@chakra-ui/react";

export interface NavlinkProps {
  path: string;
  icon: any;
  label: string;
  active?: boolean;
  keyboardNumber?: number;
  setState?: (value: boolean) => void;
}

export function Navlink({
  keyboardNumber,
  path,
  icon,
  label,
  active,
  setState = () => { }
}: NavlinkProps) {

  return (
    <Link
      as={RouterLink}
      display="flex"
      rounded="10px"
      w="100%"
      bg={active ? "variant" : "transparent"}
      border={"1px solid"}
      borderColor={"transparent"}
      _hover={{ border: "1px solid", borderColor: "variant" }}
      to={path}
      onClick={() => {
        setState(false);
      }}
    >
      <Tooltip
        label={
          <Flex
            alignItems="center"
            justifyContent="center"
            gap="5px"
          >
            <Text
              fontSize={"12px"}
              fontWeight={"500"}
            >
              {label}
            </Text>

            {keyboardNumber &&
              <Flex
                bg={"purewhite"}
                alignItems="center"
                color="main"
                justifyContent="center"
                py="3px"
                px="7px"
                h="fit-content"
                w="fit-content"
                fontSize={"10px"}
                fontWeight="600"
                rounded="4px"
              >
                {keyboardNumber}
              </Flex>
            }
          </Flex>
        }
        rounded={"6px"}
        textAlign={"center"}
        bg={"main"}
        color={"#FFFFFF"}
        px={"10px"}
        py={"5px"}
        placement='right'
      >
        <Flex
          p="10px 12px"
          alignItems="center"
          gap="10px"
          className="icon-box"
        >
          <Icon
            as={icon}
            boxSize="22px"
            color={active ? "main" : "purewhite"}
          />
        </Flex>
      </Tooltip>
    </Link>
  );
}
