import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useToast, Box, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useState } from "react";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { useClient } from "../../../../shared/context/client.context";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { updateGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { formSelectStyles } from "../../../../ui/formSelectStyles";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    empresas: {
        data: EmpresasInt[];
    } | undefined;
    grupo: GruposInt | undefined;
    Refresh: () => void;
    setGestionaEmpresa: (value: boolean) => void;
}
export const ModalGestion = ({
    isOpen,
    onClose,
    empresas,
    grupo,
    Refresh,
    setGestionaEmpresa
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    const initialValues = {
        empresaOrganizadoraId: null,
    };

    const validationSchema = Yup.object({
        empresaOrganizadoraId: Yup.string()
            .required('Debe seleccionar una empresa organizadora')
            .typeError('Debe seleccionar una empresa organizadora'),
    });

    const handleSubmit = async (values: any) => {
        if (!grupo?.id) return;
        setIsLoading(true)

        const newValues: {
            fundae: boolean;
            gestionaEmpresa: boolean;
            empresaOrganizadoraId: string | null;

        } = {
            fundae: true,
            gestionaEmpresa: false,
            empresaOrganizadoraId: values?.empresaOrganizadoraId
        }

        updateGrupo({
            id: grupo?.id,
            data: newValues,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, `Gestion FUNDAE se ha actualizado correctamente`)

                setTimeout(() => Refresh(), 100)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
                onClose()
                setGestionaEmpresa(false)
            })
    }

    const handleClose = () => {
        onClose()
        setGestionaEmpresa(true)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="purewhite"
                        bg="black"
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                        >
                            Gestión FUNDAE
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        m="10px"
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            my="20px"
                                        >
                                            <FormSelect
                                                name="empresaOrganizadoraId"
                                                label="Empresa Organizadora"
                                                options={
                                                    (empresas === undefined || empresas === null)
                                                        ? [] :
                                                        [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                }
                                                placeholder="Empresa asociada"
                                                isRequired={!values.empresaOrganizadoraId ? true : false}
                                                ui={{
                                                    formSelectStyles: formSelectStyles,
                                                }}
                                            />
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="black"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="600"
                                                _hover={{ color: "black", backgroundColor: "light_grey" }}
                                            >
                                                Modificar
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />
        </>
    )
}