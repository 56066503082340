import {
    Modal,
    ModalOverlay,
    ModalContent,
    Spinner,
    Flex,
  } from '@chakra-ui/react'

interface Props {
    isOpen: boolean;
}

export const ModaLoading = ({ isOpen }: Props) => {
    return (
        <Modal
            isCentered
            isOpen={isOpen}
            size="sm"
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner 
                    color="main"
                    boxSize="200px"
                />
            </ModalOverlay>
        </Modal>
    )
}