import { Flex, Skeleton } from '@chakra-ui/react'

export default function DashboardSkeletonRendimiento() {

    return (
        <Flex direction="column" boxSize="100%" gap="20px">
            <Flex
                flex="1" gap="20px" h="150px"
            >
                <Skeleton h="150px" flex="1" rounded="14px" />
                <Skeleton h="150px" flex="1" rounded="14px" />
                <Skeleton h="150px" flex="1" rounded="14px" />
                <Skeleton h="150px" flex="1" rounded="14px" />
            </Flex>
            <Flex h="426px" gap="20px"
            >
                <Skeleton w={"full"} h="426px" rounded="14px" />
                <Skeleton w={"full"} h="426px" rounded="14px" />
            </Flex>
        </Flex>
    )
}
