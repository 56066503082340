import { ColumnProps } from "../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";

export const SesionesColumns = (): ColumnProps[] => {

    const formatSeconds = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secondsLeft = seconds % 60;

        let totalTime = "";

        if (hours > 0) {
            totalTime += `${hours}h`;
        }
        if (minutes > 0 || hours > 0) {
            totalTime += ` ${minutes}m`;
        }
        if (secondsLeft > 0 || minutes > 0 || hours > 0) {
            totalTime += ` ${secondsLeft}s`;
        }

        return totalTime || "0s";
    }

    return [
        {
            header: 'Tipo de sesion',
            field: 'tipo',
            key: 'tipo',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.tipo}
                />
            )
        },
        {
            header: 'Tiempo de sesion',
            field: 'tiempo',
            key: 'tiempo',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={formatSeconds(rowData?.tiempo)}
                />
            )
        },
        {
            header: 'Leccion',
            field: 'leccion.nombre',
            key: 'leccion.nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.leccion?.nombre || '---'}
                    text={rowData?.leccion?.tipo || undefined}
                />
            )
        },
        {
            header: 'Fecha Acceso',
            field: 'fechaAcceso',
            key: 'fechaAcceso',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.fechaAcceso}
                />
            )
        },
    ]
}