import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet"
import { TabsConfig } from "./views/TabsConfig"
import { PerfilEmpresaInterna } from "./components/PerfilEmpresaInterna"

export const CampusConfig = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Configuración </title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <TabsConfig />
                        }
                    />

                    <Route
                        path="mis-empresas/:id"
                        element={
                            <PerfilEmpresaInterna
                            />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}