import { Flex, } from "@chakra-ui/react"
import { UserTypesEnum } from "../../../../shared/utils/Types/UserTypesEnum";
import { useNavigate } from "react-router-dom";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { Table } from "../../../../shared/components/Table/Table";

interface Props {
    loading: boolean;
    userType: UserTypesEnum;
    data: any;
    columns: any[];
    query: Record<string, string | number | string[]>;
    setQuery: (query: Record<string, string | number | string[]>) => void;
}

export const TabTable = ({ loading, data, columns, query, setQuery }: Props) => {

    return (
        <Flex direction="column" gap="20px">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.ESTADO_GRUPO,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.FUNDAE,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_INICIO_SUP,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_FIN_INF,
                        type: FiltrosTypes.DATE,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={data?.data}
                columns={columns}
                isLoading={loading}
                total={data?.meta?.total || 0}
            />
        </Flex>
    )
}