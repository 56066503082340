import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, useDisclosure, ModalCloseButton, Text, Image, Icon } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../shared/context/client.context";
import { loadData } from "../../../shared/utils/functions/loadData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../shared/utils/Types/QueryTypes";
import { addRuta } from "../../../shared/middlewares/rutas.middleware";
import { CursosInt } from "../../../interfaces/CursosInt";
import { BiTrashAlt } from "react-icons/bi";
import { CustomAsyncSelect } from "../../../shared/components/CustomElements/CustomAsyncSelect";
import { defaultIcon } from "../../Onboarding/Onboarding";
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea";
import { useNavigate } from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    refresh: () => void;
}

export const NewRutaModal = ({
    isOpen,
    onClose,
    refresh
}: Props) => {
    const toast = useToast();
    const client = useClient();
    const navigate = useNavigate();
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialValues = {
        nombre: null,
        descripcion: null
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required("El nombre es requerido")
            .typeError("El nombre es requerido"),
        descripcion: Yup.string()
            .notRequired(),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        addRuta({
            ruta: {
                nombre: values.nombre,
                descripcion: values.descripcion,
            },
            client: client
        })
            .then((response: any) => {
                const id = response?.data?.data?.id

                onClose()
                refresh()
                navigate(`/rutas/${id}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay
                />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nueva ruta
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                            >
                                                <FormInput
                                                    name="nombre"
                                                    label="Nombre"
                                                    placeholder="EJ: Master en Analísis de Datos"
                                                    isRequired={values?.nombre ? false : true}
                                                />

                                                <FormTextarea
                                                    name="descripcion"
                                                    label="Descripción"
                                                    isRequired={values?.descripcion ? false : true}
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal >

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}