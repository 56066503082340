import { Box, Flex, Icon, StyleProps, Tooltip, useToast } from "@chakra-ui/react"
import { BiCopy } from "react-icons/bi";
import { toastNotify } from "../../utils/functions/toastNotify";
import { StatusEnumTypes } from "../../utils/Types/StatusEnumTypes";

interface Props {
    children: JSX.Element;
    idName: string;
    isDisabled?: boolean;
    styles?: StyleProps;
}

export const CopyTooltip = ({
    children,
    idName,
    styles,
    isDisabled = false
}: Props) => {
    const toast = useToast();

    return (
        <Tooltip
            label={
                <Flex
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon as={BiCopy} boxSize={"17px"} />
                </Flex>
            }
            isDisabled={isDisabled}
            placement="bottom"
            hasArrow
        >
            <Flex
                {...styles}
                className="copy-btn" 
                data-clipboard-action="copy" 
                data-clipboard-target={`#${idName}`}
                onClick={() => !isDisabled && toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
            >
                {children}
            </Flex>
        </Tooltip>
    )
}