import { SetColors } from "./colors";

export const SpreadSheet = () => {
    const colors = SetColors();

    const theme = {
        semanticTokens: {
            colors,
        },
        fonts: {
            body: `'Inter', sans-serif`
        },
        colors: {
            background: "#F8F9FA"
        },
        components: {
            UploadStep: {
                baseStyle: {
                    dropZoneBorder: colors.font,
                },
            },
            Modal: {
                baseStyle: {
                    closeModalButton: {
                        top: "55px",
                        right: "110px",
                        color: colors.font,
                        fontSize: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                },
                variants: {
                    rsi: {
                        header: {
                            bg: colors.white
                        },
                        footer: {
                            bg: colors.white
                        },
                        dialog: {
                            pt: "30px",
                            minH: "calc(100vh - 10rem)",
                            maxW: "calc(100vw - 10rem)",
                        }
                    }
                }
            },
            MatchIcon: {
                baseStyle: () => {
                    return {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        borderWidth: "2px",
                        bg: "background",
                        borderColor: colors.fail,
                        color: "#FFF",
                        transitionDuration: "ultra-fast",
                        _highlighted: {
                            bg: colors.success,
                            borderColor: colors.success,
                        },
                    }
                }
            },
            Button: {
                baseStyle: {
                    borderRadius: "5px",
                },
                variants: {
                    solid: {
                        bg: colors.black,
                        color: "#FFF",
                        p: "8px 15px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                        _hover: { bg: colors.light_grey, color: "black" },
                        _active: { bg: colors.light_grey, color: "black" },
                        "&>svg": {
                            color: "#FFF",
                            fontSize: "18px"
                        }
                    },
                    outline: {
                        border: "1px solid",
                        borderColor: colors.font,
                        color: colors.font,
                        p: "8px 15px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                        _hover: { bg: colors.light_grey, color: "black" },
                        _active: { bg: colors.light_grey, color: "black" }
                    }
                }
            },
        },
        styles: {
            global: {
                ".rdg": {
                    "--rdg-row-selected-background-color": colors.variant,
                    "--row-selected-hover-background-color": colors.main,
                },
            }
        }
    }

    const translations = {
        uploadStep: {
            title: "Adjuntar",
            manifestTitle: "Información del archivo:",
            manifestDescription: "Podrá modificar filas y columnas en los siguientes pasos",
            dropzone: {
                title: "El archivo debe ser formato Excel o Csv",
                errorToastDescription: "Archivo rechazado",
                activeDropzoneTitle: "Sulte su archivo aqui...",
                buttonTitle: "Seleccionar Adjunto",
                loadingTitle: "Cargando...",
            },
        },
        selectHeaderStep: {
            title: "Seleccionar fila de encabezado",
            nextButtonTitle: "Continuar",
        },
        matchColumnsStep: {
            title: "Coincidencia de columnas",
            nextButtonTitle: "Continuar",
            userTableTitle: "Tu tabla",
            templateTitle: "",
            selectPlaceholder: "Seleccionar columna",
            ignoredColumnText: "Ignorar columna",
            subSelectPlaceholder: "Seleccionar...",
            matchDropdownTitle: "Coincidencia",
            unmatched: "No coincide",
            duplicateColumnWarningTitle: "Otra columna sin seleccionar",
            duplicateColumnWarningDescription: "Las columnas no se pueden duplicar",
        },
        validationStep: {
            title: "Validación",
            nextButtonTitle: "Confirmar",
            noRowsMessage: "Datos no encontrados",
            noRowsMessageWhenFiltered: "No hay filas que contengan errores",
            discardButtonTitle: "Descartar filas seleccionadas",
            filterSwitchTitle: "Mostrar solo filas con errores",
        },
        alerts: {
            confirmClose: {
                headerTitle: "Salir del importador",
                bodyText: "Su información actual no se guardará",
                cancelButtonTitle: "Continuar",
                exitButtonTitle: "Salir",
            },
            submitIncomplete: {
                headerTitle: "Errores detectados",
                bodyText: "Todavía hay filas que contienen errores, las filas con errores se ignorarán",
                bodyTextSubmitForbidden: "Todavía hay filas que contienen errores",
                cancelButtonTitle: "Cancelar",
                finishButtonTitle: "Cargar",
            },
            unmatchedRequiredFields: {
                headerTitle: "No todas las columnas coinciden",
                bodyText: "Hay columnas obligatorias que no coinciden. ¿Quieres continuar?",
                listTitle: "Columnas que no coinciden:",
                cancelButtonTitle: "Cancelar",
                continueButtonTitle: "Continuar",
            },
        }
    }

    return { theme, translations }
}