import { Badge, Box, Flex, Icon, Text, useToast, Button } from "@chakra-ui/react";
import { UserInt } from "../../../../interfaces/UserInt";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { BiPencil } from "react-icons/bi";
import { useEffect, useState } from "react";
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { LoadCV } from "../LoadCV";
import { SimpleLineChart } from "../../../../shared/components/EstadisticasElements/SimpleLineChart";
import { useClient } from "../../../../shared/context/client.context";
import { AxiosResponse } from "axios";
import PerfilTutorSkeleton from "../utils/PerfilTutorSkeleton";
import tenantInstance from "../../../../shared/services/tenantInstance.service";
import { resendEmail } from "../../../../shared/middlewares/token.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { FilterDateInput } from "@imaginagroup/bit-components.ui.filters.filter-date-input"
import { FilterSelect } from "../../../../shared/components/FiltersElements/FilterSelect";
import { getData } from "../../../../shared/middlewares/middlewares";
import { DateTime } from "luxon";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { InformationPhoneInput } from "../../../../shared/components/Information/InformationPhoneInput";

interface MatriculasFuturasInt {
    fecha: string;
    matriculas: number;
}
interface Props {
    tutor: UserInt | undefined;
    updateValue: (action: any) => void;
    setRefresh: (action: boolean) => void;
    loading: boolean;
}

export const PerfilTutor = ({ tutor, updateValue, setRefresh, loading }: Props) => {
    const moduloFUNDAE = useModuloFundae();
    const timeZone = useTimeZone();
    const client = useClient();
    const toast = useToast();
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editApellidos, setEditApellidos] = useState<boolean>(false)
    const [editTelefono, setEditTelefono] = useState<boolean>(false)
    const [editDni, setEditDni] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<boolean>(false)
    const [editEmailGuia, setEditEmailGuia] = useState<boolean>(false)
    const [editTelefonoGuia, setEditTelefonoGuia] = useState<boolean>(false)
    const [editComision, setEditComision] = useState<boolean>(false)
    const [fechaMatriculasFuturas, setFechaMatriculasFuturas] = useState<string>(DateTime.now().toFormat("yyyy-MM-dd"));
    const [diasMatriculasFuturas, setDiasMatriculasFuturas] = useState<{
        value: number,
        label: string
    }>({
        value: 20,
        label: "20 días"
    });


    const [contratacion, setContratacion] = useState<boolean>()
    const [files, setFiles] = useState<File[]>([])

    const [matriculasFuturas, setMatriculasFuturas] = useState<MatriculasFuturasInt[]>([])
    const [tenant, setTenant] = useState<string | null>(null);

    useEffect(() => {
        tenantInstance().then((response: any) => {
            setTenant(response);
        })
    }, []);

    useEffect(() => {
        if (!fechaMatriculasFuturas || !diasMatriculasFuturas || !tenant) return

        getData(
            `${tenant}/${client}/statsMatriculasFuturas?tutorId=${tutor?.id}&fecha=${fechaMatriculasFuturas}&dias=${diasMatriculasFuturas?.value}`
        )
            .then((response: AxiosResponse) => {
                const newData: { fecha: string; matriculas: number; }[] = []
                const data = response?.data?.data

                data?.map((d: any) => newData?.push({
                    fecha: formatTimezone({ date: d?.fecha, timeZone, customFormat: "dd-MM" }) as string,
                    matriculas: d?.matriculas
                }))

                setMatriculasFuturas(newData)
            })

    }, [fechaMatriculasFuturas, diasMatriculasFuturas, tutor, tenant, client])

    useEffect(() => {
        setContratacion(tutor?.tutorFreelance)

    }, [tutor])

    const changeContratacion = () => {
        if (
            !tutor?.id ||
            contratacion === null ||
            contratacion === undefined
        ) return

        updateValue({ tutorFreelance: !contratacion })
    }

    const handleResendCredentials = async () => {
        if (!tutor?.id) return;

        resendEmail({
            client: client,
            user: tutor?.id,
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Credenciales reenviadas correctamente"))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        loading ?
            <PerfilTutorSkeleton />
            :

            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos del tutor"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>

                                <Badge
                                    color={tutor?.activo ? "success_main" : "font"}
                                    bg={tutor?.activo ? "success_bg" : "light_grey"}
                                    borderColor={tutor?.activo ? "success_variant" : "font"}
                                    borderWidth="1px"
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    p="5px 8px"
                                >
                                    {
                                        tutor?.activo ? "Activo" : "Inactivo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={tutor?.nombre ? tutor?.nombre : ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditApellidos(true)}
                                onMouseLeave={() => setEditApellidos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="apellidos"
                                    label="Apellidos"
                                    defaultValue={tutor?.apellidos ? tutor?.apellidos : ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editApellidos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDni(true)}
                                onMouseLeave={() => setEditDni(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="dni"
                                    label="documento identidad"
                                    validatorDNI={true}
                                    isInvalidMessage="El DNI ingresado no es correcto"
                                    defaultValue={tutor?.dni ? tutor?.dni : ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editDni && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditTelefono(true)}
                                onMouseLeave={() => setEditTelefono(false)}
                                gap="10px"
                            >
                                <InformationPhoneInput
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={tutor?.telefono ? tutor?.telefono : ""}
                                    updateValue={updateValue}
                                    notValidNumber={() => toastNotify(toast, StatusEnumTypes.ERROR, "El numero no tiene un formato valido.")}
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                />

                                <Flex boxSize="20px">
                                    {editTelefono && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                pr="5%"
                                alignItems="center"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <CustomSwitch
                                    label="contratación"
                                    optionRight="Contratado"
                                    optionLeft="Colaborador"
                                    setValue={setContratacion}
                                    value={contratacion}
                                    onClick={changeContratacion}
                                // Colaborador = freelance
                                />
                            </Flex>

                            {(tutor && tutor?.tutorFreelance) &&
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onMouseEnter={() => setEditComision(true)}
                                    onMouseLeave={() => setEditComision(false)}
                                    gap="10px"
                                >
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="porcentajeComision"
                                        label="% Comisión"
                                        type="tel"
                                        defaultValue={tutor?.porcentajeComision ? tutor?.porcentajeComision : ""}
                                        updateValue={updateValue}
                                    />

                                    <Flex boxSize="20px">
                                        {editComision && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>
                            }

                            <Flex
                                alignItems="center"
                                p="10px 20px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <Text
                                    flex="1"
                                    color="gray"
                                    fontSize="15px"
                                    fontWeight="500"
                                    letterSpacing="0.24px"
                                    textTransform="capitalize"
                                    minW="30%"
                                >
                                    Curriculum vitae
                                </Text>

                                <Flex flex="1" pr="10px">
                                    <LoadCV
                                        cv={tutor?.cv}
                                        files={files}
                                        setFiles={setFiles}
                                        tutorId={tutor?.id}
                                        setRefresh={setRefresh}
                                    />
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <InformationBox
                        title="Credenciales de acceso"
                        component={
                            <Button
                                p="7px 12px"
                                h="fit-content"
                                w="fit-content"
                                borderRadius="5px"
                                bg="secondary"
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                onClick={handleResendCredentials}
                            >
                                Reenviar credenciales
                            </Button>
                        }
                    >
                        <Box>
                            <Flex
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditEmail(true)}
                                onMouseLeave={() => setEditEmail(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={tutor?.email ? tutor?.email : ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editEmail && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    {moduloFUNDAE &&
                        <InformationBox
                            title="Datos guía inicio"
                        >
                            <Box>
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onMouseEnter={() => setEditEmailGuia(true)}
                                    onMouseLeave={() => setEditEmailGuia(false)}
                                    gap="10px"
                                >
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="emailGuia"
                                        label="Email guía"
                                        type="email"
                                        defaultValue={tutor?.extraData?.emailGuia ? tutor?.extraData?.emailGuia : ""}
                                        updateValue={updateValue}
                                    />

                                    <Flex boxSize="20px">
                                        {editEmailGuia && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>

                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onMouseEnter={() => setEditTelefonoGuia(true)}
                                    onMouseLeave={() => setEditTelefonoGuia(false)}
                                    gap="10px"
                                >
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="telefonoGuia"
                                        label="Teléfono guía"
                                        defaultValue={tutor?.extraData?.telefonoGuia ? tutor?.extraData?.telefonoGuia : ""}
                                        updateValue={updateValue}
                                    />

                                    <Flex boxSize="20px">
                                        {editTelefonoGuia && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>
                            </Box>
                        </InformationBox>
                    }
                </Flex>

                <Flex direction="column" flex="1" gap="20px">
                    <Flex direction="column" gap="10px" pl="4%">
                        <Text
                            fontWeight="600"
                            color="font"
                            fontSize="16px"
                        >
                            Matrículas a {diasMatriculasFuturas?.value} días desde el {formatTimezone({ date: fechaMatriculasFuturas, timeZone, customFormat: "dd 'de' LLLL" })}
                        </Text>

                        <Flex
                            w="100%"
                            gap="20px"
                        >
                            <Box flex="65%">
                                <FilterDateInput
                                    locale="es"
                                    onChange={setFechaMatriculasFuturas}
                                    value={fechaMatriculasFuturas}
                                />
                            </Box>

                            <Box flex="35%">
                                <FilterSelect
                                    onChange={setDiasMatriculasFuturas}
                                    options={[
                                        { label: "10 días", value: 10 },
                                        { label: "20 días", value: 20 },
                                        { label: "30 días", value: 30 },
                                        { label: "40 días", value: 40 },
                                        { label: "50 días", value: 50 },
                                        { label: "60 días", value: 60 }
                                    ]}
                                    value={diasMatriculasFuturas}
                                    placeholder="Cuantos cantidad de dias"
                                />
                            </Box>
                        </Flex>
                    </Flex>

                    <Box>
                        <SimpleLineChart
                            data={matriculasFuturas}
                        />
                    </Box>
                </Flex>
            </Flex >
    );
}
