import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { Filters } from "../../../../shared/components/Filters/Filters"
import { CustomButton } from "../../../../shared/components/CustomElements/CustomButton"
import { BiPlusCircle } from "react-icons/bi"
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession"
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum"
import { useData } from "../../../../shared/hooks/useData"
import { useClient } from "../../../../shared/context/client.context"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { Table } from "../../../../shared/components/Table/Table"
import { RutasColumns } from "./columns/RutasColumns"
import { NewRutaModal } from "../../components/NewRutaModal"

export const RutasTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.HOJAS_DE_RUTA });
    const client = useClient()
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { data: ruta, loading, Refresh } = useData({
        client,
        endpoint: EndpointTypes.RUTAS,
        query: {
            ...query
        }
    })

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Hojas de Ruta
                </Text>
            }

        >
            <Flex
                direction="column"
                p="20px"
                gap="20px"
                minH="calc(100vh - 200px)"
            >
                <Filters
                    query={query}
                    setQuery={setQuery}
                    showBody={false}
                    filterHeaders={[
                        { name: FiltrosNames.NOMBRE, type: FiltrosTypes.INPUT }
                    ]}
                    button={
                        <CustomButton
                            button={{
                                label: "Nueva hoja de ruta",
                                isDisabled: false,
                                onClick: onOpen,
                                isLoading: false,
                                leftIcon: {
                                    as: BiPlusCircle,
                                    boxSize: "22px"
                                }
                            }}
                        />
                    }
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    columns={RutasColumns()}
                    data={ruta?.data || []}
                    total={ruta?.meta?.total || 0}
                    isLoading={loading}
                />

                <NewRutaModal
                    isOpen={isOpen}
                    onClose={onClose}
                    refresh={Refresh}
                />
            </Flex>
        </Topbar>
    )
}