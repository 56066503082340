import { Flex, Skeleton } from "@chakra-ui/react"

export const ModulosSkeletons = () => {
    return (
        <Flex
            pos="relative"
            direction="column"
            h={"calc(100vh - 170px)"}
        >
            <Flex
                flexDirection="column"
                gap="15px"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c7c7c7',
                        borderRadius: '5px',
                    },
                    scrollBehavior: 'smooth',
                }}
                p="10px"
                overflow="auto"
                h="calc(100vh - 215px)"
            >
                <Skeleton w={"340px"} h="350px" rounded="8px" />
                <Skeleton w={"340px"} h="71px" rounded="8px" />
                <Skeleton w={"340px"} h="71px" rounded="8px" />
            </Flex>

            <Skeleton
                position="absolute"
                bottom="0px"
                h="40px"
                w="360px"
                rounded={"6px"}
            />
        </Flex>
    )
}