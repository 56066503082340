import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useClient } from "../../../shared/context/client.context";
import { useEffect, useRef } from "react";
import { handleUnauthorized } from "../../../shared/utils/functions/handleErrors";
import { useAuthContex } from "../../../shared/context/auth.context";
import { useNavigate } from "react-router-dom";
import { DatosModal } from "../components/DatosModal";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../shared/utils/Types/FiltrosEnum";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { Table } from "../../../shared/components/Table/Table";
import { Filters } from "../../../shared/components/Filters/Filters";
import { DatosColumns } from "./Columns/DatosColumns";
import { EntityTypes, useQuerySession } from "../../../shared/hooks/useQuerySession";

export const DatosTable = () => {
    const client = useClient();
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.DATOS });
    const { data: solicitudes, loading, status, errorsList, error, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.DATOS,
        specificQuery: {
            pendiente: "true"
        },
        query: query
    })
    const solicitudRef = useRef<any>(null)

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const onRowClick = ({ data }: any) => {
        solicitudRef.current = data;

        onOpen()
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="500"
                >
                    Cambio de Datos
                </Text>
            }
        >
            <Flex
                p="30px"
                direction="column"
                gap="20px"
            >
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    showBody={false}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={solicitudes?.data || []}
                    columns={DatosColumns()}
                    isLoading={loading}
                    total={solicitudes?.meta?.total || 0}
                    onRowClick={onRowClick}
                />

                <DatosModal
                    isOpen={isOpen}
                    onClose={onClose}
                    solicitud={solicitudRef.current}
                    Refresh={Refresh}
                />
            </Flex>
        </Topbar>
    )
}