import { EmpresasInt } from "../../interfaces/EmpresasInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addEmpresa = async ({ data, client } : { data: EmpresasInt, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    const formData = new FormData()
    formData.append('nombre', data.nombre);
    if(data?.cif) formData.append('cif', data.cif);
    formData.append('email', data.email);
    if(data?.telefono) formData.append('telefono', data.telefono);

    if(data?.extraInfo) formData.append('extraInfo', JSON.stringify(data.extraInfo));
    if(data?.interna) formData.append('interna', data.interna ? 'true' : 'false');
    if (data?.icono) formData.append('icono', data.icono as any);
    if (data?.imagen) formData.append('imagen', data.imagen as any);

    return _axios.post(
        `${tenant}/${client}/empresas`,
        formData,
        {}
    )
};

export const updateEmpresa = async ({ 
    id, 
    data, 
    client 
} : { 
    id: string, 
    data: EmpresasInt,
    client: ClientInt 
}) => { 
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/empresas/${id}`,
        data,
        {}
    )
};

export const addContactoEmpresa = async ({ data, client } : { data: { userId: string, empresaId: string, departamento: string, }, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/personasContacto`,
        data,
        {}
    )
};

export const deleteEmpresa = async ({ empresaId, client }: { empresaId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/empresas/${empresaId}`)
};