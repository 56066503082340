import { Badge, Flex, Text } from "@chakra-ui/react"
import { EmpresasInt } from "../../../../interfaces/EmpresasInt"
import { ColumnProps } from "../../../../shared/components/Table/Table"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"

export const GruposInspectores = (): ColumnProps[] => {
    const timeZone = useTimeZone();

    return [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'duracion',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start" p="16px">
                    <Badge
                        color={
                            rowData?.estado == 2 ? "font" :
                                rowData?.estado == 1 ? "light_grey"
                                    : "purewhite"
                        }
                        bg={
                            rowData?.estado == 2 ? "light_grey" :
                                rowData?.estado == 1 ? "black"
                                    : "main"
                        }
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="2px 5px"
                    >
                        {
                            rowData?.estado == 2 ? "Inactivo" :
                                rowData?.estado == 1 ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>
                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" py="16px">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.curso?.nombre}
                    </Text>
                </Flex>
            )
        },
        {
            header: 'Matrículas totales',
            field: 'matriculasTotales',
            key: 'matriculasTotales',
            sortable: true,
            body: (rowData: any) => (
                <Text
                    py="16px"
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    {rowData?.meta?.totalMatriculas} matrículas
                </Text>
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" gap="5px" py="16px">
                    {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                        <Text
                            key={index}
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {empresa?.nombre}
                        </Text>
                    ))}
                </Flex>
            )
        },
    ]
}