import { Flex, Image, Text, useToast } from "@chakra-ui/react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { BiBookReader, BiGroup, BiUserCircle } from "react-icons/bi";
import { TabPerfil } from "./TabItems/TabPerfil";
import { TabAlumnos } from "./TabItems/TabAlumnos";
import { TabGrupos } from "./TabItems/TabGrupos";
import { updateEmpresa } from "../../../../shared/middlewares/empresas.middleware";
import { Skeleton } from "primereact/skeleton";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { TabsRouterInt } from "../../../../interfaces/ComponentsInt";
import { defaultIcon } from "../../../Onboarding/components/OnboardingForm";
import { TabsRouter } from "../../../../shared/components/TabsRouter/TabsRouter";

export const TabsInformationEmpresa = () => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const { data: empresa, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.EMPRESA,
        client: client
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/empresas/${id}/perfil`,
            `/empresas/${id}/alumnos`,
            `/empresas/${id}/grupos`
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("perfil");
        }
    }, [location.pathname]);


    const updateValue = (value: any) => {
        if (!id) return;

        return updateEmpresa({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "perfil",
            label: "Perfil",
            icon: BiUserCircle,
            content:
                <TabPerfil
                    empresa={empresa}
                    updateValue={updateValue}
                    setRefresh={Refresh}
                    loading={loading}
                />
        },
        {
            isAllowed: true,
            path: "alumnos",
            label: "Alumnos",
            icon: BiBookReader,
            content:
                <TabAlumnos
                    empresa={empresa}
                />
        },
        {
            isAllowed: true,
            path: "grupos",
            label: "Grupos",
            icon: BiGroup,
            content:
                <TabGrupos
                    empresaId={empresa?.id}
                />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: true,
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.EMPRESA}
        //         />
        // }
    ]

    return (
        <Topbar
            buttonBack="/empresas"
            title={
                loading ?
                    <Flex gap="15px" w="100%" alignItems="center">
                        <Skeleton size="55px"></Skeleton>

                        <Flex direction="column" gap="5px">
                            <Skeleton height="22px" width="200px"></Skeleton>

                            <Skeleton height="18px" width="200px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px">
                        {empresa?.icono?.url ?
                            <Image
                                src={empresa?.icono?.url}
                                boxSize="45px"
                                border="none"
                                padding="0"
                                objectFit="cover"
                            />
                            :
                            <IconBox
                                icon={defaultIcon}
                                size="45px"
                                border="none"
                                padding="0"
                                bg="transparent"
                            />
                        }

                        <Flex direction="column" justifyContent="center">
                            <Flex
                                alignItems="center"
                            >
                                <Text
                                    color="font"
                                    fontSize="17px"
                                    fontWeight="600"
                                    lineHeight="128%"
                                >
                                    {empresa?.nombre}
                                </Text>
                            </Flex>

                            <Text
                                color="font"
                                fontSize="10px"
                                fontWeight="500"
                                textTransform="uppercase"
                            >
                                {empresa?.cif ? "Cif: " + empresa?.cif : ""}
                            </Text>
                        </Flex>
                    </Flex>
            }
        >
            <Flex
                direction="column"
                p="30px"
            >
                <TabsRouter
                    tabs={tabs}
                />
            </Flex>
        </Topbar>

    )
}