import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { ColumnProps } from "../../../../../shared/components/Table/Table"
import { Box, Flex, Image, Text, Link } from "@chakra-ui/react"
import { CursosInt } from "../../../../../interfaces/CursosInt"
import { defaultIcon } from "../../../../Onboarding/Onboarding"
import { Link as RouterLink } from "react-router-dom"

export const RutasColumns = () => {
    const columns: ColumnProps[] = [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.nombre}
                    link={{
                        to: `/rutas/${rowData?.id}`
                    }}
                />
            )
        },
        {
            header: 'Cursos',
            field: 'cursos',
            key: 'cursos',
            body: (rowData: any) => (
                <Link
                    to={`/rutas/${rowData?.id}`}
                    _hover={{
                        textDecoration: "none",
                    }}
                    textDecoration="none"
                    as={RouterLink}
                    display="flex"
                    alignItems="center"
                    rounded="5px"
                    py="16px"
                    px="10px"
                    color="font"
                    overflow="auto"
                    maxW={"calc(100vw - 600px)"}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        'scrollbarWidth': 'none',
                        '-ms-overflow-style': 'none',
                        scrollBehavior: 'smooth',
                    }}
                >
                    <Flex
                        alignItems="center"
                        flexWrap="nowrap"
                        width="100%"
                    >
                        {rowData?.cursos?.map((curso: CursosInt, index: number) => (
                            <Flex
                                key={index}
                                alignItems="center"
                                flexShrink={0}
                            >
                                <Flex
                                    align="center"
                                    gap="5px"
                                    bg="purewhite"
                                    shadow={"0px 4px 15px 0px rgba(199, 205, 255, 0.60)"}
                                    py="5px"
                                    px="10px"
                                    rounded="6px"
                                    flexShrink={0}
                                >
                                    <Image
                                        src={curso?.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
                                        padding="0"
                                        boxSize="30px"
                                        flexShrink={0}
                                    />

                                    <Text
                                        color="font"
                                        fontSize="13px"
                                        fontWeight="500"
                                        whiteSpace="nowrap"
                                    >
                                        {curso?.nombre}
                                    </Text>
                                </Flex>

                                {index < rowData?.cursos?.length - 1 &&
                                    <Box
                                        w="25px"
                                        h="3px"
                                        bg="secondary"
                                        flexShrink={0}
                                        mx="5px"
                                    />
                                }
                            </Flex>
                        ))}
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Grupos',
            field: 'grupos',
            key: 'grupos',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.rutaGrupos_count || 0) + " grupos"}
                    link={{
                        to: `/rutas/${rowData?.id}`
                    }}
                />
            )
        },
    ]

    return columns
}
