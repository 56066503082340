import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Text,
    ListItem,
    UnorderedList,
    Button,
} from '@chakra-ui/react'
import { useState } from 'react';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onDowload: (setState: (action: boolean) => void) => void;
}

export const InformesGeneralModal = ({
    isOpen,
    onClose,
    onDowload,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="2xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                rounded="20px"
            >
                <ModalHeader
                    mt="20px"
                    fontSize="28px"
                    fontWeight="600"
                    letterSpacing="-0.32px"
                >
                    Va a descargar TODOS los informes de TODOS los alumnos
                </ModalHeader>

                <ModalCloseButton onClick={onClose} />

                <ModalBody
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    color="font"
                    fontSize="16px"
                    fontWeight="500"
                >
                    <Text>
                        Significa que va a descargar toda información del grupo completo.
                    </Text>

                    <UnorderedList
                        pl="20px"
                    >
                        <ListItem>Expediente</ListItem>
                        <ListItem>Conexiones</ListItem>
                        <ListItem>Horarios</ListItem>
                        <ListItem>Actividades</ListItem>
                        <ListItem>Interacción en foros</ListItem>
                        <ListItem>Mensajería</ListItem>
                        <ListItem>Diplomas de los alumnos que tengan todos los requisitos cumplidos</ListItem>
                    </UnorderedList>
                </ModalBody>

                <ModalFooter
                    mt="20px"
                    justifyContent="center"
                >
                    <Button
                        isLoading={loading}
                        bg="black"
                        color="#FFF"
                        p="10px 15px"
                        h="fit-content"
                        w="fit-content"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="600"
                        _hover={{ bg: "light_grey", color: "black" }}
                        onClick={() => onDowload(setLoading)}
                    >
                        Descargar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}