import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonGuiaDidactica = () => {
    return(
        <Flex
            direction="column"
            gap="20px"
            w="full"
        >
            <Flex
                w="full"
                gap="20px"
            >
                <Skeleton w="50%" h="320px" rounded="10px" />
                <Skeleton w="50%" h="320px" rounded="10px" />
            </Flex>

            <Flex
                w="full"
                gap="20px"
            >
                <Skeleton w="50%" h="320px" rounded="10px" />
                <Skeleton w="50%" h="320px" rounded="10px" />
            </Flex>

        </Flex>
    )
}