import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EndpointTypes } from "../utils/Types/EndpointTypes";

export const dowloadBatche = async ({ id, client }: { id: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}${EndpointTypes.BATCHES}/${id}`,
        {},
        {}
    )
}