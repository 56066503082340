import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    title: string;
    onlyAccept?: boolean;
}

export const ModalAlert = ({ isOpen, onClose, onConfirm = () => { }, title, onlyAccept = false }: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="lg"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="10px"
            >
                <ModalBody
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text
                        color="font"
                        textAlign="center"
                        fontSize="20px"
                        fontWeight="600"
                        mt="45px"
                        mb="20px"
                    >
                        {title}
                    </Text>
                </ModalBody>

                {!onlyAccept ?
                    <ModalFooter justifyContent="center" gap="15px">
                        <Button
                            type="submit"
                            bg="purewhite"
                            p="10px 15px"
                            rounded="5"
                            borderWidth="1px"
                            borderColor="secondary"
                            color="secondary"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </Button>

                        <Button
                            type="submit"
                            p="10px 15px"
                            bg="secondary"
                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                            rounded="5"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={() => {
                                onConfirm()
                                onClose()
                            }}
                        >
                            Si
                        </Button>
                    </ModalFooter>
                    :
                    <ModalFooter justifyContent="center" gap="15px">
                        <Button
                            type="submit"
                            p="10px 15px"
                            bg="secondary"
                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                            rounded="5"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Aceptar
                        </Button>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    )
}