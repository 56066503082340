
import { ComponentConfig } from "@measured/puck";
import { TextVariable } from "../CustomElements/Fields/TextVariable";
import { textColors } from "../Table/constants";

export type TextProps = {
    align: "left" | "center" | "right";
    text?: string;
    padding?: string;
    size?: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
    color: "default" | "muted";
    maxWidth?: string;
    weight?: "light" | "normal" | "medium" | "semibold" | "bold";
};

export const Text: ComponentConfig<TextProps> = {
    fields: {
        text: {
            type: "custom",
            label: "Texto",
            render: ({ name, onChange, puck, value }: any) => {
                const textarea = document.querySelector('textarea[name="text"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                )
            }
        },
        size: {
            type: "select",
            options: [
                { label: "12px", value: "12px" },
                { label: "14px", value: "14px" },
                { label: "16px", value: "16px" },
                { label: "18px", value: "18px" },
                { label: "20px", value: "20px" },
                { label: "24px", value: "24px" },
                { label: "28px", value: "28px" },
                { label: "32px", value: "32px" },
                { label: "36px", value: "36px" },
                { label: "40px", value: "40px" },
                { label: "48px", value: "48px" },
                { label: "56px", value: "56px" },
                { label: "64px", value: "64px" },
                { label: "72px", value: "72px" },
                { label: "80px", value: "80px" },
            ],
        },
        weight: {
            type: "select",
            options: [
                { label: "Light", value: "light" },
                { label: "Normal", value: "normal" },
                { label: "Medium", value: "medium" },
                { label: "Semi Bold", value: "semibold" },
                { label: "Bold", value: "bold" },
            ],
        },
        align: {
            type: "radio",
            options: [
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Right", value: "right" },
            ],
        },
        color: {
            type: "select",
            options: textColors,
        },
        padding: { type: "text" },
        maxWidth: { type: "text" },
    },
    defaultProps: {
        align: "left",
        text: "Text",
        padding: "24px",
        size: "m",
        color: "default",
    },
    render: ({ align, color, text, size, padding, maxWidth, weight }) => {
        return (
            <span
                style={{
                    color: color,
                    display: "flex",
                    textAlign: align,
                    width: "100%",
                    fontSize: size,
                    fontWeight: weight === "light" ? 300 : weight === "normal" ? 400 : weight === "medium" ? 500 : weight === "semibold" ? 600 : weight === "bold" ? 700 : 400,
                    maxWidth,
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent:
                        align === "center"
                            ? "center"
                            : align === "right"
                                ? "flex-end"
                                : "flex-start",
                }}
            >
                {text}
            </span>
        );
    },
};