import { Badge } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnDate } from "../../../../shared/components/CustomColumns/ColumnDate";

export const AnunciosColumns = (): ColumnProps[] => {
    return [
        {
            header: 'titulo',
            field: 'titulo',
            key: 'titulo',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.titulo}
                />
            ),
        },
        {
            header: 'destinatario',
            field: 'destinatario',
            key: 'destinatario',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData.general ? "General" : rowData?.grupo?.nombre}
                />
            ),
        },
        {
            header: 'publicado',
            field: 'publicado',
            key: 'publicado',
            body: (rowData: any) => (
                <Badge
                    color={
                        rowData.publicado === true ? "success_main" : "font"
                    }
                    bg={
                        rowData.publicado === true ? "success_bg" : "light_grey"
                    }
                    borderColor={
                        rowData.publicado === true ? "success_variant" : "light_grey"
                    }
                    fontWeight="500"
                    fontSize="10px"
                    letterSpacing="0.2px"
                    borderRadius="14px"
                    borderWidth="1px"
                    textAlign="center"
                    width="30px"
                    p="5px 8px"
                >
                    {rowData.publicado ? "Si" : "No"}
                </Badge>
            ),
        },
        {
            header: 'Expiracion',
            field: 'fechaExpiracion',
            key: 'fechaExpiracion',
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData.fechaExpiracion}
                />
            ),
        },
        {
            header: 'Creacion',
            field: 'createdAt',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData.createdAt}
                />
            ),
        },
    ]
}