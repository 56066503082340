import { Flex } from "@chakra-ui/react";
import { CursosInt, LeccionInt, ModulosInt } from "../../../../../interfaces/CursosInt";
import { EditModule } from "../../../components/utils/EditModule";
import { useEffect, useState } from "react";
import { DndAcordeon } from "../../../components/DndAcordeon/Contenidos";
import { AddLeccion } from "../../../components/utils/AddLeccion";
import { EditLeccion } from "../../../components/utils/EditLeccion";
import { NoSelectContenido } from "../../../components/utils/NoSelecContenido";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { useClient } from "../../../../../shared/context/client.context";
import { useParams, useSearchParams } from "react-router-dom";
import { TabTemario } from "./TabTemario";
import { getData } from "../../../../../shared/middlewares/middlewares";
import tenantInstance from "../../../../../shared/services/tenantInstance.service";

export enum TipoSelect {
    MODULO = "MODULO",
    LECCION = "LECCION",
    NUEVA_LECCION = "NUEVA_LECCION"
}

interface Props {
    curso: CursosInt | undefined;
    showIndice?: boolean;
}

export const TabContenidoCurso = ({ curso, showIndice = false }: Props) => {
    const { id } = useParams();
    const client = useClient();
    const [search, setSearch] = useSearchParams();

    const { data, Refresh, loading } = useData({
        endpoint: EndpointTypes.MODULOS,
        client: client,
        specificQuery: {
            curso: id!,
            sortBy: "orden",
            order: "asc",
            limit: 100,
        },
        ignoreRequest: !id
    })

    const [refreshLeccion, setRefreshLeccion] = useState<boolean>(false)

    const [selected, setSelected] = useState<{
        type: TipoSelect,
        data: ModulosInt | LeccionInt | null
    }>()

    useEffect(() => {
        if (!selected && (search.has("leccion") || search.has("modulo"))) {
            const leccionId = search.get("leccion");
            const moduloId = search.get("modulo");

            if (leccionId && !moduloId) {
                tenantInstance().then(async (tenant) => {
                    const { data: leccion } = await getData(`${tenant}/${client}${EndpointTypes.LECCIONES}/${leccionId}`);

                    if (leccion) {
                        setSelected({
                            type: TipoSelect.LECCION,
                            data: leccion?.data
                        })
                    }
                })
            } else if (moduloId && !leccionId) {
                const modulo = data?.data?.find((m: ModulosInt) => m?.id?.toString() === moduloId);

                if (modulo) {
                    setSelected({
                        type: TipoSelect.MODULO,
                        data: modulo
                    })

                    return;
                }
            }
        }
    }, []);

    const handleSelected = ({ type, data }: { type: TipoSelect, data: ModulosInt | LeccionInt | null }) => {
        setSelected({
            type,
            data
        })

        setSearch({
            [type === TipoSelect.LECCION ? "leccion" : "modulo"]: data?.id?.toString() || ''
        })
    }

    return (
        <>
            {showIndice ?
                <TabTemario />
                :
                <Flex
                    gap="30px"
                    h={"calc(100vh - 170px)"}
                >
                    <Flex
                        position={"relative"}
                        opacity={selected?.type === TipoSelect.NUEVA_LECCION ? 0.5 : 1}
                        w="360px"
                    >
                        <DndAcordeon
                            selected={selected}
                            setSelected={handleSelected}
                            dataModulos={data?.data}
                            setRefreshLeccion={setRefreshLeccion}
                            refreshLeccion={refreshLeccion}
                            setRefreshModulos={Refresh}
                            loadingModulos={loading}
                        />

                        <Flex
                            display={selected?.type === TipoSelect.NUEVA_LECCION ? "block" : "none"}
                            pos={"absolute"}
                            bottom={"0"}
                            right={"0"}
                            top={"0"}
                            left={"0"}
                            bg={"transparent"}
                            cursor={"not-allowed"}
                        />
                    </Flex>

                    <Flex
                        flex="1"
                        p="10px"
                    >
                        {!selected &&
                            <NoSelectContenido />
                        }

                        {selected?.type === TipoSelect.MODULO &&
                            <EditModule
                                setSelected={setSelected}
                                modulo={selected?.data as ModulosInt}
                                setRefreshModulos={Refresh}
                            />
                        }

                        {selected?.type === TipoSelect.LECCION &&
                            <EditLeccion
                                setSelected={setSelected}
                                leccion={selected?.data as LeccionInt}
                                setRefreshLeccion={setRefreshLeccion}
                                curso={curso}
                            />
                        }

                        {selected?.type === TipoSelect.NUEVA_LECCION &&
                            <AddLeccion
                                data={selected?.data as Partial<LeccionInt>}
                                setRefreshLeccion={setRefreshLeccion}
                                setRefreshModulos={Refresh}
                                setSelected={setSelected}
                            />
                        }
                    </Flex>
                </Flex>
            }
        </>

    );
}