import { Accept } from "react-dropzone"

export const passTypeFile = (formats: string[]) => {
    let accept: Accept = {}

    formats?.forEach((file: string) => {
      if(file === ".rar") {
        accept = {...accept, 'application/vnd.rar': [".rar"]}
      } else if(file === ".zip") {
        accept = {...accept, 'application/zip': [".zip"]}
      } else if(file === ".png") {
        accept = {...accept, 'image/png': ['.png']}
      } else if(file === ".jpg") {
        accept = {...accept, 'image/jpg': ['.jpg']}
      } else if(file === ".jpeg") {
        accept = {...accept, 'image/jpeg': ['.jpeg']}
      } else if(file === ".gif") {
        accept = {...accept, 'image/gif': ['.gif']}
      } else if(file === ".pdf") {
        accept = {...accept, 'application/pdf': ['.pdf']}
      }
    })

    return accept
  }