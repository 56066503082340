import { Accordion, Button, Flex, Icon, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DndContext, KeyboardSensor, MeasuringStrategy, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { addModulo, updateModulo } from "../../../../shared/middlewares/modulos.middleware";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { useClient } from "../../../../shared/context/client.context";
import { useParams } from "react-router-dom";
import { DndModulos } from "./Modulos";
import { BiPlus } from "react-icons/bi";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { ModulosSkeletons } from "../Skeletons/ModulosSkeletons";

interface Props {
    dataModulos: ModulosInt[];
    selected: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined;
    setSelected: ({ type, data }: { type: TipoSelect, data: ModulosInt | LeccionInt | null }) => void;
    refreshLeccion: boolean;
    setRefreshLeccion: Dispatch<SetStateAction<boolean>>;
    setRefreshModulos: () => void;
    loadingModulos: boolean;
}
export const DndAcordeon = ({
    dataModulos,
    selected,
    setSelected,
    refreshLeccion,
    setRefreshLeccion,
    setRefreshModulos,
    loadingModulos
}: Props) => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const [modulos, setModulos] = useState<any[]>([]);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (dataModulos && dataModulos.length > 0) {
            setModulos(dataModulos)
        }

    }, [dataModulos])

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        const oldIndex = modulos?.findIndex((modulo: any) => modulo?.id === active?.id)
        const newIndex = modulos?.findIndex((modulo: any) => modulo?.id === over?.id)

        const newOrder = arrayMove(modulos, oldIndex, newIndex)

        const newModulosData: ModulosInt[] = []

        newOrder?.map((m: any, index: number) => {
            const module = {
                ...m,
                orden: (index + 1)
            }

            newModulosData.push(module)
            updateOrder(module)
        })

        setModulos(newModulosData)
    };

    const updateOrder = async (modulo: ModulosInt) => {
        if (!modulo?.id) return

        updateModulo({
            id: modulo?.id,
            data: {
                orden: modulo.orden
            },
            client: client
        })
    }

    const handleDragOver = (event: any) => {
        const overId = event?.over?.id;

        if (overId == null) {
            return;
        }

        const overContainerIndex = modulos.findIndex((modulo) => modulo.id === overId);
        const activeContainerIndex = modulos.findIndex((modulo) => modulo.id === event?.active.id);

        if (overContainerIndex !== -1 && activeContainerIndex !== -1 && activeContainerIndex !== overContainerIndex) {
            setModulos((currentModulos) => {
                const newModulos = [...currentModulos];
                const [movingItem] = newModulos.splice(activeContainerIndex, 1);
                newModulos.splice(overContainerIndex, 0, movingItem);

                return newModulos?.map((modulo, index) => ({
                    ...modulo,
                    orden: index + 1,
                }));
            });
        }
    }

    const handleAddModulo = () => {
        const lastOrden = dataModulos && dataModulos?.length || 0

        const newModulo = {
            cursoId: id!,
            nombre: `Módulo ${lastOrden}`.trim(),
            descripcion: `Este es un módulo de ejemplo, puedes borrarlo o modificar el mismo.`,
            publicado: true,
            orden: lastOrden + 1,
        }

        addModulo({
            modulo: newModulo,
            client: client
        })
            .then((response) => {
                const modulo = response?.data?.data

                setRefreshModulos()

                setSelected({
                    type: TipoSelect.MODULO,
                    data: modulo
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        loadingModulos ? <ModulosSkeletons /> :
            <Flex
                pos="relative"
                direction="column"
            >
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    measuring={{
                        droppable: {
                            strategy: MeasuringStrategy.Always,
                        },
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <Accordion
                        defaultIndex={[0]}
                        display="flex"
                        flexDirection="column"
                        gap="15px"
                        allowMultiple
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            'scrollbarWidth': 'none',
                            '-ms-overflow-style': 'none',
                            scrollBehavior: 'smooth',
                        }}
                        p="10px"
                        overflow="auto"
                    >
                        <SortableContext
                            items={modulos}
                            strategy={verticalListSortingStrategy}
                        >
                            {modulos?.map((modulo: ModulosInt, index) => (
                                <DndModulos
                                    key={modulo.id}
                                    modulo={modulo}
                                    selected={selected}
                                    setSelected={setSelected}
                                    id={modulo.id}
                                    setRefreshLeccion={setRefreshLeccion}
                                    refreshLeccion={refreshLeccion}
                                />
                            ))}
                        </SortableContext>
                    </Accordion>
                </DndContext>

                <Button
                    h="fit-content"
                    w="340px"
                    ml="10px"
                    mb="10px"
                    py="10px"
                    px="10px"
                    rounded={"6px"}
                    border="0.6px solid"
                    borderColor="#AAA"
                    bg="white"
                    fontSize="14px"
                    color="pureblack"
                    fontWeight="400"
                    leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation()

                        handleAddModulo()
                    }}
                >
                    Añadir módulo
                </Button>
            </Flex>
    );
};