import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CustomGroupTable } from "../../../../../shared/components/Table/CustomGroupTable";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../shared/context/client.context";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useData } from "../../../../../shared/hooks/useData";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useEffect, useRef } from "react";
import { handleErrors, handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { InscripcionesColumns } from "./Columns/InscripcionesColumns";
import { Table } from "../../../../../shared/components/Table/Table";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { ConvertirInscripcionModal } from "../../../components/Modals/ConvertirInscripcionModal";
import { InscripcionesInt } from "../../../../../interfaces/InscripcionesInt";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { rechazarInscripcion } from "../../../../../shared/middlewares/inscripciones.middleware";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";

export const InscripcionesTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS })
    const client = useClient();
    const { logout } = useAuthContex()
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast()
    const { data: inscripciones, Refresh, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.INSCRIPCIONES,
        query: query,
    });

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const inscripcionRef = useRef<InscripcionesInt | null>(null);

    const onEventConvertirInscripcion = (inscripcion: InscripcionesInt) => {
        inscripcionRef.current = inscripcion;

        if (isOpen) onClose();
        onOpen();
    }

    const handleRechazarInscripcion = async (inscripcionId: string | undefined) => {
        if (!inscripcionId) return;

        rechazarInscripcion({
            inscripcionId: inscripcionId,
            client: client
        }).then(() => {
            Refresh()
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Inscripción rechazada correctamente.");
        }).catch((error: any) => {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            )

            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
        })
    }

    return (

        <Flex
            direction="column"
            gap="20px"
        >
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                showBody={false}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={inscripciones?.data || []}
                columns={InscripcionesColumns({
                    onEventConvertirInscripcion,
                    rechazarInscripcion: handleRechazarInscripcion
                })}
                isLoading={loading}
                total={inscripciones?.meta?.total || 0}
            />

            <ConvertirInscripcionModal
                isOpen={isOpen}
                onClose={onClose}
                Refresh={Refresh}
                inscripcionId={inscripcionRef.current?.id ?? ""}
            />
        </Flex>
    );
};