import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { useClient } from "../../../shared/context/client.context";
import tenantInstance from "../../../shared/services/tenantInstance.service";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { TipoPlantillasEnum } from "../../../shared/utils/Types/PlantillasTypesEnum";
import { UserInt } from "../../../interfaces/UserInt";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { sendTestPlantilla, testDiplomaPlantilla } from "../../../shared/middlewares/plantillas.middleware";
import { formSelectStyles } from "../../../ui/formSelectStyles";
import { getData } from "../../../shared/middlewares/middlewares";
import { PlantillaInt } from "../../../interfaces/PlantillasInt";
import { GruposInt } from "../../../interfaces/GruposInt";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    plantilla: PlantillaInt;
}

export const TestPlantillasModal = ({ isOpen, onClose, plantilla }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>();
    const [grupoId, setGrupoId] = useState<string>();
    const [optionsMatriculas, setOptionsMatriculas] = useState<{ value: string; label: string; }[]>([]);
    const [optionsGrupos, setOptionsGrupos] = useState<{ value: string; label: string; }[]>([]);
    // const { onOpen: onOpenFinalConfig, onClose: onCloseFinalConfig, isOpen: isOpenFinalConfig } = useDisclosure();

    useEffect(() => {
        if (userId)
            loadMatriculasAlumno(userId)
                .then((response: any) => setOptionsMatriculas(response))

    }, [userId])

    useEffect(() => {
        if (userId)
            loadGrupoSupervisor(userId)
                .then((response: any) => setOptionsGrupos(response))
    }, [userId])

    const initialValues = {
        isDiploma: plantilla?.categoria === "diploma",
        userId: null,
        matriculaId: null,
        plantilla: plantilla?.nombre?.includes(TipoPlantillasEnum.TUTOR) ? TipoPlantillasEnum.TUTOR : plantilla?.nombre,
        email: null,
        porcentaje: null,
        semana: null,
    };

    const validationSchema = Yup.object({
        isDiploma: Yup.boolean(),
        userId: Yup.string()
            .required("El usuario es requerido")
            .typeError("El usuario es requerido"),
        matriculaId: Yup.string().when('plantilla', {
            is: (plantilla: TipoPlantillasEnum) =>
                plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_TUTOR) ||
                plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL),
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .required("La matricula es requerida")
                .typeError("La matricula es requerida")
        }),
        grupoId: Yup.string().when('plantilla', {
            is: (plantilla: TipoPlantillasEnum) =>
                !plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                !plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                !plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL),
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .required("El grupo es requerido")
                .typeError("El grupo es requerido")
        }),
        email: Yup.string().when('isDiploma', {
            is: (isDiploma: boolean) => isDiploma,
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .email("El email no es válido")
                .required("El email es requerido")
                .typeError("El email es requerido")
        }),
        porcentaje: Yup.number().when('plantilla', {
            is: (plantilla: TipoPlantillasEnum) => plantilla === TipoPlantillasEnum.CONTROL,
            then: (validationScheme) => validationScheme
                .required("El porcentaje es requerido")
                .typeError("El porcentaje es requerido")
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
        }),
        // semana: Yup.number().when('plantilla', {
        //     is: (plantilla: TipoPlantillasEnum) => !plantilla.includes(TipoPlantillasEnum.TUTOR),
        //     then: (validationScheme) => validationScheme
        //         .notRequired()
        //         .nullable(),
        //     otherwise: (validationScheme) => validationScheme
        //         .required("La semana es requerida")
        //         .typeError("La semana es requerida")
        // }),
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true)

        if (values?.isDiploma) {
            const diplomaTest = {
                plantillaId: plantilla?.id as string,
                matriculaId: values.matriculaId,
            }

            testDiplomaPlantilla({
                client: client,
                data: diplomaTest
            })
                .then((response) => window.open(response?.data?.url, "_blank"))
                .catch((error: any) => {
                    const errors = handleErrors(
                        error?.response?.data?.errors,
                        error?.response?.status
                    )

                    errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                })
                .finally(async () => {
                    setIsLoading(false)
                    handleClose()
                })
        } else {
            const plantillaTest = {
                userId: values.userId,
                matriculaId: values.matriculaId,
                grupoId: grupoId!,
                plantilla: values.plantilla,
                email: values.email,
                datos: {
                    porcentaje: Number(values.porcentaje),
                    semana: plantilla?.nombre?.includes(TipoPlantillasEnum.TUTOR) ? Number(plantilla?.nombre.replace("tutor", "")) : null
                }
            }

            sendTestPlantilla({
                client: client,
                data: plantillaTest
            })
                .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, `Email enviado correctamente a ${values.email}`))
                .catch((error: any) => {
                    const errors = handleErrors(
                        error?.response?.data?.errors,
                        error?.response?.status
                    )

                    errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                })
                .finally(async () => {
                    setIsLoading(false)
                    handleClose()

                    // TODO: COMO SE PASO EL PROBAR PLANTILLAS DENTRO DE :/ID HAY QUE VOLVER A PLANTEAR EL ONBOARDING EN EL GENERAL DE PLANTILLAS
                    // if (!user?.config?.plantillas && total) {
                    //     await updateUsuario({
                    //         id: user?.id as string,
                    //         client: client,
                    //         data: {
                    //             config: {
                    //                 ...user?.config,
                    //                 plantillas: true
                    //             }
                    //         }
                    //     })
                    //         .then(() => {
                    //             refreshUser({
                    //                 config: {
                    //                     ...user?.config,
                    //                     plantillas: true
                    //                 }
                    //             })
                    //         })
                    //         .finally(() => onOpenFinalConfig())
                    // }
                })
        }
    }

    const loadUsers = async (value: string, type: "alumnos" | "tutores" | "supervisores") => {
        const tenant = await tenantInstance()
        let query = type === "alumnos" ? "/users" : type === "tutores" ? "/tutores" : "/supervisores"

        if (value && value !== "") query = query + `?nombreYemail=${value}`

        const { data } = await getData(tenant + "/" + client + query)

        const users = data?.data?.data.map((user: UserInt) => ({
            value: user?.id,
            label: `${user?.nombre} ${user?.apellidos}`,
        }));

        return users;
    }

    const loadMatriculasAlumno = async (userId: string | null) => {
        if (!userId) return []

        const tenant = await tenantInstance()
        const query = "/matriculas" + `?user=${userId}`


        const { data } = await getData(tenant + "/" + client + query)

        const matriculas = data?.data?.data.map((matricula: MatriculasInt) => ({
            value: matricula?.id,
            label: matricula?.grupo?.nombre,
            grupoId: matricula?.grupo?.id
        }));

        return matriculas;
    }

    const loadGrupoSupervisor = async (userId: string | null) => {
        if (!userId) return []

        const tenant = await tenantInstance()
        const query = "/supervisores/" + userId + "/grupos"


        const { data } = await getData(tenant + "/" + client + query)

        const grupos = data?.data?.data.map((grupo: GruposInt) => ({
            value: grupo?.id,
            label: grupo?.nombre,
        }));

        return grupos;
    }

    const handleClose = () => {
        setUserId(undefined)
        onClose()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            {plantilla?.categoria === "diploma" ? "Descargar plantilla diploma" : "Enviar plantilla email"}
                        </ModalHeader>

                        <ModalCloseButton onClick={() => handleClose()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="2px"
                                            >
                                                <FormAsyncSelect
                                                    name="userId"
                                                    label={
                                                        values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_TUTOR)
                                                            ? "Tutor"
                                                            : (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? "Supervisor"
                                                                : "Alumno"
                                                    }
                                                    defaultOptions={true}
                                                    defaultValue={values?.userId}
                                                    loadOptions={(value: string) =>
                                                        values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_TUTOR)
                                                            ? loadUsers(value, "tutores")
                                                            : (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? loadUsers(value, "supervisores")
                                                                : loadUsers(value, "alumnos")
                                                    }
                                                    placeholder={
                                                        values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_TUTOR)
                                                            ? "Tutor"
                                                            : (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? "Supervisor"
                                                                : "Alumno"
                                                    }
                                                    onChangeCallback={(e: any) => {
                                                        setUserId(e?.value)
                                                    }}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles
                                                    }}
                                                />

                                                {!values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_TUTOR) &&
                                                    <FormSelect
                                                        isDisabled={!values?.userId}
                                                        name={
                                                            (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? "grupoId"
                                                                : "matriculaId"
                                                        }
                                                        label={
                                                            (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? "Grupo del supervisor"
                                                                : "Matrícula del alumno"
                                                        }
                                                        options={
                                                            (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? optionsGrupos
                                                                : optionsMatriculas
                                                        }
                                                        placeholder={
                                                            (
                                                                values?.plantilla?.includes(TipoPlantillasEnum.BIENVENIDA_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.CONTROL_SUPERVISOR) ||
                                                                values?.plantilla?.includes(TipoPlantillasEnum.RESUMEN_SEMANAL)
                                                            )
                                                                ? "Grupo del supervisor"
                                                                : "Matrícula del alumno"
                                                        }
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                        onChangeCallback={(e: { value: string, grupoId: string, label: string }) => {
                                                            setGrupoId(e.value)
                                                        }}
                                                    />
                                                }

                                                {values?.plantilla === TipoPlantillasEnum.CONTROL &&
                                                    <FormSelect
                                                        name="porcentaje"
                                                        label="Porcentaje control"
                                                        options={[
                                                            { value: 25, label: "25%" },
                                                            { value: 50, label: "50%" },
                                                            { value: 75, label: "75%" },
                                                        ]}
                                                        placeholder="Porcentaje control"
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                }

                                                {/* {values?.plantilla?.includes(TipoPlantillasEnum.TUTOR) &&
                                                    <FormSelect
                                                        name="semana"
                                                        label="Semana"
                                                        options={[
                                                            { value: 1, label: "1" },
                                                            { value: 2, label: "2" },
                                                            { value: 3, label: "3" },
                                                            { value: 4, label: "4" },
                                                            { value: 5, label: "5" },
                                                            { value: 6, label: "6" },
                                                        ]}
                                                        placeholder="Semana"
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                } */}

                                                {!values?.isDiploma &&
                                                    <FormInput
                                                        name="email"
                                                        label="Email"
                                                        placeholder="Email"
                                                    />
                                                }
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Enviar
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            {/* 
                // TODO: COMO SE PASO EL PROBAR PLANTILLAS DENTRO DE :/ID HAY QUE VOLVER A PLANTEAR EL ONBOARDING EN EL GENERAL DE PLANTILLAS
                <ModalFinalConfig
                    isOpen={isOpenFinalConfig}
                    onClose={onCloseFinalConfig}
                /> 
            */}
        </>
    );
}