import { Flex, Skeleton } from "@chakra-ui/react";

export default function MatriculasSkeleton({ moduloFUNDAE, isSupervisor }: { moduloFUNDAE: boolean, isSupervisor?: boolean }) {
    return (
        <Flex gap="20px">
            <Flex direction="column" flex="1" gap="20px">

                <Skeleton height="320px" rounded="10px" />
                <Skeleton height="342px" rounded="10px" />

            </Flex>

            <Flex direction="column" flex="1" gap="20px">
                <Skeleton height="460px" rounded="10px" />
                {moduloFUNDAE || !isSupervisor &&
                    <Skeleton height="300px" rounded="10px" />
                }
            </Flex>
        </Flex>
    )
}
