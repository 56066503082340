import { truncate } from "lodash";
import { getData } from "../../middlewares/middlewares";
import { ClientInt } from "../../services/http.service";
import tenantInstance from "../../services/tenantInstance.service"
import { EndpointTypes, RelationshipTypes } from "../Types/EndpointTypes"
import { QueryTypes } from "../Types/QueryTypes";
import { checkQuerys } from "./checkQuerys";

export const loadData = async ({
    value,
    client,
    endpoint,
    query,
    specificQuery = null,
    nombreCompleto = false,
    id,
    relationship,
    limit = 10,
    extraData = false
}: {
    value: string;
    client: ClientInt;
    endpoint: EndpointTypes;
    query: QueryTypes;
    specificQuery?: QueryTypes | null;
    nombreCompleto?: boolean;
    id?: string | undefined;
    relationship?: RelationshipTypes;
    limit?: number;
    extraData?: boolean;
}) => {
    const tenant = await tenantInstance()
    const relationQuery = (relationship && id) ? "/" + id + relationship : ""
    let newQuery: string = ""

    if (specificQuery) newQuery = specificQuery
    if ((value && value !== "") && specificQuery) newQuery = newQuery + checkQuerys(query) + value
    else if ((value && value !== "") && !specificQuery) newQuery = newQuery + query + value

    if(newQuery === "") newQuery = `?limit=${limit}`
    else newQuery = newQuery + `&limit=${limit}` 

    const { data } = await getData(
        tenant + "/" + client + endpoint + relationQuery + newQuery
    )
    
    if(nombreCompleto)
        return data?.data?.data.map((item: any) => ({
            value: item.id,
            label: item.nombre + " " + item.apellidos,
            data: item
        }));

    if(extraData) 
        return data?.data?.data.map((item: any) => ({
            value: item.id,
            label: truncate(item?.nombre + " - " + item?.curso?.nombre + (item?.empresas && item?.empresas?.length > 0 ? " - " + item?.empresas[0]?.nombre : ""), { length: 48}),
            data: item
        }));    

    return data?.data?.data.map((item: any) => ({
        value: item.id,
        label: item.nombre,
        data: item
    }));
}
