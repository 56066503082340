import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, FormLabel, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { useClient } from "../../../../shared/context/client.context";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { updateGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { capitalizeFirst } from "../../../../shared/utils/functions/capitalizeFirst";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { useData } from "../../../../shared/hooks/useData";
import { getKey } from "../../../../shared/middlewares/config.middleware";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { formSelectStyles } from "../../../../ui/formSelectStyles";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    grupo: GruposInt | undefined;
    Refresh: () => void;
    setSwitchFormacion: (value: boolean) => void;
}
export const ModalFundae = ({
    isOpen,
    onClose,
    grupo,
    Refresh,
    setSwitchFormacion
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const { user } = useAuthContex();
    const { gestion, contenido } = useUserPolicy();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const [gestionaEmpresa, setGestionaEmpresa] = useState<boolean>(false)
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const initialValues = {
        empresaOrganizadoraId: null,
        gestionaEmpresa: gestionaEmpresa,
    };

    const validationSchema = Yup.object({
        gestionaEmpresa: Yup.boolean(),
        empresaOrganizadoraId: Yup.string().when(['gestionaEmpresa'], {
            is: (gestionaEmpresa: boolean) => gestionaEmpresa,
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .required('Debe seleccionar una empresa organizadora')
                .typeError('Debe seleccionar una empresa organizadora'),
        }),
    });

    const handleSubmit = async (values: any) => {
        if (!grupo?.id) return;
        setIsLoading(true)

        let newValues: {
            fundae: boolean;
            gestionaEmpresa: boolean;
            empresaOrganizadoraId?: string | null;

        } = {
            fundae: true,
            gestionaEmpresa: gestionaEmpresa,
        }

        if (!gestionaEmpresa) {
            newValues = {
                ...newValues,
                empresaOrganizadoraId: values?.empresaOrganizadoraId,
            }
        }

        updateGrupo({
            id: grupo?.id,
            data: newValues,
            client: client
        })
            .then(() => {
                for (const [key, value] of Object.entries(newValues)) {
                    toastNotify(toast, StatusEnumTypes.SUCCESS, `${[capitalizeFirst(key)]} se ha actualizado correctamente`)
                }

                setTimeout(() => Refresh(), 100)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
                handleClose(true)
            })
    }

    const handleClose = (isConfirm = false) => {
        onClose()
        setGestionaEmpresa(false)

        if (!isConfirm) setSwitchFormacion(false)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                        roundedTop="20px"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                        >
                            FUNDAE
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        m="10px"
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody>
                                            <Flex direction="column" gap="15px" mb="30px">

                                                <Flex direction="column" gap="20px">
                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <FormLabel
                                                            htmlFor="gestionaEmpresa"
                                                            display="flex"
                                                            gap="3px"
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="400"
                                                            textTransform="capitalize"
                                                        >
                                                            Gestionado por
                                                        </FormLabel>

                                                        <CustomSwitch
                                                            value={!gestionaEmpresa}
                                                            setValue={setGestionaEmpresa}
                                                            optionLeft="Empresa Organizadora"
                                                            optionRight="Empresa Bonificada"
                                                            height="25px"
                                                            width="50%"
                                                            padding="0"
                                                        />
                                                    </Flex>

                                                    {(gestionaEmpresa !== null && !gestionaEmpresa) &&
                                                        <FormSelect
                                                            options={(empresas === undefined || empresas === null)
                                                                ? []
                                                                : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                            }
                                                            name="empresaOrganizadoraId"
                                                            label="Empresa Organizadora"
                                                            placeholder="Empresa asociada"
                                                            isRequired={!values.empresaOrganizadoraId ? true : false}
                                                            ui={{
                                                                formSelectStyles: formSelectStyles,
                                                            }}
                                                        />
                                                    }
                                                </Flex>
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="600"
                                                _hover={{ color: "black", backgroundColor: "light_grey" }}
                                            >
                                                Modificar
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />
        </>
    )
}