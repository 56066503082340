import {
    Flex,
    Box,
    Text,
    FormControl,
    FormLabel,
    Icon,
    Tooltip
} from '@chakra-ui/react'
import { Field } from 'formik';
import { useState, useEffect } from 'react'
import { BiTime } from 'react-icons/bi';

export type TimePickerProps = {
    onChange: (time: any) => void
    name: string;
    label?: string;
    isDisabled?: boolean;
    time?: string;
    date?: string;
    disabledTooltip?: string;
};

export function FormNewTimeInput({
    onChange,
    name,
    label,
    isDisabled = false,
    time,
    date,
    disabledTooltip = "No se puede seleccionar un horario"
}: TimePickerProps) {
    const [selectedTime, setSelectedTime] = useState<string>(time || '')
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (date) {
            const fechaDate = new Date(date);
            const horaMinutos = `${fechaDate.getHours().toString().padStart(2, '0')}:${fechaDate.getMinutes().toString().padStart(2, '0')}`;
            time ? setSelectedTime(time) : setSelectedTime(horaMinutos);
        } else if (time) {
            setSelectedTime(time);
        }
    }, [date, time]);

    const handleTimeChange = (newTime: string) => {
        setSelectedTime(newTime);

        if (date) {
            const fechaDate = new Date(date);
            const [horas, minutos] = newTime.split(':');
            fechaDate.setHours(parseInt(horas, 10), parseInt(minutos, 10));
            onChange(fechaDate);
        } else {
            onChange(newTime);
        }
    };

    const generarHoras = (): string[] => {
        const horas: string[] = [];
        for (let hora = 8; hora < 32; hora++) {
            for (let minuto = 0; minuto < 60; minuto += 15) {
                const horaFormateada = `${(hora % 24).toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
                horas.push(horaFormateada);
            }
        }

        return horas;
    };

    const horas = generarHoras();

    useEffect(() => {
        if (isOpen) {
            const el = document.getElementById("time-picker");
            if (el) {
                const selectedElement = el.querySelector(`[data-hour="${selectedTime}"]`);
                if (selectedElement) {
                    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                }
            }
        }
    }, [isOpen, selectedTime]);

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl isInvalid={form.errors[name] && form.touched[name]} isDisabled={isDisabled}>
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {label}
                        </FormLabel>
                    }

                    <Tooltip
                        label={disabledTooltip}
                        placement='bottom'
                        hasArrow
                        fontSize="12px"
                        color="purewhite"
                        bg="pureblack"
                        borderRadius="6px"
                        p="5px 10px"
                        textAlign="center"
                        isDisabled={!isDisabled}
                    >
                        <Flex position="relative">
                            <Box
                                as="input"
                                value={selectedTime}
                                readOnly
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation()

                                    !isDisabled && setIsOpen((prev) => !prev)
                                }}
                                cursor={isDisabled ? "not-allowed" : "pointer"}
                                opacity={isDisabled ? 0.4 : 1}
                                border="1px solid"
                                borderColor="#EAEAEA"
                                borderRadius="md"
                                p={2}
                                width="100%"
                                bg="purewhite"
                                textAlign="center"
                                fontSize="md"
                                height="40px"
                                _focus={{
                                    borderColor: "#EAEAEA",
                                    outline: "none",
                                    boxShadow: "none",
                                }}
                            />
                            <Flex
                                as="button"
                                type="button"
                                position="absolute"
                                justifyContent="center"
                                alignItems="center"
                                right="0"
                                top="50%"
                                transform="translateY(-50%)"
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    !isDisabled && setIsOpen((prev) => !prev);
                                }}
                                bg="black"
                                color="white"
                                roundedRight="6px"
                                boxSize="40px"
                                opacity={isDisabled ? 0.4 : 1}
                                cursor={isDisabled ? "not-allowed" : "pointer"}
                            >
                                <Icon
                                    as={BiTime}
                                    color="purewhite"
                                    boxSize="18px"
                                />
                            </Flex>
                        </Flex>
                    </Tooltip>

                    {isOpen && (
                        <Box
                            id="time-picker"
                            position="absolute"
                            zIndex={9999}
                            bg="purewhite"
                            borderRadius="md"
                            mt={2}
                            p={3}
                            maxHeight="200px"
                            overflowY="auto"
                            width="100%"
                            boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
                        >
                            {horas.map((hora, index) => (
                                <Flex
                                    key={index}
                                    cursor="pointer"
                                    _hover={{ bg: "main", color: "purewhite" }}
                                    bg={hora === selectedTime ? "secondary" : "purewhite"}
                                    color={hora === selectedTime ? "purewhite" : "font"}
                                    p="5px 7px"
                                    alignItems="center"
                                    justifyContent="center"
                                    rounded="8px"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation()

                                        setSelectedTime(hora);
                                        handleTimeChange(hora);
                                        setIsOpen(false);
                                    }}
                                    data-hour={hora}
                                    textAlign="center"
                                    fontSize="16px"
                                >
                                    {hora}
                                </Flex>
                            ))}
                        </Box>
                    )}

                    <Box h="18px" w="100%" mt="2px">
                        {form.touched[name] && form.errors[name] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                {form.errors[name]}
                            </Text>
                        )}
                    </Box>
                </FormControl>
            )}
        </Field>
    );
}
