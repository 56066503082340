import { Box, Flex, Image } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";

export const CursosColumns = (): ColumnProps[] => {
    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <Flex align="center" gap="15px" ml="16px" py="16px">
                    {rowData?.icono?.url ?
                        <Image
                            src={rowData?.icono?.url}
                            padding="0"
                            boxSize="50px"
                        />
                        : undefined
                    }

                    <Box
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.nombre}
                    </Box>
                </Flex>
            ),
        },
        {
            header: 'grupos activos',
            field: 'gruposActivos',
            key: 'gruposActivos',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={rowData?.meta?.totalGruposActivos + " grupos"}
                />
        },
        {
            header: 'grupos asignados',
            field: 'gruposAsignados',
            key: 'gruposAsignados',
            body: (rowData: any) =>
                <ColumnSimpleText
                    text={rowData?.meta?.totalGrupos + " grupos"}
                />
        },
    ];
}