import React from 'react';

interface Props {
    tiempoAcceso: number;
}

const TiempoFormato: React.FC<Props> = ({ tiempoAcceso }) => {
    function segundosAHoraMinutos(segundos: number): string {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);

        const horasFormateadas = horas < 10 ? `0${horas}` : `${horas}`;
        const minutosFormateados = minutos < 10 ? `0${minutos}` : `${minutos}`;

        const horaMinutos = `${horasFormateadas}:${minutosFormateados}`;

        return horaMinutos;
    }

    const tiempoHHMM = segundosAHoraMinutos(tiempoAcceso);

    return tiempoHHMM;
};

export default TiempoFormato;
