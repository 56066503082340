import { Flex } from "@chakra-ui/react";
import { useClient } from "../../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { Table } from "../../../../../shared/components/Table/Table";
import { ForoColumns } from "./Columns/ForoColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";

export const ForoTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.FORO })
    const { data: preguntas, loading } = useData({
        client: client,
        endpoint: EndpointTypes.PREGUNTAS,
        specificQuery: {
            sinRespuestas: "true",
            limit: 50,
        },
        query: query
    })

    return (
        <Flex direction="column" gap="20px">
            <Table
                query={query}
                setQuery={setQuery}
                data={preguntas?.data || []}
                columns={ForoColumns()}
                isLoading={loading}
                total={preguntas?.meta?.total || 0}
                emptyMessage={EmptyMessageType.foros}
            />
        </Flex>
    )
}