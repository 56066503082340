import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Text,
    ListItem,
    UnorderedList,
    Button,
} from '@chakra-ui/react'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onCloseIngreso: () => void;
    onLogin: () => void;
    isLoading: boolean;
}

export const IngresoAlumno = ({
    isOpen,
    onClose,
    onCloseIngreso,
    onLogin,
    isLoading
}: Props) => {

    const handleClose = () => {
        onClose()
        onCloseIngreso()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="2xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                rounded="20px"
            >
                <ModalHeader
                    mt="20px"
                    fontSize="28px"
                    fontWeight="600"
                    letterSpacing="-0.32px"
                >
                    Va a acceder en modo SÓLO LECTURA
                </ModalHeader>

                <ModalCloseButton onClick={onClose} />

                <ModalBody
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    color="font"
                    fontSize="16px"
                    fontWeight="500"
                >
                    <Text>
                        Significa que podrá ver lo mismo que el propio alumno, pero no podrá hacer ninguna acción que interfiera en el resultado del curso, lo cual implica:
                    </Text>

                    <UnorderedList
                        pl="20px"
                    >
                        <ListItem>No se registrará la conexión ni el tiempo que esté conectado</ListItem>
                        <ListItem>No podrá marcar las actividades como terminadas</ListItem>
                        <ListItem>No podrá realizar tests</ListItem>
                        <ListItem>No podrá realizar ejercicios</ListItem>
                        <ListItem>No podrá enviar mensajes</ListItem>
                        <ListItem>No podrá participar en foros</ListItem>
                        <ListItem>Los paquetes SCORM se verán, pero no cargarán las funciones que requieran escritura en la base de datos.</ListItem>
                    </UnorderedList>
                </ModalBody>

                <ModalFooter
                    mt="20px"
                    justifyContent="center"
                >
                    <Button
                        bg="black"
                        color="#FFF"
                        p="10px 15px"
                        h="fit-content"
                        w="fit-content"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="600"
                        _hover={{ bg: "light_grey", color: "black" }}
                        onClick={onLogin}
                        isLoading={isLoading}
                    >
                        Acceder
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}