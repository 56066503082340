import { Flex } from "@chakra-ui/react"
import { useClient } from "../../../../shared/context/client.context";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { Table } from "../../../../shared/components/Table/Table";
import { AsignacionesLiquidadasColumns } from "./Colums/AsignacionesLiquidadasColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";

export const AsignacionesLiquidadasTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ASIGNACIONES_LIQUIDADAS });
    const { data, loading } = useData({
        client: client,
        endpoint: EndpointTypes.ASIGNACIONES,
        specificQuery: {
            conLiquidacion: "true",
            sortBy: "matriculas.fecha_inicio",
            order: "asc"
        },
        query: query
    });

    return (
        <Flex direction="column" gap="20px" w="100%">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterElements={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                    {
                        name: FiltrosNames.CURSO,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_INICIO_SUP,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_FIN_INF,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.ESTADO_LIQUIDACION,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.ASIGNACION_EMPRESA,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={data?.data || []}
                columns={AsignacionesLiquidadasColumns()}
                isLoading={loading}
                total={data?.meta?.total || 0}
            />
        </Flex>
    )
}