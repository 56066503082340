import { useQuery } from "@tanstack/react-query"
import queryString from 'query-string';
import _axios, { ClientInt } from "../services/http.service";
import { omit } from 'lodash';
import { EndpointTypes, RelationshipTypes } from "../utils/Types/EndpointTypes";
import { getData } from "../middlewares/middlewares";
import tenantInstance from "../services/tenantInstance.service";

const tenant = await tenantInstance();

type AdonisErrorInt = {
    field: string;
    message: string;
    rule: string;
};

declare module '@tanstack/react-query' {
    interface Register {
        defaultError: { response: { status: number, data: { errors: any[] } } };
    }
}
interface UseDataIdProps {
    id: string | undefined;
    client: ClientInt;
    endpoint: EndpointTypes;
    relationship: RelationshipTypes;
    specificQuery?: Record<string, string | number | string[]> | undefined;
    query?: Record<string, string | number | string[]>;
    ignoreRequest?: boolean;
}

export const useDataRelationship = ({
    id,
    client,
    endpoint,
    relationship,
    specificQuery,
    query = {},
    ignoreRequest = false,
}: UseDataIdProps) => {
    const cleanQuery = omit(query, ['tabIndex']);
    let finalEndpoint = `${tenant}/${client}${endpoint}/${id}${relationship}`;

    if (specificQuery) finalEndpoint += `?${queryString.stringify({ ...specificQuery, ...cleanQuery })}`;
    else if (Object.keys(cleanQuery).length) {
        finalEndpoint += `?${queryString.stringify(cleanQuery)}`;
    }

    const queryKey = [endpoint, client, id, relationship, { ...cleanQuery, ...specificQuery }];

    const {
        data,
        isLoading,
        error,
        refetch,
    } = useQuery({
        queryKey,
        queryFn: () => getData(finalEndpoint),
        enabled: !!id && !!relationship && !ignoreRequest,
        refetchOnWindowFocus: true,
        retry: 1,
    })

    return {
        data: data?.data?.data,
        loading: isLoading,
        error: error,
        Refresh: refetch,
        status: !error ? data?.status : error?.response?.status,
        errorsList: error?.response?.data?.errors as AdonisErrorInt[],
    }
}