import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EndpointTypes } from "../utils/Types/EndpointTypes";

export const getKey = async (key: string, client: ClientInt) => {
    const tenant = await tenantInstance();

    return _axios.get(
        `${tenant}/${client}${EndpointTypes.CAMPUS_CONFIG_KEY}/${key}`,

    ).then((response: any) => response);
}

export const updateCampusConfig = async ({ data, client }: { data: Record<string, string | object>; client: ClientInt; }) => {
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}${EndpointTypes?.CAMPUS_CONFIG_UPDATE}`,
        data,
        {}
    ).then((response: any) => response);
}