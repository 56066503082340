import { ComponentConfig } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { textColors } from '../constants';
import { TextVariable } from "../../CustomElements/Fields/TextVariable";

const getClassName = getClassNameFactory("EmailText", styles);


export type EmailTextBlockProps = {
    text: string;
    variable?: string | null;
    align: "left" | "center" | "right";
    color: string;
    fontSize: string;
    lineHeight: string;
    letterSpacing: string;
    fontWeight: string;
    marginX: string;
    marginY: string;
};

export const EmailTextBlock: ComponentConfig<EmailTextBlockProps> = {
    fields: {
        text: {
            type: "custom",
            label: "Texto",
            render: ({ name, onChange, puck, value }: any) => {
                const textarea = document.querySelector('textarea[name="text"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                )
            }
        },
        align: {
            type: "radio",
            label: "Alineación",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ],
        },
        color: {
            type: "select",
            label: "Color del texto",
            options: textColors
        },
        fontSize: {
            type: "select",
            label: "Tamaño de fuente",
            options: [
                { label: "12px", value: "12px" },
                { label: "14px", value: "14px" },
                { label: "16px", value: "16px" },
                { label: "18px", value: "18px" },
                { label: "20px", value: "20px" },
                { label: "24px", value: "24px" },
                { label: "28px", value: "28px" },
                { label: "32px", value: "32px" },
                { label: "36px", value: "36px" },
                { label: "40px", value: "40px" },
                { label: "48px", value: "48px" },
                { label: "56px", value: "56px" },
                { label: "64px", value: "64px" },
                { label: "72px", value: "72px" },
                { label: "80px", value: "80px" },
            ],
        },
        lineHeight: {
            type: "select",
            label: "Altura de línea",
            options: [
                { label: "1", value: "1" },
                { label: "1.25", value: "1.25" },
                { label: "1.5", value: "1.5" },
                { label: "1.75", value: "1.75" },
                { label: "2", value: "2" },
            ],
        },
        letterSpacing: {
            type: "select",
            label: "Espaciado entre letras",
            options: [
                { label: "Normal", value: "normal" },
                { label: "-0.5px", value: "-0.5px" },
                { label: "0.5px", value: "0.5px" },
                { label: "1px", value: "1px" },
            ],
        },
        fontWeight: {
            type: "select",
            label: "Peso de fuente",
            options: [
                { label: "Normal", value: "normal" },
                { label: "Medio", value: "500" },
                { label: "Semi-negrita", value: "600" },
                { label: "Negrita", value: "bold" },
            ],
        },
        marginX: {
            type: "select",
            label: "Margen lateral",
            options: [
                { label: "0", value: "0" },
                { label: "5", value: "5px" },
                { label: "10", value: "10px" },
                { label: "15", value: "15px" },
                { label: "20", value: "20px" },
                { label: "25", value: "25px" },
                { label: "30", value: "30px" },
            ],
        },
        marginY: {
            type: "select",
            label: "Margen vertical",
            options: [
                { label: "0", value: "0" },
                { label: "5", value: "5px" },
                { label: "10", value: "10px" },
                { label: "15", value: "15px" },
                { label: "20", value: "20px" },
                { label: "25", value: "25px" },
                { label: "30", value: "30px" },
            ],
        },
    },
    defaultProps: {
        text: "Texto de ejemplo",
        variable: null,
        align: "left",
        color: "#333333",
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "normal",
        fontWeight: "normal",
        marginX: "0px",
        marginY: "0px",
    },
    render: ({
        text,
        align,
        color,
        fontSize,
        lineHeight,
        letterSpacing,
        fontWeight,
        marginX,
        marginY,
    }: EmailTextBlockProps) => {

        return (
            <table
                className={getClassName()}
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{
                    marginLeft: marginX,
                    marginRight: marginX,
                    marginTop: marginY,
                    marginBottom: marginY,
                }}
            >
                <tr>
                    <td align={align}>
                        <span
                            style={{
                                color,
                                fontSize,
                                lineHeight,
                                letterSpacing,
                                fontWeight,
                                marginLeft: "auto",
                                marginRight: "auto",
                                justifyContent:
                                    align === "center"
                                        ? "center"
                                        : align === "right"
                                            ? "flex-end"
                                            : "flex-start",
                            }}
                        >
                            {text}
                        </span>
                    </td>
                </tr>
            </table>
        );
    },
}; 