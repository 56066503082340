import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { ConfigInformation } from "./Config/Information";
import { MdOutlineVideoSettings } from "react-icons/md";
import { TabsRouterInt } from "../../../interfaces/ComponentsInt";
import { BiBuilding, BiPlusCircle } from "react-icons/bi";
import { EmpresasOrganizadoras } from "./Empresas/EmpresasOrganizadoras";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { PerfilEmpresaInterna } from "../components/PerfilEmpresaInterna";

export const TabsConfig = () => {
    const { isOpen: isOpenNew, onClose: onCloseNew, onOpen: onOpenNew } = useDisclosure()
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            "/configuracion/mis-empresas",
            "/configuracion/campus"
        ];

        const isSubPath = subPaths.includes(location.pathname) || location.pathname.includes("/configuracion/mis-empresas/");

        if (!isSubPath) {
            navigate("/configuracion/mis-empresas");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "mis-empresas",
            label: "Mis empresas",
            icon: BiBuilding,
            content:
                <EmpresasOrganizadoras
                    onCloseNew={onCloseNew}
                    isOpenNew={isOpenNew}
                />
        },
        {
            isAllowed: true,
            path: "campus",
            label: "Configuración general",
            icon: MdOutlineVideoSettings,
            content:
                <ConfigInformation />
        },
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Configuración
                </Text>
            }
        >
            <Flex
                w="100%"
                p="20px 40px"
                minH={"calc(100vh - 70px)"}
                pos={"relative"}
            >
                <TabsRouter
                    tabs={tabs}
                    buttons={
                        [
                            {
                                isAllowed: true,
                                paths: ["/mis-empresas"],
                                button: {
                                    label: "Agregar empresa",
                                    onClick: onOpenNew,
                                    leftIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "20px"
                                    },
                                    isDisabled: false,
                                },
                                tooltip: {
                                    label: "Crea una nueva empresa interna para gestionar tus grupos.",
                                    isDisabled: false,
                                }
                            }
                        ]
                    }
                />
            </Flex>
        </Topbar>
    )
}