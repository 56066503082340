import { ComponentConfig } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { backgroundColors, textColors } from '../constants';
import { TextVariable } from "../../CustomElements/Fields/TextVariable";

const getClassName = getClassNameFactory("EmailHeader", styles);

export type EmailHeaderProps = {
    logoSrc: string;
    logoAlt: string;
    logoWidth: string;
    logoHeight: string;
    logoAlign: "left" | "center" | "right";
    showTitle: boolean;
    titleText: string;
    titleFontSize: string;
    titleFontWeight: "normal" | "bold" | "600" | "700";
    titleColor: string;
    titleAlign: "left" | "center" | "right";
    backgroundColor: string;
    padding: string;
    borderBottom: string;
};

export const EmailHeader: ComponentConfig<EmailHeaderProps> = {
    fields: {
        logoSrc: {
            type: "text",
            label: "URL del logo"
        },
        logoAlt: {
            type: "text",
            label: "Texto alternativo"
        },
        logoWidth: {
            type: "select",
            label: "Ancho del logo",
            options: [
                { label: "100px", value: "100px" },
                { label: "120px", value: "120px" },
                { label: "150px", value: "150px" },
                { label: "180px", value: "180px" },
                { label: "200px", value: "200px" },
                { label: "250px", value: "250px" },
                { label: "300px", value: "300px" },
                { label: "350px", value: "350px" },
                { label: "400px", value: "400px" },
                { label: "Completo", value: "100%" }
            ]
        },
        logoHeight: {
            type: "select",
            label: "Alto del logo",
            options: [
                { label: "Auto", value: "auto" },
                { label: "30px", value: "30px" },
                { label: "40px", value: "40px" },
                { label: "50px", value: "50px" },
            ]
        },
        logoAlign: {
            type: "radio",
            label: "Alineación del logo",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ]
        },
        showTitle: {
            type: "radio",
            label: "Mostrar título",
            options: [
                { label: "Sí", value: true },
                { label: "No", value: false },
            ]
        },
        titleText: {
            type: "custom",
            label: "Texto del título",
            render: ({ name, onChange, value }: any) => {
                const textarea = document.querySelector('textarea[name="titleText"]') as HTMLTextAreaElement;

                return (
                    <TextVariable
                        textarea={textarea}
                        value={value}
                        name={name}
                        onChange={onChange}
                        minHeight="47px"
                    />
                )
            }
        },
        titleFontSize: {
            type: "select",
            label: "Tamaño de fuente del título",
            options: [
                { label: "20px", value: "20px" },
                { label: "24px", value: "24px" },
                { label: "28px", value: "28px" },
                { label: "32px", value: "32px" },
            ]
        },
        titleFontWeight: {
            type: "select",
            label: "Peso de la fuente del título",
            options: [
                { label: "Normal", value: "normal" },
                { label: "Semi-negrita", value: "600" },
                { label: "Negrita", value: "bold" },
                { label: "Extra-negrita", value: "700" },
            ]
        },
        titleColor: {
            type: "select",
            label: "Color del título",
            options: textColors
        },
        titleAlign: {
            type: "radio",
            label: "Alineación del título",
            options: [
                { label: "Izquierda", value: "left" },
                { label: "Centro", value: "center" },
                { label: "Derecha", value: "right" },
            ]
        },
        backgroundColor: {
            type: "select",
            label: "Color de fondo",
            options: backgroundColors
        },
        padding: {
            type: "select",
            label: "Padding",
            options: [
                { label: "16px", value: "16px" },
                { label: "24px", value: "24px" },
                { label: "32px", value: "32px" },
                { label: "40px", value: "40px" },
            ]
        },
        borderBottom: {
            type: "select",
            label: "Borde inferior",
            options: [
                { label: "Ninguno", value: "none" },
                { label: "Fino", value: "1px solid #dee2e6" },
                { label: "Medio", value: "2px solid #dee2e6" },
                { label: "Grueso", value: "3px solid #dee2e6" },
            ]
        }
    },
    defaultProps: {
        logoSrc: "https://proacademy.app/_next/image?url=%2Fproacademy-logotipo-1.svg&w=256&q=75",
        logoAlt: "Proacademy",
        logoWidth: "250px",
        logoHeight: "auto",
        logoAlign: "left",
        showTitle: true,
        titleText: "Título del email",
        titleFontSize: "24px",
        titleFontWeight: "normal",
        titleColor: "#333333",
        titleAlign: "right",
        backgroundColor: "transparent",
        padding: "24px",
        borderBottom: "1px solid #dee2e6"
    },
    render: ({
        logoSrc,
        logoAlt,
        logoWidth,
        logoHeight,
        logoAlign,
        showTitle,
        titleText,
        titleFontSize,
        titleFontWeight,
        titleColor,
        titleAlign,
        backgroundColor,
        padding,
        borderBottom
    }: EmailHeaderProps) => {
        return (
            <table
                className={getClassName()}
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{
                    backgroundColor,
                    borderBottom,
                }}
            >
                <tr>
                    <td style={{ padding }}>
                        <table width="100%" cellPadding="0" cellSpacing="0" role="presentation">
                            <tr>
                                <td width="50%" align={logoAlign} style={{ verticalAlign: "middle" }}>
                                    <img
                                        src={logoSrc}
                                        alt={logoAlt}
                                        width={logoWidth}
                                        height={logoHeight}
                                        style={{
                                            display: "block",
                                            border: "0",
                                            maxWidth: "100%",
                                        }}
                                    />
                                </td>
                                {showTitle && (
                                    <td width="50%" align={titleAlign} style={{ verticalAlign: "middle" }}>
                                        <h1
                                            className={getClassName("title")}
                                            style={{
                                                margin: "0",
                                                fontSize: titleFontSize,
                                                color: titleColor,
                                                fontFamily: "'Arial', sans-serif",
                                                fontWeight: titleFontWeight,
                                            }}
                                        >
                                            {titleText}
                                        </h1>
                                    </td>
                                )}
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        );
    },
}; 