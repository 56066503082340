import { Flex, Text } from "@chakra-ui/react"
import { OnboardingForm } from "./components/OnboardingForm"
import CampusScreen from "./components/CampusScreen"
import { useEffect, useState } from "react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { updateCampusConfig } from "../../shared/middlewares/config.middleware";
import { useTenantInfo } from "../../shared/hooks/useTenantInfo";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../shared/context/client.context";
import { useData } from "../../shared/hooks/useData";
import { EndpointTypes } from "../../shared/utils/Types/EndpointTypes";

export const Onboarding = () => {
  const { handleSetInfo } = useTenantInfo();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const client = useClient();
  const navigate = useNavigate();

  const { data, Refresh } = useData({
    endpoint: EndpointTypes.INFO_TENANT,
    client: client,
  });

  useEffect(() => {
    if (data) {
      data?.icono && handleSetInfo({ key: "icono", value: data?.icono })
    }
  }, [data])

  const initialValues = {
    nombre: data?.nombre,
    senderName: null,
    replyTo: null,
    moduloFUNDAE: false,
    colores: {
      main: "#0F1122",
      secondary: "#8694FF",
      variant: "#C7CDFF",
    },
  };

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().notRequired().nullable(),
    senderName: Yup.string().required('Campo requerido'),
    replyTo: Yup.string().required('Campo requerido'),
    moduloFUNDAE: Yup.boolean().notRequired().nonNullable(),
  });


  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    const campusConfig = {
      timeZone: timeZone,
      senderName: values.senderName,
      replyTo: values.replyTo,
      moduloFUNDAE: values.moduloFUNDAE,
      colores: {
        main: values.colores.main,
        secondary: values.colores.secondary,
        variant: values.colores.variant,
      },
    };

    updateCampusConfig({
      data: campusConfig,
      client: client,
    })
      .then(async () => {
        handleSetInfo({ key: "produccion", value: true });

        if (campusConfig.colores) {
          handleSetInfo({ key: 'colores', value: campusConfig.colores as any });
        } else {
          data?.colores && handleSetInfo({ key: "colores", value: data?.colores })
        }

        navigate('/cursos');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Flex
      minH={'100vh'}
      px={{ lg: "60px", xl: "120px" }}
      py={{ lg: "0", xl: "150px" }}
      pt={{ lg: "50px", xl: "100px" }}
      gap="60px"
      justifyContent="center"
      alignItems="start"
      overflow="hidden"
    >
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const { handleSubmit, values } = formik;

          return (
            <Flex w="100%">
              <FormikForm style={{ width: '100%' }} onSubmit={handleSubmit}>
                <Flex
                  gap="60px"
                >
                  <Flex
                    direction="column"
                    gap="30px"
                    w="70%"
                  >
                    <Text
                      color="pureblack"
                      fontSize="28px"
                      fontWeight="600"
                    >
                      Previsualización
                    </Text>

                    <CampusScreen
                      mainColor={values.colores.main}
                      secondaryColor={values.colores.secondary}
                      variantColor={values.colores.variant}
                    />
                  </Flex>

                  <Flex
                    direction="column"
                    w="30%"
                  >
                    <OnboardingForm
                      formik={formik}
                      data={data}
                      isLoading={isLoading}
                      Refresh={Refresh}
                    />
                  </Flex>
                </Flex>
              </FormikForm>
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  )
}

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`;
