const filterSelectStyles = {
    container: (styles: any, { isDisabled, isFocused }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        zIndex: isFocused ? "999" : "0",
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '7px',
        backgroundColor: 'transparent',
        borderColor: "light_grey",
        padding: "1px 0",
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontWeight: 'medium',
        color: '#000',
        backgroundColor: isFocused ? '#CCC' : '#FFF',
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "dark_grey",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
};

export { filterSelectStyles };